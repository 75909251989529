import React from "react";

interface ITimerProps  {
  thickness: number;
  precision: number;
  progress: number;
  updateEveryMilliseconds: number;
  fontSize: number;
  text: string;
  secondaryText: string;
  primaryColor: string;
  secondaryColor: string;
  style?: React.CSSProperties;
  radius: number;
  stroke: "butt" | "round" | "square" | "inherit";
}

export default class Timer extends React.Component<ITimerProps, {}> {

  static defaultProps: ITimerProps = {
    progress: 0,
    text: "",
    secondaryText: "",
    thickness: 8,
    fontSize: 16,
    precision: 1000,
    updateEveryMilliseconds: 1000,
    primaryColor: "#005AAB",
    secondaryColor: "#D8D9DB",
    radius: 240,
    stroke: "round",
  };

  polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians)),
    };
  }

  describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {

    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const d = [
      "M", start.x, start.y,
      "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
    ].join(" ");

    return d;
  }

  calculateParams(props: ITimerProps) {
    const viewboxHeight = props.precision;
    const viewboxWidth = props.precision;
    const thickness = props.thickness * props.precision / 100;
    const fontSize = props.fontSize * props.precision / 100;

    const arcRadius = this.props.radius;

    const centerX = viewboxWidth / 2;
    const centerY = viewboxHeight / 2;

    const emptyRadius = 360 - arcRadius;
    const halfEmptyRadius = emptyRadius / 2;
    const startAngle = halfEmptyRadius - 180;
    const endAngle = 180 - halfEmptyRadius;
    const secondaryEndAngle = (arcRadius * Math.min(Math.max(props.progress, 0), 1))
     - 180 + halfEmptyRadius;

    const radius = Math.min(viewboxWidth, viewboxHeight) / 2 - thickness;

    return {
      arcPath: this.describeArc(centerX, centerY, radius, startAngle, endAngle),
      secondaryArcPath: this.describeArc(centerX, centerY, radius, startAngle, secondaryEndAngle),
      viewboxWidth,
      viewboxHeight,
      thickness,
      fontSize,
      time: "00:00:00",
    };
  }

  render() {
    const {
      thickness,
      viewboxHeight,
      viewboxWidth,
      arcPath,
      secondaryArcPath,
      fontSize,
    } = this.calculateParams(this.props);

    const { stroke } = this.props;

    return (
      <svg viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}>
        <path strokeLinecap={stroke} stroke={this.props.secondaryColor} fill="none"
          strokeWidth={thickness} d={arcPath} shapeRendering="geometricPrecision" />
        <path strokeLinecap={stroke} stroke={this.props.primaryColor} fill="none"
          strokeWidth={thickness} d={secondaryArcPath} shapeRendering="geometricPrecision" />
        <text x="50%" y="50%" dominantBaseline="middle" fontSize={fontSize}
          textAnchor="middle">
          {this.props.text}
        </text>
        <text x="50%" y="65%" dominantBaseline="middle" fontSize={fontSize * 0.4}
          textAnchor="middle">
          {this.props.secondaryText}
        </text>
      </svg>
    );
  }
}
