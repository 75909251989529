import React, { createRef, useState } from "react";
import { Dialog, Flex, Image, Button, Text, Input, Loader, } from "@fluentui/react-northstar";
import { inject, observer } from "mobx-react";
import { UserStore } from "../../../store/user";
// import logo from "../../../assests/images/logo.png";
import defaultProfile from "../../../assests/images/defaultProfile.png";
import { useTranslation } from 'react-i18next';
import { LogAttachment, LogSource, LogType, Result } from "../../../models";
import { Icon, TextField } from "@fluentui/react";
import { AcceptIcon, AddIcon, UserFriendsIcon } from '@fluentui/react-icons-northstar'
import { UpdateCustomStatus, Log, AddLogAttachment, addLabeledFaceDescriptors, getUserInfo } from "../../../api/account";
import CurrentStatus from "../../../components/currentStatus";
import { loadLabeledFaceDescriptors, loadModels } from "../../../utils/faceSystem";
import * as faceapi from 'face-api.js';



declare const baseUrl: string;

export default (inject("user"))(observer(({ user }: { user: UserStore }) => {
  const ref = createRef<HTMLInputElement>();

  const { t } = useTranslation();

  const [file, setFile] = useState<any>(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [statusInputText, setStatusInputText] = useState(user.currentCustomStatusText);
  const [BugDialogOpen, setBugDialogOpen] = useState(false);
  const [bugReport, setBugReport] = useState("");
  const [bugFiles, setBugFiles] = useState([] as File[])
  const [loading, setLoading] = useState(false);


  const handleImageChange = (event: React.FormEvent<HTMLInputElement>) => {
    setLoading(true);
    const f = (event.target as any).files[0];
    setFile(f);
    setLoading(false);
  };

  const saveImage = async () => {
    // const userOld = await getUser(user.userId);
    setLoading(true);
    var input: any = {
      image: file,
      // companyId: userOld.payload.company?.id,
      // countryId: userOld.payload.country?.id,
      // title: userOld.payload.title,
      // firstName: userOld.payload.firstName,
      // lastName: userOld.payload.lastName,
      // fullName: userOld.payload.fullName,
      // phoneNumber: userOld.payload.phoneNumber,
      // role: userOld.payload.role,
      // weekends: userOld.payload.weekends,
      // workingHours: userOld.payload.workingHours,
      // workplaceConfig: userOld.payload.workplaceConfig,
      // supervisorIds: []
    }
    try {
      await user.updateProfile(input);
      // const labeledFaceDescriptors: any[] = await loadLabeledFaceDescriptors(user.userFaceImage?.path, user.firstName);
      // const serializedLabeledFaceDescriptors = JSON.stringify(labeledFaceDescriptors)
      // var test = addLabeledFaceDescriptors({
      //   labeledFaceDescriptors: serializedLabeledFaceDescriptors,
      //   imageId: user.userFaceImage?.id as number
      // })
      // console.log(test)

    } catch (error: any) {
      console.log("error: ", error);
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    }
    setLoading(false);
    setFile(null);
  };

  const handleBugChange = (e: any) => {
    setBugReport(e.target.value);
    //console.log(bugReport);
  };

  const handleFileChange = (e: any) => {
    var files: File[] = []
    for (var i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i])
    }
    console.log("files", files)
    setBugFiles(files);
  };

  const handleBugSubmit = async () => {
    console.log(bugReport);
    try {
      const newLog = await Log({
        message: bugReport,
        logType: LogType.BugReport,
        attachments: bugFiles,
        logSource: LogSource.Request,
        clientUserAgent: ""
      });
      console.log(newLog.payload)

    } catch (error: any) {
      console.log("error: ", error);
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    }
    setBugDialogOpen(false)

  };

  const dialogs = (
    <>
      <Dialog
        open={file && true}
        header={t("Upload Image")}
        cancelButton={t("Cancel")}
        confirmButton={loading ? <Loader /> : t("Save")}
        onCancel={() => setFile(null)}
        onConfirm={async () => {
          console.log("heyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy")
          await saveImage()
          var updatedUser = await getUserInfo();
          console.log(updatedUser.payload.userFaceImage);
          console.log("hiiiiiii",user.userFaceImage?.path)

          const MODEL_URL = '../../../models/';
          await faceapi.loadSsdMobilenetv1Model(MODEL_URL);
          await faceapi.loadFaceLandmarkModel(MODEL_URL);
          await faceapi.loadFaceRecognitionModel(MODEL_URL);

          const labeledFaceDescriptors: any[] = await loadLabeledFaceDescriptors(updatedUser.payload.userFaceImage.path, user.firstName);
          const serializedLabeledFaceDescriptors = JSON.stringify(labeledFaceDescriptors)

          console.log("hiiiiiii",labeledFaceDescriptors)
          var test = await addLabeledFaceDescriptors({
            labeledFaceDescriptors: serializedLabeledFaceDescriptors,
            imageId: updatedUser.payload.userFaceImage.id as number
          })
          console.log(test)
        }} content={
          <Image fluid src={file && URL.createObjectURL(file)} />
        } />
      <Dialog
        open={confirmationDialogOpen}
        header={t("Delete Image")}
        cancelButton={t("Cancel")}
        confirmButton={loading ? <Loader /> : t("Yes")}
        content={("Are you sure you want to remove your profile image?")}
        onCancel={() => setConfirmationDialogOpen(false)}
        onConfirm={() => {
          setLoading(true);
          setConfirmationDialogOpen(false);
          user.removeImage();
          setLoading(false);
        }} />
      <Dialog
        styles={{ maxWidth: "500px", justifyContent: "center", alignContent: "center" }}
        open={BugDialogOpen}
        header={t("Report a bug")}
        cancelButton={t("Cancel")}
        confirmButton={t("Submit")}
        onConfirm={handleBugSubmit}
        content={
          <Flex column gap="gap.medium" hAlign="stretch"
            styles={{ width: "100%" }}>
            <TextField
              style={{ width: '435px', height: '100px' }}
              multiline
              onChange={handleBugChange}
            />
            <Input type="file" onChange={handleFileChange} multiple />
          </Flex>
        }
        onCancel={() => setBugDialogOpen(false)}
      // onConfirm = {() => {
      //   setBugDialogOpen(false);
      //   //apicall
      // }}

      />
    </>
  );

  const handleChange = (e: any) => {
    setStatusInputText(e.target.value);
    //console.log(statusInputText);
  };


  const handleSubmit = async () => {
    //request call to update current....
    console.log(statusInputText);
    try {
      await UpdateCustomStatus({
        currentCustomStatus: statusInputText,
      });
      user.currentCustomStatusText = statusInputText;
    } catch (error: any) {
      console.log("error: ", error);
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    }
    console.log(user.currentCustomStatusText)
  };

  const clear = async () => {
    //request call to update current....
    try {
      setStatusInputText("");
      await UpdateCustomStatus({
        currentCustomStatus: statusInputText,
      });
      user.currentCustomStatusText = "";
    } catch (error: any) {
      console.log("error: ", error);
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    }
  };


  return (

    <>
      {dialogs}
      <Flex column styles={{ alignItems: "center", justifyContent: "center" }}
        padding="padding.medium">
        <Flex column gap="gap.medium">
          <Text><Text weight="semibold">{t("Title-")}</Text>{user.title}</Text>
          <Text><Text weight="semibold">{t("Name-")}</Text>{user.fullname}</Text>
          <Text><Text weight="semibold">{t("Company-")}</Text>{user.companyName}</Text>

          <CurrentStatus
            user={user}
            semibold={true}
            asDialog={false}
          ></CurrentStatus>

          <input ref={ref} onChange={handleImageChange} type="file" style={{ display: "none" }}
            accept="image/*" />
          {loading ? <Loader /> :
            <Image src={user.imageUrl ? baseUrl + user.imageUrl : defaultProfile}
              styles={{
                width: "300px",
                height: "300px",
                backgroundColor: "lightgrey",
                objectFit: "cover",
              }} />
          }
          <Button fluid primary onClick={() => ref.current?.click()}>
            {t("Edit")}
          </Button>
          <Button fluid onClick={() => setConfirmationDialogOpen(true)}>
            {t("Delete")}
          </Button>
          <Button fluid onClick={() => setBugDialogOpen(true)}>
            {t("Report a bug")}
          </Button>
        </Flex>
      </Flex>

    </>

  );
}));
