import React from "react";

import {
  Flex,
  Divider,
  Menu,
  Grid,
  MenuBehaviorProps,
  tabListBehavior,
} from "@fluentui/react-northstar";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { UserStore } from "../../store/user";
import Holidays from './tabs/holidays';
import Leaves from './tabs/leaves';
import Balance from './tabs/balance';
import * as AccountApi from "../../api/account";
import { withTranslation } from "react-i18next";

type State = {
  currentTab: number | string;
  userName: string;
};

type Props = {
  t: any;
  user: UserStore;
  match: { params: { id?: string } };
};

@inject("user")
@observer
class HolidaysAndLeaves extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      currentTab: 0,
      userName: "",
    };
  }

  componentDidMount() {
    this.loadData();
  }

  get userId() {
    return this.props.match.params.id ?? this.props.user.userId;
  }

  loadData = async () => {
    const { payload: user } = await AccountApi.getUser(this.userId)
    if (user)
      this.setState({ userName: user.fullName })
  };

  getCurrentTab = (index: number | string) => {

    switch (index) {
      case 0:
      case "0":
        return <Leaves />;
      case 1:
      case "1":
        return <Balance />;
      case 2:
      case "2":
        return <Holidays />;
      default:
        return <div />;
    }
  };

  render() {
    const { t, user } = this.props

    return (
      <>
        {/* {dialogs} */}
        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
          <div
            className="mainHeader" style={{ color: user.companyPrimaryColor ?? "#005bab" }}
          >{t("Holidays & Leaves")} ({this.state.userName})</div>
        </Flex>
        <Divider />
        <Grid style={{ width: "100%", gridTemplateColumns: "auto auto 1fr" }}>
          <Menu pointing vertical activeIndex={this.state.currentTab}
            accessibility={(props: MenuBehaviorProps) => { return tabListBehavior() }}
            defaultActiveIndex={0}
            onActiveIndexChange={(e, i) => this.setState({ currentTab: i?.activeIndex ?? 0 })}
            styles={{ width: "200px" }}
            items={[
              {
                key: "leaves",
                content: t("Leaves"),
              },
              {
                key: "balance",
                content: t("Balance"),
              },
              {
                key: "holidays",
                content: t("Holidays")
              },
            ]} />
          <Divider vertical />
          {
            this.getCurrentTab(this.state.currentTab)
          }
        </Grid>
      </>
    );
  }
}

export default withRouter(withTranslation()(HolidaysAndLeaves as any) as any);
