import React from "react";
import { Flex, FlexItem, Menu, Divider, Button } from "@fluentui/react-northstar";
import { withRouter } from "react-router-dom";
import { UserStore } from "../../store/user";
import { inject, observer } from "mobx-react";
import { Separator } from "@fluentui/react";
import { getUser } from "../../api/account";
import Timesheet from "./index";
import Weeklyschedule from "./weeklyschedule";
import { withTranslation } from "react-i18next";
import { UserView } from "../../models";

type Props = {
  match: {
    params: {
      id: string;
    };
  };
  t: any;
  user: UserStore;
};

interface IState {
  currentTab: number | string;
  userInView?: UserView;
}

@inject("user")
@observer
class UserDetailsTabs extends React.Component<Props, IState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      currentTab: 0
    };
  }

  get id() {
    return this.props.match.params.id ?? this.props.user.userId;
  }

  componentDidMount(): void {
    this.fetch()
  }
  fetch = async () => {
    const user = await getUser(this.id);
    this.setState({userInView : user.payload})
    console.log(user.payload)
  }

  render() {
    const { t, match, user } = this.props;
    const { userInView } = this.state
    return (
      <Flex styles={{ height: "100%" }} column>
        <Flex  gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
        <div
            className="mainHeader" style={{ color: user.companyPrimaryColor ?? "#005bab" }}
          >
            {this.id ? <><></><br /><>{userInView ? userInView.fullName : ""}</></> : <></>}
          </div>
        </Flex>
        
        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
            <Menu 
            onActiveIndexChange={(e, i) => this.setState({ currentTab: i?.activeIndex ?? 0 })}
            activeIndex={this.state.currentTab}
            styles ={{height :"45px" , overflow :"hidden"}}
            items={ 
              [
                {
                  key: "timsheet",
                  content: t('Timesheet')
                },
                {
                  key: "weeklyscheduleview",
                  content: t('Weekly Schedule')
                }
          
              ]}
              ></Menu>
          
        </Flex>
        {(function (i) {
              switch (i) {
                case 0:
                case "0":
                  return <Timesheet />;
                case 1:
                case "1":
                  return <Weeklyschedule  />;
              }
            })(this.state.currentTab)}

      </Flex> 
      
    );
  }
}

export default withRouter(withTranslation()(UserDetailsTabs as any) as any);
