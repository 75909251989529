import React, { CSSProperties } from "react";
import { Flex, FlexItem, Menu, Divider, Grid, Text, ComponentSlotStyle } from "@fluentui/react-northstar";
import { Link, withRouter } from "react-router-dom";

import { UserStore } from "../../store/user";
import { inject, observer } from "mobx-react";
import { Icon, Separator } from "@fluentui/react";
import { withTranslation } from "react-i18next";
import Clockings from "./tabs/Clockings";
import Shifts from "./tabs/Shifts";
import Leaves from "./tabs/Leaves";
import Custom from "./tabs/Custom";
import UsersStatuses from "./tabs/UsersStatuses";
import Icons from "../../assests/images/SVGIcons/svgIcons";


type Props = {
  user: UserStore;
  match: { params: { id?: string } };
  history: any;
  t: any;
};

interface IState {
  currentTab: number | string;
}

@inject("user")
@observer
class Reports extends React.Component<Props, IState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      currentTab: 0
    };
  }


  render() {
    const { t, user } = this.props;
    const gridItemStyle : ComponentSlotStyle = { color: user.companyPrimaryColor?? "#005bab", fill: user.companyPrimaryColor?? "#005bab", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: '50px', marginBottom: '50px', borderRadius: '4px', minWidth: '100px', padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' } //box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    const svgIconStyle: CSSProperties = { width: '22px', height: '28.56px' }
    const iconStyle: CSSProperties = { fontSize: '20px' }
    return (
      <Flex styles={{ height: "100%" }} column>
        
        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
          <div
          className="mainHeader" style={{color: user.companyPrimaryColor?? "#005bab"}}
          >{t('Reports')}</div>
        </Flex>
        <Divider/>
        <Grid styles={{ marginTop: '20px', marginRight: '50px' }} content={
          [
            <Link to={`/reports/Clockings`}>
              <Flex styles={gridItemStyle}>
                <Icons.ClockIn style={svgIconStyle}/>
                <Text>{t("Clockings")}</Text>
              </Flex>
            </Link>,
            <Link to={`/reports/Shifts`}>
              <Flex styles={gridItemStyle}>
                <Icon iconName="TimeEntry" style={iconStyle}></Icon>
                <Text>{t("Shifts")}</Text>
              </Flex>
            </Link>,
            <Link to={`/reports/Leaves`}>
              <Flex styles={gridItemStyle}>
                <Icon iconName="Vacation" style={iconStyle}></Icon>
                <Text>{t("Leaves")}</Text>
              </Flex>
            </Link>,
            <Link to={`/reports/UsersStatuses`}>
              <Flex styles={gridItemStyle}>
                <Icon iconName="PlannerLogo" style={iconStyle}></Icon>
                <Text>{t("Users Statuses")}</Text>
              </Flex>
            </Link>,
            // <Link to={`/reports/Overtime`}>
            //   <Flex styles={gridItemStyle}>
            //     <Icon iconName="AwayStatus" style={iconStyle}></Icon>
            //     <Text>{t("Overtime")}</Text>
            //   </Flex>
            // </Link>,
            // <Link to={`/reports/LeavesBalance`}>
            //   <Flex styles={gridItemStyle}>
            //     <Icon iconName="CompanyDirectory" style={iconStyle}></Icon>
            //     <Text>{t("Leaves Balance")}</Text>
            //   </Flex>
            // </Link>
          ]
        }></Grid>

        {/* <Flex styles={{ height: "100%" }}> 
          <Menu pointing vertical
            defaultActiveIndex={0}
            onActiveIndexChange={(e, i) => this.setState({ currentTab: i?.activeIndex ?? 0 })}
            styles={{ width: "200px", minWidth: "200px" }}
            items={[
              {
                key: "clockings",
                content: t('Clockings')
              },
              {
                key: "shifts",
                content: t('Shifts')
              },
              {
                key: "leaves",
                content: t('Leaves')
              },
              {
                key: "statuses",
                content: t('Statuses')
              },
              // {
              //   key: "custom",
              //   content: t('Custom')
              // }
            ]} />
          <Separator vertical />
          <FlexItem grow>
            {(function (i) {
              switch (i) {
                case 0:
                case "0":
                  return <Clockings />;
                case 1:
                case "1":
                  return <Shifts />;
                case 2:
                case "2":
                  return <Leaves />;
                case 3:
                case "3":
                  return <UsersStatuses />;
                case 4:
                case "4":
                  return <Custom />;
              }
            })(this.state.currentTab)}
          </FlexItem>
        </Flex> */}
     
      </Flex>
    );
  }
}

export default withRouter(withTranslation()(Reports as any) as any);
