import React from 'react';
import { Flex, FlexItem, Divider, Button,
    Text,
    CloseIcon,
    Checkbox,
    AddIcon,
    Loader,
    Alert,
    Dialog, } from "@fluentui/react-northstar";
import { ShimmeredDetailsList, CommandBar, IDetailsGroupRenderProps, GroupHeader, TextField, DatePicker, DetailsListLayoutMode, IGroup, } from "@fluentui/react";
import { HolidayConfigInput, HolidayConfigView, HolidayInput, HolidaysConfigView, Result } from "../models";
import { UserStore } from "../store/user";
import { inject, observer } from "mobx-react";
import * as HolidaysApi from "../api/holidays";
import { isNullOrWhiteSpace, shadeBlend } from '../utils';

interface IProps {
    companyId: number;
    user: UserStore;
    t: any;
};

interface IState {
    loading: boolean;
    columnNames: any[];
    items: HolidayConfigView[];
    count: number;
    offset: number;
    options: any;
    groups: any[];
    holidaysConfigPayload: HolidaysConfigView;
    createHolidayDialogOpen: boolean;
    holidaysInput: (HolidayInput 
        & { dateParsed: Date }
        )[];
    holidayInputError?: string;
}


@inject("user")
@observer
class HolidaysConfig extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const { t } = props;
        this.state = {
            loading: false,
            count: 5,
            offset: 0,
            holidaysConfigPayload: {
                holidayConfigInputList: [],
                countriesCount: 0,
                holidaysCount: 0
            },
            columnNames: ["", t('Country'), t('Date')],
            options: {
                filter: true,
                filterType: "dropdown",
                responsive: true,
            },
            items: [],
            groups: [],
            createHolidayDialogOpen: false,
            holidaysInput: [{
                description: "",
                name: "",
                defaultDate: new Date().toJSON().split('T')[0],
                dateParsed: new Date(),
                id: 0,
            }]
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        const { companyId } = this.props
        this.setState({ loading: true, items: [], groups: [], offset: 0 });

        const { payload } = await HolidaysApi.getHolidaysConfig(companyId);
        await this.tableDataGenerator(payload.holidaysCount, payload.countriesCount, payload.holidayConfigInputList)
        
        this.setState({
            loading: false,
            holidaysConfigPayload: payload            
        });
    };

    tableDataGenerator = async (holidaysCount: number, countriesCount: number, holidayConfigInputList : HolidayConfigView[]) => {
        const { t } = this.props
        let body : HolidayConfigView[] = [];
        let groups : IGroup[] = [];

        let currentHoliday = 0;
        holidayConfigInputList.map( holidayConfig => {
            if (currentHoliday !== holidayConfig.holidayId){
                currentHoliday = holidayConfig.holidayId

                groups.push({ 
                    key: holidayConfig.holidayId.toString(),
                    name: t(holidayConfig.holiday.name),
                    startIndex: body.length,
                    count: countriesCount,
                    level: 0,
                    isCollapsed: true
                })
            }
            body.push(holidayConfig)
            return holidayConfig
        })
        this.setState({items : body, groups})
    }

    submit = async () => {
        this.setState({loading : true})
        var itemsToUpdate : HolidayConfigInput[] = [];
        this.state.items.forEach( item => {
            if (item && (item.id > 0 || item.enabled)){
                if (item.date){
                    item.date = new Date(item.date);
                    item.date.setHours(item.date.getHours() - (item.date.getTimezoneOffset() / 60))
                }
                var inputItem : HolidayConfigInput = {
                    id : item.id,
                    enabled : item.enabled,
                    date : item.date.toJSON().split('T')[0],
                    companyId : item.companyId,
                    holidayId : item.holidayId,
                    countryId : item.countryId,
                    company : item.company,
                    holiday : item.holiday,
                    country : item.country
                } 
                itemsToUpdate.push(inputItem)
            }
        });

        if (itemsToUpdate){
            const { payload } = await HolidaysApi.updateHolidaysConfig({
                companyId : this.props.companyId,
                holidayConfigInputList : itemsToUpdate
            })

            await this.tableDataGenerator(payload.holidaysCount, payload.countriesCount, payload.holidayConfigInputList)
        
            this.setState({
                loading: false,
                holidaysConfigPayload: payload            
            });
        }
    }

    createHolidays = async () => {
        const { t, companyId } = this.props
        this.setState({loading : true})
        if (this.state.holidaysInput.some(x => isNullOrWhiteSpace(x.name))) {
            this.setState({ holidayInputError: t("Holiday name is mandatory") });
            return;
        } else {
            this.setState({ holidayInputError: undefined });
        }
        try {
            await HolidaysApi.createHolidays({ companyId, holidayInputList: this.state.holidaysInput});
            this.fetchData();
            this.setState({
                createHolidayDialogOpen: false, holidaysInput: [{
                    description: "",
                    name: "",
                    defaultDate: new Date().toJSON().split('T')[0],
                    dateParsed: new Date(),
                    id: 0,
                }]
            });
        } catch (error) {
            var res = error as Result<never>;
            this.setState({ holidayInputError: t(res.errors[0].description) })
        }
        this.setState({loading : false})
    };

    _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
        return <GroupHeader {...props} />;
    };

    render() {
        const {
            loading,
            items,
            groups,
        } = this.state;

        const { t, user } = this.props

        const dialogs = <>
        <Dialog 
            open={this.state.createHolidayDialogOpen}
            confirmButton={loading? <Loader/> : t("Submit")}
            cancelButton={t("Cancel")}
            onConfirm={!loading ? this.createHolidays : undefined} 
            onCancel={() => this.setState({ createHolidayDialogOpen: false })}
            header={t("Add Holidays")}
            headerAction={(
                <Button fluid icon={<AddIcon />} iconOnly
                    onClick={() => this.setState((state) => {
                        state.holidaysInput.push({
                            description: "",
                            name: "",
                            defaultDate: new Date().toJSON().split('T')[0],
                            dateParsed: new Date(),
                            id: 0,
                        });
                        return state;
                    })} />
            )} content={(
                <Flex column gap="gap.large" padding="padding.medium"
                    styles={{ maxHeight: "300px", overflowY: "auto" }}>
                    {this.state.holidayInputError &&
                        <Alert danger content={this.state.holidayInputError} />}
                    {this.state.holidaysInput.map((x, $i) => (
                        <Flex key={$i} column gap="gap.small">
                            <Flex>
                                <FlexItem grow>
                                    <Divider content={t("Holiday")} />
                                </FlexItem>
                                {$i !== 0
                                    && (
                                        <Button iconOnly icon={<CloseIcon />} text onClick={() => this.setState((state) => {
                                            state.holidaysInput.splice($i, 1);
                                            return state;
                                        })} />
                                    )}
                            </Flex>
                            <TextField label={t("Name")} value={x.name} required validateOnFocusOut
                                onChange={(e, v) => this.setState((state) => {
                                    x.name = v ?? x.name;
                                    return state;
                                })} />
                            <DatePicker
                                isRequired
                                value={x.dateParsed} 
                                onSelectDate={(v) => {
                                    this.setState((state) => {
                                        x.dateParsed = v ?? new Date();
                                        if (v){
                                            v = new Date(v)
                                            v.setHours(v.getHours() - (v.getTimezoneOffset() / 60))
                                            x.defaultDate = v ? v.toJSON().split('T')[0] : new Date().toJSON().split('T')[0];
                                        }
                                        return state;
                                    })
                                }} label={t("Date")} />
                            <TextField label={t("Description")} multiline value={x.description}
                                onChange={(e, v) => this.setState((state) => {
                                    x.description = v ?? x.description;
                                    return state;
                                })} />
                        </Flex>
                    ))}
                </Flex>
            )}
        />
            {/* <Dialog 
                    open={this.state.createLeaveDialogOpen}
                    header={t("Create leave type")}
                    confirmButton={t("Submit")}
                    onConfirm={() => this.createLeaveType()}
                    cancelButton={t("Cancel")} 
                    onCancel={() => this.setState({ createLeaveDialogOpen: false })}
                    content={(
                        <Flex column gap="gap.medium" 
                            hAlign="stretch"
                            styles={{ width: "100%" }}
                        >
                            <TextField 
                                placeholder={t("Leave type name")}
                                onChange={(e, props) => this.setState({ newLeaveTypeName : props?? ""})} />
                        </Flex>
                    )} 
            /> */}
        </>

        const submitButton = (<Button
                primary
                disabled={loading}
                styles={{ margin: "0px 10px 0px 10px"}}
                onClick={() => {
                    this.submit()
                }}
            >
                {loading? <Loader/> : t("Submit")}
            </Button>)

        return (
            <Flex column >
                {dialogs}
                <CommandBar 
                    items={[]}
                    farItems={
                        [
                            {
                                key: "Add Holidays",
                                text: t("Add Holidays"),
                                iconProps: { iconName: "add" },
                                buttonStyles: {
                                    icon: { color: user.companyPrimaryColor },
                                    iconHovered: { color: shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) },
                                    },
                                onClick: () => this.setState({createHolidayDialogOpen: true}),
                            },
                            {
                                key: "submit",
                                onRender: () => <Flex vAlign="center">{submitButton}</Flex>
                            }
                        ]
                    }
                />
                <Divider />
                <ShimmeredDetailsList
                    selectionMode={0}
                    layoutMode={DetailsListLayoutMode.justified}
                    detailsListStyles={{ root: { width: "100%", overflowY: "auto" } }}
                    enableShimmer={loading}
                    items={items}
                    
                    groups={groups}
                    groupProps={{
                        showEmptyGroups: true,
                        onRenderHeader:this._onRenderGroupHeader,
                        isAllGroupsCollapsed: true
                    }}
                    columns={[
                        {
                            key: "enabled",
                            name: "",
                            onRender: (item: HolidayConfigView) => {
                                return (
                                    <Flex styles={{height:"100%"}} hAlign="center">
                                        <Checkbox
                                            title={t("Enabled")}
                                            checked={item.enabled}
                                            onChange={(ev, props) => {
                                                item.enabled = props?.checked?? false;
                                                this.setState({})
                                            }}
                                        />
                                    </Flex>
                                )
                            },
                            minWidth: 20,
                            maxWidth: 30
                        },
                        {
                            key: "Country",
                            name: t("Country"),
                            onRender: (item: HolidayConfigView) => (<Flex styles={{height:"100%"}} vAlign="center"><Text>{item.country.name}</Text></Flex>),
                            minWidth: 100,
                            maxWidth: 150,
                        },
                        {
                            key: "date",
                            name: t("Date"),
                            onRender: (item: HolidayConfigView) => (<Flex vAlign="center">
                                {/* {item.date.toString()} */}
                                <DatePicker
                                    value={new Date(item.date)} onSelectDate={(v) => this.setState((state) => {
                                        item.date = v ?? new Date();
                                        return state;
                                    })} />
                                </Flex>),
                            minWidth: 60,
                            maxWidth: 100,
                        },
                    ]}
                    
                    
                />
                {/* <Flex hAlign="end">
                    {submitButton}
                </Flex> */}
            </Flex>
        );
    }
}

export default HolidaysConfig;