import React, { CSSProperties } from 'react';
import {
    Flex, Button, Divider, Dropdown,
    Text,
    ExcelColorIcon,
    CloseIcon,
    Layout,
    FilesPdfColoredIcon,
    Loader,
} from "@fluentui/react-northstar";
import { ShimmeredDetailsList, IColumn, IDetailsGroupRenderProps, GroupHeader, Icon, Spinner } from "@fluentui/react";
import { withRouter } from 'react-router-dom';
// import Pagination from "react-paginating";
import { Pagination } from "@fluentui/react-experiments/lib/Pagination";
import { ShiftReportView, UsersStatusesReportView, UserStatus } from "../../../models";

import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import * as ReportsApi from "../../../api/reports";
import * as AccountApi from "../../../api/account";
import * as LeaveApi from "../../../api/leaves";
import { RowInput } from 'jspdf-autotable'
// @ts-ignore
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle'
import './Shifts.scss'
import dateformat from 'dateformat';
import moment from 'moment';
import { dayStatusColor } from '../../../assests/styles/colors';
import Icons from '../../../assests/images/SVGIcons/svgIcons';
import { getBranches, getDepartments } from '../../../api/Company';
import { getCountries, getUsersCountries } from '../../../api/Country';
import ReportsFilter from '../../../components/reportsFilter';

interface IProps {
    user: UserStore;
    match: { params: { id?: string } };
    history: any;
    t: any;
};

interface IState {
    loading: boolean;
    today: Date;
    columnNames: any[];
    items: any[];
    columns: IColumn[];
    count: number;
    offset: number;
    options: any;
    groups: any[];
    users: any[];
    dateFrom: Date;
    dateTo: Date;
    shiftsPayload: ShiftReportView[];
    selectedUsers: any[];
    selectedPageIndex: number;
    users_: any[];
    branches: any[];
    departments: any[];
    countries: any[];
    selectedDepartments: any[];
    selectedBranches: any[];
    selectedCountries: any[];
    showFilters: boolean;
    ANDOR: boolean;
    loggedInUser: any,
    selectedDepartmentNames: any[],
    selectedBrancheNames: any[],
    selectedCountryNames: any[],
    searchMethod: string;
}

@inject("user")
@observer
class UsersStatuses extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        var { t } = props;
        this.state = {
            loading: false,
            today: new Date(Date.now()),
            users: [],
            selectedUsers: [],
            count: 5,
            offset: 0,
            shiftsPayload: [],
            columnNames: [],
            searchMethod: "AND",
            loggedInUser: "",
            ANDOR: true,
            showFilters: false,
            items: [],
            users_: [],
            selectedDepartments: [],
            branches: [],
            departments: [],
            countries: [],
            selectedCountries: [],
            selectedBranches: [],
            selectedDepartmentNames: [],
            selectedBrancheNames: [],
            selectedCountryNames: [],
            options: {
                filter: true,
                filterType: "dropdown",
                responsive: true,
            },
            columns: [],
            groups: [],
            dateFrom: new Date(Number(new Date()) + 24 * 3600 * 1000 * -7),
            dateTo: new Date(new Date().setHours(23)),
            selectedPageIndex: 0
        }
    }

    componentDidMount() {
        // this.setState({today : Date.now()})
        this.fetchData();
    }

    get userId() {
        return this.props.match.params.id ?? this.props.user.userId;
    }

    setColumns = async () => {
        const { t } = this.props
        var columns = [{
            key: 'names',
            name: t('Names'),
            onRender: (item: any, index: any, column: any) => {
                // console.log(item)
                return <Flex vAlign='center' fill>{item.user.fullName}</Flex>
            },
            // item.totalDurations ? 
            // <Text styles={{ fontWeight: "bold"}} > {t("Total hours")} </Text> 
            // :
            // dateformat(new Date(item.clockInTime), 'ddd, mmm dS, yyyy'), //new Date(item.clockInTimeUtc).toISOString().split('T')[0],
            minWidth: 100,
            maxWidth: 150,
        }];
        var diff = Math.abs(this.state.dateFrom.getTime() - this.state.dateTo.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        const svgIconStyle: CSSProperties = { width: '11px', height: '14.2px' }
        for (var i = 0; i < diffDays; i++) {
            var date = new Date(Number(this.state.dateFrom) + 24 * 3600 * 1000 * i);
            var currentIndex = i.valueOf()
            columns.push({
                key: Number(date).toString(),
                name: dateformat(date, 'ddd mmm dS'),
                onRender: (item: any, index: any, column: any) => {
                    if (item && item.days) {
                        var findResult = item.days.find((d: any) => d.dateRef == column.key)
                        if (findResult) {
                            var { status, clockInTimes, clockOutTimes, leaveRequestView } = findResult 
                            if (status) {
                                var backgroundColor = dayStatusColor.weekendWithOpacity
                                var text = "Week Day Off"
                                switch (status) {
                                    case UserStatus[UserStatus.Available]:
                                        backgroundColor = dayStatusColor.normalWithOpacity
                                        text = "Available"
                                        break
                                    case UserStatus[UserStatus.Abscent]:
                                        backgroundColor = dayStatusColor.abscentWithOpacity
                                        text = "Abscent"
                                        break
                                    case UserStatus[UserStatus.Leave]:
                                    case UserStatus[UserStatus.SickLeave]:
                                        backgroundColor = dayStatusColor.leaveWithOpacity
                                        text = "Leave"
                                        break
                                    case UserStatus[UserStatus.Holiday]:
                                        backgroundColor = dayStatusColor.holidayWithOpacity
                                        text = "Holiday"
                                        break
                                    case UserStatus[UserStatus.Weekend]:
                                        backgroundColor = dayStatusColor.weekendWithOpacity
                                        text = "Week Day Off"
                                        break
                                    case UserStatus[UserStatus.WorkingHoursNotFulfilled]:
                                        backgroundColor = dayStatusColor.workingHoursNotFulfilledWithOpacity
                                        text = "Hours Not Fulfilled"
                                        break
                                    case UserStatus[UserStatus.HalfDayLeave]:
                                        //backgroundColor = dayStatusColor.workingHoursNotFulfilledWithOpacity
                                        text = "Half Day Leave"
                                        break
                                    default:
                                        backgroundColor = dayStatusColor.weekendWithOpacity
                                        text = "Future"
                                        break
                                }
                                if (status == UserStatus.HalfDayLeave) {
                                    //   var leaves = await (await LeaveApi.getLeaveRequestsPerUser(item.user.id)).payload.leaveRequests
                                    //   leaves.map(leave => 
                                    //     {if(leave.fromHalfDay == true)
                                    //         {
                                    //           console.log("hi")  
                                    //         }})
                                    if (leaveRequestView.fromHalfDay != undefined) {
                                        if (leaveRequestView.fromHalfDay == true) {
                                            return <Flex column >
                                                <Flex vAlign='center' hAlign='center'
                                                    fill style={{
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                        backgroundColor: dayStatusColor.normalWithOpacity,
                                                    }}>
                                                    {clockInTimes && clockOutTimes && clockInTimes.length > 0 && clockOutTimes.length > 0 ?
                                                        <div>
                                                            {dateformat(clockInTimes[0], "HH:MM")}
                                                            <Icons.ClockIn style={svgIconStyle} />
                                                            {"->"}
                                                            {clockOutTimes[clockOutTimes.length - 1] ? dateformat(clockOutTimes[clockOutTimes.length - 1], "HH:MM") : ""}
                                                            <Icons.ClockOut style={svgIconStyle} />
                                                        </div> : <div>{t("Not Clocked ")}</div>}
                                                </Flex>
                                                <Flex vAlign='center' hAlign='center' fill style={{
                                                    padding: '5px',
                                                    borderRadius: '5px',
                                                    backgroundColor: dayStatusColor.leaveWithOpacity,
                                                }}>{text}</Flex>
                                            </Flex>
                                        }
                                        else {
                                            return <Flex column >
                                                <Flex vAlign='center' hAlign='center'
                                                    fill style={{
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                        backgroundColor: dayStatusColor.leaveWithOpacity,
                                                    }}>{text}</Flex>
                                                <Flex vAlign='center' hAlign='center' fill style={{
                                                    padding: '5px',
                                                    borderRadius: '5px',
                                                    backgroundColor: dayStatusColor.normalWithOpacity,
                                                }}>
                                                    {clockInTimes && clockOutTimes && clockInTimes.length > 0 && clockOutTimes.length > 0 ?
                                                        <div>
                                                            {dateformat(clockInTimes[0], "HH:MM")}
                                                            <Icons.ClockIn style={svgIconStyle} />
                                                            {"->"}
                                                            {clockOutTimes[clockOutTimes.length - 1] ? dateformat(clockOutTimes[clockOutTimes.length - 1], "HH:MM") : ""}
                                                            <Icons.ClockOut style={svgIconStyle} />
                                                        </div> : <div>{t("Not Clocked ")}</div>}
                                                </Flex>
                                            </Flex>
                                        }
                                    }

                                }
                                else {
                                    return <Flex column vAlign='center' hAlign='center' gap="gap.small" fill style={{
                                        padding: '3px',
                                        borderRadius: '5px',
                                        backgroundColor: backgroundColor,
                                        flexWrap: 'wrap',
                                    }}>
                                        <Flex vAlign='center' hAlign='center'>{text}</Flex>
                                        {clockInTimes && clockOutTimes && clockInTimes.length > 0 && clockOutTimes.length > 0 ? <Flex vAlign='center' hAlign='center' gap='gap.smaller'>
                                            {dateformat(clockInTimes[0], "HH:MM")}
                                            <Icons.ClockIn style={svgIconStyle} />
                                            {"->"}
                                            {clockOutTimes[clockOutTimes.length - 1] ? dateformat(clockOutTimes[clockOutTimes.length - 1], "HH:MM") : ""}
                                            <Icons.ClockOut style={svgIconStyle} />
                                        </Flex> : null}
                                    </Flex>
                                }
                                // return <Flex column vAlign='center' hAlign='center' gap="gap.small" fill style={{
                                //     padding: '3px',
                                //     borderRadius: '5px',
                                //     backgroundColor: backgroundColor,
                                //     flexWrap: 'wrap',
                                // }}>
                                //     <Flex vAlign='center' hAlign='center'>{text}</Flex>
                                //     {clockInTimes && clockOutTimes && clockInTimes.length > 0 && clockOutTimes.length > 0 ? <Flex vAlign='center' hAlign='center' gap='gap.smaller'>
                                //         {dateformat(clockInTimes[0], "HH:MM")}
                                //         <Icons.ClockIn style={svgIconStyle}/>
                                //         {"->"}
                                //         {clockOutTimes[clockOutTimes.length -1] ? dateformat(clockOutTimes[clockOutTimes.length -1], "HH:MM") : ""}
                                //         <Icons.ClockOut style={svgIconStyle}/>
                                //     </Flex> : null}
                                // </Flex>
                            }
                        }
                    }
                    return <></>
                },
                // item.totalDurations ? 
                // <Text styles={{ fontWeight: "bold"}} > {t("Total hours")} </Text> 
                // :
                // dateformat(new Date(item.clockInTime), 'ddd, mmm dS, yyyy'), //new Date(item.clockInTimeUtc).toISOString().split('T')[0],
                minWidth: 120,
                maxWidth: 160,
            })
        }
        this.setState({ columns })
    }

    onChangeUser(ev: any, props: any) {
        var filteredList = props.value.find((i: any) => i.key === 0);
        var allUsersSelected = filteredList ? filteredList.key === 0 : false;

        if (allUsersSelected) {
            var allUsersList = this.state.users.filter((u: any) => u.key !== 0);
            this.setState({
                selectedUsers: allUsersList
            });
        } else {
            this.setState({
                selectedUsers: [...props.value]
            });
        }
    }
    clearAllUsers() {
        this.setState({
            selectedUsers: []
        }, () => {
            this.getBranches()
            this.getDepartments()
        })
    }

    onChangeCountry = async (ev: any, props: any) => {
        var filteredList = props.value.find((i: any) => i.key === 0);
        var allCountriesSelected = filteredList ? filteredList.key === 0 : false;

        if (allCountriesSelected) {
            var allCountriesList = this.state.countries.filter((u: any) => u.key !== 0);
            this.setState({
                selectedCountries: allCountriesList,
                selectedCountryNames: allCountriesList
            }, () => {
                this.filterData()
            });
        } else {
            this.setState({
                selectedCountries: [...props.value]
            }, () => {

                var names: string[] = []
                this.state.selectedCountries.map(((selectedCountry) => {
                    names.push(selectedCountry.name)
                }))
                this.setState({ selectedCountryNames: names }, () => {
                    this.filterData()
                })
                var countryIds: any[] = []
                const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
                if (this.state.searchMethod == "AND") {
                    this.state.selectedCountries.map((country) => { countryIds.push(country.id) })
                    this.getBranchesDepartment(countryIds)
                }
            });
        }
    }
    clearAllCountries() {
        this.setState({
            selectedCountries: [],
            selectedCountryNames: []
        }, () => {
            this.filterData()
            this.getBranches()
            this.getDepartments()
        });
    }
    getBranches = async () => {
        const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
        let branchesRequest: any = await getBranches(id as number);
        if (branchesRequest && branchesRequest.payload) {
            var i;
            for (i = 0; i < branchesRequest.payload.length; i++) {
                branchesRequest.payload[i].header = branchesRequest.payload[i]['name'];
                branchesRequest.payload[i].key = branchesRequest.payload[i]['id'];
            }
            branchesRequest.payload.unshift({
                header: 'All Branches',
                key: 0
            });
            this.setState({ branches: branchesRequest.payload })
        }
    }

    getDepartments = async () => {
        const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
        let departmentsRequest: any = await getDepartments(id as number)
        if (departmentsRequest && departmentsRequest.payload) {
            var i;
            for (i = 0; i < departmentsRequest.payload.length; i++) {
                departmentsRequest.payload[i].header = departmentsRequest.payload[i]['name'];
                departmentsRequest.payload[i].key = departmentsRequest.payload[i]['id'];
            }
            departmentsRequest.payload.unshift({
                header: 'All Departments',
                key: 0
            });
            this.setState({ departments: departmentsRequest.payload })
        }
    }

    onChangeBranch(ev: any, props: any) {

        var filteredList = props.value.find((i: any) => i.key === 0);
        var allBranchesSelected = filteredList ? filteredList.key === 0 : false;

        if (allBranchesSelected) {
            var allBranchesList = this.state.branches.filter((u: any) => u.key !== 0);
            this.setState({
                selectedBranches: allBranchesList,
                selectedBrancheNames: [],
            }, () => { this.filterData() });
        } else {
            this.setState({
                selectedBranches: [...props.value]
            }, () => {

                var names: string[] = []
                this.state.selectedBranches.map(((selectedBranch) => {
                    names.push(selectedBranch.name)
                }))
                this.setState({ selectedBrancheNames: names }, () => { this.filterData() })
                var branchIds: any[] = []
                if (this.state.searchMethod == " AND") {
                    this.state.selectedBranches.map((branch) => { branchIds.push(branch.id) })
                    this.getBranchesByDepartment(branchIds)
                }
            });
        }

    }
    clearAllBranches() {
        this.setState({
            selectedBranches: [],
            selectedBrancheNames: [],
        }, () => {
            this.filterData()
             if (this.state.selectedCountries != null && this.state.searchMethod == "AND") {
                var countryIds: any[] = []
                this.state.selectedCountries.map((country) => { countryIds.push(country.id) })
                this.getBranchesDepartment(countryIds)
            }
            else {
                this.getBranches();
                this.getDepartments();
            }
        });
    }
    onChangeDepartments(ev: any, props: any) {

        var filteredList = props.value.find((i: any) => i.key === 0);
        var allDepartmentsSelected = filteredList ? filteredList.key === 0 : false;

        if (allDepartmentsSelected) {
            var allDepartmentsList = this.state.departments.filter((u: any) => u.key !== 0);
            this.setState({
                selectedDepartments: allDepartmentsList,
                selectedDepartmentNames: allDepartmentsList,
            }, () => { this.filterData() });
        } else {
            this.setState({
                selectedDepartments: [...props.value],

            }, () => {

                var names: string[] = []
                this.state.selectedDepartments.map(((selectedDep) => {
                    names.push(selectedDep.name)
                }))
                this.setState({ selectedDepartmentNames: names }, () => { this.filterData() })
            });
        }
    }

    clearAllDepartment() {
        this.setState({
            selectedDepartments: [],
            selectedDepartmentNames: [],
        }, () => { this.filterData() });
    }
    getBranchesDepartment = (countryIds: any[]) => {
        var users: any[] = []
        var branchNames: any[] = []
        var branches: any[] = []
        var departmentNames: any[] = []
        var departments: any[] = []
        countryIds.map((id) => {
            this.state.users.map((user) => {
                if (user.country != undefined) {
                    if (user.country.id == id) {
                        users.push(user);
                    }
                }
            })
        })
        users.forEach(user => {
            var branch = user.branch
            var department = user.department
            if (branch != null) {
                if (branchNames.length > 0) {
                    if (!branchNames.includes(branch.name)) {
                        branchNames.push(branch.name)
                        branches.push(branch)
                    }
                }
                else {
                    branchNames.push(branch.name)
                    branches.push(branch)
                }
            }
            if (department != null) {
                if (departmentNames.length > 0) {
                    if (!departmentNames.includes(department.name)) {
                        departmentNames.push(department.name)
                        departments.push(department)
                    }
                }
                else {
                    departmentNames.push(department.name)
                    departments.push(department)
                }
            }
        });

        for (var i = 0; i < branches.length; i++) {
            branches[i].header = branches[i]['name'];
            branches[i].key = branches[i]['id'];
        }
        branches.unshift({
            header: 'All Branches',
            key: 0
        });
        this.setState({ branches: branches })

        for (var i = 0; i < departments.length; i++) {
            departments[i].header = departments[i]['name'];
            departments[i].key = departments[i]['id'];
        }
        departments.unshift({
            header: 'All Departments',
            key: 0
        });
        this.setState({ departments: departments })
    }
    getBranchesByDepartment = (branchIds: any[]) => {
        var users: any[] = []
        var departmentNames: any[] = []
        var departments: any[] = []
        branchIds.map((id) => {
            this.state.users_.map((user) => {
                if (user.branch != undefined) {
                    if (user.branch.id == id) {
                        users.push(user);
                    }
                }
            })
        })
        users.forEach(user => {
            var department = user.department
            if (department != null) {
                if (departmentNames.length > 0) {
                    if (!departmentNames.includes(department.name)) {
                        departmentNames.push(department.name)
                        departments.push(department)
                    }
                }
                else {
                    departmentNames.push(department.name)
                    departments.push(department)
                }
            }
        });
        for (var i = 0; i < departments.length; i++) {
            departments[i].header = departments[i]['name'];
            departments[i].key = departments[i]['id'];
        }
        departments.unshift({
            header: 'All Departments',
            key: 0
        });
        this.setState({ departments: departments })
    }
    filterData() {
        this.state.searchMethod == "AND" ?
            this.setState({
                users_:
                    this.state.users.filter(u =>
                        ((this.state.selectedCountryNames.length == 0)
                            || (u.country != undefined && this.state.selectedCountryNames.includes(u.country.name)))
                        && ((this.state.selectedBrancheNames.length == 0)
                            || (u.branch != undefined && this.state.selectedBrancheNames.includes(u.branch.name)))
                        && ((this.state.selectedDepartmentNames.length == 0)
                            || (u.department != undefined && this.state.selectedDepartmentNames.includes(u.department.name)))

                    )
            }, () => { this.setState({ selectedUsers: this.state.users_ }) }) :
            this.setState({
                users_: this.state.users
            }, () => { this.setState({ selectedUsers: [] }) })
    }

    fetchData = async () => {
        const { t } = this.props
        this.setState({ loading: true, items: [], groups: [], offset: 0 });
        this.setColumns();

        // users dropdown
        if (this.state.users.length === 0) {
            let usersRequest: any = await AccountApi.GetUsers(this.props.user.company?.id ?? 0)

            var i;
            for (i = 0; i < usersRequest.payload.length; i++) {
                // usersRequest.payload[i].content = usersRequest.payload[i]['fullName'];
                usersRequest.payload[i].header = usersRequest.payload[i]['fullName'];
                usersRequest.payload[i].key = usersRequest.payload[i]['id'];
                // delete usersRequest.payload[i].key1;
            }
            usersRequest.payload.unshift({
                header: t("All Users"),
                key: 0
            });
            this.setState({ users: usersRequest.payload, users_: usersRequest.payload })
        }

        const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;

        if (this.state.departments.length == 0) {
            this.getDepartments()
        }

        if (this.state.branches.length == 0) {
            this.getBranches()
        }
        if (this.state.countries.length == 0) {
            let countriesRequest: any = await getUsersCountries(this.props.user.userId)
            if (countriesRequest && countriesRequest.payload) {
                var i;
                for (i = 0; i < countriesRequest.payload.length; i++) {
                    countriesRequest.payload[i].header = countriesRequest.payload[i]['name'];
                    countriesRequest.payload[i].key = countriesRequest.payload[i]['id'];
                }
                countriesRequest.payload.unshift({
                    header: 'All Countries',
                    key: 0
                });
                this.setState({ countries: countriesRequest.payload })
            }
        }

        var selectedUsers = this.state.selectedUsers.map(selectedUser => (selectedUser.id))
        var selectedCountries = this.state.selectedCountries.map(selectedCountry => (selectedCountry.id))
        var selectedDepartments = this.state.selectedDepartments.map(selectedDepartment => (selectedDepartment.id))
        var selectedBranches = this.state.selectedBranches.map(selectedBranch => (selectedBranch.id))

        // report data
        // const { payload } = await ReportsApi.getShiftsPerUser({
        //     userId : this.props.user.userId, 
        //     from : this.state.dateFrom, 
        //     to : this.state.dateTo, 
        //     userIds : selectedUsers.length > 0 ? selectedUsers : [this.props.user.userId]
        // });

        var from = new Date(this.state.dateFrom.getTime() - this.state.dateFrom.getTimezoneOffset() * 60 * 1000)
        var to = new Date(this.state.dateTo.getTime() - this.state.dateTo.getTimezoneOffset() * 60 * 1000)
        const { payload } = await ReportsApi.getUsersStatuses({
            userId: this.props.user.userId,
            from: from,
            to: to,
            userIds: selectedUsers,
            searchMethod: this.state.searchMethod,
            departmentIds: selectedDepartments,
            branchIds: selectedBranches,
            countryIds: selectedCountries
        });
        await this.tableDataGenerator(payload)
        this.setState({
            loading: false,
            // shiftsPayload: payload            
        });
    };

    tableDataGenerator = async (payload: UsersStatusesReportView[]) => {
        // var {t} = this.props
        var diff = Math.abs(this.state.dateFrom.getTime() - this.state.dateTo.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        let body: any[] = [];
        let groups: any[] = [];

        let currentUser = "";
        let currentUserIndex = -1;
        let shiftsCountPerUser = "";
        let firstUser = true;
        payload.map((userStatus, i) => {
            if (currentUser !== userStatus.user.id) {
                // if (!firstUser){
                // let row : RowInput = {
                //     totalDurations: true,
                //     totalWorkingDuration: payload[i - 1].totalWorkingDuration,
                //     totalBreaksDuration: payload[i - 1].totalBreaksDuration,
                //     totalOvertimeDuration: payload[i - 1].totalOvertimeDuration

                // };
                //     body.push(row)
                // }

                // firstUser = false
                currentUser = userStatus.user.id
                currentUserIndex++
                // shiftsCountPerUser = payload.reduce(function(total,x){return x.user.id === currentUser ? total+1 : total}, 0);

                // var currentlyUnavailable = payload.some(shift => {
                //     return shift.user.id && shift.isApproved && new Date(shift.startDate) <= new Date() && new Date(shift.endDate) >= new Date()
                // });

                // groups.push({ key: shift.user.id, 
                //     name: shift.user.fullName, 
                //     startIndex: body.length, 
                //     count: shiftsCountPerUser + 1, 
                //     level: 0 })
                body.push({ user: userStatus.user, days: [] as any[] })
            }
            if (userStatus) {
                // console.log(body, currentUserIndex)
                var dateRef = Number(new Date(Number(this.state.dateFrom) + 24 * 3600 * 1000 * body[currentUserIndex].days.length)).toString();
                var { status, clockInTimes, clockOutTimes, totalWorkHours, overtimeHours, date, leaveRequestView } = userStatus
                body[currentUserIndex].days.push({ status, clockInTimes, clockOutTimes, totalWorkHours, overtimeHours, date, dateRef, leaveRequestView })

                // let row : RowInput = { ...userStatus};
                // body.push(row)
            }
            return userStatus
        })

        // last user total durations
        // if(payload.length > 0){
        //     var shift = payload[payload.length - 1]
        //     let row : RowInput = {
        //         totalDurations: true,
        //         totalWorkingDuration: shift.totalWorkingDuration,
        //         totalBreaksDuration: shift.totalBreaksDuration,
        //         totalOvertimeDuration: shift.totalOvertimeDuration
        //     };
        //     body.push(row)
        // }

        this.setState({ items: body })
    }

    _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
        // if (props?.group?.level == 0) {
        //   return (
        //       <Flex styles={{
        //           backgroundColor: user.companyPrimaryColor,
        //           color: 'white',
        //           fontSize: '16px',
        //           fontWeight: 'bold',
        //           borderRadius: '3px',
        //           padding: '10px 50px'
        //       }} padding='padding.medium' hAlign='start'>{`${props.group!.name}`}</Flex>
        //   );
        // }

        return <GroupHeader {...props} />;
    };

    exportReport = async (format: string) => {
        var selectedUsers = this.state.selectedUsers.map(selectedUser => (selectedUser.id))
        var selectedCountries = this.state.selectedCountries.map(selectedCountry => (selectedCountry.id))
        var selectedDepartments = this.state.selectedDepartments.map(selectedDepartment => (selectedDepartment.id))
        var selectedBranches = this.state.selectedBranches.map(selectedBranch => (selectedBranch.id))
        this.setState({ loading: true })
        var from = new Date(this.state.dateFrom.getTime() - this.state.dateFrom.getTimezoneOffset() * 60 * 1000)
        var to = new Date(this.state.dateTo.getTime() - this.state.dateTo.getTimezoneOffset() * 60 * 1000)
        await ReportsApi.exportUsersStatuses({
            userId: this.props.user.userId,
            from: from,
            to: to,
            userIds: selectedUsers,
            searchMethod: this.state.searchMethod,
            departmentIds: selectedDepartments,
            branchIds: selectedBranches,
            countryIds: selectedCountries
        }, format)
        this.setState({ loading: false })

        // const type = response.headers['content-type']
        // const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        // const link = document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.download = 'file.xlsx'
        // link.click()
    }

    onDateChange = (time: any, type: string) => {
        const timeSelected = new Date(time)
        timeSelected.setHours(0)
        if (type === 'from') {
            timeSelected.setHours(0)
            this.setState({ dateFrom: timeSelected })
        }
        else {
            timeSelected.setHours(19)
            this.setState({ dateTo: timeSelected })
        }
    }

    setShowFilters() {
        this.setState({
            showFilters: !this.state.showFilters
        })
    }
    setSearchMethod = (event: any) => {
        var searchMethod = event.target.value == undefined ? "AND" : event.target.value
        this.setState({ searchMethod: searchMethod }, () => { this.filterData() })
    }
    render() {
        const {
            loading,
            columns,
            items,
            count,
            offset,
            groups,
        } = this.state;

        const { t, user } = this.props

        return (
            <Flex column gap="gap.medium" padding="padding.medium" fill>
                <ReportsFilter
                    users={this.state.users}
                    users_={this.state.users_}
                    selectedUsers={this.state.selectedUsers}
                    onChangeUser={this.onChangeUser.bind(this)}
                    clearAllUsers={this.clearAllUsers.bind(this)}
                    dateFrom={this.state.dateFrom}
                    dateTo={this.state.dateTo}
                    onDateChange={this.onDateChange.bind(this)}
                    loading={this.state.loading}
                    exportReport={this.exportReport.bind(this)}
                    fetchData={this.fetchData.bind(this)}
                    setShowFilters={this.setShowFilters.bind(this)}
                    showFilters={this.state.showFilters}
                    countries={this.state.countries}
                    selectedCountries={this.state.selectedCountries}
                    selectedCountryNames={this.state.selectedCountryNames}
                    onChangeCountry={this.onChangeCountry.bind(this)}
                    clearAllCountries={this.clearAllCountries.bind(this)}
                    branches={this.state.branches}
                    selectedBranches={this.state.selectedBranches}
                    selectedBranchNames={this.state.selectedBrancheNames}
                    onChangeBranches={this.onChangeBranch.bind(this)}
                    clearAllBranches={this.clearAllBranches.bind(this)}
                    departments={this.state.departments}
                    selectedDepartments={this.state.selectedDepartments}
                    selectedDepartmentNames={this.state.selectedDepartmentNames}
                    onChangeDepartments={this.onChangeDepartments.bind(this)}
                    clearAllDepartments={this.clearAllDepartment.bind(this)}
                    searchMethod={this.state.searchMethod}
                    setSearchMethod={this.setSearchMethod.bind(this)}
                >
                </ReportsFilter>
                <Divider />
                <ShimmeredDetailsList
                    selectionMode={0}
                    detailsListStyles={{ root: { width: "100%" } }}
                    enableShimmer={loading}
                    items={items}
                    columns={columns}
                // groups={groups.slice(offset, Math.min(offset + count, groups.length))}
                // groupProps={{
                //     showEmptyGroups: true,
                //     onRenderHeader:this._onRenderGroupHeader,
                // }}
                />
                {/* <Pagination
                    selectedPageIndex={this.state.selectedPageIndex}
                    pageCount={Math.ceil((10 ?? 0) / (10 ?? 15))}
                    // itemsPerPage={notification?.notificationsPageCount}
                    // totalItemCount={notification?.notificationsTotalCount?? 0}
                    format='buttons'
                    previousPageAriaLabel={'previous page'}
                    nextPageAriaLabel={'next page'}
                    firstPageAriaLabel={'first page'}
                    lastPageAriaLabel={'last page'}
                    pageAriaLabel={'page'}
                    firstPageIconProps={{ iconName: user?.rtl ? 'DoubleChevronRight' : 'DoubleChevronLeft' }}
                    previousPageIconProps={{ iconName: user?.rtl ? 'ChevronRight' : 'ChevronLeft' }}
                    nextPageIconProps={{ iconName: user?.rtl ? 'ChevronLeft' : 'ChevronRight' }}
                    lastPageIconProps={{ iconName: user?.rtl ? 'DoubleChevronLeft' : 'DoubleChevronRight' }}
                // onPageChange={onPageChange}
                /> */}
                <Divider />
                <Flex padding="padding.medium" gap="gap.large" hAlign="end">
                    <Layout
                        gap="8px"
                        start={(
                            <div style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: dayStatusColor.normal,
                            }} />
                        )}
                        main={t("Available")} />
                    <Layout
                        gap="8px"
                        start={(
                            <div style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: dayStatusColor.workingHoursNotFulfilled,
                            }} />
                        )}
                        main={t("Hours Not Fulfilled")} />
                    <Layout
                        gap="8px"
                        start={(
                            <div style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: dayStatusColor.abscent,
                            }} />
                        )}
                        main={t("Absent")} />
                    <Layout
                        gap="8px"
                        start={(
                            <div style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: dayStatusColor.leave,
                            }} />
                        )}
                        main={t("Leave")} />
                    <Layout
                        gap="8px"
                        start={(
                            <div style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: dayStatusColor.holiday,
                            }} />
                        )}
                        main={t("Holiday")} />
                    <Layout
                        gap="8px"
                        start={(
                            <div>
                                <div style={{
                                    width: "20px",
                                    height: "10px",
                                    backgroundColor: dayStatusColor.normal,
                                }} />
                                <div style={{
                                    width: "20px",
                                    height: "10px",
                                    backgroundColor: dayStatusColor.leave,
                                }} />
                            </div>
                        )}
                        main={t("Half Day Leave")} />

                    <Layout
                        gap="8px"
                        start={(
                            <div style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: dayStatusColor.weekend,
                            }} />
                        )}
                        main={t("Week Day Off")} />
                </Flex>
            </Flex>
        );
    }
}

export default withRouter(withTranslation()(UsersStatuses as any) as any);