import React from "react";
import { Box, Divider, Flex } from "@fluentui/react-northstar";

export type FooterProps = {
  content?: JSX.Element[] | JSX.Element | string;
  children?: JSX.Element[] | JSX.Element | string;
};

function Footer ({ content, children }: FooterProps) {
  return (
    <Box styles={(vars) => ({ backgroundColor: vars.theme.siteVariables.colors.grey[50] })}>
      <Divider styles={{ padding: "0px" }} />
      <Flex padding="padding.medium" styles={{ justifyContent: "space-around" }}>
        {content || children}
      </Flex>
    </Box>
  );
}

export default Footer