import React, { useState, FormEvent } from "react";
import { Flex, Segment, Button, Dialog, EyeSlashIcon, EyeIcon, Box } from "@fluentui/react-northstar";
import { TextField } from "@fluentui/react";
import { useLocation } from "react-router-dom";
import { resetPassword } from "../../api/account";
import { Error } from "../../models";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const ResetPassword = function() {

  const [email, setEmail] = useState("");
  const [newPassword, setPassword] = useState("");
  const [newPasswordVerify, setPasswordVerify] = useState("");
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);
  const [error, setError] = useState("");
  const query = useQuery();
  const [success, setSuccess] = useState(false);
  
  const submit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await resetPassword({
        email,
        newPassword,
        newPasswordVerify,
        token: query.get("token") ?? "",
      
      });
      setSuccess(true);
    } catch (data : any) {
      const errors = data.errors as Error[];
      setError(errors[0]?.description ?? "Failed to reset password");
    }
  };

  return (
    <>
      <Dialog open={success} header="Success" confirmButton={{ content: "Ok" }}
        onConfirm={() => window.location.href = "/"} />
      <Flex vAlign="center" hAlign="center">
        <Segment color="brand" styles={{
          width: "80%",
          maxWidth: "350px",
        }}>
          <form onSubmit={submit}>
            <Flex gap="gap.medium" column>

              <TextField type="text" placeholder="Email"
                label="Email" onChange={(e, value) => setEmail(value ?? "")}
                errorMessage={error} />

              <TextField type={showNewPass ? "text" : "password"} placeholder="New Password"
                label="New Password" 
                onChange={(e, value) => setPassword(value ?? "")} 
                onRenderSuffix={() => (
                  <Box
                    styles={{ cursor: "pointer" }}
                    onClick={() => setShowNewPass( !showNewPass )}>
                    {showNewPass ? <EyeSlashIcon /> : <EyeIcon />}
                  </Box>
                )}/>

              <TextField type={showRePass ? "text" : "password"} placeholder="Re-enter New Password"
                label="Re-enter New Password"
                onChange={(e, value) => setPasswordVerify(value ?? "")} 
                onRenderSuffix={() => (
                  <Box
                    styles={{ cursor: "pointer" }}
                    onClick={() => setShowRePass( !showRePass )}>
                    {showRePass ? <EyeSlashIcon /> : <EyeIcon />}
                  </Box>
                )}/>

              <Button type="submit" primary fluid>
                Reset Password
              </Button>

            </Flex>
          </form>
        </Segment>
      </Flex>
    </>
  );
}

export default ResetPassword