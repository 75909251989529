import { observable, action, IObservableArray, computed } from "mobx";
import { RootStore } from './rootStore'
import moment from "moment";
import * as AccountApi from "../api/account";
import * as AttendanceApi from "../api/attendance";
import {
  LoginInput, ClockingType, ClockingInput, ProfileInput, ShiftView, DaySummaryView, UserCompanyView, WorkplaceConfig, UserAttendanceImageConfig, UserShiftScheduleType,UserFaceImage, UserFaceRecognitionConfig
} from "../models";
// import tokenStore from "./token";
import logo from "../assests/images/logo.png";





export class UserStore {
  rootStore: RootStore;
  @observable userId = "";
  @observable isLoggedIn = false;
  @observable company: UserCompanyView | null = null;
  @observable username = "";
  @observable title = "";
  @observable firstName = "";
  @observable lastName = "";
  @observable fullname = "";
  @observable linkedInUrl="";
  @observable birthDate=moment(new Date());
  @observable currentStatus = "";
  @observable currentCustomStatusText = "";
  @observable allowedActions: ClockingType[] = [];
  @observable currentShift?: ShiftView;
  @observable today?: DaySummaryView;
  @observable loading = true;
  @observable now = moment(new Date());
  @observable incrementWorkingTimer = 0;
  @observable incrementBreakTimer = 0;
  // @observable workingHours = 0.5;
  @observable currentWorkingDuration = 0.5;
  @observable role: string = "";
  @observable isSupervisor: boolean = false;
  @observable imageUrl?: string;
  @observable attendanceImageConfig : UserAttendanceImageConfig = UserAttendanceImageConfig.InheritedFromCompany;
  @observable workplaceConfig : WorkplaceConfig = WorkplaceConfig.CompanyOrUserSpecific;
  @observable isGCCCountry: boolean = false;
  @observable shiftScheduleType : UserShiftScheduleType = UserShiftScheduleType.InheritedFromCompany;
  @observable userFaceRecognitionConfig : UserFaceRecognitionConfig = UserFaceRecognitionConfig.InheritedFromCompany
  @observable locale : string = 'en'
  @observable rtl = false
  @observable firstLogin = false
  @observable userFaceImage?:UserFaceImage;
  @observable faceRecognitionModelsLoaded : boolean = false;
  @observable labeledFaceDescriptors : any[] = [];

  // eslint-disable-next-line max-len
  @observable companyLogo = logo;
  @observable companyPrimaryColor? : string = "#005bab";
  @computed get companyName() {
    return this.company?.name ?? "";
  }
  @computed get companyLocales() {
    return this.company?.locales ?? "";
  }

  // constructor() {
  //   if (tokenStore.token) {
  //     this.getUserInfo();
  //   } else {
  //     this.refreshToken().then(res => {
  //       if (res.status === 200){
  //         this.getUserInfo();
  //       } else {
  //         this.loading = false;
  //       }
  //     })
  //   }
  // }

  @computed get token() {
    return this.rootStore.token ? this.rootStore.token.token : undefined;
  }

  constructor(rootStore : RootStore) {
    this.rootStore = rootStore;
  }

  @action
  init = async () => {
    this.rootStore.firstLoad = false
    if (this.token) {
      await this.getUserInfo();
    } else {
        await this.refreshToken().then( async res => {
            if (res.status === 200){
              await this.getUserInfo();
            } else {
              this.loading = false;
            }
        })
    }
  }

  @computed
  get isTimeRunning() {
    return this.currentShift && !this.currentShift.hasEnded;
  }

  @computed
  get isBreakTimeRunning() {
    return this.currentShift && this.currentShift.breaks.filter((x) => !x.hasEnded).length > 0;
  }

  // old
  @computed
  get workingTime() {
    if (this.currentShift && !this.currentShift.hasEnded) {
      const start = moment(this.currentShift.clockInTime);
      if (!this.isBreakTimeRunning)
        return moment.duration(this.now.diff(start)).subtract(this.breaksTime);
    }
    return this.currentShift
      ? moment.duration(this.currentShift.workingDuration) : moment.duration(0);
  }

  // @computed
  // get workingTime() {
  //   // if (this.currentShift && !this.currentShift.hasEnded) {
  //   if (this.isTimeRunning) {
  //     return moment.duration(this.currentShift?.workingDuration).add(this.incrementWorkingTimer, 'seconds');
  //   }
  //   return this.currentShift
  //     ? moment.duration(this.currentShift.workingDuration) : moment.duration(0);
  // }

  // old
  @computed
  get totalWorkingTime() {
    var total = this.today ? moment.duration(this.today.workingTime) : moment.duration(0);

    if (this.today && this.currentShift && !this.currentShift.hasEnded){
      const start = moment(this.currentShift.clockInTime);
      const activeBreak = this.currentShift.breaks.filter((x) => !x.hasEnded)[0];
      if (!activeBreak){
        total.subtract(this.currentShift.workingDuration)
        total.add(this.now.diff(start)).subtract(this.currentShift.breaksDuration)
      }
    }

    return total;
  }

  // @computed
  // get totalWorkingTime() {
  //   const total = this.today ? moment.duration(this.today.workingTime) : moment.duration(0);

  //   // if (this.currentShift && !this.currentShift.hasEnded) {
  //   if (this.isTimeRunning){
  //     total.add(this.incrementWorkingTimer, 'seconds')
  //   }

  //   return total;
  // }

  // old
  @computed
  get breaksTime() {
    const time = this.currentShift ? moment.duration(this.currentShift.breaksDuration) : moment.duration(0);
    const activeBreak = this.currentShift && this.currentShift.breaks.filter((x) => !x.hasEnded)[0];
    if (this.currentShift && activeBreak) {
      const start = moment(activeBreak.breakInTime);
      time.add(moment.duration(this.now.diff(start)));
    }
    return time;
  }

  // @computed
  // get breaksTime() {
  //   const time = this.currentShift ? moment.duration(this.currentShift.breaksDuration) : moment.duration(0);
  //   if (this.isBreakTimeRunning) {
  //     time.add(this.incrementBreakTimer, 'seconds')
  //   }
  //   return time;
  // }

  // old
  @computed
  get totalBreaksTime() {
    const total = this.today ? moment.duration(this.today.breaksTime) : moment.duration(0);
    // if (this.today) console.log("breaksTime: ", total)

    if (this.today && this.today.shifts.length > 0) total.add(this.breaksTime);
    // if (this.today && this.today.shifts.length > 0) console.log("adding: ", this.breaksTime, " totalBreaksTime: ", total)

    if (this.currentShift) total.subtract(this.currentShift.breaksDuration);
    // if (this.currentShift) console.log("subtracting: ", this.currentShift.breaksDuration, " totalBreaksTime: " , total)

    return total;
  }

  // @computed
  // get totalBreaksTime() {
  //   const total = this.today ? moment.duration(this.today.breaksTime) : moment.duration(0)

  //   if (this.isBreakTimeRunning){
  //     total.add(this.incrementBreakTimer, 'seconds')
  //   }

  //   return total;
  // }

  @action
  async login(input: LoginInput) {
    this.loading = true
    const data = await AccountApi.login(input);
    if (data) {
      this.rootStore.token.token = data.payload.accessToken;
      // tokenStore.refreshToken = data.payload.refreshToken;
      this.firstLogin = data.payload.firstLogin;
      this.isLoggedIn = true;
      await this.getUserInfo();
    }
    this.loading = false;
    return data;
  }

  @action
  async refreshToken() {
    const data = await AccountApi.refreshToken();
    if (data && data.status === 200) {
      this.rootStore.token.token = data.payload.accessToken;
    }
    return data;
  }

  @action
  async logout(withRequest: boolean = true) {
    let res
    if (this.token && withRequest)
      res = await AccountApi.logout();
    if (res || !withRequest) {
      this.rootStore.token.token = null;
      this.company = null;
      this.isLoggedIn = false;
      this.role = "";
      this.imageUrl="";
      this.companyPrimaryColor = "#005bab"
      this.companyLogo = logo;
      return new Promise<void>((resolve) => setTimeout(() => resolve(), 1000));
    }
  }

  // async faceRecognitionPrep() {
  //   if(!this.faceRecognitionModelsLoaded)
  //   {
  //     await loadModels()
  //     this.faceRecognitionModelsLoaded = true;
  //   }
  //   if(this.userFaceImage?.path)
  //   {
  //     console.log("loading descriptors")
  //     this.labeledFaceDescriptors = await loadLabeledFaceDescriptors(this.userFaceImage?.path,this.firstName);
  //     //this.userFaceImage = info.payload.userFaceImage;
  //   }
  // }

  @action
  async getUserInfo() {
    try {

      //this.faceRecognitionPrep()
      const info = await AccountApi.getUserInfo();

      console.log(info.payload);
      if (info.status === 200) {
        this.locale = info.payload.locale?? "en";
        this.userId = info.payload.id;
        this.company = info.payload.company;
        //this.workingHours = info.payload.workingHours;
        this.currentWorkingDuration = info.payload.currentWorkingDuration
        this.role = info.payload.role;
        this.attendanceImageConfig = info.payload.userRules.attendanceImageConfig;
        this.workplaceConfig = info.payload.userRules.workplaceConfig;
        this.isLoggedIn = true;
        this.username = info.payload.userName;
        this.currentStatus = info.payload.currentStatus;
        this.currentCustomStatusText = info.payload.currentCustomStatusText ;
        this.title = info.payload.title;
        this.firstName = info.payload.firstName;
        this.lastName = info.payload.lastName;
        this.fullname = info.payload.fullName;
        this.imageUrl = info.payload.imageUrl;
        this.isSupervisor = info.payload.isSupervisor;
        this.isGCCCountry = info.payload.country ? info.payload.country.isGcc?? false : false;
        this.shiftScheduleType = info.payload.userRules.shiftScheduleType;
        this.userFaceImage = info.payload.userFaceImage;
        this.userFaceRecognitionConfig = info.payload.userRules.faceRecognitionConfig;
       

        if (info.payload.company && info.payload.company.imageUrl) {
          this.companyLogo = info.payload.company.imageUrl;
        }
        if (info.payload.company && info.payload.company.primaryColor) {
          this.companyPrimaryColor = info.payload.company.primaryColor;
        } else {
          this.companyPrimaryColor = "#005bab"
        }
        await this.getStatus();


      }
      // else {
      //   this.isLoggedIn = false;
      // }
      this.loading = false;
    } catch (error) {
      console.log(error)
      this.loading = false;
    }

  }

  @action
  async getStatus() {
    const status = await AttendanceApi.getStatus();
    if (status.status == 200) {
      (this.allowedActions as IObservableArray).replace(status.payload.allowedActions);
      this.currentShift = status.payload.currentShift;
      this.incrementWorkingTimer = 0
      this.incrementBreakTimer = 0

      const today = await AttendanceApi.getClockings(this.userId, new Date().toJSON().split('T')[0], new Date().toJSON().split('T')[0]);

      this.today = today.payload.results[0];
    }
  }

  @action
  async updateProfile(input: ProfileInput) {
    const result = await AccountApi.updateProfile(input);
    if (result && result.payload) {
      this.imageUrl = result.payload.imageUrl;
    }
  }

  @action
  async removeImage() {
    const result = await AccountApi.removeImage();
    if (result && result.payload) {
      this.imageUrl = undefined;
    }
  }


  @action
  async clock(type: ClockingType, input: ClockingInput) {
    const d = new Date();
    const n = d.getTimezoneOffset();
    input.time = d;
    input.hoursOffset = n / -60;
    await AttendanceApi.clock(type, input);
  }

}

// export default (window as any).userStore = new UserStore();
