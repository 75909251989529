import React, { createRef } from "react";
import { Flex, Text, Button, FlexItem, Divider } from "@fluentui/react-northstar";
import { TextField, Dropdown, ComboBox, MessageBar, MessageBarType,DatePicker } from "@fluentui/react";
import { withRouter } from "react-router-dom";
import {
  RequestInput,
  RequestModel,
  RequestStatus,
  Priority,
  UserView,
} from "../../models";
import { getStatuses,getRequest ,addRequest,getPriorities} from "../../api/Request";
import { inject,observer } from "mobx-react";
import { GetUsers} from "../../api/account";
import { UserStore } from "../../store/user";
interface IState {
    
  statuses:RequestStatus[];
  priorities:Priority[];
  input: RequestInput;
  requests: RequestModel[];
  success: boolean;
   users:UserView[];
   disabled:boolean;
}

interface IProps {
  match: {params: {id?: number}};
  history: any;
  user: UserStore;
}
@inject("user")
@observer
class AddRequest extends React.Component<IProps, IState> {
  ref: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      statuses:[],
      disabled:false,
      users:[],
      priorities:[],
      success: false,
      requests:[],
      input: {
        title: "",
        expectedClosedDate:new Date(),
        requestedById:this.props.user.userId,
        description: "",
        assignedUsers:[],
       statusId:0,
       priorityId:0

      },
     
    };
    this.ref = createRef<HTMLInputElement>();
  }
  get userId() {
    return this.props.match.params.id ?? this.props.user.userId;
  }
  get CompanyId() {
    return  this.props.user.company?.id ?? 0;
  }
  componentDidMount() {
    this.fetch();
  }

  get id() {
    return Number(this.props.match.params.id);
  }

  fetch = async () => {
   
    const statuses = await getStatuses();
    const priorities = await getPriorities();
    const users = await GetUsers(this.CompanyId);
    this.setState({statuses:statuses.payload});
    this.setState({priorities:priorities.payload});
    this.setState({users:users.payload});
    if (this.id) {
      const request = await getRequest(this.id);
      this.setState({
        input: {
          ...request.payload,
          assignedUsers:request.payload.assignedUsers,
          expectedClosedDate:new Date(request.payload.expectedDate),
          requestedById:"",
          statusId:request.payload.statusId,
          description:request.payload.description,
          title:request.payload.title,
          priorityId:request.payload.priorityId?? 0
        },
        disabled:true
      });
  
    }
  
  };


 
  addRequest = async () => {
    try {
      const result = await addRequest(this.state.input);
      this.setState({
        input: {
            title: "",
            expectedClosedDate:new Date(),
           requestedById:"",
           
           description: "",
           assignedUsers:[],
          statusId:0,
          priorityId:0
        },
      });

      // const id = result.payload.id ?? 0;
      this.props.history.goBack();
      
      return result.payload.id;
    } catch (error) {
      alert(error);
    }
    return undefined;
  };

//   updateCompany = async () => {
//     if (this.id) {
//       try {
//         await updateCompany(this.id, this.state.input);
//         this.setState({ success: true });
//       } catch (error) {
//         alert("Something went wrong!");
//       }
//     }
//   };

//   updateCompanyLeaves = async (id: number) => {
//     await assignLeavesToCompany(id, this.state.leavesId);
//   };

//   addOrUpdate = async () => {
//     let id = this.id;
//     if (this.id) {
//       await this.updateCompany();
//     } else {
//       id = await this.addCompany() ?? 0;
//       this.props.history.goBack();
//     }
//     if (id) await this.updateCompanyLeaves(id);
//   };

  render() {
    return (
      <Flex>
        <FlexItem grow>
          <Flex column gap="gap.large" padding="padding.medium" hAlign="center">
            {!this.id && <Text content="Add Request" size="large" weight="bold" />}
            {this.state.success && (
              <MessageBar messageBarType={MessageBarType.success}>
                Company Updated Successfully
              </MessageBar>
            )}
            <Flex column gap="gap.medium" styles={{ width: "500px", maxWidth: "100%" }}>
              <TextField disabled={this.state.disabled} required={true} label="Title" placeholder="Title"
                onChange={(ev, props) => {
                  var input = {...this.state.input}
                  input.title = props ?? ""
                  this.setState({ input })
                }}
                value={this.state.input.title} />
     
        
              <TextField disabled={this.state.disabled} type="text" label="Description" placeholder="Description"
                onChange={(ev, props) => {
                  var input = {...this.state.input}
                  input.description= props ?? ""
                  this.setState({ input })
                }}
                value={this.state.input.description} />
              

              <DatePicker disabled={this.state.disabled} disableAutoFocus minDate={new Date()}
                onSelectDate={(date) => this.setState((state) => ({
                  input: { ...state.input, expectedClosedDate: date ?? state.input.expectedClosedDate },
                }))} label="Expected Delivery Date" value={this.state.input.expectedClosedDate} />
          
              <ComboBox disabled={this.state.disabled} label="Status" placeholder="Status"
                styles={{ optionsContainerWrapper: { maxHeight: "250px" } }}
                selectedKey={this.state.input.statusId}
                options={this.state.statuses.map((x) => ({
                  key: x.id,
                  text: x.name,
                  data: x,
                }))}
                onChange={(ev, props) => {
                  var input = {...this.state.input}
                  input.statusId = props?.data.id;
                  this.setState({ input });
                }} />
              <ComboBox disabled={this.state.disabled} label="Priority" placeholder="Priority" 
                styles={{ optionsContainerWrapper: { maxHeight: "250px" } }}
                selectedKey={this.state.input.priorityId}
                options={this.state.priorities.map((x) => ({
                  key: x.id,
                  text: x.name,
                  data: x,
                }))}
                onChange={(ev, props) => {
                  var input = {...this.state.input}
                  input.priorityId = props?.data.id;
                  this.setState({ input });
                }} />
          
          
          
             
                  <Dropdown  disabled={this.state.disabled}  label="Assigned Users" placeHolder="Users"  
                    multiSelect selectedKeys={this.state.input.assignedUsers}
                    options={this.state.users.map((x) => ({
                      key: x.id,
                      text: x.fullName,
                      data: x,
                    }))}
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.assignedUsers = props?.selected
                        ? [...input.assignedUsers, props.key.toString()]
                        : input.assignedUsers.filter((x) => x !== props?.data);
                        this.setState({ input })
                      // item.weekdays = props?.data;
                      // item.weekdays = props?.data;
                    }} />
               
              



              <Button disabled={this.state.disabled} primary fluid content="Submit" onClick={this.addRequest} />
            </Flex>
          </Flex>
        </FlexItem>
        <Divider vertical />
     
      </Flex>
    );
  }
}

export default withRouter(AddRequest as any);
