import React from "react"
import { Flex, FlexItem, Menu, Divider, Button } from "@fluentui/react-northstar"
import { withRouter } from "react-router-dom"
import { UserStore } from "../../../store/user"
import { inject, observer } from "mobx-react"
import { withTranslation } from "react-i18next"
import { CompanyAddEditStore } from "../../../store/companyAddEdit"
import Schedule from "../../../components/schedule";
import { threadId } from "worker_threads"
import { Color } from "react-color"
import { DayOfWeek, TextField } from "@fluentui/react"
import { DayHours, EventActions, ProcessedEvent } from "@aldabil/react-scheduler/types"
import { Result, WeekScheduleView } from "../../../models"
import { getWeekSchedule, setWeekSchedule } from "../../../api/Company"


interface Props {
  user: UserStore;
  match: { params: { id?: string } };
  history: any;
  t: any;
};
interface Events {
  event_id: number,
  title: string,
  start: Date,
  end: Date,
  disabled?: boolean,
  editable?: boolean,
  deletable?: boolean,
  draggable?: boolean,
  color?: string
}

interface IState {
  Title: string;
  items: Events[];
  startHour: DayHours;
  endHour: DayHours;
  dayTimings: any[];
  dates: any[];
  weekschedule: WeekScheduleView;
  days: [];
  test: number;
  ifExist: boolean;
}

@observer
@inject("user")
class WeekSchedule extends React.Component<Props, IState> {

  constructor(props: Props) {
    super(props);


    const { t, user } = props;


    this.state = {
      Title: "Week Schedule                    ",
      items: [],
      startHour: 0,
      endHour: 23,
      dayTimings: [],
      dates: [
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
      ],
      weekschedule: {} as WeekScheduleView,
      test: 0,
      days: [],
      ifExist: false


    };
  }


  componentDidMount() {
    setTimeout(this.fetch, 1000);
    setTimeout(this.ChangeHeaders, 1000);
  }

  ChangeHeaders() {
    var elements = document.getElementsByClassName("rs__cell rs__header")
    for (let i = 0; i < elements.length; i++) {
      var nodes = elements[i].children
      for (let j = 0; j < nodes.length; j++) {
        if (nodes[j].tagName.toLowerCase() === "div") {
          nodes[j].children[0].remove()
          var node = nodes[j].children[0] as HTMLElement
          node.style.fontSize = "14px"
        }
      }
    }
  }

  handleConfirm = async (
    event: ProcessedEvent,
    action: EventActions
  ): Promise<ProcessedEvent> => {
    if (event.start > event.end) {
      alert("Select valid times")
      return null as unknown as Promise<ProcessedEvent>
    }
    else {
      const companyid = this.props.match.params.id ?? this.props.user.company?.id ?? 0 as number;
      if (action === "edit") {
        var dates = [...this.state.dates]
        var starthour = event.start.getHours();
        var startminute = event.start.getMinutes();
        var endhour = event.end.getHours();
        var endminute = event.end.getMinutes();
        var day = [starthour, startminute, endhour, endminute]
        dates[event.start.getDay()] = day
        this.setState({ dates: dates, items: dates })

        this.updateSchedule()
        event.color = "#BED1DF"

      }
      else if (action === "create") {
        var starthour = event.start.getHours();
        var startminute = event.start.getMinutes();
        var endhour = event.end.getHours();
        var endminute = event.end.getMinutes();

        var day = [starthour, startminute, endhour, endminute]
        var mutatabledates = [...this.state.dates] ? [...this.state.dates] : [[null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null],
        ]
        mutatabledates[event.start.getDay()] = day
        this.setState({ dates: mutatabledates, items: mutatabledates })

        var schedule = await (await getWeekSchedule(companyid)).payload
        if (schedule != undefined) {
          this.updateSchedule()
        }
        else {
          this.handleSubmit()
        }
        event.color = "#BED1DF"
      }
      return event;
    }
  };

  handleDelete = async (deletedId: string | number): Promise<string> => {

    var dates = [...this.state.dates]
    var starthour = null;
    var startminute = null;
    var endhour = null;
    var endminute = null;
    var day = [starthour, startminute, endhour, endminute]
    dates[deletedId as number] = day
    this.setState({ dates: dates, items: dates })
    this.updateSchedule()
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(deletedId as string);
      }, 1000);
    });
  };

  handleSubmit = async () => {

    const companyid = this.props.match.params.id ?? this.props.user.company?.id ?? 0 as number;
    const { t, user } = this.props
    try {

      const weekschedule = await setWeekSchedule({
        companyId: companyid as number,
        userId: null as any,
        weekStartEndHoursAndMinutes: this.state.dates,
        id: 0
      });

      if (weekschedule) {
        console.log("Schedule added successfully")
      }
      this.fetch()
    } catch (error: any) {
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    }

  }

  updateSchedule = async () => {
    const companyid = this.props.match.params.id ?? this.props.user.company?.id ?? 0 as number;
    var schedule = await getWeekSchedule(companyid)
    var scheduleId = schedule.payload.id
    try {

      const weekschedule = await setWeekSchedule({
        companyId: companyid as number,
        userId: null as any,
        weekStartEndHoursAndMinutes: this.state.dates,
        id: scheduleId
      });

      if (weekschedule) {
        console.log("Schedule updated successfully")
      }
      this.fetch()
    } catch (error: any) {
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    }
  }

  fetch = async () => {
    const companyid = this.props.match.params.id ?? this.props.user.company?.id ?? 0 as number;
    const data = await getWeekSchedule(companyid)
    var days = data.payload != undefined ? data.payload.weekStartEndHoursAndMinutes : undefined
    var days = data.payload != undefined ? data.payload.weekStartEndHoursAndMinutes : undefined
    var events: any[] = []
    var dates = days ? days : [
      [null, null, null, null],
      [null, null, null, null],
      [null, null, null, null],
      [null, null, null, null],
      [null, null, null, null],
      [null, null, null, null],
      [null, null, null, null],
    ]
    this.setState({ dates: dates })
    if (days != undefined) {
      this.setState({ ifExist: true })
      for (let i = 0; i < data.payload.weekStartEndHoursAndMinutes.length; i++) {

        var arr: any = data.payload.weekStartEndHoursAndMinutes[i]
        var starthour = arr[0]
        var startminute = arr[1]
        var endhour = arr[2]
        var endminute = arr[3]
        var date = new Date()

        var j = i == 0 ? 7 : i

        var newShift = {
          event_id: i,
          title: " ",
          start: new Date(
            new Date(new Date(new Date().setHours(starthour)).setMinutes(startminute)).setDate(
              (date.getDate() + j - date.getDay())

            )
          ),
          end: new Date(
            new Date(new Date(new Date().setHours(endhour)).setMinutes(endminute)).setDate(
              (date.getDate() + j - date.getDay())
            )
          ),
          disabled: false,
          editable: true,
          deletable: true,
          draggable: false,
          color: "#BED1DF"
        }
        events.push(newShift)

      }
      this.setState({ items: events })
    }
    else {
      this.setState({ ifExist: false })
    }
  }

  render() {
    const { t, user } = this.props;
    return (

      <FlexItem grow>
        <Flex column gap="gap.large" padding="padding.medium" hAlign="center">
          <Flex column gap="gap.medium" styles={{ minWidth: "90%" }}>
            <Schedule
              userId=""
              handleConfirm={this.handleConfirm}
              timesheet={false}
              company={true}
              allEmployees={false}
              startHour={this.state.startHour}
              endHour={this.state.endHour}
              events={this.state.items}
              resources={{}}
              direction="ltr"
              onConfirm={this.handleConfirm}
              onDelete={this.handleDelete}
              eventRenderer={(event: Events) => {
                var AMPMStart = event.start.toLocaleTimeString().split(" ")[1] == undefined?event.start.toLocaleTimeString().split(" ")[1]:event.start.toLocaleTimeString().split(" ")[1]
                var AMPMEnd = event.end.toLocaleTimeString().split(" ")[1] == undefined?event.end.toLocaleTimeString().split(" ")[1]:event.end.toLocaleTimeString().split(" ")[1]
                var startTime = event.start.toLocaleTimeString().split(":")[0] +
                  ":" + event.start.toLocaleTimeString().split(":")[1] + " "
                  + AMPMStart
                var endTime = event.end.toLocaleTimeString().split(":")[0] + ":"
                  + event.end.toLocaleTimeString().split(":")[1] + " "
                  + AMPMEnd
        
                return <div className="shifts">
                  <div style={{ backgroundColor: "rgba(211, 223, 232,0.6)", fontSize: "11px", fontWeight: "semibold", padding: "3px", marginTop: "10px" }}>
                    {startTime}-{endTime}
                  </div>
                </div>;
              }
              }
            ></Schedule>
          </Flex>

        </Flex>
      </FlexItem>


    );
  }
}

export default withRouter(withTranslation()(WeekSchedule as any) as any);

