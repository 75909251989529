import React from 'react';
import { TextField } from '@fluentui/react';
import { Dialog, Flex, Alert, ExclamationTriangleIcon, Loader } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { LeaveRequestView } from '../../models';

export type SubmitLeaveApprovalFormProps = {
    headerText: string, 
    isRequired: boolean, 
    isOpen: boolean, 
    loading: boolean, 
    commentDialogData: any, 
    commentRequiredAlert: boolean, 
    leaveRequests: LeaveRequestView[], 
    onCommentChange: (e: any, props: string | undefined) => void, 
    onConfirm: () => void, 
    onCancel: () => void
};

const SubmitLeaveApprovalForm = ({headerText, isRequired, isOpen, loading, commentDialogData, commentRequiredAlert, leaveRequests, onCommentChange, onConfirm, onCancel} : SubmitLeaveApprovalFormProps) => {
    const { t } = useTranslation()
    return (
        <Dialog
            open={isOpen}
            onConfirm={() => loading ? {} : onConfirm()}
            confirmButton={loading ? <Loader /> : t("Submit")}
            cancelButton={loading ? <Loader /> : t("Cancel")}
            onCancel={() => loading ? {} : onCancel()}
            header={t(headerText)}
            content={(
                <Flex column>
                    {(headerText === "Leave Approval" && leaveRequests.find(l => l.id === commentDialogData.leaveId)?.attachmentRequired &&
                        (leaveRequests.find(l => l.id === commentDialogData.leaveId)?.attachmentCount as number) <= 0) &&
                        <Alert warning content={
                            <>
                                <ExclamationTriangleIcon />{' '}
                            {t("This leave type requires an attachment")}
                            </>
                        } />}
                    {commentRequiredAlert ? <Alert content={t('Comment is required')} danger /> : null}
                    <TextField
                        multiline
                        value={commentDialogData.commentValue}
                        required={isRequired}
                        label={t("Comment")}
                        onChange={(e, props) => onCommentChange(e, props)}
                    />
                </Flex>
            )} />
    )
}

export default SubmitLeaveApprovalForm