import React from 'react';
import {
    Flex, Button, Divider, Dropdown,
    Text,
    ExcelColorIcon,
    CloseIcon,
    FilesPdfColoredIcon,
} from "@fluentui/react-northstar";
import { ShimmeredDetailsList, IColumn, IDetailsGroupRenderProps, GroupHeader, Icon, Spinner, thProperties } from "@fluentui/react";
import { withRouter } from 'react-router-dom';
import Pagination from "react-paginating";
import { Branch, ClockingsPerUserPerDayView, Department, UserView } from "../../../models";
import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import * as ReportsApi from "../../../api/reports";
import * as AccountApi from "../../../api/account";
import { RowInput } from 'jspdf-autotable'
// // @ts-ignore
// import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle'
import './Clockings.scss'
import dateformat from 'dateformat';
import { getBranchDepartments, getBranches, getCountryBranches, getCountryDepartments, getDepartments } from '../../../api/Company';
import { getCountries, getUsersCountries } from '../../../api/Country';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import users from '../../users';
import { Checkbox } from '@fluentui/react-northstar';
import ReportsFilter from '../../../components/reportsFilter';

interface IProps {
    user: UserStore;
    match: { params: { id?: string } };
    history: any;
    t: any;
};

interface IState {
    loading: boolean;
    columnNames: any[];
    items: any[];
    columns: IColumn[];
    count: number;
    offset: number;
    options: any;
    groups: any[];
    users: any[];
    users_: any[];
    dateFrom: Date;
    dateTo: Date;
    clockingsPayload: ClockingsPerUserPerDayView[][];
    selectedUsers: any[];
    branches: any[];
    departments: any[];
    countries: any[];
    selectedDepartments: any[];
    selectedBranches: any[];
    selectedCountries: any[];
    showFilters: boolean;
    ANDOR: boolean;
    loggedInUser: any,
    selectedDepartmentNames: any[],
    selectedBrancheNames: any[],
    selectedCountryNames: any[],
    searchMethod: string;
}


@inject("user", "ui")
@observer
class Clockings extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        var { t } = props;
        this.state = {
            searchMethod: "AND",
            loggedInUser: "",
            ANDOR: true,
            showFilters: false,
            loading: false,
            users: [],
            items: [],
            users_: [],
            selectedUsers: [],
            selectedDepartments: [],
            count: 5,
            offset: 0,
            clockingsPayload: [],
            branches: [],
            departments: [],
            countries: [],
            selectedCountries: [],
            selectedBranches: [],
            selectedDepartmentNames: [],
            selectedBrancheNames: [],
            selectedCountryNames: [],
            columnNames: [t('Day'), t('Clock Type'), t('Time'), t('Address'), t('Comment')],
            options: {
                filter: true,
                filterType: "dropdown",
                responsive: true,
            },
            columns: [
                {
                    key: "Clock Type",
                    name: t("Clock Type"),
                    onRender: (item: any) => t(item.type),
                    minWidth: 100,
                    maxWidth: 200,
                },
                {
                    key: "Time",
                    name: t("Time"),
                    onRender: (item: any) => dateformat(new Date(item.time), 'h:MM TT'), // new Date(item.time).toISOString().split('T')[1].split('.')[0],
                    minWidth: 60,
                    maxWidth: 100,
                },
                {
                    key: "Device",
                    name: t("Device"),
                    onRender: (item: any) => item.clientType ? t(item.clientType) : '',
                    minWidth: 60,
                    maxWidth: 100,
                },
                {
                    key: "Address",
                    name: t("Address"),
                    onRender: (item: any) => item.address ?? '',
                    minWidth: 350,
                    maxWidth: 600,
                },
                {
                    key: "Comment",
                    name: t("Comment"),
                    onRender: (item: any) => item.comments ?? '',
                    minWidth: 250,
                    maxWidth: 350,
                },
            ],
            groups: [],
            dateFrom: new Date(new Date().getFullYear(), 0, 1),
            dateTo: new Date()
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    get userId() {
        return this.props.match.params.id ?? this.props.user.userId;
    }


    onChangeUser(ev: any, props: any) {
        var filteredList = props.value.find((i: any) => i.key === 0);
        var allUsersSelected = filteredList ? filteredList.key === 0 : false;

        if (allUsersSelected) {
            var allUsersList = this.state.users.filter((u: any) => u.key !== 0);
            this.setState({
                selectedUsers: allUsersList
            });
        } else {
            this.setState({
                selectedUsers: [...props.value]
            });
        }
    }
    clearAllUsers() {
        this.setState({
            selectedUsers: []
        }, () => {
            this.getBranches()
            this.getDepartments()
        })
    }


    onChangeCountry = async (ev: any, props: any) => {
        var filteredList = props.value.find((i: any) => i.key === 0);
        var allCountriesSelected = filteredList ? filteredList.key === 0 : false;

        if (allCountriesSelected) {
            var allCountriesList = this.state.countries.filter((u: any) => u.key !== 0);
            this.setState({
                selectedCountries: allCountriesList,
                selectedCountryNames: allCountriesList
            }, () => {
                this.filterData()
            });
        } else {
            this.setState({
                selectedCountries: [...props.value]
            }, () => {

                var names: string[] = []
                this.state.selectedCountries.map(((selectedCountry) => {
                    names.push(selectedCountry.name)
                }))
                this.setState({ selectedCountryNames: names }, () => {
                    this.filterData()
                })
                var countryIds: any[] = []
                const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
                if (this.state.searchMethod == "AND") {
                    this.state.selectedCountries.map((country) => { countryIds.push(country.id) })
                    this.getBranchesDepartment(countryIds)
                }
            });
        }
    }

    clearAllCountries() {
        this.setState({
            selectedCountries: [],
            selectedCountryNames: []
        }, () => {
            this.filterData()
            this.getBranches()
            this.getDepartments()
        });
    }

    getBranches = async () => {
        const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
        let branchesRequest: any = await getBranches(id as number);
        if (branchesRequest && branchesRequest.payload) {
            var i;
            for (i = 0; i < branchesRequest.payload.length; i++) {
                branchesRequest.payload[i].header = branchesRequest.payload[i]['name'];
                branchesRequest.payload[i].key = branchesRequest.payload[i]['id'];
            }
            branchesRequest.payload.unshift({
                header: 'All Branches',
                key: 0
            });
            this.setState({ branches: branchesRequest.payload })
        }
    }

    getDepartments = async () => {
        const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
        let departmentsRequest: any = await getDepartments(id as number)
        if (departmentsRequest && departmentsRequest.payload) {
            var i;
            for (i = 0; i < departmentsRequest.payload.length; i++) {
                departmentsRequest.payload[i].header = departmentsRequest.payload[i]['name'];
                departmentsRequest.payload[i].key = departmentsRequest.payload[i]['id'];
            }
            departmentsRequest.payload.unshift({
                header: 'All Departments',
                key: 0
            });
            this.setState({ departments: departmentsRequest.payload })
        }
    }
    onChangeBranch(ev: any, props: any) {

        var filteredList = props.value.find((i: any) => i.key === 0);
        var allBranchesSelected = filteredList ? filteredList.key === 0 : false;

        if (allBranchesSelected) {
            var allBranchesList = this.state.branches.filter((u: any) => u.key !== 0);
            this.setState({
                selectedBranches: allBranchesList,
                selectedBrancheNames: [],
            }, () => { this.filterData() });
        } else {
            this.setState({
                selectedBranches: [...props.value]
            }, () => {

                var names: string[] = []
                this.state.selectedBranches.map(((selectedBranch) => {
                    names.push(selectedBranch.name)
                }))
                this.setState({ selectedBrancheNames: names }, () => { this.filterData() })
                var branchIds: any[] = []
                if (this.state.searchMethod == " AND") {
                    this.state.selectedBranches.map((branch) => { branchIds.push(branch.id) })
                    this.getBranchesByDepartment(branchIds)
                }
            });
        }

    }
    clearAllBranches() {
        this.setState({
            selectedBranches: [],
            selectedBrancheNames: [],
        }, () => {
            this.filterData()
            if (this.state.selectedCountries != null && this.state.searchMethod == "AND") {
                var countryIds: any[] = []
                this.state.selectedCountries.map((country) => { countryIds.push(country.id) })
                this.getBranchesDepartment(countryIds)
            }
            else {
                this.getBranches();
                this.getDepartments();
            }
        });
    }
    onChangeDepartments(ev: any, props: any) {

        var filteredList = props.value.find((i: any) => i.key === 0);
        var allDepartmentsSelected = filteredList ? filteredList.key === 0 : false;

        if (allDepartmentsSelected) {
            var allDepartmentsList = this.state.departments.filter((u: any) => u.key !== 0);
            this.setState({
                selectedDepartments: allDepartmentsList,
                selectedDepartmentNames: allDepartmentsList,
            }, () => { this.filterData() });
        } else {
            this.setState({
                selectedDepartments: [...props.value],

            }, () => {

                var names: string[] = []
                this.state.selectedDepartments.map(((selectedDep) => {
                    names.push(selectedDep.name)
                }))
                this.setState({ selectedDepartmentNames: names }, () => { this.filterData() })
            });
        }
    }

    clearAllDepartment() {
        this.setState({
            selectedDepartments: [],
            selectedDepartmentNames: [],
        }, () => { this.filterData() });
    }

    getBranchesDepartment = (countryIds: any[]) => {
        var users: any[] = []
        var branchNames: any[] = []
        var branches: any[] = []
        var departmentNames: any[] = []
        var departments: any[] = []
        countryIds.map((id) => {
            this.state.users.map((user) => {
                if (user.country != undefined) {
                    if (user.country.id == id) {
                        users.push(user);
                    }
                }
            })
        })
        users.forEach(user => {
            var branch = user.branch
            var department = user.department
            if (branch != null) {
                if (branchNames.length > 0) {
                    if (!branchNames.includes(branch.name)) {
                        branchNames.push(branch.name)
                        branches.push(branch)
                    }
                }
                else {
                    branchNames.push(branch.name)
                    branches.push(branch)
                }
            }
            if (department != null) {
                if (departmentNames.length > 0) {
                    if (!departmentNames.includes(department.name)) {
                        departmentNames.push(department.name)
                        departments.push(department)
                    }
                }
                else {
                    departmentNames.push(department.name)
                    departments.push(department)
                }
            }
        });

        for (var i = 0; i < branches.length; i++) {
            branches[i].header = branches[i]['name'];
            branches[i].key = branches[i]['id'];
        }
        branches.unshift({
            header: 'All Branches',
            key: 0
        });
        this.setState({ branches: branches })

        for (var i = 0; i < departments.length; i++) {
            departments[i].header = departments[i]['name'];
            departments[i].key = departments[i]['id'];
        }
        departments.unshift({
            header: 'All Departments',
            key: 0
        });
        this.setState({ departments: departments })
    }
    getBranchesByDepartment = (branchIds: any[]) => {
        var users: any[] = []
        var departmentNames: any[] = []
        var departments: any[] = []
        branchIds.map((id) => {
            this.state.users_.map((user) => {
                if (user.branch != undefined) {
                    if (user.branch.id == id) {
                        users.push(user);
                    }
                }
            })
        })
        users.forEach(user => {
            var department = user.department
            if (department != null) {
                if (departmentNames.length > 0) {
                    if (!departmentNames.includes(department.name)) {
                        departmentNames.push(department.name)
                        departments.push(department)
                    }
                }
                else {
                    departmentNames.push(department.name)
                    departments.push(department)
                }
            }
        });
        for (var i = 0; i < departments.length; i++) {
            departments[i].header = departments[i]['name'];
            departments[i].key = departments[i]['id'];
        }
        departments.unshift({
            header: 'All Departments',
            key: 0
        });
        this.setState({ departments: departments })
    }

    filterData() {
        this.state.searchMethod == "AND" ?
            this.setState({
                users_:
                    this.state.users.filter(u =>
                        ((this.state.selectedCountryNames.length == 0)
                            || (u.country != undefined && this.state.selectedCountryNames.includes(u.country.name)))
                        && ((this.state.selectedBrancheNames.length == 0)
                            || (u.branch != undefined && this.state.selectedBrancheNames.includes(u.branch.name)))
                        && ((this.state.selectedDepartmentNames.length == 0)
                            || (u.department != undefined && this.state.selectedDepartmentNames.includes(u.department.name)))

                    )
            }, () => { this.setState({ selectedUsers: this.state.users_ }) }) :
            this.setState({
                users_: this.state.users
            }, () => { this.setState({ selectedUsers: [] }) })
    }


    fetchData = async () => {
        this.setState({ loading: true, items: [], groups: [], offset: 0 });

        var loggedInUser = await AccountApi.getUser(this.props.user.userId)
        this.setState({ loggedInUser: loggedInUser })

        if (this.state.users.length === 0) {
            let usersRequest: any = await AccountApi.GetUsers(this.props.user.company?.id ?? 0)
            if (usersRequest && usersRequest.payload) {
                var i;
                for (i = 0; i < usersRequest.payload.length; i++) {
                    usersRequest.payload[i].header = usersRequest.payload[i]['fullName'];
                    usersRequest.payload[i].key = usersRequest.payload[i]['id'];
                }
                usersRequest.payload.unshift({
                    header: 'All Users',
                    key: 0
                });
                this.setState({ users: usersRequest.payload, users_: usersRequest.payload })
            }
        }
        const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;

        if (this.state.departments.length == 0) {
            this.getDepartments()
        }

        if (this.state.branches.length == 0) {
            this.getBranches()
        }
        if (this.state.countries.length == 0) {
            let countriesRequest: any = await getUsersCountries(this.props.user.userId)
            if (countriesRequest && countriesRequest.payload) {
                var i;
                for (i = 0; i < countriesRequest.payload.length; i++) {
                    countriesRequest.payload[i].header = countriesRequest.payload[i]['name'];
                    countriesRequest.payload[i].key = countriesRequest.payload[i]['id'];
                }
                countriesRequest.payload.unshift({
                    header: 'All Countries',
                    key: 0
                });
                this.setState({ countries: countriesRequest.payload })
            }
        }

        var selectedUsers = this.state.selectedUsers.map(selectedUser => (selectedUser.id))
        var selectedCountries = this.state.selectedCountries.map(selectedCountry => (selectedCountry.id))
        var selectedDepartments = this.state.selectedDepartments.map(selectedDepartment => (selectedDepartment.id))
        var selectedBranches = this.state.selectedBranches.map(selectedBranch => (selectedBranch.id))


        const { payload } = await ReportsApi.getClockingsPerUserPerDay({
            userId: this.props.user.userId,
            from: this.state.dateFrom,
            to: this.state.dateTo,
            userIds: selectedUsers,
            searchMethod: this.state.searchMethod,
            departmentIds: selectedDepartments,
            branchIds: selectedBranches,
            countryIds: selectedCountries
        });
        await this.tableDataGenerator(payload)

        this.setState({
            loading: false,
            clockingsPayload: payload
        });
    };

    tableDataGenerator = async (payload: any[]) => {
        // var {t} = this.props

        let body: any[] = [];
        let groups: any[] = [];


        let currentUser = ""
        payload.map((shift: any[]) => {
            if (shift.length > 0 && currentUser !== shift[0].userId) {
                currentUser = shift[0].userId
                groups.push({ key: shift[0].userId, name: shift[0].userFullName, startIndex: body.length, count: 0, level: 0 })
            }
            let currentDate = ""
            shift.map(clock => {
                if (clock) {
                    let row: RowInput
                    // const d = new Date(clock.time).toISOString().split('T')[0] + ' ' + new Date(clock.time).toISOString().split('T')[1].split('.')[0]
                    // const d =  new Date(clock.time).toISOString().split('T')[1].split('.')[0]
                    if (currentDate !== new Date(clock.time).toISOString().split('T')[0]) {
                        currentDate = new Date(clock.time).toISOString().split('T')[0]
                        groups.push({ key: currentDate, name: currentDate, startIndex: body.length, count: shift.length, level: 1 })
                        row = clock
                    } else {
                        row = clock
                    }
                    body.push(row)
                }
                return clock
            })
            return shift
        })
        this.setState({ items: body, groups })
    }

    setSearchMethod = (event: any) => {
        var searchMethod = event.target.value == undefined ? "AND" : event.target.value
        this.setState({ searchMethod: searchMethod }, () => {
            this.filterData()
            if (this.state.selectedCountries != null && this.state.searchMethod == "AND") {
                var countryIds: any[] = []
                this.state.selectedCountries.map((country) => { countryIds.push(country.id) })
                this.getBranchesDepartment(countryIds)
            }
            else if (this.state.selectedBranches != null && this.state.searchMethod == "AND") {
                var branchIds: any[] = []
                this.state.selectedBranches.map((branch) => { branchIds.push(branch.id) })
                this.getBranchesDepartment(branchIds)
            }
            else {
                this.getBranches()
                this.getDepartments()
            }
        })
    }
    _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
        const { user } = this.props
        if (props?.group?.level === 0) {
            return (
                <Flex styles={{
                    backgroundColor: user.companyPrimaryColor,
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    borderRadius: '3px',
                    padding: '10px 50px'
                }} padding='padding.medium' hAlign='start'>{`${props.group!.name}`}</Flex>
            );
        }

        return <GroupHeader {...props} />;
    };


    // jsPdfGenerator = async (payload : any[]) => {
    //     var {t} = this.props
    //     // Example From https://parall.ax/products/jspdf
    //     var doc = new jsPDF({ filters: ["ASCIIHexEncode"], unit: 'px', orientation: 'p'});

    //     // doc.addFont('Helvetica', '1', 'regular', 'WinAnsiEncoding', true);

    //     doc.setFont('Arial Unicode MS')

    //     doc.setLanguage("tr")

    //     let img = new Image();
    //     const imageLoadPromise = new Promise(resolve => {
    //         img.onload = resolve;
    //         // img.src = 'https://cors-anywhere.herokuapp.com/' + this.props.user.companyLogo;
    //         img.src = '/static/media/logo.ff86e377.png';
    //     });

    //     await imageLoadPromise;

    //     var height = (100/img.width) * img.height

    //     doc.addImage(img, 'PNG', 10,10,100, height)

    //     doc.setFontSize(22);

    //     const title = t('Clockings')
    //     const titleXOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(title) * doc.getFontSize() / 2);

    //     doc.text(t('Clockings'), titleXOffset, 70, {})

    //     doc.setFontSize(12);
    //     const date = new Date().toDateString()
    //     const dateXOffset = doc.internal.pageSize.width - (doc.internal.pageSize.width / 6) - (doc.getStringUnitWidth(title) * doc.getFontSize() / 2);

    //     doc.text(date, dateXOffset, 50, { })
    //     var body : RowInput[]= [];

    //     // body.push([
    //     //     {content: '', colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //     //     {content: '', colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //     //     {content: '', colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //     //     {content: 'شسيبفغقهغع', colSpan: 1, rowSpan: 1, styles: {  }},
    //     //     {content: '', colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //     // ])

    //     payload.map( (shift : any[]) => {
    //         let currentDate = ""
    //         shift.map( clock => {
    //             if (clock){
    //                 let row : RowInput
    //                 // const d = new Date(clock.time).toISOString().split('T')[0] + ' ' + new Date(clock.time).toISOString().split('T')[1].split('.')[0]
    //                 const d =  new Date(clock.time).toISOString().split('T')[1].split('.')[0]
    //                 if (currentDate != new Date(clock.time).toISOString().split('T')[0])
    //                 {
    //                     currentDate = new Date(clock.time).toISOString().split('T')[0]
    //                     row = [
    //                         {content: currentDate, colSpan: 1, rowSpan: shift.length, styles: { halign: 'center' }},
    //                         {content: clock.type, colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //                         {content: d, colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //                         {content: clock.address??'', colSpan: 1, rowSpan: 1, styles: {  }},
    //                         {content: clock.comments??'', colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //                     ]
    //                 } else {
    //                     row = [
    //                         {content: clock.type, colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //                         {content: d, colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //                         {content: clock.address??'', colSpan: 1, rowSpan: 1, styles: { }},
    //                         {content: clock.comments??'', colSpan: 1, rowSpan: 1, styles: { halign: 'center' }},
    //                     ]
    //                 }
    //                 body.push(row)
    //             }
    //         })
    //     })

    //     autoTable(doc, {
    //         head: [[t('Day'), t('Clock Type'), t('Time'), t('Address'), t('Comment')]],
    //         body: body,
    //         startY: 120,

    //     })

    //     // Save the Data
    //     doc.autoPrint({variant: 'non-conform'});
    //     doc.save('report.pdf')
    // }

    exportReport = async (format: string) => {
        var selectedUsers = this.state.selectedUsers.map(selectedUser => (selectedUser.id))
        var selectedDepartments = this.state.selectedDepartments.map(selectedDepartment => (selectedDepartment.id))
        var selectedBranches = this.state.selectedBranches.map(selectedBranch => (selectedBranch.id))
        var selectedCountries = this.state.selectedCountries.map(selectedCountry => (selectedCountry.id))

        await ReportsApi.exportClockingsPerUserPerDay({
            userId: this.props.user.userId,
            from: this.state.dateFrom,
            to: this.state.dateTo,
            userIds: selectedUsers,
            searchMethod: this.state.searchMethod,
            departmentIds: selectedDepartments,
            branchIds: selectedBranches,
            countryIds: selectedCountries
        }, format)

        // const type = response.headers['content-type']
        // const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        // const link = document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.download = 'file.xlsx'
        // link.click()
    }

    onDateChange = (time: any, type: string) => {
        const timeSelected = new Date(time)
        if (type === 'from')
            this.setState({ dateFrom: timeSelected })
        else
            this.setState({ dateTo: timeSelected })
    }
    setShowFilters() {
        this.setState({
            showFilters: !this.state.showFilters
        })
    }
    render() {
        const {
            loading,
            columns,
            items,
            count,
            offset,
            groups
        } = this.state;

        const { t, user } = this.props

        return (
            <Flex column gap="gap.medium" padding="padding.medium" fill>

                <ReportsFilter
                    users={this.state.users}
                    users_={this.state.users_}
                    selectedUsers={this.state.selectedUsers}
                    onChangeUser={this.onChangeUser.bind(this)}
                    clearAllUsers={this.clearAllUsers.bind(this)}
                    dateFrom={this.state.dateFrom}
                    dateTo={this.state.dateTo}
                    onDateChange={this.onDateChange.bind(this)}
                    loading={this.state.loading}
                    exportReport={this.exportReport.bind(this)}
                    fetchData={this.fetchData.bind(this)}
                    setShowFilters={this.setShowFilters.bind(this)}
                    showFilters={this.state.showFilters}
                    countries={this.state.countries}
                    selectedCountries={this.state.selectedCountries}
                    selectedCountryNames={this.state.selectedCountryNames}
                    onChangeCountry={this.onChangeCountry.bind(this)}
                    clearAllCountries={this.clearAllCountries.bind(this)}
                    branches={this.state.branches}
                    selectedBranches={this.state.selectedBranches}
                    selectedBranchNames={this.state.selectedBrancheNames}
                    onChangeBranches={this.onChangeBranch.bind(this)}
                    clearAllBranches={this.clearAllBranches.bind(this)}
                    departments={this.state.departments}
                    selectedDepartments={this.state.selectedDepartments}
                    selectedDepartmentNames={this.state.selectedDepartmentNames}
                    onChangeDepartments={this.onChangeDepartments.bind(this)}
                    clearAllDepartments={this.clearAllDepartment.bind(this)}
                    searchMethod={this.state.searchMethod}
                    setSearchMethod={this.setSearchMethod.bind(this)}

                >
                </ReportsFilter>
                <Divider />


                <ShimmeredDetailsList
                    selectionMode={0}
                    detailsListStyles={{ root: { overflowY: "auto" } }}
                    enableShimmer={loading}
                    items={items}
                    columns={columns}
                    groups={groups.slice(offset, Math.min(offset + count, groups.length))}
                    groupProps={{
                        showEmptyGroups: true,
                        onRenderHeader: this._onRenderGroupHeader,
                    }}


                />
                <div style={{ padding: "1em" }}>

                    <Pagination
                        currentPage={offset / count + 1}
                        total={groups.length}
                        limit={count}
                        onPageChange={(page) => this.setState({ offset: ((page ?? 1) - 1) * count })}>
                        {({ pages, currentPage, hasNextPage, hasPreviousPage, nextPage, previousPage, totalPages, getPageItemProps }) => (
                            <div>

                                {(currentPage !== 1) && (
                                    <Button iconOnly
                                        {...getPageItemProps({
                                            pageValue: 1,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: 0 })
                                        }) as any}
                                    >
                                        {user.rtl ? ">>" : "<<"}
                                    </Button>
                                )}

                                {hasPreviousPage && (
                                    <Button iconOnly
                                        {...getPageItemProps({
                                            pageValue: previousPage,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((previousPage ?? 1) - 1) * count })
                                        }) as any}
                                    >
                                        {user.rtl ? ">" : "<"}
                                    </Button>
                                )}

                                {pages.map((page) => (
                                    <Button key={"ListPageButton-" + page} iconOnly
                                        primary={currentPage === page}
                                        {...getPageItemProps({
                                            pageValue: page,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((page ?? 1) - 1) * count }),
                                        }) as any}>
                                        {page}
                                    </Button>
                                ))}

                                {hasNextPage && (
                                    <Button iconOnly
                                        {...getPageItemProps({
                                            pageValue: nextPage,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((nextPage ?? 1) - 1) * count })
                                        }) as any}
                                    >
                                        {user.rtl ? "<" : ">"}
                                    </Button>
                                )}

                                {(currentPage !== totalPages) && (
                                    <Button iconOnly
                                        {...getPageItemProps({
                                            pageValue: totalPages,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((totalPages ?? 1) - 1) * count })
                                        }) as any}
                                    >
                                        {user.rtl ? "<<" : ">>"}
                                    </Button>
                                )}

                            </div>
                        )}
                    </Pagination>
                </div>
            </Flex >
        );
    }
}

export default withRouter(withTranslation()(Clockings as any) as any);