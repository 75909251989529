import React from "react";
import { geolocated, GeolocatedProps } from "react-geolocated";

interface Props  {
  visible: boolean;
};

class GPSInput extends React.Component<GeolocatedProps & Props> {
  render() {
    return (
      // <div style={{ display: this.props.visible ? "block" : "none" }}>
      <div style={{ display:"none" }}>
        {!this.props.isGeolocationAvailable && (
          <div>Your browser does not support Geolocation</div>
        )}

        {!this.props.isGeolocationEnabled && (
          <div>Geolocation is not enabled</div>
        )}

        {this.props.coords && (
          <table>
            <tbody>
              <tr>
                <td>latitude</td>
                <td>{this.props.coords.latitude}</td>
              </tr>
              <tr>
                <td>longitude</td>
                <td>{this.props.coords.longitude}</td>
              </tr>
            </tbody>
          </table>
        )}

        {!this.props.coords && <div>Getting the location data&hellip; </div>}
      </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  // userDecisionTimeout: 5000,
  watchPosition: false,
})(GPSInput);
