import { UserStore } from "./store/user";
import { shadeBlend } from "./utils";
import { createTheme } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

export const fluentUINorthStarTheme = (user : UserStore) => ({
    // fontFaces: [
    //   {
    //     name: 'sans-serif',
    //     paths: [],
    //     props: {
    //       fontStyle : 'sans-serif'
    //     }
    //   }
    // ],
    siteVariables: {
      colorScheme: {
        brand: {
          background: user.companyPrimaryColor ?? "#005AAB",
          foreground: user.companyPrimaryColor ?? "#005AAB",
          backgroundHover: user.companyPrimaryColor ? shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) : "#00519a",
          foregroundHover: user.companyPrimaryColor ? shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) : "#00519a",
          backgroundActive: user.companyPrimaryColor ? shadeBlend( -0.4 ,user.companyPrimaryColor, undefined) : "#004889",
          foregroundActive: user.companyPrimaryColor ? shadeBlend( -0.4 ,user.companyPrimaryColor, undefined) : "#004889",
          backgroundPressed: user.companyPrimaryColor ? shadeBlend( -0.4 ,user.companyPrimaryColor, undefined) : "#004889",
          foregroundPressed: user.companyPrimaryColor ? shadeBlend( -0.4 ,user.companyPrimaryColor, undefined) : "#004889",
        },
      },
    },
    componentVariables: {
      // SvgIcon: {
      //   color: user.companyPrimaryColor ? shadeBlend( -0.5, user.companyPrimaryColor, undefined) : "#004889"
      // },
      Input: {
        inputFocusBorderColor: user.companyPrimaryColor
      },
      TextArea: {
        borderColorFocus: user.companyPrimaryColor
      },
      MenuItemWrapper: {
        pointingIndicatorBackgroundColor: user.companyPrimaryColor
      },
      Checkbox: {
        borderColor: user.companyPrimaryColor,
        borderColorHover: user.companyPrimaryColor ? shadeBlend( -0.2, user.companyPrimaryColor, undefined) : "#00519a",
        checkedBorderColor: user.companyPrimaryColor,
        checkedBackground: user.companyPrimaryColor,
        checkedBackgroundHover: user.companyPrimaryColor ? shadeBlend( -0.2, user.companyPrimaryColor, undefined) : "#00519a"
      }
    }
  });

export const fluentUITheme = (user : UserStore) => ({
    palette: {
        themePrimary: user.companyPrimaryColor ?? "#005AAB",
        themeLighterAlt: user.companyPrimaryColor ?? "#00519a",
    }
});

export const materialUITheme = (user : UserStore) => createTheme ({
  palette: {
    primary: {
      main: user.companyPrimaryColor ?? "#005AAB",
    },
  },
  typography: {
    fontSize: 12,
  },
});