import React, { CSSProperties } from 'react';
import { CommandBar, ShimmeredDetailsList, IColumn, DatePicker, Dropdown, TextField, Label, Icon } from '@fluentui/react';
import { Dialog, Flex, Alert, Button, Checkbox, AcceptIcon, CloseIcon, Divider, Text, UndoIcon, ExclamationTriangleIcon, Loader } from '@fluentui/react-northstar';
import { LeaveType, Result, LeaveRequestView, LeaveRequestInput, LeaveRequestStatus, QuotaUnit, HalfDayPart, Roles, LeaveTypeView } from '../../../models';
import * as LeavesApi from '../../../api/leaves';
import { observer, inject } from 'mobx-react';
import { UserStore } from '../../../store/user';
import { withRouter } from 'react-router-dom';
import { shadeBlend } from '../../../utils';
import { withTranslation } from 'react-i18next';
import dateformat from 'dateformat';
//@ts-ignore
import DateTimePicker from 'react-datetime-picker';
import "../../../components/styles/dateTimePicker.scss";
import LeaveAttachments from "../../../components/leaves/leaveAttachments";
import SubmitLeaveApprovalForm from "../../../components/leaves/submitLeaveApprovalForm";

interface IState {
    loading: boolean;
    leavesLoading: boolean;
    leaveDialogOpen: boolean;
    approveDialogOpen: boolean;
    rejectDialogOpen: boolean;
    undoDialogOpen: boolean;
    commentDialogData: {
        leaveId: number;
        commentValue: string;
    }
    commentRequiredAlert: boolean;
    leaveAttachmentsDialogOpen: boolean;
    // AssignleaveDialogOpen: boolean;
    isSupervisor: boolean;
    leaveRequestApprovalFormIsOpen: boolean;
    leaveRequestApprovalFormId: number;
    leaveRequests: LeaveRequestView[];
    // balance: LeaveBalanceModel[];
    leaveTypes: LeaveTypeView[];
    requestStartDate: Date;
    requestEndDate: Date;
    //quotaUnit: QuotaUnit;
    input: LeaveRequestInput & {attachmentsCount : number};
    inputError?: string;
    selectedLeaveId: number;
    submitAttachmentsNow: boolean;
    // allowedLeaveInput: AllowedLeaveInput;
}

interface IProps {
    user: UserStore;
    match: { params: { id?: string } };
    t: any;
}

@inject("user")
@observer
class Leaves extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        let dateWithOffset = new Date();
        dateWithOffset.setHours(dateWithOffset.getHours() - (dateWithOffset.getTimezoneOffset() / 60))

        this.state = {
            // balance: [],
            loading: false,
            leaveTypes: [],
            isSupervisor: false,
            leaveRequestApprovalFormIsOpen: false,
            leaveRequestApprovalFormId: 0,
            leaveRequests: [],
            requestStartDate: new Date(),
            requestEndDate: new Date(),
            input: {
                leaveTypeId: 0,
                userId: this.userId,
                startDate: dateWithOffset.toJSON().split('T')[0],
                fromHalfDay: false,
                fromHalfDayPart: HalfDayPart.AM,
                endDate: dateWithOffset.toJSON().split('T')[0],
                toHalfDay: false,
                toHalfDayPart: HalfDayPart.AM,
                comment: "",
                attachmentsCount: 0
            },
            // allowedLeaveInput:{
            //     allowedCount:0,
            //     leaveTypeId:0,
            //     userID:this.userId,
            // },
            leaveDialogOpen: false,
            leaveAttachmentsDialogOpen: false,
            approveDialogOpen: false,
            rejectDialogOpen: false,
            undoDialogOpen: false,
            commentDialogData: {
                leaveId: 0,
                commentValue: ""
            },
            commentRequiredAlert: false,
            // AssignleaveDialogOpen:false,
            leavesLoading: false,
            selectedLeaveId: 0,
            submitAttachmentsNow: false,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    get userId() {
        return this.props.match.params.id ?? this.props.user.userId;
    }

    fetchData = async () => {
        this.setState({ leavesLoading: true });
        const leaveRequests = await LeavesApi.getLeaveRequestsPerUser(this.userId);
        const leaveTypes = await LeavesApi.getLeaveTypes(this.userId);
        // const balance = await LeavesApi.getBalancePerUser(this.userId);

        if (leaveTypes.payload
            && leaveTypes.payload.length > 0
            && leaveTypes.payload[0].quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Hours].toLowerCase()) {
            let dateWithOffset = new Date();
            dateWithOffset.setHours(dateWithOffset.getHours() - (dateWithOffset.getTimezoneOffset() / 60))

            this.setState({
                requestStartDate: new Date(),
                requestEndDate: new Date(),
                input: {
                    ...this.state.input,
                    startDate: dateWithOffset.toJSON().split('.')[0],
                    endDate: dateWithOffset.toJSON().split('.')[0],
                },
            })
        }

        this.setState({
            leavesLoading: false,
            isSupervisor: leaveRequests.payload.isSupervisor,
            leaveRequests: leaveRequests.payload.leaveRequests,
            leaveTypes: leaveTypes.payload,
            input: { ...this.state.input, userId: this.userId }
        });
        // this.setState({ leavesLoading: false, balance: balance.payload });
        // this.setState({ leavesLoading: false, companyleaves: companyleaves.payload });
    }

    // assignLeave = async () => {
    //     const { t } = this.props
    //     if (!this.state.allowedLeaveInput.leaveTypeId) {
    //         this.setState({ inputError: t("Leave Type is mandatory") });
    //     } else {
    //         try {
    //             await LeavesApi.assignLeavesToUser(this.state.allowedLeaveInput);
    //             this.setState({ AssignleaveDialogOpen: false });
    //             this.fetchData();
    //         } catch (error) {
    //             const res = error as Result<never>;
    //             this.setState({ inputError: res.errors[0].description });
    //         }
    //     }
    // };

    requestLeave = async () => {
        this.setState({loading : true});
        const { t } = this.props
        if (!this.state.input.leaveTypeId) {
            this.setState({ inputError: t("Leave Type is mandatory") });
        } else {
            try {
                var response = await LeavesApi.requestLeave(this.state.input);
                this.setState({ selectedLeaveId: response.payload.id, submitAttachmentsNow: true });
                this.fetchData();
                let dateWithOffset = new Date();
                dateWithOffset.setHours(dateWithOffset.getHours() - (dateWithOffset.getTimezoneOffset() / 60))
                this.setState({
                    leaveDialogOpen: false,
                    inputError: "",
                    requestStartDate: new Date(),
                    requestEndDate: new Date(),
                    input: {
                        leaveTypeId: 0,
                        userId: this.userId,
                        startDate: dateWithOffset.toJSON().split('T')[0],
                        fromHalfDay: false,
                        fromHalfDayPart: HalfDayPart.AM,
                        endDate: dateWithOffset.toJSON().split('T')[0],
                        toHalfDay: false,
                        toHalfDayPart: HalfDayPart.AM,
                        comment: "",
                        attachmentsCount: 0
                    },
                });
            } catch (error) {
                const res = error as Result<never>;
                this.setState({ inputError: res.errors[0].description });
            }
        }
        this.setState({loading : false});
    };

    // onRequestApprovalFormCancel = () => {
    //     this.setState({ leaveRequestApprovalFormIsOpen: false, leaveRequestApprovalFormId: 0})
    // }

    requestApproval = async (id: number, isApproved: boolean, supervisorComment: string) => {
        this.setState({loading : true});
        try {
            await LeavesApi.leaveRequestApproval({ id, isApproved, supervisorComment })
            this.setState({
                approveDialogOpen: false,
                rejectDialogOpen: false,
                commentRequiredAlert: false
            })
            this.fetchData();
            
        } catch (error) {
            if (error) {
                const res = error as Result<never>;
                alert(res.errors[0].description);
            }
        }
        this.setState({loading : false});
    }

    undoRequestApproval = async (id: number, supervisorComment: string) => {
        this.setState({loading : true});
        try {
            await LeavesApi.leaveRequestUndoApproval({ id, supervisorComment, isApproved: false })
            this.setState({
                undoDialogOpen: false,
                commentRequiredAlert: false
            })
            this.fetchData();
        } catch (error) {
            if (error) {
                const res = error as Result<never>;
                alert(res.errors[0].description);
            }
        }
        this.setState({loading : false});
    }

    setAttachmentsCount(n: number) {
        this.setState({
            input: {
                ...this.state.input,
                attachmentsCount: n
            }
        });
    }

    leaveTypeChange = (e: any, item: any) => {
        let dateWithOffset = new Date();
        dateWithOffset.setHours(dateWithOffset.getHours() - (dateWithOffset.getTimezoneOffset() / 60))

        if (this.state.leaveTypes.find(l => l.id === this.state.input.leaveTypeId)?.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Days].toLowerCase()
            && this.state.leaveTypes.find(l => l.id === (item?.key as number) ?? 0)?.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Hours].toLowerCase()) {
            this.setState({
                requestStartDate: new Date(),
                requestEndDate: new Date(),
                input: {
                    ...this.state.input,
                    startDate: dateWithOffset.toJSON().split('.')[0],
                    endDate: dateWithOffset.toJSON().split('.')[0],
                },
            })
        } else if (this.state.leaveTypes.find(l => l.id === this.state.input.leaveTypeId)?.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Hours].toLowerCase()
            && this.state.leaveTypes.find(l => l.id === (item?.key as number) ?? 0)?.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Days].toLowerCase()) {
            this.setState({
                requestStartDate: new Date(),
                requestEndDate: new Date(),
                input: {
                    ...this.state.input,
                    startDate: dateWithOffset.toJSON().split('T')[0],
                    endDate: dateWithOffset.toJSON().split('T')[0],
                },
            })
        }



        this.setState((state) => ({
            input: {
                ...state.input,
                leaveTypeId: (item?.key as number) ?? 0,
            }
        }))

    }

    render() {
        const { user, t } = this.props;

        

        const IconSelectedStyles : CSSProperties = { fontSize: "20px", fontWeight: 'bolder', cursor: 'pointer', color: user.companyPrimaryColor }
        const IconNotSelectedStyles : CSSProperties = { fontSize: "17px", fontWeight:  'normal', cursor: 'pointer'  }

        const dialogs = (
            <>
                {/* <LeaveRequestApprovalForm  
                    leaveRequestId={this.state.leaveRequestApprovalFormId} 
                    open={this.state.leaveRequestApprovalFormIsOpen}
                    onCancel={this.onRequestApprovalFormCancel}
                /> */}
                <Dialog
                    open={this.state.leaveDialogOpen}
                    onConfirm={() => this.state.loading ? {} : this.requestLeave()}
                    onCancel={() => this.state.loading ? {} : this.setState({ leaveDialogOpen: false })}
                    confirmButton={this.state.loading ? <Loader/> : t("Request Leave")}
                    cancelButton={this.state.loading ? <Loader/> : t("Cancel")}
                    header={t("Request a leave")}
                    content={(
                        <Flex column gap="gap.medium" hAlign="stretch"
                            styles={{ width: "100%" }}>
                            {this.state.inputError && <Alert danger content={this.state.inputError} />}
                            <Dropdown
                                required
                                label={t("Leave type")}
                                // loading={this.state.balanceLoading}
                                options={[{ key: 0, text: t("Select leave type") }, ...this.state.leaveTypes.map((leaveType) => ({
                                    key: leaveType.id,
                                    text: t(leaveType.name),
                                }))]}
                                selectedKey={this.state.input.leaveTypeId}
                                // itemToString={(item: any) => item?.content}
                                onChange={(e, item) => this.leaveTypeChange(e, item)}
                                placeholder={t("Select leave type")} />
                            {this.state.leaveTypes.find(l => l.id === this.state.input.leaveTypeId)?.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Days].toLowerCase() ? <>
                                <Label required>{t("From")}</Label>
                                <Flex vAlign='center' gap='gap.small'>
                                    <DatePicker
                                        disableAutoFocus
                                        // minDate={new Date()}
                                        onSelectDate={(date) => {
                                            if (date)
                                                date.setHours(date.getHours() - (date.getTimezoneOffset() / 60))
                                            this.setState((state) => ({
                                                requestStartDate: date ?? state.requestStartDate,
                                                input: {
                                                    ...state.input,
                                                    startDate: date ? date.toJSON().split('T')[0] : state.input.startDate
                                                },
                                            }))
                                        }}
                                        // label={t("From")}
                                        value={this.state.requestStartDate} />
                                    <Checkbox label={t("Half day")}
                                        checked={this.state.input.fromHalfDay}
                                        onChange={() => this.setState({ input: { ...this.state.input, fromHalfDay: !this.state.input.fromHalfDay } })} />
                                    <Icon 
                                        iconName="Sunny"
                                        title={t("AM")}
                                        style={this.state.input.fromHalfDay && this.state.input.fromHalfDayPart === HalfDayPart.AM ? IconSelectedStyles : IconNotSelectedStyles} 
                                        onClick={() => this.setState({ input: { ...this.state.input, fromHalfDayPart: HalfDayPart.AM } })}
                                    />
                                    <Icon 
                                        iconName="ClearNight"
                                        title={t("PM")}
                                        style={this.state.input.fromHalfDay && this.state.input.fromHalfDayPart === HalfDayPart.PM ? IconSelectedStyles : IconNotSelectedStyles} 
                                        onClick={() => this.setState({ input: { ...this.state.input, fromHalfDayPart: HalfDayPart.PM } })}
                                    />
                                </Flex>
                                <Label required>{t("To")}</Label>
                                <Flex vAlign='center' gap='gap.small'>
                                    <DatePicker
                                        disableAutoFocus
                                        // minDate={new Date()}
                                        onSelectDate={(date) => {
                                            if (date)
                                                date.setHours(date.getHours() - (date.getTimezoneOffset() / 60))
                                            this.setState((state) => ({
                                                requestEndDate: date ?? state.requestEndDate,
                                                input: {
                                                    ...state.input,
                                                    endDate: date ? date.toJSON().split('T')[0] : state.input.endDate
                                                },
                                            }))
                                        }}
                                        value={this.state.requestEndDate} />
                                    <Checkbox label={t("Half day")}
                                        checked={this.state.input.toHalfDay}
                                        onChange={() => this.setState({ input: { ...this.state.input, toHalfDay: !this.state.input.toHalfDay } })} />
                                    <Icon 
                                        iconName="Sunny"
                                        title={t("AM")}
                                        style={this.state.input.toHalfDay && this.state.input.toHalfDayPart === HalfDayPart.AM ? IconSelectedStyles : IconNotSelectedStyles} 
                                        onClick={() => this.setState({ input: { ...this.state.input, toHalfDayPart: HalfDayPart.AM } })}
                                    />
                                    <Icon 
                                        iconName="ClearNight"
                                        title={t("PM")}
                                        style={this.state.input.toHalfDay &&this.state.input.toHalfDayPart === HalfDayPart.PM ? IconSelectedStyles : IconNotSelectedStyles} 
                                        onClick={() => this.setState({ input: { ...this.state.input, toHalfDayPart: HalfDayPart.PM } })}
                                    />
                                </Flex>
                            </> : <>
                                <Label required>{t("From")}</Label>
                                <DateTimePicker
                                    required
                                    onChange={(date: any) => {
                                        if (date) {
                                            let newDate = new Date(date) ?? this.state.requestStartDate;
                                            newDate.setHours(newDate.getHours() - (newDate.getTimezoneOffset() / 60))
                                            this.setState((state) => ({
                                                requestStartDate: new Date(date) ?? state.requestStartDate,
                                                input: {
                                                    ...state.input,
                                                    startDate: newDate.toJSON().substring(0, date.toJSON().length - 5) ?? state.input.startDate
                                                },
                                            }))
                                        }
                                    }}
                                    value={new Date(this.state.requestStartDate)}
                                    format="y-MM-dd h:mm:ss a"
                                    calendarIcon={null}
                                    clearIcon={null}
                                />
                                <Label required>{t("To")}</Label>
                                <DateTimePicker
                                    required
                                    onChange={(date: any) => {
                                        if (date) {
                                            let newDate = new Date(date) ?? this.state.requestEndDate;
                                            newDate.setHours(newDate.getHours() - (newDate.getTimezoneOffset() / 60))
                                            this.setState((state) => ({
                                                requestEndDate: new Date(date) ?? state.requestEndDate,
                                                input: {
                                                    ...state.input,
                                                    endDate: newDate.toJSON().substring(0, date.toJSON().length - 5) ?? state.input.endDate
                                                },
                                            }))
                                        }
                                    }}
                                    value={new Date(this.state.requestEndDate)}
                                    format="y-MM-dd h:mm:ss a"
                                    calendarIcon={null}
                                    clearIcon={null}
                                />
                            </>}

                            <TextField multiline placeholder={t("Enter comments if you have any")}
                                onChange={(e, props) => this.setState((state) => ({
                                    input: {
                                        ...state.input,
                                        comment: props ?? "",
                                    },
                                }))} />
                            <Label>{t("Attachments")}</Label>
                            {this.state.leaveTypes.find(l => l.id === this.state.input.leaveTypeId)?.attachmentRequired && this.state.input.attachmentsCount <= 0 && <Alert warning content={
                                <>
                                    <ExclamationTriangleIcon />{' '}
                                    {t("This leave type requires an attachment")}
                                </>
                            } />}
                            <LeaveAttachments 
                                setAttachmentsCount={(n: number) => this.setAttachmentsCount(n)} 
                                fetchData={this.fetchData} 
                                leaveRequestId={this.state.selectedLeaveId} 
                                newRequest={true} 
                                submitNow={this.state.submitAttachmentsNow} 
                            />
                        </Flex>
                    )} />

                <SubmitLeaveApprovalForm
                    headerText="Leave Approval"
                    isRequired={false} 
                    isOpen={this.state.approveDialogOpen}
                    loading={this.state.loading}
                    commentDialogData={this.state.commentDialogData}
                    commentRequiredAlert={this.state.commentRequiredAlert}
                    leaveRequests={this.state.leaveRequests}
                    onCommentChange={(e: any, props: string | undefined) => {
                        this.setState({
                            commentDialogData: {
                                ...this.state.commentDialogData,
                                commentValue: props ?? ""
                            }
                        })
                    }}
                    onConfirm={() => this.requestApproval(this.state.commentDialogData.leaveId, true, this.state.commentDialogData.commentValue)}
                    onCancel={() => this.setState({ approveDialogOpen: false })}
                />
                <SubmitLeaveApprovalForm
                    headerText="Leave Rejection"
                    isRequired
                    isOpen={this.state.rejectDialogOpen}
                    loading={this.state.loading}
                    commentDialogData={this.state.commentDialogData}
                    commentRequiredAlert={this.state.commentRequiredAlert}
                    leaveRequests={this.state.leaveRequests}
                    onCommentChange={(e: any, props: string | undefined) => {
                        this.setState({
                            commentDialogData: {
                                ...this.state.commentDialogData,
                                commentValue: props ?? ""
                            }
                        })
                    }}
                    onConfirm={() => {
                        if (!this.state.commentDialogData.commentValue)
                            this.setState({ commentRequiredAlert: true })
                        else
                            this.requestApproval(this.state.commentDialogData.leaveId, false, this.state.commentDialogData.commentValue)
                    }}
                    onCancel={() => this.setState({ rejectDialogOpen: false })}
                />
                <SubmitLeaveApprovalForm
                    headerText="Undo Leave Approval or Rejection"
                    isRequired={false} 
                    isOpen={this.state.undoDialogOpen}
                    loading={this.state.loading}
                    commentDialogData={this.state.commentDialogData}
                    commentRequiredAlert={this.state.commentRequiredAlert}
                    leaveRequests={this.state.leaveRequests}
                    onCommentChange={(e: any, props: string | undefined) => {
                        this.setState({
                            commentDialogData: {
                                ...this.state.commentDialogData,
                                commentValue: props ?? ""
                            }
                        })
                    }}
                    onConfirm={() => this.undoRequestApproval(this.state.commentDialogData.leaveId, this.state.commentDialogData.commentValue)}
                    onCancel={() => this.setState({ undoDialogOpen: false })}
                />
                <Dialog
                    open={this.state.leaveAttachmentsDialogOpen}
                    onCancel={() => this.setState({ leaveAttachmentsDialogOpen: false })}
                    // onConfirm={() => this.requestLeave()}
                    // confirmButton={t("Request Leave")}
                    cancelButton={t("Cancel")}
                    header={t("Leave Attachments")}
                    //let leave = this.state.leaveRequests.find(l => l.id == this.state.selectedLeaveId);
                    content={
                        <>
                            {this.state.leaveRequests.find(l => l.id === this.state.selectedLeaveId)?.attachmentRequired &&
                                (this.state.leaveRequests.find(l => l.id === this.state.selectedLeaveId)?.attachmentCount as number) <= 0 &&
                                <Alert styles={{marginBottom: "20px", padding: "5px"}} warning content={
                                    <>
                                        <ExclamationTriangleIcon />{' '}
                                    {t("This leave type requires an attachment")}
                                    </>
                                } />}
                            <LeaveAttachments setAttachmentsCount={(n: number) => this.setAttachmentsCount(n)} fetchData={this.fetchData} leaveRequestId={this.state.selectedLeaveId} newRequest={false} submitNow={false} />
                        </>}
                />

            </>
        );

        const leavesCols: IColumn[] = [
            {
                key: "leaveType",
                name: t("Leave type"),
                minWidth: 200,
                maxWidth: 200,
                onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                    <Text styles={{ whiteSpace: "break-spaces" }} content={t(leave.leaveType)} />
                    {(leave.attachmentRequired && leave.attachmentCount <= 0) && <ExclamationTriangleIcon title={t("This leave requires an attachment")} />}
                </Flex>,
            },
            {
                key: "startDate",
                name: t("Start Date"),
                minWidth: 125,
                maxWidth: 125,
                onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                    <div>{dateformat(leave.startDate, leave.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Hours].toLowerCase() ? 'dd/mm/yyyy h:MM:ss TT' : 'dd/mm/yyyy')}</div>
                    <div>{leave.fromHalfDay ? <Icon 
                        iconName={leave.fromHalfDayPart.toString() === HalfDayPart[HalfDayPart.AM] ? 'Sunny' : 'ClearNight'} 
                        title={t("Half day " + leave.fromHalfDayPart)} /> : null}
                    </div>
                </Flex>,
            },
            // {
            //     key: "fromHalfDay",
            //     name: t("From Half Day"),
            //     minWidth: 125,
            //     maxWidth: 125,
            //     onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
            //         {leave.fromHalfDay ? t("Yes") : t("No")}
            //     </Flex>,
            // },
            {
                key: "endDate",
                name: t("End Date"),
                minWidth: 125,
                maxWidth: 125,
                onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.smaller">
                    <div>{dateformat(leave.endDate, leave.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Hours].toLowerCase() ? 'dd/mm/yyyy h:MM:ss TT' : 'dd/mm/yyyy')}</div>
                    <div>{leave.toHalfDay ? <Icon 
                        iconName={leave.toHalfDayPart.toString() === HalfDayPart[HalfDayPart.AM] ? 'Sunny' : 'ClearNight'} 
                        title={t("Half day " + leave.toHalfDayPart)} /> : null}
                    </div>
                </Flex>,
            },
            // {
            //     key: "toHalfDay",
            //     name: t("To Half Day"),
            //     minWidth: 125,
            //     maxWidth: 125,
            //     onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
            //         {leave.toHalfDay ? t("Yes") : t("No")}
            //     </Flex>,
            // },
            {
                key: "Status",
                name: t("Status"),
                minWidth: 100,
                maxWidth: 100,
                onRender: (leave: LeaveRequestView) => {
                    return <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">{t(leave.status)}</Flex>
                },
            },
            {
                key: "lastUpdate",
                name: t("Last Update"),
                minWidth: 125,
                maxWidth: 125,
                onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                    {dateformat(leave.lastUpdate, 'dd/mm/yyyy h:MM:ss TT')}
                </Flex>,
            },
            // {
            //     key: "numberOfDays",
            //     name: t("Number of days"),
            //     minWidth: 200,
            //     maxWidth: 200,
            //     onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">{leave.numberOfDays}</Flex>,
            // },
            {
                key: "comment",
                name: t("Comment"),
                minWidth: 200,
                maxWidth: 200,
                onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                    {leave.comments
                        ? <Text styles={{ whiteSpace: "break-spaces" }} content={leave.comments} />
                        : <Text disabled content={t("Not available")} />}
                </Flex>,
            },
            {
                key: "supervisor",
                name: t("Supervisor"),
                minWidth: 100,
                maxWidth: 100,
                onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                    {leave.supervisorName
                        ? <Text styles={{ whiteSpace: "break-spaces" }} content={leave.supervisorName} />
                        : <Text disabled content={t("Not available")} />}
                </Flex>,
            },
            {
                key: "supervisorComments",
                name: t("Supervisor Comments"),
                minWidth: 200,
                maxWidth: 200,
                onRender: (leave: LeaveRequestView) => {
                    // if (this.state.isSupervisor || this.props.user.role.toLowerCase() == Roles.Hr.toLowerCase() || this.props.user.role.toLowerCase() == Roles.Admin.toLowerCase())
                    //     return (
                    //         <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                    //             <TextField
                    //                 value={leave.supervisorComments}
                    //                 onChange={(e, props) => {
                    //                     leave.supervisorComments = props ?? ""
                    //                     this.setState({})
                    //                 }}
                    //             />
                    //         </Flex>
                    //     )
                    // else
                    return (
                        leave.supervisorComments
                            ? <Text styles={{ whiteSpace: "break-spaces" }} content={leave.supervisorComments} />
                            : <Text disabled content={t("Not available")} />
                    )
                },
            },
            {
                key: "actions",
                name: t("Actions"),
                minWidth: 150,
                maxWidth: 150,
                onRender: (leave: LeaveRequestView) => {
                    let attachmentButton = <Button
                        onClick={() => {
                            this.setState({
                                selectedLeaveId: leave.id,
                                leaveAttachmentsDialogOpen: true
                            })
                        }}
                        icon={<Icon iconName="Attach" style={{ fontSize: "15px" }} />}
                        title={t("Attachments")}
                        iconOnly
                    />
                    if (this.state.isSupervisor || this.props.user.role.toLowerCase() === Roles.Hr.toLowerCase() || this.props.user.role.toLowerCase() === Roles.Admin.toLowerCase())
                        return (
                            <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                                {leave.status.toString().toLowerCase() === LeaveRequestStatus[LeaveRequestStatus.Pending].toLowerCase() ?
                                    <>
                                        <Button
                                            onClick={() => this.setState({
                                                approveDialogOpen: true,
                                                commentDialogData: {
                                                    leaveId: leave.id,
                                                    commentValue: leave.supervisorComments
                                                }
                                            }) /*this.requestApproval(leave.id, true, leave.supervisorComments)*/}
                                            icon={<AcceptIcon />}
                                            title={t("Approve")}
                                            iconOnly
                                        />
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    rejectDialogOpen: true,
                                                    commentDialogData: {
                                                        leaveId: leave.id,
                                                        commentValue: leave.supervisorComments
                                                    }
                                                })
                                            } /*this.requestApproval(leave.id, false, leave.supervisorComments)*/}
                                            icon={<CloseIcon />}
                                            title={t("Reject")}
                                            iconOnly
                                        />
                                    </> :
                                    <>
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    undoDialogOpen: true,
                                                    commentDialogData: {
                                                        leaveId: leave.id,
                                                        commentValue: leave.supervisorComments
                                                    }
                                                })
                                            } /*this.undoRequestApproval(leave.id, leave.supervisorComments)*/}
                                            icon={<UndoIcon />}
                                            title={t("Undo")}
                                            iconOnly
                                        />
                                    </>
                                }
                                {attachmentButton}
                                {(leave.attachmentRequired && leave.attachmentCount <= 0) && <ExclamationTriangleIcon title={t("This leave requires an attachment")} />}
                            </Flex>
                        )
                    else
                        return (
                            <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                                {attachmentButton}
                                {(leave.attachmentRequired && leave.attachmentCount <= 0) && <ExclamationTriangleIcon title={t("This leave requires an attachment")} />}
                            </Flex>
                        )
                }
            },
        ];

        return (
            <>
                {dialogs}

                <div style={{ overflow: "hidden" }}>


                    {this.userId === this.props.user.userId || this.props.user.role.toLowerCase() === Roles.Hr.toLowerCase() || this.props.user.role.toLowerCase() === Roles.Admin.toLowerCase() ? <><CommandBar items={[]} farItems={[
                        // {
                        //     key: "Assign.leave",
                        //     text: t("Assign Leave"),
                        //     disabled: this.props.user.role.toLowerCase() === "clocking" || this.props.user.role.toLowerCase() === "chief" ? true : false,
                        //     iconProps: { iconName: "add" },
                        //     buttonStyles: {
                        //         icon: { color: user.companyPrimaryColor },
                        //         iconHovered: { color: shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) },
                        //       },
                        //     onClick: () => this.setState({ AssignleaveDialogOpen: true }),
                        // },
                        {
                            key: "request.leave",
                            text: t("Request Leave"),
                            disabled: this.state.leaveTypes.length === 0,
                            iconProps: { iconName: "add" },
                            buttonStyles: {
                                icon: { color: this.props.user.companyPrimaryColor },
                                iconHovered: { color: shadeBlend(-0.2, this.props.user.companyPrimaryColor, undefined) },
                            },
                            onClick: () => this.setState({ leaveDialogOpen: true, selectedLeaveId: 0 }),
                        },
                    ]} /><Divider /></> : <></>}
                    <ShimmeredDetailsList
                        detailsListStyles={{ root: { overflowX: "auto", maxWidth: "100%" } }}
                        enableShimmer={this.state.leavesLoading}
                        items={this.state.leaveRequests}
                        columns={leavesCols}
                        selectionMode={0}
                    />
                </div>
            </>
        );
    }
}

export default withRouter(withTranslation()(Leaves as any) as any);