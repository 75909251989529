import * as faceapi from 'face-api.js';

export async function loadModels() {
  const MODEL_URL = 'models/';
  await faceapi.loadSsdMobilenetv1Model(MODEL_URL);
  await faceapi.loadFaceLandmarkModel(MODEL_URL);
  await faceapi.loadFaceRecognitionModel(MODEL_URL);

  console.log('Models loaded');
}
export async function loadLabeledFaceDescriptors(urlImage, firstname) {

  const labels = [firstname];


  var labeledFaceDescriptors = await Promise.all(
    labels.map(async (label) => {
      const imgUrl = urlImage;
      const response = await fetch(imgUrl);
      const blob = await response.blob();
      const img = await faceapi.bufferToImage(blob);
      const faceDescription = await faceapi
        .detectSingleFace(img)
        .withFaceLandmarks()
        .withFaceDescriptor();

      if (!faceDescription) {
        return [];
      }

      const faceDescriptors = [faceDescription.descriptor];
      return new faceapi.LabeledFaceDescriptors(label, faceDescriptors);
    })
  );
  return labeledFaceDescriptors;
}
