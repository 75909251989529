import React, { useState } from "react";
import {
  Box, Divider, Flex, MenuItemProps, FlexItem, MenuButton, Button, CloseIcon, MenuIcon
} from "@fluentui/react-northstar";
import { Link, withRouter } from "react-router-dom";
import AnimateHeight from "react-animate-height";
import { useMinWidth } from "../../hooks/media-query";
//import "./index.scss";

const center = { display: "flex", alignItems: "center" };

export type NavbarProps = {
  title?: string;
  logo?: string;
  items?: NavbarItemProps[];
  sideItems?: NavbarItemProps[];
  primaryColor?: string;
};

export type NavbarItemProps = {
  id: string;
  to?: string;
  content: JSX.Element | string;
  menu?: MenuItemProps[];
  onClick?: () => void;
  primaryColor?: string
};

export function NavbarItem({ id, to, content, menu, onClick, selected, primaryColor }:
  NavbarItemProps & { selected?: boolean }) {
  if (to) {
    return (
      <Box key={"NavBarItemBox-" + id} style={{
        ...center,
        cursor: "pointer",
        whiteSpace: "nowrap",
        borderBottom: selected ? ("2px solid " + primaryColor?? "#005AAB" ) : undefined,
        maxHeight: "500px",
        // overflow: "scroll"
      }} onClick={onClick}>
        {content}
      </Box>
    );
  }
  return (
    <MenuButton key={"NavBarItemMenuButton-" + id} trigger={(
      <Flex style={{
        height: "100%",
        cursor: "pointer",
        borderBottom: selected ? ("2px solid " + primaryColor?? "#005AAB" ) : undefined,
      }} vAlign="center">
        {content}
      </Flex>
    )} menu={menu} />
  );
}

function Navbar({ items, sideItems, logo, title, history, primaryColor }: NavbarProps & { history: any }) {

  const lg = useMinWidth("1200");
  const [expended, setExpended] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const navbarItemCallback = (item: NavbarItemProps, history?: any, i?: number) => () => {
    if (item.onClick) {
      item.onClick();
    }
    if (item.to) {
      history.push(item.to);
    }
    setExpended(false);
    if (i !== undefined) setSelectedIndex(i); else setSelectedIndex(0);
  };

  const brand = (
    <Link to="/" onClick={() => { setSelectedIndex(0) }}>
      <Flex vAlign="center" gap="gap.smaller" styles={{ padding: ".5em 0", color: primaryColor}}>
        {logo && <img src={logo} height={35} alt={title} />}
        {title && <b>{title}</b>}
      </Flex>
    </Link>
  );
  
  if (items){
    for (var i = 0; i < items.length; i++) {
      if (history.location.pathname.includes(items[i].id)) {
        if(selectedIndex !== i){
          setSelectedIndex(i);
        }
        break;
      }
    }
  }

  if (lg) {
    return (
      <Box styles={(vars) => ({ backgroundColor: vars.theme.siteVariables.colors.grey[50] })}>
        <Flex vAlign="stretch" gap="gap.medium" styles={{ padding: "0 .5em" }}>
          {brand}
          <Flex  gap="gap.medium">
            {items && items.map((item, i) => { 
                if (item.menu && item.menu.length > 0){
                  item.menu = item.menu.map((subItem, subI) => {
                    var subItemNav : NavbarItemProps = {...subItem.variables}
                    subI = i + (subI/10);
                    subItem.onClick = () => {navbarItemCallback(subItemNav, history, i)()}
                    return subItem;
                  })
                }
                return (<NavbarItem key={"NavbarItem-" + item.id} {...item} onClick={navbarItemCallback(item, history, i)}
                selected={selectedIndex === i} primaryColor={primaryColor}/>)
              }
            )}
          </Flex>
          <FlexItem grow>
            <div />
          </FlexItem>
          <Flex gap="gap.medium">
            {sideItems && sideItems.map((item) => (
              <NavbarItem key={"NavbarItem-" + item.id} {...item} onClick={navbarItemCallback(item, history)} primaryColor={primaryColor}/>
            ))}
          </Flex>
        </Flex>
        <Divider style={{ padding: "0px" }} />
      </Box>
    );
  }

  return (
    <Box styles={(vars) => ({ backgroundColor: vars.theme.siteVariables.colors.grey[50] })}>
      <Flex column vAlign="stretch" styles={{ padding: "0 .5em" }}>
        <Flex vAlign="stretch">
          {brand}
          <FlexItem grow>
            <div />
          </FlexItem>
          <div style={center}>
            <Button iconOnly icon={expended ? <CloseIcon /> : <MenuIcon />}
              onClick={() => setExpended(!expended)} />
          </div>
        </Flex>
        <AnimateHeight height={expended ? "auto" : 0}>
          <Flex column>
            {items && items.length > 0 && <Divider style={{ padding: "0px" }} />}
            {items && items.map((item, i) => {
                if (item.menu && item.menu.length > 0){
                  item.menu = item.menu.map((subItem, subI) => {
                    var subItemNav : NavbarItemProps = {...subItem.variables}
                    subI = i + (subI/10);
                    subItem.onClick = () => {navbarItemCallback(subItemNav, history, i)()}
                    return subItem;
                  })
                }
                return (
                <Flex key={item.id} vAlign="stretch" styles={{
                  minHeight: "40px",
                  padding: "0 .5em",
                }}>
                  <NavbarItem key={"NavbarItem-" + item.id} {...item} onClick={navbarItemCallback(item, history, i)}
                    selected={i === selectedIndex} primaryColor={primaryColor}/>
                </Flex>
                )
              }
            )}
            {sideItems && sideItems.length && <Divider style={{ padding: "0px" }} />}
            {sideItems && sideItems.map((item) => (
              <Flex key={item.id} vAlign="stretch" styles={{
                minHeight: "40px",
                padding: "0 .5em",
              }}>
                <NavbarItem key={"NavbarItem-" + item.id} {...item} onClick={navbarItemCallback(item, history)} primaryColor={primaryColor}/>
              </Flex>
            ))}
          </Flex>
        </AnimateHeight>
      </Flex>
      <Divider style={{ padding: "0px" }} />
    </Box>
  );
}

export default withRouter(Navbar as any) as unknown as React.FunctionComponent<NavbarProps>;