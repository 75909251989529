import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { Flex, Loader } from "@fluentui/react-northstar";
import { UserStore } from "../../store/user";
import { RootStore } from "../../store/rootStore";

// TODO: implement role based authorization
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AuthRoute (component: React.ComponentType<any>, authorizedRoles: string[] = Roles.ANY) {
  return inject("root", "user", "ui")(observer((props: { root: RootStore, user: UserStore }) => {

    if (props.root.firstLoad){
      props.user.init();
    }

    if (props.user.loading) {
      return (
        <Flex vAlign="center" hAlign="center">
          <Loader />
        </Flex>
      );
    }

    if (props.user.isLoggedIn && authorizedRoles.includes(props.user.role)) 
      return React.createElement(component, {});

    if (props.user.isLoggedIn && !authorizedRoles.includes(props.user.role)) {
      return <Redirect to={`/${props.user.companyName}/attendance`} />;
    }

    return <Redirect to={`${props.user.companyName}/login?redirect=${window.location.pathname.includes('change-password') ? 'attendance' : encodeURIComponent(window.location.pathname)}`} />;
  }));
}

export class Roles {
  static ADMIN = ["Admin"];
  static CHIEF = ["Chief", "Hr"];
  static HR = ["Hr", "Admin"];
  static CLOCKING = ["Clocking", "Hr", "Chief", "Admin"];
  static NOTADMIN = ["Clocking", "Hr", "Chief"];
  static ANY = ["", "Clocking", "Hr", "Chief", "Admin"];
}

export default AuthRoute