import request from "../utils/request";
import { Result, Holiday, HolidaysConfigInput, HolidaysConfigView, HolidaysInput } from "../models";

export async function createHolidays(input: HolidaysInput) {
  const response = await request.post<Result<Holiday[]>>("Holiday/CreateHoliday", input);
  return response.data;
}

export async function getHolidays() {
  const response = await request.get<Result<Holiday[]>>("Holiday/GetListOfHolidays");
  return response.data;
}

export async function getHolidaysConfig(companyId: number) {
  const response = await request.get<Result<HolidaysConfigView>>(`Holiday/GetHolidaysConfig/${companyId}`);
  return response.data;
}

export async function updateHolidaysConfig(input: HolidaysConfigInput) {
  const response = await request.post<Result<HolidaysConfigView>>("Holiday/UpdateHolidaysConfig", input);
  return response.data;
}

export async function getUserHolidays(userId: string) {
  const response = await request.get<Result<Holiday[]>>(`Holiday/${userId}`);
  return response.data;
}

// export async function assignHolidays(input: UserHolidayInput) {
//   const response = await request.post<Result<Holiday[]>>("Holiday/AssignHolidayToUser", input);
//   return response.data;
// }

// export async function unassignHolidays(input: UserHolidayInput) {
//   const response = await request.post<Result<Holiday[]>>("Holiday/UnassignHolidayToUser", input);
//   return response.data;
// }
