import React from "react";
import { Flex, FlexItem, Divider, Button, Input, Alert, TabsIcon, MoreIcon, CalendarIcon, TrashCanIcon, UndoIcon } from "@fluentui/react-northstar";
import { observer, inject } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import { CommandBar, SearchBox, IColumn, ShimmeredDetailsList, TooltipHost, Icon, Dropdown,Checkbox } from "@fluentui/react";
import { GetAllUsers,GetUsers, deleteUser, revokeAccess,restoreUser } from "../../api/account";
import { getBranches, getCompany, getDepartments } from "../../api/Company";
import { UserStore } from "../../store/user";
import { Branch, Department, Roles, UserView } from "../../models";
import moment from "moment";
import Pagination from "react-paginating";
import { copyAndSort, shadeBlend } from "../../utils";
import { withTranslation } from "react-i18next";
import { Roles as AuthRoles } from '../../components/auth-route'
import { throws } from "assert";



interface IProps {
  user: UserStore;
  match: { params: { id?: number } };
  history: any;
  t: any;
}

interface IState {
  users: UserView[];
  allusers: UserView[];
  columns: IColumn[];
  showFilters: boolean;
  isArchivedChecked: boolean;
  loading: boolean;
  items: UserView[];
  count: number;
  offset: number;
  error?: string;
  companyName: string;
  roles: string[];
  branches: Branch[];
  departments: Department[];
  selectedRole: string;
  selectedBranch: string;
  selectedDepartment: string;
  selectedStatus: string;
  filter: {
    employeeId: string;
    fullName: string;
    email: string;
    company: string;
    branch: string;
    department: string;
    phoneNumber: string;
    country: string;
    role: string;
    isarchived: boolean
  }
}

@inject("user")
@observer
class Users extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    const { t, user } = props;

    this.state = {
      offset: 0,
      count: 10,
      items: [],
      users: [],
      allusers: [],
      roles: [],
      branches: [],
      departments: [],
      loading: false,
      showFilters: false,
      isArchivedChecked:false,
      companyName: '',
      selectedRole: '',
      selectedBranch: '',
      selectedDepartment: '',
      selectedStatus:"",
      filter: {
        employeeId: "",
        fullName: "",
        email: "",
        company: "",
        branch: "",
        department: "",
        phoneNumber: "",
        country: "",
        role: "",
        isarchived:false,
      },
      columns:[],
    };
    this.state.columns.forEach((c) => c.onColumnClick = (_: any, column: IColumn) => {
      const newColumns: IColumn[] = this.state.columns.slice();
      const currColumn: IColumn = newColumns.filter((currCol) => {
        return column.key === currCol.key
      })[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newItems = copyAndSort(this.state.items, currColumn.key, currColumn.isSortedDescending);
      this.setState({ columns: newColumns, items: newItems });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  

  fetchData = async () => {
    const { t, user } = this.props
    const checked = this.state.isArchivedChecked
    this.setState({ loading: true });
    const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
    if (id || AuthRoles.ADMIN.includes(user.role)) {
      try {
        if (id){
          var company = (await getCompany(id)).payload;
          this.setState({companyName: company.name})
        }
        this.setcolumn();
        const users =await GetAllUsers(id);
        //await GetUsers(id);
        //const allusers = await GetAllUsers(id);

        // const roles = await getRoles();
        const roles = { payload: ["Clocking", "Chief", "Hr"] };
        this.setState({
          loading: false,
          users: users.payload.filter(u=>u.isArchived == checked),
          items: users.payload.filter(u=>u.isArchived == checked),
          roles: roles.payload
        });
       
        const branches = await getBranches(id)
        this.setState({ branches: branches.payload });
        const departments = await getDepartments(id)
        this.setState({ departments: departments.payload });
        //console.log(users.payload)
        
      } catch (error: any) {
        if (error && error.errors && error.errors.length > 0 && error.errors[0] && error.errors[0].description) {
          alert(error.errors[0].description);
        }
      }
    } else {
      this.setState({
        loading: false,
        error: t("User does not have a valid company"),
      })
    }
  };

  filterData(newState: any) {
    this.setState({
      filter: {
        ...this.state.filter,
        ...newState
      }
    }, () => {
      this.setState(state => {
        return {
          items: state.users.filter(u =>
            (!state.filter.fullName || u.fullName?.toLowerCase().includes(state.filter.fullName.toLowerCase()))
            && (!state.filter.email || u.email?.toLowerCase().includes(state.filter.email.toLowerCase()))
            && (!state.filter.employeeId || u.employeeId?.toLowerCase().includes(state.filter.employeeId.toLowerCase()))
            && (!state.filter.company || u.company?.name?.toLowerCase().includes(state.filter.company.toLowerCase()))
            && (!state.filter.phoneNumber || u.phoneNumber?.toLowerCase().includes(state.filter.phoneNumber.toLowerCase()))
            && (!state.filter.country || u.country?.name?.toLowerCase().includes(state.filter.country.toLowerCase()))
            && (!state.filter.role || u.role?.toLowerCase().includes(state.filter.role.toLowerCase()))
            && (!state.filter.branch || u.branch?.name?.toLowerCase().includes(state.filter.branch.toLowerCase()))
            && (!state.filter.department || u.department?.name?.toLowerCase().includes(state.filter.department.toLowerCase()))
         
          )
        }
      })
    })
  }

  showArchivedUsers (newState: any) {
    this.setState({
      filter: {
        ...this.state.filter,
        ...newState
      }
    }, () => {
      this.setState(state => {
        
          return {
            items: state.users.filter(u =>
              (u.isArchived))  

          }
      })
    })
  }

  setcolumn(){
    const { t, user } = this.props
    const checked = this.state.isArchivedChecked
        this.setState({
          columns: [
            {
              key: "employeeId",
              name: t("Employee Id"),
              minWidth: 170,
              maxWidth: 200,
              onRender: (item: UserView) => item.employeeId,
            },
            {
              key: "fullName",
              name: t("Full Name"),
              minWidth: 100,
              maxWidth: 150,
              onRender: (item: UserView) => item.fullName,
            },
            {
              key: "email",
              name: t("Email"),
              minWidth: 150,
              maxWidth: 200,
              onRender: (item: UserView) => item.email,
            },
            ...(user.role.toLowerCase() === 'admin' ? [{
              key: "company",
              name: t("Company"),
              minWidth: 100,
              maxWidth: 150,
              onRender: (item: UserView) => item.company.name,
            }] : []),
            ...(AuthRoles.HR.includes(user.role) ? [{
              key: "branch",
              name: t("Branch"),
              minWidth: 100,
              maxWidth: 150,
              onRender: (item: UserView) => item.branch ? item.branch.name : "",
            },
            {
              key: "department",
              name: t("Department"),
              minWidth: 100,
              maxWidth: 150,
              onRender: (item: UserView) => item.department ? item.department.name : "",
            }] : []),
            {
              key: "phoneNumber",
              name: t("Phone Number"),
              minWidth: 150,
              maxWidth: 150,
              onRender: (item: UserView) => item.phoneNumber,
            },
            {
              key: "countryName",
              name: t("Country"),
              minWidth: 150,
              maxWidth: 150,
              onRender: (item: UserView) => item.country.name,
            },
            {
              key: "role",
              name: t("Role"),
              minWidth: 150,
              maxWidth: 150,
              onRender: (item: UserView) => item.role,
            },
            {
              key: "createdOn",
              name: t("Created On"),
              minWidth: 150,
              maxWidth: 150,
              onRender: (item: UserView) => moment(item.createdOn).format("DD/MM/YYYY HH:mm:ss"),
            },
            ...(checked ==true?[
              {
                key: "isArchived",
                name: t("is Archived"),
                minWidth: 150,
                maxWidth: 150,
                onRender: (item: UserView) => item.isArchived? "Archived" : "Unarchived" ,
              }] : []),
    
    
            {
              key: "options",
              name: t("Options"),
              minWidth: 250,
              onRender: (item: UserView) => (
                <Flex gap="gap.medium">
                  {(user.role === Roles.Hr || user.role === Roles.Admin) ? <><TooltipHost content={t("Details")}>
                    <Link to={`/users/${item.id}`}>
                      <Button size="small" iconOnly icon={<MoreIcon />} />
                    </Link>
                  </TooltipHost>
                  </> : <></>}
                  <TooltipHost content={t("Timesheet")}>
                    <Link to={`/users/${item.id}/${item.country.isGcc ? 1 : 0}/timesheet`}>
                      <Button size="small" iconOnly icon={<CalendarIcon />} />
                    </Link>
                  </TooltipHost>
                  <TooltipHost content={t("Holidays & Leaves")}>
                    <Link to={`/users/${item.id}/leavesAndHolidays`}>
                      <Button size="small" iconOnly icon={<Icon iconName="Vacation" />} />
                    </Link>
                  </TooltipHost>
                  {(user.role === Roles.Hr || user.role === Roles.Admin) ? <><TooltipHost content={t("Restore")}>
                    {(checked === true)? [
                    <TooltipHost content={t("Archive")}>
                    <Button iconOnly size="small" icon={<UndoIcon />} onClick={async () => {
                      if (window.confirm(t("Are you sure you want to restore this user"))) {
                        await restoreUser(item.id);
                        this.fetchData();
                      }
                    }} />
                     </TooltipHost>
                    ]:[ 
                    <TooltipHost content={t("Archive")}>
                    <Button iconOnly size="small" icon={<TrashCanIcon />} onClick={async () => {
                      if (window.confirm(t("Are you sure you want to archive this user"))) {
                        await deleteUser(item.id);
                        this.fetchData();
                      }
                    }} /> </TooltipHost>]}
                  </TooltipHost></> : <></>}
                  {(user.role === Roles.Admin) ? <><TooltipHost content={t("End Session")}>
                    <Button iconOnly size="small" icon={<Icon iconName="SignOut" />} onClick={async () => {
                      if (window.confirm(t("Are you sure you want to end the session for this user"))) {
                        await revokeAccess(item.id);
                        this.fetchData();
                      }
                    }} />
                  </TooltipHost></> : <></>}
                </Flex>
              ),
            },
          ]
        }) 
      }

  filterDataGlobal(value: string) {
    var valueLower = value.toLowerCase();
    this.setState(state => {
      return {
        items: state.users.filter(u => {
          return u.userName?.toLowerCase().includes(valueLower)
            || u.employeeId?.toLowerCase().includes(valueLower)
            || u.company?.name?.toLowerCase().includes(valueLower)
            || u.email?.toLowerCase().includes(valueLower)
            || u.country?.name?.toLowerCase().includes(valueLower)
            || u.fullName?.toLowerCase().includes(valueLower)
            || u.phoneNumber?.toLowerCase().includes(valueLower)
            || u.role?.toLowerCase().includes(valueLower)
            || u.branch?.name.toLowerCase().includes(valueLower)
            || u.department?.name.toLowerCase().includes(valueLower)
        })
      }
    });
  }


  render() {

    const { t, user } = this.props;

    const {
      offset,
      columns,
      count,
      items,
      showFilters,
      loading,
      roles,
      branches,
      departments,
    } = this.state;

    return (
      <Flex column style={{ overflowX: "hidden" }}>
        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
          <div
            className="mainHeader" style={{ color: user.companyPrimaryColor ?? "#005bab" }}
          >
            <>{t("Employees")}</><br /><>{this.state.companyName}</>
          </div>
        </Flex>
        <Divider />
        <CommandBar
          items={[
            {
              key: "search",
              onRender: () => (
                <Flex vAlign="center" styles={{ paddingRight: "10px" }}>
                  <SearchBox
                    placeholder={t("Search")}
                    iconProps={{ styles: { root: { color: user.companyPrimaryColor } } }}
                    onChange={(ev, input) => this.filterDataGlobal(input ?? "")}
                  />
                </Flex>
              ),
            },
            {
              key: "filters",
              iconProps: { iconName: "filter" },
              buttonStyles: {
                icon: { color: user.companyPrimaryColor },
                iconHovered: { color: shadeBlend(-0.2, user.companyPrimaryColor, undefined) },
              },
              onClick: () => this.setState(state => ({ showFilters: !state.showFilters })),
            },
          ]}
          farItems={[
            {
              key: "sendNotification",
              name: t("Send notification"),
              iconProps: { iconName: "send" },
              buttonStyles: {
                icon: { color: user.companyPrimaryColor },
                iconHovered: { color: shadeBlend(-0.2, user.companyPrimaryColor, undefined) },
              },
              onClick: () => this.props.history.push(`${this.props.user.companyName ? "/" + this.props.user.companyName : ""}/users/sendNotification`),
            },
            ...((user.role === Roles.Admin ) ? [{
              key: "add.users",
              name: t("Add multiple employee"),
              iconProps: { iconName: "add" },
              buttonStyles: {
                icon: { color: user.companyPrimaryColor },
                iconHovered: { color: shadeBlend(-0.2, user.companyPrimaryColor, undefined) },
              },
              onClick: () => this.props.history.push(`${this.props.user.companyName ? "/" + this.props.user.companyName : ""}/users/addmultiple`),
            }] : []),

            ...((user.role === Roles.Admin || user.role === Roles.Hr) ? [{
              key: "add.user",
              name: t("Add employee"),
              iconProps: { iconName: "add" },
              buttonStyles: {
                icon: { color: user.companyPrimaryColor },
                iconHovered: { color: shadeBlend(-0.2, user.companyPrimaryColor, undefined) },
              },
              onClick: () => this.props.history.push(`${this.props.user.companyName ? "/" + this.props.user.companyName : ""}/users/add`),
            }] : []),


          ]} 
          />
        <Divider />

        <FlexItem grow>
          <Flex column>
            {showFilters && (
              <Flex gap="gap.medium" padding="padding.medium">
                <Input
                  placeholder={t("Employee Id")}
                  onChange={(_ev, input) => this.filterData({ employeeId: input?.value ?? "" })}
                />
                <Input
                  placeholder={t("Full Name")}
                  onChange={(_ev, input) => this.filterData({ fullName: input?.value ?? "" })}
                />
                <Input
                  placeholder={t("Email")}
                  onChange={(_ev, input) => this.filterData({ email: input?.value ?? "" })}
                />
                {AuthRoles.ADMIN.includes(user.role) ? <Input
                  placeholder={t("Company")}
                  onChange={(_ev, input) => this.filterData({ company: input?.value ?? "" })}
                /> : <></>}
                <Input
                  placeholder={t("Phone Number")}
                  onChange={(_ev, input) => this.filterData({ phoneNumber: input?.value ?? "" })}
                />
                <Input
                  placeholder={t("Country")}
                  onChange={(_ev, input) => this.filterData({ country: input?.value ?? "" })}
                />
                <Dropdown
                  options={[{ key: "", text: "Select Branch" }, ...branches.map((item) => ({ key: item.id, text: item.name }))]}
                  onChange={(_ev, input) => {
                    var inputText = input?.text ?? ""
                    inputText = inputText == "Select Branch" ? "" : inputText;
                    this.filterData({ branch: inputText });
                    this.setState({ selectedBranch: input?.key as string });
                  }}
                  selectedKey={this.state.selectedBranch}
                  styles={{ dropdown: { width: "150px", borderWidth: "0px" }, title: { backgroundColor: "rgb(243 ,242, 241)", borderWidth: "0px" } }}
                />
                <Dropdown
                  options={[{ key: "", text: t("Select Department") }, ...departments.map((item) => ({ key: item.id, text: item.name }))]}
                  onChange={(_ev, input) => {
                    var inputText = input?.text ?? ""
                    inputText = inputText == t("Select Department") ? "" : inputText;
                    this.filterData({ department: inputText });
                    this.setState({ selectedDepartment: input?.key as string });
                  }}
                  selectedKey={this.state.selectedDepartment}
                  styles={{ dropdown: { width: "150px", borderWidth: "0px" }, title: { backgroundColor: "rgb(243 ,242, 241)", borderWidth: "0px" } }}
                />
                <Dropdown
                  options={[{ key: "", text: t("Select Role") }, ...roles.map((item) => ({ key: item, text: item }))]}
                  onChange={(_ev, input) => {
                    this.filterData({ role: input?.text ?? "" });
                    this.setState({ selectedRole: input?.key as string });
                  }}
                  selectedKey={this.state.selectedRole}
                  styles={{ dropdown: { width: "150px", borderWidth: "0px" }, title: { backgroundColor: "rgb(243 ,242, 241)", borderWidth: "0px" } }}
                />
                 {(user.role == Roles.Admin || user.role == Roles.Hr) ?[
                    <Checkbox label={t("Archived")}
                    onChange={(_ev, input) => {
                      this.setState({isArchivedChecked : !this.state.isArchivedChecked},() => 
                      {
                        console.log(this.state.isArchivedChecked)
                         this.fetchData()
                        //this.showArchivedUsers({})

                        this.setcolumn()
                      })
                    
                    }}
                    />
                 ]:[]}
              </Flex>
            )}

            {this.state.error && <Alert content={this.state.error} />}

            <ShimmeredDetailsList
              selectionMode={0}
              enableShimmer={loading}
              detailsListStyles={{ root: { width: "100vw", overflowY: "auto" } }}
              items={items.slice(offset, Math.min(offset + count, items.length))}
              columns={columns}
            />


            <div style={{ padding: "1em" }}>
              <Pagination
                currentPage={offset / count + 1}
                total={items.length}
                limit={count}
                onPageChange={(page) => this.setState({ offset: ((page ?? 1) - 1) * count })} >
                {({ pages, currentPage, totalPages, getPageItemProps }) => (
                  <div>
                    {pages.map((page) => (
                      <Button key={"UsersPageButton-" + page} iconOnly
                        primary={currentPage === page}
                        {...getPageItemProps({
                          pageValue: page,
                          total: totalPages,
                          onClick: () => this.setState({ offset: ((page ?? 1) - 1) * count }),
                        }) as any}>
                        {page}
                      </Button>
                    ))}
                  </div>
                )}
              </Pagination>
            </div>
          </Flex>
        </FlexItem>
      </Flex >
    );
  }
}
export default withRouter(withTranslation()(Users as any) as any);
function useConst(arg0: () => IColumn[]): IColumn[] {
  throw new Error("Function not implemented.");
}

