import React, { createRef } from "react";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { TextField, ComboBox, Dropdown, } from "@fluentui/react";
import { Divider, Segment, Flex, Dialog, Text, Image, Button, } from "@fluentui/react-northstar";
import { UserStore } from "../../store/user";
import { CountryView, Error, SignUpTrialInput } from "../../models";
import { NotificationStore } from "../../store/notification";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { getCountries } from "../../api/Country";
import { signupTrial } from "../../api/Company";
import Industries from '../../assests/data/industries';
import CompanySizes from '../../assests/data/companySizes';
import CircleCheck from '../../assests/images/circleCheck.png';
import shortid from "shortid";
import { validateEmail } from "../../utils";
import BackgroundArt from '../../assests/images/watermark-cover-art.png';


interface ISignuptrialState {
  // email: string;
  // password: string;
  // emailError: string;
  // passwordError: string;
  // showPass: boolean;
  input: SignUpTrialInput;
  errorDialogOpen: boolean;
  errorMessage: string;
  countries: CountryView[];
  activeStep: number;
  trialSubmited: boolean;
  showSumitTexts: boolean;
  buttonsDisabled: boolean;
  pleaseSpecifyVisible: boolean;
  baseReference: string;
  specifiedReference: string;
}

type Props = Readonly<{ user?: UserStore; notification?: NotificationStore; match?: any, t : any }>;

const steps = [ 
  "Company Details", 
  "User Details", 
  // "User Credentials" 
]

@inject("user")
class Signuptrial extends React.Component<Props, ISignuptrialState> {
  ref: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      // showPass: false,
      // email: "",
      // password: "",
      // emailError: "",
      // passwordError: "",
      input: {
        company: {
          name: "",
          countryId: 0,
          phoneNumber: "",
          industry: "",
          size: "",
          reference: ""
        },
        user: {
          email: "",
          firstName: "",
          lastName: "",
          countryId: 0,
          phoneNumber: "",
          //weekends: [],
          password: `${shortid.generate()}@Abc123`
        }
      },
      errorDialogOpen: false,
      errorMessage: "",
      countries: [],
      activeStep: 0,
      trialSubmited: false,
      showSumitTexts: false,
      buttonsDisabled: false,
      pleaseSpecifyVisible: false,
      baseReference: "",
      specifiedReference: ""
    };
    this.ref = createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const countries = await getCountries();
    this.setState({
      countries: countries.payload
    });
  };

  validateForm = () => {
    var valid = true;
    const { input } = this.state;
    if (input.user.email.length > 0){
      valid = validateEmail(input.user.email);
    } else {
      valid = false
    }

    if (input.company.name.length <= 0){
      valid = false
    }

    if (input.user.firstName.length <= 0 || input.user.lastName.length <= 0){
      valid = false
    }

    if (input.user.countryId === 0){
      valid = false
    }

    return valid
  };

  submit = async () => {
    const { t } = this.props
    try {
      this.setState({buttonsDisabled: true});
      if (this.validateForm()){
        await signupTrial(this.state.input);
        this.setState({trialSubmited: true})
      } else {
        alert(t("Kindly fill all the required fields"));
      }
      this.setState({buttonsDisabled: false});
    } catch (data: any) {
      console.log("error: ", data)
      const errors = data.errors as Error[];
      if (errors) {
        this.setState({
          errorDialogOpen : true,
          errorMessage: `${t(errors[0].description)} ${errors[0].description.toLowerCase().includes("company") ? t('Company-') : t('Email-')} ${errors[0].path}`
        });
      }
      this.setState({buttonsDisabled: false});
    }
  };

  render() {
    const { t } = this.props;
    // const classes = this.useStyles();
    const segmentStyle = { width : '400px'}
    return (
      <>
        <Dialog open={this.state.errorDialogOpen}
          onConfirm={() => this.setState({ errorDialogOpen: false })}
          confirmButton={{ content: "Ok" }}
          content={this.state.errorMessage}
          header={t('Error')} />
        <Flex column padding="padding.medium" hAlign="center" vAlign="center" styles={{height: '100%', backgroundImage: `url(${BackgroundArt})`}} >
          {/* <Flex space="around"> */}
          <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
            {/* <Text 
              weight="bold"
              style={{color: "#005bab", fontSize: "20px"}}
            >{t('Trial Signup')}</Text> */}
          </Flex>
        {
          this.state.trialSubmited ?
            <Flex fill column hAlign="center">
              <Segment color="brand">
                <Flex hAlign="center" space="around" column>
                  <Image src={CircleCheck} styles={{ width: '100px'}} onLoad={() => this.setState({showSumitTexts : true})} />
                  {this.state.showSumitTexts ? <Text styles={{color: "#005bab", fontSize: '16px'}}>{t("Thank you for signing up for a trial.")}</Text>: <></>}
                  {this.state.showSumitTexts ? <Text styles={{color: "#005bab", fontSize: '16px'}}>{t("We have sent you an email with the next steps to proceed.")}</Text>: <></>}
                </Flex>
              </Segment>
            </Flex>
            :
            <>
            <Segment color="brand" styles={segmentStyle}>
              <Divider content={
                    <Text 
                    weight="bold"
                    style={{color: "#005bab", fontSize: "20px"}}
                  >{t('Trial SignUp')}</Text>
                  } />
                <Flex fill column hAlign="center">
                  <Flex column vAlign="start" padding="padding.medium" gap="gap.medium" >
                  <TextField 
                    required={true} 
                    style={{minWidth: '300px'}}
                    label={t("Company Name")}
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.company.name = props ?? "";
                      this.setState({input});
                    }}
                    value={this.state.input.company.name} />
                <TextField required type="email" label={t("Email")} 
                  value={this.state.input.user.email}
                  onChange={(e, val) => {
                    var input = {...this.state.input}
                    input.user.email = val ?? ""
                    this.setState({input})
                  }}
                />
                <Flex column>
                  <TextField
                    required
                    label={t("First Name")} 
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.user.firstName = props ?? "";
                      this.setState({input});
                    }}
                    value={this.state.input.user.firstName} />
                </Flex>
                <Flex column>
                  <TextField
                    required
                    label={t("Last Name")} 
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.user.lastName = props ?? "";
                      this.setState({input});
                    }}
                    value={this.state.input.user.lastName} />
                </Flex>
                <Flex column>
                  <Text weight="semibold" styles={{paddingBottom : '5px'}} >
                    {t("Country")}
                    <span style={{color: 'rgb(164, 38, 44)'}}> *</span>
                  </Text>
                  <ComboBox
                    styles={{ optionsContainerWrapper: { maxHeight: "250px" } }}
                    style={{direction: 'ltr'}}
                    selectedKey={this.state.input.user.countryId}
                    options={this.state.countries.map((x) => ({
                      key: x.id,
                      text: x.name,
                      data: x,
                    }))}
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.user.countryId = props?.data.id;
                      if (!input.user.phoneNumber || input.user.phoneNumber.length < 6){
                        input.user.phoneNumber = '+' + props?.data.phoneCode
                        this.setState({input});
                      }
                      this.setState({input});
                    }} />
                </Flex>
                {/* <Flex column>
                  <Text content={t("Phone Number")} weight="semibold" styles={{paddingBottom : '5px'}} />
                    <PhoneInput
                          international
                          style={{direction: 'ltr', height : '30px'}}
                          value={this.state.input.user.phoneNumber}
                          onChange={(ev : any) => {
                            var input = {...this.state.input}
                            input.user.phoneNumber = ev;
                            this.setState({input});
                          }}/>
                </Flex> */}
                <Flex column>
                  <Text content={t("Referral")} weight="semibold" styles={{paddingBottom : '5px'}} />
                  <Dropdown
                    options={[
                      "Google",
                      "Whatsapp",
                      "Facebook",
                      "Instagram",
                      "Word of Mouth",
                      "In-Person Event",
                      "Partner Company", 
                      "Other"
                    ].map((x, index) => ({
                      key: x,
                      text: t(x),
                      data: index,
                    }))}
                    onChange={(ev, props) => {
                      var input = {...this.state.input};
                      console.log(props?.data);
                      if (props && props?.data > 4)
                        this.setState({pleaseSpecifyVisible: true});
                      else
                        this.setState({pleaseSpecifyVisible: false});
                      var baseReference = props?.key.toString() ?? "";
                      input.company.reference = props?.key.toString() ?? "";
                      this.setState({input, baseReference, specifiedReference: ""});
                    }} />
                    {
                      this.state.pleaseSpecifyVisible ? 
                      <TextField
                        label={t("Please specify")} 
                        onChange={(ev, props) => {
                          var input = {...this.state.input}
                          var specifiedReference = props ?? ""
                          input.company.reference = this.state.baseReference + " - " + specifiedReference ?? this.state.baseReference;
                          this.setState({input, specifiedReference});
                        }}
                        value={this.state.specifiedReference}
                      /> 
                      : null
                    }
                </Flex>
              </Flex>
              <Button 
                disabled={this.state.buttonsDisabled}
                primary
                style={{margin : '10px'}}
                onClick={this.submit}>
                {t('Submit')}
              </Button>
              </Flex>
            </Segment>
              
          </>
        }
        </Flex>
      </>
    );
  }
}

export default withRouter(withTranslation()(Signuptrial as any) as any);
