import React, { createRef } from "react";
import {
  Flex,
  Loader,
  Text,
  FlexItem,
  Button,
  Dropdown as NDropdown,
} from "@fluentui/react-northstar";
import { withRouter } from "react-router-dom";
import { Dropdown, MessageBar, MessageBarType } from "@fluentui/react";
import { UserView, CompanyView, Country, ProfileInput, WorkplaceConfig, Result, TimeZoneInfoView, UserAttendanceImageConfig, UserFaceRecognitionConfig, Branch, Department, UserRulesInput, UserShiftScheduleType, ShiftScheduleType } from "../../../models";
import { getUser, updateProfile, getRoles, GetUsers, getUserRules, updateUserRules } from "../../../api/account";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { UserStore } from "../../../store/user";
import { getCompany, getCompanyRules } from "../../../api/Company";

interface IUserDetailsProps {
    match: 
    { params: 
      {
         id: string; 
      } 
    };
    t: any;
    user: UserStore;
    userInView?: UserView;
    refreshParent : any;
}

interface IUserDetailsState {
    input: UserRulesInput;
    loading: boolean;
    edit: boolean;
    success: boolean;
    companyShiftType:ShiftScheduleType;
    filteredScheduledType:UserShiftScheduleType[];
}

@inject("user")
@observer
class RulesManagement extends React.Component<IUserDetailsProps, IUserDetailsState> {
  constructor(props: IUserDetailsProps) {
    super(props);
    this.state = {
      success: false,
      loading: false,
      edit: true,
      input: {
        attendanceImageConfig: UserAttendanceImageConfig.InheritedFromCompany,
        faceRecognitionConfig: UserFaceRecognitionConfig.InheritedFromCompany,
        workplaceConfig: WorkplaceConfig.CompanyOrUserSpecific,
        userId: "",
        shiftScheduleType:UserShiftScheduleType.InheritedFromCompany
      },
      companyShiftType:ShiftScheduleType.FixedWeekSchedule,
      filteredScheduledType:[UserShiftScheduleType.InheritedFromCompany,UserShiftScheduleType.FixedWeekSchedule,UserShiftScheduleType.VariableShiftSchedule]
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ loading: true });
    try {
      const userRules = await getUserRules(this.props.match.params.id);
      var company;
      var companyRule;
      if(this.props.user.company?.id != undefined) 
      {
         company = await getCompany(this.props.user.company?.id as number)
         companyRule = await (await getCompanyRules(company.payload.id)).payload.shiftScheduleType
        console.log(companyRule)
      }
      this.setState({
        input: {
          attendanceImageConfig: userRules.payload.attendanceImageConfig,
          faceRecognitionConfig: userRules.payload.faceRecognitionConfig,
          workplaceConfig: userRules.payload.workplaceConfig,
          userId: userRules.payload.userId,
          shiftScheduleType:userRules.payload.shiftScheduleType
        }
      });
      if(companyRule != null && companyRule == ShiftScheduleType.VariableShiftSchedule)
      {
        this.setState({filteredScheduledType:
          [UserShiftScheduleType.InheritedFromCompany,UserShiftScheduleType.FixedWeekSchedule]})
      }
    } catch (error: any) {
      console.log("error: ", error);
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  save = async () => {
    this.setState({ loading: true });
    try {
      await updateUserRules(this.state.input.userId, this.state.input);
      this.setState({ success: true });
    } catch (error: any) {
      console.log("error: ", error);
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    } finally {
      this.setState({ loading: false });
      this.props.refreshParent();
    }
   
  };

  render() {
    const { t, userInView,user } = this.props
    const { input, loading } = this.state;
    if (input) {
      return (
        <FlexItem grow>
          <Flex column gap="gap.large" padding="padding.medium" hAlign="center">

            {this.state.success && (
              <MessageBar messageBarType={MessageBarType.success}>
                {t("User Rules Updated Successfully")}
              </MessageBar>
            )}
            {/* <Flex styles={{ alignItems: "center", justifyContent: "center" }}
              padding="padding.medium"> */}
            {/* <FlexItem grow align="stretch"> */}
            <Flex column gap="gap.large" styles={{ width: "380px" }}>
              <Flex column gap="gap.smaller">
                <Text content={t('Attendance Image Rule')} weight="semibold" />
                <Dropdown
                  defaultSelectedKey={this.state.input.attendanceImageConfig}
                  options={Object.values(UserAttendanceImageConfig) //.splice(0, Object.values(UserAttendanceImageConfig).length / 2)
                    .map(x => ({
                      key: x.valueOf().toString(),
                      text: t(x.toString()),
                      id: x.valueOf().toString(),
                    }))}
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    input.attendanceImageConfig = props?.key ? props.key as UserAttendanceImageConfig : UserAttendanceImageConfig.InheritedFromCompany;
                    this.setState({ input });
                  }} />
              </Flex>
              {
                userInView?.company.faceRecognitionEnabled ?
                  <Flex column gap="gap.smaller">
                    <Text content={t('Face Recognition Rule')} weight="semibold" />
                    <Dropdown
                      defaultSelectedKey={this.state.input.faceRecognitionConfig}
                      options={Object.values(UserFaceRecognitionConfig) //.splice(0, Object.values(UserFaceRecognitionConfig).length / 2)
                        .map(x => ({
                          key: x.valueOf().toString(),
                          text: t(x.toString()),
                          id: x.valueOf().toString(),
                        }))}
                      onChange={(ev, props) => {
                        var input = { ...this.state.input }
                        input.faceRecognitionConfig = props?.key ? props.key as UserFaceRecognitionConfig : UserFaceRecognitionConfig.InheritedFromCompany;
                        this.setState({ input });
                      }} />
                  </Flex>
                  : null
              }
              <Flex column gap="gap.smaller" >
                <Text content={t("Workplace Config")} weight="semibold" />
                <Dropdown
                  defaultSelectedKey={this.state.input.workplaceConfig}
                  // disabled={!this.state.edit}
                  options={Object.values(WorkplaceConfig) //.splice(0, Object.values(WorkplaceConfig).length / 2)
                    .map(x => ({
                      key: x.valueOf().toString(),
                      text: t(x.toString()),
                      id: x.valueOf().toString(),
                    }))
                  }
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    input.workplaceConfig = props?.key ? props.key as WorkplaceConfig : WorkplaceConfig.CompanyOrUserSpecific;
                    this.setState({ input });
                  }} />
              </Flex>
              <Flex column gap="gap.smaller" >
                <Text content={t("Shift Schedule Type")} weight="semibold" />
                <Dropdown
                  defaultSelectedKey={this.state.input.shiftScheduleType}
                  // disabled={!this.state.edit}
                  options={Object.values(this.state.filteredScheduledType)
                                                          
                    .map(x => ({
                      key: x.valueOf().toString(),
                      text: t(x.toString()),
                      id: x.valueOf().toString(),
                    }))
                  }
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    input.shiftScheduleType = props?.key ? props.key as UserShiftScheduleType : UserShiftScheduleType.InheritedFromCompany;
                    this.setState({ input });
                  }} />
              </Flex>
              <Flex hAlign="center">
                <Button primary disabled={this.state.loading} content={this.state.loading ? <Loader /> : t("Save")} onClick={() => this.save()} />
              </Flex>
            </Flex>
          </Flex>
        </FlexItem>
      );
    } if (loading) {
      return <FlexItem grow><Flex hAlign="center"><Loader /></Flex></FlexItem>;
    }
    return <div>An Error occured</div>;
  }
}

export default RulesManagement;