import request from "../utils/request";
import { Result, Timesheet, TimesheetApprovalInput, TimesheetStatus, TimesheetView } from "../models";

export async function getTimesheetStatus(userId: string, from: string, to: string) {
  const response = await request.get<Result<TimesheetView>>(`timesheet/${userId}`, {
    params: { from, to },
  });

  return response.data;
}

export async function approveTimesheet(timesheetApprovalInput: TimesheetApprovalInput) {
  const response = await request
    .post<Result<Timesheet>>(`timesheet/ApproveTimesheet`, timesheetApprovalInput);
  return response.data;
}

export async function getTimesheetStatuses() {
  const response = await request.get<Result<TimesheetStatus[]>>("timesheet/statuses");
  return response.data;
}

export async function getTimesheet(id: string | number) {
  const response = await request.get<Result<Timesheet>>(`timesheet/GetTimesheetById/${id}`);
  return response.data;
}