import React from 'react';
import {
    Flex,
    Button,
    ChevronStartIcon,
    ChevronEndIcon
  } from "@fluentui/react-northstar";

import './styles/yearMonthPicker.scss'
import { useTranslation } from 'react-i18next';

export type YearMonthFormProps = {
    date: Date; 
    onChange: Function; 
    fromMonth: Date;
    toMonth: Date;
    isRtl: boolean;
  };

export default function YearMonthForm({ date, onChange, fromMonth, toMonth, isRtl } : YearMonthFormProps) {
    const { t } = useTranslation()
    
    const months = [
        t("January"),
        t("February"),
        t("March"),
        t("April"),
        t("May"),
        t("June"),
        t("July"),
        t("August"),
        t("September"),
        t("October"),
        t("November"),
        t("December")
    ]
  
    const years : number[] = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }
  
    const handleChange = function handleChange(e: any) {
      const { year, month } = e.target.form;
      onChange(new Date(year.value, month.value, 1));
    };
  
    return (
        <Flex gap="gap.medium" padding="padding.medium" vAlign="start" hAlign="center">
            <Button iconOnly icon={isRtl ? <ChevronEndIcon /> : <ChevronStartIcon />} title="Previous Month" onClick={() => {
                var previousMonth = new Date(date)
                previousMonth.setMonth(new Date(previousMonth).getMonth() - 1, 1)
                if (previousMonth.getFullYear() >= years[0])
                    onChange(previousMonth)
            }} />
            <form className="DayPicker-Caption">
                <select
                    name="month" 
                    onChange={handleChange} 
                    value={date.getMonth()}
                    >
                    {months.map((month, i) => (
                        <option key={month} value={i}>
                        {month}
                        </option>
                    ))}
                </select>
                <select 
                    name="year" 
                    onChange={handleChange} 
                    value={date.getFullYear()}
                    >
                    {years.map(year => (
                        <option key={year} value={year}>
                        {year}
                        </option>
                    ))}
                </select>
            </form>
            
            <Button iconOnly icon={isRtl ? <ChevronStartIcon /> : <ChevronEndIcon />} 
            title="Next Month" 
            onClick={() => {
                var nextMonth = new Date(date)
                nextMonth.setMonth(new Date(nextMonth).getMonth() + 1, 1)
                if(nextMonth.getFullYear() <= years[years.length - 1])
                    onChange(nextMonth)
            }} />
        </Flex>
    );
  }