import React from 'react';
import { Flex, Button, Divider, Dropdown,
    Text,
    ExcelColorIcon,
    CloseIcon,
    FilesPdfColoredIcon, } from "@fluentui/react-northstar";
import { ShimmeredDetailsList, IColumn, IDetailsGroupRenderProps, GroupHeader, Icon, Spinner } from "@fluentui/react";
import { withRouter } from 'react-router-dom';
import Pagination from "react-paginating";
import {  ShiftReportView } from "../../../models";

import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import * as ReportsApi from "../../../api/reports";
import * as AccountApi from "../../../api/account";
import { RowInput } from 'jspdf-autotable'
// @ts-ignore
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle'
import './Shifts.scss'
import dateformat from 'dateformat';
import moment from 'moment';

interface IProps {
    user: UserStore;
    match: { params: { id?: string } };
    history: any;
    t: any;
};

interface IState {
    loading: boolean;
    columnNames: any[];
    items: any[];
    columns: IColumn[];
    count: number;
    offset: number;
    options: any;
    groups: any[];
    users: any[];
    dateFrom: Date;
    dateTo: Date;
    shiftsPayload: ShiftReportView[];
    selectedUsers: any[];
}

@inject("user")
@observer
class LeavesBalance extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        var {t} = props;
        this.state = {
            loading: false,
            users: [],
            selectedUsers: [],
            count: 5,
            offset: 0,
            shiftsPayload: [],
            columnNames: [t('Date'), t('Clock In'), t('Clock Out'), t('Work Duration'), t('Breaks Duration'), t("Overtime")],
            options: {
                filter: true,
                filterType: "dropdown",
                responsive: true,
            },
            items: [],
            columns: [
                {
                    key: "date",
                    name: t("Date"),
                    onRender: (item: any) => item.totalDurations ? 
                        <Text styles={{ fontWeight: "bold"}} > {t("Total hours")} </Text> 
                        :
                        dateformat(new Date(item.clockInTime), 'ddd, mmm dS, yyyy'), //new Date(item.clockInTimeUtc).toISOString().split('T')[0],
                    minWidth: 100,
                    maxWidth: 140,
                },
                {
                    key: "clockIn",
                    name: t("Clock In"),
                    onRender: (item: any) => item.totalDurations ? "" : dateformat(new Date(item.clockInTime), 'h:MM TT'), //new Date(item.clockInTimeUtc).toISOString().split('T')[1].split('.')[0],
                    minWidth: 60,
                    maxWidth: 100,
                },
                {
                    key: "clockOut",
                    name: t("Clock Out"),
                    onRender: (item: any) => {
                        if (item.totalDurations){
                            return ""
                        } else {
                            if (new Date(item.clockOutTime?? new Date()).getDate() === new Date(item.clockInTime).getDate())
                                return dateformat(new Date(item.clockOutTime?? new Date()), 'h:MM TT') //.toISOString().split('T')[1].split('.')[0],
                            else
                                return dateformat(new Date(item.clockOutTime?? new Date()), 'h:MM TT ddd, mmm dS, yyyy')
                        }
                    },
                    minWidth: 150,
                    maxWidth: 200,
                },
                {
                    key: "workDuration",
                    name: t("Work Duration"),
                    onRender: (item: any) => item.totalDurations ?
                        (
                            <Text styles={{ fontWeight: "bold"}} >{(Math.round(moment.duration(item.totalWorkingDuration).asHours() * 10) / 10)}</Text>
                            //(item.totalWorkingDuration.split('.').length) <= 2 ? item.totalWorkingDuration.split('.')[0] : item.totalWorkingDuration.split('.')[0] + ' days, ' + item.totalWorkingDuration.split('.')[1]
                        )
                        : 
                        (
                            (item.workingDuration.split('.').length) <= 2 ? item.workingDuration.split('.')[0] : item.workingDuration.split('.')[0] + ' days, ' + item.workingDuration.split('.')[1]
                        ),
                    minWidth: 60,
                    maxWidth: 100,
                },
                {
                    key: "breakDuration",
                    name: t("Breaks Duration"),
                    onRender: (item: any) => {
                        return item.totalDurations ? 
                        (
                            <Text styles={{ fontWeight: "bold"}} >{(Math.round(moment.duration(item.totalBreaksDuration).asHours() * 10) / 10)}</Text>
                            //(item.totalBreaksDuration.split('.').length) <= 2 ? item.totalBreaksDuration.split('.')[0] : item.totalBreaksDuration.split('.')[0] + ' days, ' + item.totalBreaksDuration.split('.')[1]
                        )
                        : 
                        (
                            (item.breaksDuration.split('.').length) <= 2 ? item.breaksDuration.split('.')[0] : item.breaksDuration.split('.')[0] + ' days, ' + item.breaksDuration.split('.')[1]
                        )
                    },
                    minWidth: 60,
                    maxWidth: 100,
                },
                {
                    key: "overtime",
                    name: t("Overtime"),
                    onRender: (item: any) => item.totalDurations ? 
                        (
                            <Text styles={{ fontWeight: "bold"}} >{(Math.round(moment.duration(item.totalOvertimeDuration).asHours() * 10) / 10)}</Text>
                            //(item.totalOvertimeDuration.split('.').length) <= 2 ? item.totalOvertimeDuration.split('.')[0] : item.totalOvertimeDuration.split('.')[0] + ' days, ' + item.totalOvertimeDuration.split('.')[1]
                        ) 
                        : 
                        (
                            (item.overtimeDuration.split('.').length) <= 2 ? item.overtimeDuration.split('.')[0] : item.overtimeDuration.split('.')[0] + ' days, ' + item.overtimeDuration.split('.')[1]
                        ),
                    minWidth: 60,
                    maxWidth: 100,
                },
            ],
            groups: [],
            dateFrom: new Date(new Date().getFullYear(), 0, 1),
            dateTo: new Date()
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    get userId() {
        return this.props.match.params.id ?? this.props.user.userId;
    }

    fetchData = async () => {
        const { t } = this.props
        this.setState({ loading: true, items: [], groups: [], offset: 0 });

        // users dropdown
        if (this.state.users.length === 0) {
            let usersRequest : any = await AccountApi.GetUsers(this.props.user.company?.id??0)
            
            var i;
            for(i = 0; i < usersRequest.payload.length; i++){
                // usersRequest.payload[i].content = usersRequest.payload[i]['fullName'];
                usersRequest.payload[i].header = usersRequest.payload[i]['fullName'];
                usersRequest.payload[i].key = usersRequest.payload[i]['id'];
                // delete usersRequest.payload[i].key1;
            }
            usersRequest.payload.unshift({
                header : t("All Users"),
                key : 0
            });
            this.setState({users: usersRequest.payload})
        }

        var selectedUsers = this.state.selectedUsers.map(selectedUser => (selectedUser.id))

        // report data
        const { payload } = await ReportsApi.getShiftsPerUser({
            userId : this.props.user.userId, 
            from : this.state.dateFrom, 
            to : this.state.dateTo, 
            userIds : selectedUsers.length > 0 ? selectedUsers : [this.props.user.userId],
            departmentIds:[],
            countryIds:[],
            branchIds:[],
            searchMethod:""
        });

        await this.tableDataGenerator(payload)
        
        this.setState({
            loading: false,
            shiftsPayload: payload            
        });
    };

    tableDataGenerator = async (payload : any[]) => {
        // var {t} = this.props

        let body : any[] = [];
        let groups : any[] = [];

        let currentUser = "";
        let shiftsCountPerUser = "";
        let firstUser = true;
        payload.map( (shift, i) => {
            if (currentUser !== shift.user.id)
            {
                if (!firstUser){
                    let row : RowInput = {
                        totalDurations: true,
                        totalWorkingDuration: payload[i - 1].totalWorkingDuration,
                        totalBreaksDuration: payload[i - 1].totalBreaksDuration,
                        totalOvertimeDuration: payload[i - 1].totalOvertimeDuration
                    };
                    body.push(row)
                }
                firstUser = false
                currentUser = shift.user.id
                shiftsCountPerUser = payload.reduce(function(total,x){return x.user.id === currentUser ? total+1 : total}, 0);

                // var currentlyUnavailable = payload.some(shift => {
                //     return shift.user.id && shift.isApproved && new Date(shift.startDate) <= new Date() && new Date(shift.endDate) >= new Date()
                // });

                groups.push({ key: shift.user.id, 
                    name: shift.user.fullName, 
                    startIndex: body.length, 
                    count: shiftsCountPerUser + 1, 
                    level: 0 })
            }
            if (shift){
                let row : RowInput = shift;
                body.push(row)
            }
            return shift
        })

        // last user total durations
        if(payload.length > 0){
            var shift = payload[payload.length - 1]
            let row : RowInput = {
                totalDurations: true,
                totalWorkingDuration: shift.totalWorkingDuration,
                totalBreaksDuration: shift.totalBreaksDuration,
                totalOvertimeDuration: shift.totalOvertimeDuration
            };
            body.push(row)
        }
        this.setState({items : body, groups})
    }

    _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
        // if (props?.group?.level == 0) {
        //   return (
        //       <Flex styles={{
        //           backgroundColor: user.companyPrimaryColor,
        //           color: 'white',
        //           fontSize: '16px',
        //           fontWeight: 'bold',
        //           borderRadius: '3px',
        //           padding: '10px 50px'
        //       }} padding='padding.medium' hAlign='start'>{`${props.group!.name}`}</Flex>
        //   );
        // }
    
        return <GroupHeader {...props} />;
    };

    exportReport = async (format: string) => {
        var selectedUsers = this.state.selectedUsers.map(selectedUser => (selectedUser.id))

        await ReportsApi.exportShiftsPerUser({
            userId : this.props.user.userId, 
            from : this.state.dateFrom, 
            to : this.state.dateTo, 
            userIds : selectedUsers.length > 0 ? selectedUsers : [this.props.user.userId],
            departmentIds:[],
            countryIds:[],
            branchIds:[],
            searchMethod:""
        }, format)

        // const type = response.headers['content-type']
        // const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        // const link = document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.download = 'file.xlsx'
        // link.click()
    }

    onDateChange = (time : any, type : string) => {
        const timeSelected = new Date(time)
        if (type === 'from')
            this.setState({ dateFrom : timeSelected})
        else
            this.setState({ dateTo : timeSelected})
    }


    render() {
        const {
            loading,
            columns,
            items,
            count,
            offset,
            groups,
        } = this.state;

        const { t, user } = this.props

        return (
            <Flex column gap="gap.medium" padding="padding.medium" fill>
                <Flex gap="gap.medium" padding="padding.medium">
                    <Flex column gap="gap.small">
                        <Text content={t("Users")} weight="bold" />
                        <Flex>
                            <Dropdown 
                                multiple
                                search
                                items={this.state.users}
                                value={this.state.selectedUsers}
                                // defaultSelectedKey={0}
                                // options={this.state.users}
                                onChange={(ev, props : any) => {

                                    var filteredList = props.value.find((i : any) => i.key === 0);
                                    var allUsersSelected = filteredList ? filteredList.key === 0 : false;

                                    if (allUsersSelected) {
                                        var allUsersList = this.state.users.filter((u: any) => u.key !== 0);
                                        this.setState({
                                            selectedUsers: allUsersList
                                        });
                                    } else {
                                        this.setState({
                                            selectedUsers: [...props.value]
                                        });
                                    }
                                }}
                                />
                                {this.state.selectedUsers.length > 0 ? <Button text iconOnly icon={<CloseIcon title={t('clear all')}/> } onClick={() => {
                                    this.setState({
                                        selectedUsers: []
                                    });
                                }}></Button> : <></>}
                        </Flex>
                    </Flex>
                    <Flex column gap="gap.small">
                        <Text content={t("From")} weight="bold" />
                        <DateTimePicker
                            key='fromDate'
                            onChange={(e: any) => this.onDateChange(e, 'from')}
                            value={this.state.dateFrom}
                            format="y-MM-dd"
                            calendarIcon={null}
                            clearIcon={null}
                            disableClock
                            className='dateTimePickerCustomStyles'
                        />
                    </Flex>
                    <Flex column gap="gap.small">
                        <Text content={t("To")} weight="bold" />
                        <DateTimePicker
                            key='toDate'
                            onChange={(e: any) => this.onDateChange(e, 'to')}
                            value={this.state.dateTo}
                            format="y-MM-dd"
                            calendarIcon={null}
                            clearIcon={null}
                            disableClock
                            className='dateTimePickerCustomStyles'
                        />
                    </Flex>
                    <Flex vAlign="end" gap="gap.medium">
                        <Button onClick={() => !this.state.loading ? this.fetchData() : {}}>{ !this.state.loading ? t('Search') : <Spinner/>}</Button>
                        <Button iconOnly 
                            icon={!this.state.loading ? <ExcelColorIcon title={t('Export Excel')} /> : <Spinner/>} 
                            onClick={() => !this.state.loading ? this.exportReport('xlsx') : {}}></Button>
                        <Button iconOnly 
                            icon={!this.state.loading ? <FilesPdfColoredIcon title={t('Export Excel')} /> : <Spinner/>} 
                            // icon={<Icon iconName='PDF' title={t('Export Pdf')} ariaLabel={t('Export Pdf')} />} 
                            onClick={() => !this.state.loading ? this.exportReport('pdf') : {}}></Button>
                    </Flex>
                </Flex>
                <Divider/>
                

                <ShimmeredDetailsList
                            selectionMode={0}
                            detailsListStyles={{ root: { width: "100%", overflowY: "auto" } }}
                            enableShimmer={loading}
                            items={items}
                            columns={columns}
                            groups={groups.slice(offset, Math.min(offset + count, groups.length))}
                            groupProps={{
                                showEmptyGroups: true,
                                onRenderHeader:this._onRenderGroupHeader,
                            }}
                            

                            />
                        <div style={{ padding: "1em" }}>

                            <Pagination
                                currentPage={offset / count + 1}
                                total={groups.length}
                                limit={count}
                                onPageChange={(page) => this.setState({ offset: ((page ?? 1) - 1) * count })}>
                                {({ pages, currentPage, hasNextPage, hasPreviousPage, nextPage, previousPage, totalPages, getPageItemProps }) => (
                                    <div>
                                    
                                        {(currentPage !== 1) && (
                                            <Button iconOnly
                                            {...getPageItemProps({
                                                pageValue: 1,
                                                total: totalPages,
                                                onClick: () => this.setState({ offset: 0 })
                                            }) as any}
                                            >
                                            {user.rtl ? ">>" : "<<"}
                                        </Button>
                                        )}

                                        {hasPreviousPage && (
                                        <Button iconOnly
                                            {...getPageItemProps({
                                            pageValue: previousPage,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((previousPage ?? 1) - 1) * count })
                                            }) as any}
                                        >
                                            {user.rtl ? ">" : "<"}
                                        </Button>
                                        )}
                                    
                                        {pages.map((page) => (
                                            <Button key={"ListPageButton-" + page} iconOnly
                                                primary={currentPage === page}
                                                {...getPageItemProps({
                                                    pageValue: page,
                                                    total: totalPages,
                                                    onClick: () => this.setState({ offset: ((page ?? 1) - 1) * count }),
                                                }) as any}>
                                                {page}
                                            </Button>
                                        ))}

                                        {hasNextPage && (
                                            <Button iconOnly
                                            {...getPageItemProps({
                                                pageValue: nextPage,
                                                total: totalPages,
                                                onClick: () => this.setState({ offset: ((nextPage ?? 1) - 1) * count })
                                            }) as any}
                                            >
                                            {user.rtl ? "<" : ">"}
                                        </Button>
                                        )}

                                        {(currentPage !== totalPages) && (
                                        <Button iconOnly
                                            {...getPageItemProps({
                                            pageValue: totalPages,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((totalPages ?? 1) - 1) * count })
                                            }) as any}
                                        >
                                            {user.rtl ? "<<" : ">>"}
                                        </Button>
                                        )}

                                    </div>
                                )}
                            </Pagination>
                        </div>
            </Flex>
        );
    }
}

export default withRouter(withTranslation()(LeavesBalance as any) as any);