import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

interface IProps {
    downloadFile: () => void;
    t: any;
};
interface IState{}

class Shared extends Component<IProps, IState> {

    render() {
        const {t} = this.props
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{textAlign: "center"}}>
                    <img alt="Download" style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "2rem"
                    }} height="300" width="300" src="/images/download.png"/>
                    <h2>{t("The file download will start shortly, please wait")}<br/> 
                    {t("If your download does not start automatically within 1 minute,")} <span style={{color : 'blue', cursor: 'pointer'}} onClick={() => this.props.downloadFile()}>{t("Click here")}</span></h2>
                </div>
            </div>)
    }
}

export default withTranslation()(Shared as any) as any;