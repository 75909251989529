import React, { createRef } from "react";
import {
  Flex,
  Image,
  Loader,
  Text,
  FlexItem,
  Button,
  Dropdown as NDropdown,
  CloseIcon,
  Datepicker
} from "@fluentui/react-northstar";
import { withRouter } from "react-router-dom";
import { Dropdown, TextField, MessageBar, MessageBarType, ComboBox,DatePicker } from "@fluentui/react";
import { UserView, CompanyView, Country, ProfileInput, WorkplaceConfig, Result, TimeZoneInfoView, UserAttendanceImageConfig, UserFaceRecognitionConfig, Branch, Department } from "../../../models";
import { getUser, updateProfile, getRoles, GetUsers } from "../../../api/account";
import { getBranches, getCompanies, getDepartments } from "../../../api/Company";
import { getCountries, getTimeZones } from "../../../api/Country";
import PhoneInput from 'react-phone-number-input';
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { UserStore } from "../../../store/user";
import TimePicker from "react-time-picker"
import { Roles } from "../../../components/auth-route";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";


interface IUserDetailsProps {
  match: {
    params: {
      id: string;
    };
  };
  t: any;
  user: UserStore;
}

interface IUserDetailsState {
  user?: UserView;
  input: ProfileInput;
  loading: boolean;
  edit: boolean;
  companies: CompanyView[];
  countries: Country[];
  timeZones: TimeZoneInfoView[];
  success: boolean;
  roles: string[];
  allUsers: UserView[];
  branches: Branch[];
  departments: Department[];
  emailChanged: boolean;
}

@inject("user")
@observer
class GeneralDetails extends React.Component<IUserDetailsProps, IUserDetailsState> {

  ref: React.RefObject<HTMLInputElement>;

  constructor(props: IUserDetailsProps) {
    super(props);
    this.ref = createRef<HTMLInputElement>();
    this.state = {
      success: false,
      loading: false,
      edit: true,
      roles: [],
      input: {
        image: null,
        employeeId: "",
        companyId: 0,
        countryId: 0,
        title: "",
        firstName: "",
        lastName: "",
        fullName: "",
        linkedInUrl:"",
        birthDate:new Date() as Date,
        phoneNumber: "",
        role: "",
        //weekends: [],
        //workingHours: 0,
        supervisorIds: [],
        timeZone: "",
        //startTime: "09:00",
        //endTime: "18:00",
        fiscalYearStartingMonth: 1,
        branchId: 0,
        departmentId: 0,
        userName:"",
        email:""
      },
      companies: [],
      countries: [],
      timeZones: [],
      allUsers: [],
      branches: [],
      departments: [],
      emailChanged: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }
  



  fetch = async () => {
    this.setState({ loading: true });
    try {
      const user = await getUser(this.props.match.params.id);

      this.setState({
        user: user.payload,
        input: {
          image: null,
          employeeId: user.payload.employeeId,
          companyId: user.payload.company?.id,
          countryId: user.payload.country?.id,
          title: user.payload.title,
          firstName: user.payload.firstName,
          lastName: user.payload.lastName,
          fullName: user.payload.fullName,
          linkedInUrl: user.payload.linkedInUrl,
          birthDate:user.payload.birthDate as Date,
          branchId: user.payload.branchId,
          departmentId: user.payload.departmentId,
          phoneNumber: user.payload.phoneNumber,
          role: user.payload.role,
          //weekends: user.payload.weekends,
          //workingHours: user.payload.workingHours,
          supervisorIds: user.payload.supervisorIds,
          timeZone: user.payload.timeZone,
          //startTime: user.payload.startTime ? user.payload.startTime : "09:00",
          //endTime: user.payload.endTime ? user.payload.endTime : "18:00",
          fiscalYearStartingMonth: user.payload.fiscalYearStartingMonth ?? 1,
          userName: user.payload.userName,
          email: user.payload.email
        },
      });
      //console.log(this.props.user.role)
      if (Roles.ADMIN.includes(this.props.user.role)) {
        const companies = await getCompanies();
        this.setState({ companies: companies.payload });
      }
      if (Roles.HR.includes(this.props.user.role)) {
        const branches = await getBranches(user.payload.company?.id)
        this.setState({ branches: branches.payload });
        const departments = await getDepartments(user.payload.company?.id)
        this.setState({ departments: departments.payload });
        const roles = await getRoles();
        this.setState({ roles: roles.payload });
        const allusers = await GetUsers(user.payload.company?.id);
        this.setState({ allUsers: allusers.payload });
      }
      const countries = await getCountries();
      this.setState({ countries: countries.payload });
      const timeZones = await getTimeZones();
      //console.log(timeZones.payload)
      this.setState({ timeZones: timeZones.payload });
    } catch (error: any) {
      console.log("error: ", error);
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  save = async () => {
    
    this.setState({ loading: true });
    try {
      console.log(this.state.user?.userName,this.state.input.userName)
      if(this.state.user?.userName != this.state.input.userName)
      {
        this.setState({emailChanged: true})
      }
      await updateProfile(this.state.input, this.state.user?.id);
      this.setState({ success: true });
    } catch (error: any) {
      console.log("error: ", error);
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    } finally {
      this.fetch()
      this.setState({ loading: false });
    }
  };
  onFormatDate = (date?: Date): string => {
    console.log(date?.toString())
    return date ? date?.toString().split("T")[0] : ""
    //return !date? '' : !date ? '': (date.getFullYear() ) + '-' + (date.getMonth() + 1) + '-' +date.getDate();
 };

  handleImageChange = (event: React.FormEvent<HTMLInputElement>) => {
    var input = { ...this.state.input }
    input.image = (event.target as any).files[0];
    this.setState({ input });
  };
  closeDioalog = () =>{
    this.setState({emailChanged : false})
  }

  render() {
    const { t } = this.props
    const { user, loading } = this.state;
    if (user) {
      return (
        <FlexItem grow>
          <Flex column gap="gap.large" padding="padding.medium" hAlign="center">
         
            {this.state.success && (
              <MessageBar messageBarType={MessageBarType.success}>
                {t("User Updated Successfully")}
              </MessageBar>
            )}
            {/* <Flex styles={{ alignItems: "center", justifyContent: "center" }}
              padding="padding.medium"> */}
            {/* <FlexItem grow align="stretch"> */}
            <Dialog
                    open={this.state.emailChanged} 
                    maxWidth = 'sm'
                    >
                    <DialogTitle>
                    <b>{t("Confirmation Email")}</b>
                    </DialogTitle>
                    <DialogContent>
                       <Flex column gap="gap.large" vAlign="center" hAlign="center">
                        <Flex>
                        <Text  content={"An email has been sent to "  + this.state.input.userName}/>
                        </Flex>
                        <Flex>
                        <Button primary disabled={this.state.loading} 
                        styles={{width:"50px"}}
                        content={this.state.loading ? <Loader /> : t("OK")} onClick={() => this.closeDioalog()} />
                        </Flex>
                        </Flex>
                    </DialogContent>
                </Dialog>
            <Flex column gap="gap.large" styles={{ width: "380px" }}>
              <Flex column gap="gap.medium" hAlign="center">
                <Image src={(this.state.input.image && URL.createObjectURL(this.state.input.image))
                  ?? user?.imageUrl} styles={{
                    width: "150px",
                    height: "150px",
                    backgroundColor: "lightgrey",
                    objectFit: "cover",
                  }} />
                <input ref={this.ref} onChange={this.handleImageChange}
                  type="file" style={{ display: "none" }}
                  accept="image/*" />
                {
                  this.state.edit
                  && <Button content={t("Upload")} onClick={() => this.ref.current?.click()} />
                }
              
              <Text size="larger" content={user?.userName}/>

              </Flex>
              <Flex column gap="gap.smaller" >
                <Text content={t("Employee Id")} weight="semibold" />
                <TextField readOnly={!this.state.edit}
                  onChange={(ev, props) => {
                    var input = {...this.state.input}
                    input.employeeId = props ?? "";
                    this.setState({input});
                  }}
                  value={this.state.input.employeeId} />
              </Flex>
              <Flex column gap="gap.smaller" >
              {Roles.ADMIN.includes(this.props.user.role) ? 
                <><Text content={t("Email")} weight="semibold" /><TextField readOnly={!this.state.edit}
                    onChange={(_ev, props) => {
                      var input = { ...this.state.input };
                      input.userName = props ?? "";
                      input.email = props ?? "";
                      this.setState({ input });
                    } }
                    value={this.state.input.userName} /></>
                 
               :<></> }
                <Text content={t("Title")} weight="semibold" />
                <TextField readOnly={!this.state.edit}
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    input.title = props ?? "";
                    this.setState({ input });
                  }}
                  value={this.state.input.title} />
              </Flex>
              <Flex column gap="gap.smaller" >
                <Text content={t("First Name")} weight="semibold" />
                <TextField readOnly={!this.state.edit}
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    input.firstName = props ?? "";
                    this.setState({ input });
                  }}
                  value={this.state.input.firstName} />
              </Flex>
              <Flex column gap="gap.smaller" >
                <Text content={t("Last Name")} weight="semibold" />
                <TextField readOnly={!this.state.edit}
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    input.lastName = props ?? "";
                    this.setState({ input });
                  }}
                  value={this.state.input.lastName} />
              </Flex>
              {/* to fix */}
              <Flex column gap="gap.smaller" >
                <Text content={t("Birth Date")} weight="semibold" />
                  <DatePicker 
                   value={new Date(this.state.input.birthDate)}
                   onSelectDate={(date:any) => {
                    var input = { ...this.state.input }
                    input.birthDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000)
                    this.setState({input});
                 }} 
                //formatDate={this.onFormatDate}
                 />
               </Flex>
              <Flex column gap="gap.smaller" >
                <Text content={t("LinkedIn Url")} weight="semibold" />
                <TextField readOnly={!this.state.edit}
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    input.linkedInUrl= props ?? "";
                    this.setState({ input });
                  }}
                  value={this.state.input.linkedInUrl} />
              </Flex>

              <Flex column gap="gap.smaller" >
                <Text content={t("Phone Number")} weight="semibold" />
                <PhoneInput
                  international
                  // disabled={!this.state.edit}
                  style={{ direction: 'ltr' }}
                  value={this.state.input.phoneNumber}
                  onChange={(ev: any) => {
                    var input = { ...this.state.input }
                    input.phoneNumber = ev;
                    this.setState({ input });
                  }}
                />
              </Flex>
              {Roles.ADMIN.includes(this.props.user.role) ?
                <Flex column gap="gap.smaller" >
                  <Text content={t("Company")} weight="semibold" />
                  <Dropdown
                    defaultSelectedKey={this.state.input.companyId}
                    disabled={!this.state.edit}
                    options={this.state.companies.map((x) => ({
                      key: x.id,
                      text: x.name,
                      data: x,
                    }))}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.companyId = props?.data.id;
                      this.setState({ input });
                    }} />
                </Flex> : null}
              {Roles.HR.includes(this.props.user.role) ? <>
                <Flex column gap="gap.smaller" >
                  <Text content={t("Role")} weight="semibold" />
                  <Dropdown
                    defaultSelectedKey={this.state.input.role}
                    disabled={!this.state.edit}
                    options={this.state.roles.map(x => ({
                      key: x,
                      text: x,
                      id: x,
                    }))}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.role = props?.key ? props.key.toString() : "clocking";
                      this.setState({ input });
                    }} />
                </Flex>
                <Flex column gap="gap.smaller" >
                  <Text content={t("Branch")} weight="semibold" />
                  <Dropdown
                    defaultSelectedKey={this.state.input.branchId}
                    disabled={!this.state.edit}
                    options={this.state.branches.map((x) => ({
                      key: x.id,
                      text: x.name,
                      data: x,
                    }))}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.branchId = props?.data.id;
                      this.setState({ input });
                    }} />
                </Flex>
                <Flex column gap="gap.smaller" >
                  <Text content={t("Department")} weight="semibold" />
                  <Dropdown
                    defaultSelectedKey={this.state.input.departmentId}
                    disabled={!this.state.edit}
                    options={this.state.departments.map((x) => ({
                      key: x.id,
                      text: x.name,
                      data: x,
                    }))}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.departmentId = props?.data.id;
                      this.setState({ input });
                    }} />
                </Flex>
                <Flex column gap="gap.smaller" >
                  <Text content={t("Country")} weight="semibold" />
                  <ComboBox
                    styles={{ optionsContainerWrapper: { maxHeight: "250px" } }}
                    style={{ direction: 'ltr' }}
                    selectedKey={this.state.input.countryId}
                    disabled={!this.state.edit}
                    options={this.state.countries.map((x) => ({
                      key: x.id,
                      text: x.name,
                      data: x,
                    }))}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.countryId = props?.data.id;
                      if (!input.phoneNumber || input.phoneNumber.length < 6) {
                        input.phoneNumber = '+' + props?.data.phoneCode
                        this.setState({ input });
                      }
                      this.setState({ input });
                    }} />
                </Flex>
                <Flex column gap="gap.smaller" >
                  <Text content={t("TimeZone")} weight="semibold" />
                  <Dropdown
                    selectedKey={this.state.input.timeZone}
                    options={this.state.timeZones.map((x, index) => ({
                      key: x.id,
                      text: x.displayName,
                      data: x.id,
                    }))}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.timeZone = props?.data;
                      this.setState({ input });
                    }} />
                </Flex>
                <Flex column gap="gap.smaller" >
                  <Text content={t("Fiscal Year Starting Month")} weight="semibold" />
                  <Dropdown
                    selectedKey={this.state.input.fiscalYearStartingMonth.toString()}
                    // disabled={!this.state.edit}
                    options={["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                      .map((x, i) => ({
                        key: (i + 1).toString(),
                        text: t(x.toString()),
                        id: (i + 1).toString(),
                      }))
                    }
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.fiscalYearStartingMonth = parseInt(props?.key ? props?.key.toString() : "1") ?? 1;
                      this.setState({ input })
                    }} />
                </Flex>

              </> : null}
              {/* <Flex column gap="gap.smaller" >
                <Text content={t("Weekend Days")} weight="semibold" />
                <Dropdown
                  defaultSelectedKeys={this.state.input.weekends}
                  disabled={!this.state.edit}
                  options={[
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ].map((x, index) => ({
                    key: index,
                    text: t(x),
                    data: index,
                  }))}
                  multiSelect
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    if (props?.selected) {
                      input.weekends.push(props.data);
                    } else {
                      input.weekends = input.weekends
                        .filter((x) => x !== props?.data);
                    }
                    this.setState({ input });
                  }} />
              </Flex> */}
              {/* <Flex column gap="gap.smaller" >
                <Text content={t("Working Hours")} weight="semibold" />
                <TextField placeholder="9"
                  readOnly={!this.state.edit}
                  type="number" max="24" min="0"
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    input.workingHours = parseFloat(props ? props : "9") ?? "9";
                    this.setState({ input });
                  }}
                  value={this.state.input.workingHours.toString()} />
              </Flex> */}
              {/* <Flex column gap="gap.smaller" >
                <Text content={t("Start Time")} weight="semibold" />
                <TimePicker
                  value={this.state.input.startTime ? this.state.input.startTime : "09:00"}
                  onChange={(e) => {
                    var input = { ...this.state.input }
                    input.startTime = e.toString()
                    this.setState({ input })
                  }}
                />
              </Flex> */}
              {/* <Flex column gap="gap.smaller" >
                <Text content={t("End Time")} weight="semibold" />
                <TimePicker
                  value={this.state.input.endTime ? this.state.input.endTime : "18:00"}
                  onChange={(e) => {
                    var input = { ...this.state.input }
                    input.endTime = e.toString()
                    this.setState({ input })
                  }}
                />
              </Flex> */}
              <Flex column gap="gap.smaller" >
                <Flex column>
                  <Text content={t("Supervisors")} weight="semibold" />
                  <Flex>
                    <NDropdown
                      multiple
                      search
                      disabled={!this.state.edit}
                      items={this.state.allUsers.map(user => ({
                        key: user.id,
                        header: user.fullName,
                      }))}
                      value={this.state.input.supervisorIds.map(sId => ({
                        key: sId,
                        header: this.state.allUsers.find(u => u.id === sId)?.fullName
                      }))}
                      onChange={(ev, props: any) => {
                        if (this.state.edit) {
                          var input = { ...this.state.input }
                          input.supervisorIds = props.value.map((x: any) => x.key)
                          this.setState({ input });
                        }
                      }}
                    />
                    {this.state.input.supervisorIds.length > 0 ? <Button
                      text
                      iconOnly
                      icon={<CloseIcon title={t('clear all')} />}
                      disabled={!this.state.edit}
                      onClick={() => {
                        var input = { ...this.state.input }
                        input.supervisorIds = []
                        this.setState({ input });
                      }}></Button> : <></>}
                  </Flex>
                </Flex>
              </Flex>
              <Flex hAlign="center">
                <Button primary disabled={this.state.loading} content={this.state.loading ? <Loader /> : t("Save")} onClick={() => this.save()} />
              </Flex>
            </Flex>
          </Flex>
        </FlexItem>
      );
    } if (loading) {
      return <FlexItem grow><Flex hAlign="center"><Loader /></Flex></FlexItem>;
    }
    return <div>An Error occured</div>;
  }
}

export default withRouter(withTranslation()(GeneralDetails as any) as any);
