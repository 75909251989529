import React from "react";
import { Flex, FlexItem, Menu, Divider,MenuBehaviorProps,tabListBehavior, } from "@fluentui/react-northstar";
import { withRouter } from "react-router-dom";

import { UserStore } from "../../store/user";
import { inject, observer } from "mobx-react";
import MyRequests from "./tabs/myRequests";
import Assigned from "./tabs/assigned";
import { Separator } from "@fluentui/react";
import { withTranslation } from "react-i18next";


type Props = {
  t: any;
  user: UserStore;
  match: { params: { id?: string } };
  history: any;
};

interface IState {
  currentTab: number | string;
}

@inject("user")
@observer
class Requests extends React.Component<Props, IState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      currentTab: 0
    };
  }


  render() {
    const { t } = this.props
    return (
      <Flex styles={{ height: "100%" }} column>
        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
          <div
            className="mainHeader" style={{ color: this.props.user.companyPrimaryColor ?? "#005bab" }}
          >{t("Requests")} ({this.props.user.fullname})</div>
        </Flex>
        <Divider />
        <Flex>
          <Menu pointing vertical activeIndex={this.state.currentTab}
            accessibility={(props: MenuBehaviorProps) => { return tabListBehavior() }}
            defaultActiveIndex={0}
            onActiveIndexChange={(e, i) => this.setState({ currentTab: i?.activeIndex ?? 0 })}
            styles={{ width: "200px", minWidth: "200px" }}
            items={[
              {
                key: "assigned",
                content: t("Assigned to {{FirstName}}", { FirstName: this.props.user.firstName })
              },
              {
                key: "created",
                content: t("Created by {{FirstName}}", { FirstName: this.props.user.firstName })
              }
            ]} />
          <Separator vertical />
          {/* <FlexItem grow> */}
          {(function (i) {
            switch (i) {
              case 0:
              case "0":
                return <Assigned />;
              case 1:
              case "1":
                return <MyRequests />;
            }
          })(this.state.currentTab)}
          {/* </FlexItem> */}
        </Flex>

      </Flex>
    );
  }
}

export default withRouter(withTranslation()(Requests as any) as any);
