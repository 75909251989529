import React, { createRef } from "react";
import { Flex, Text, Button, Layout, FlexItem, TrashCanIcon, Label, Image, Grid, Alert, Loader } from "@fluentui/react-northstar";
import { TextField, Dropdown, ComboBox, MessageBar, MessageBarType } from "@fluentui/react";
import { withRouter } from "react-router-dom";
import { ChromePicker } from 'react-color';
import { getPlans, addCompany, updateCompany, getCompany, getCompanyRules, updateCompanyRules } from "../../../api/Company";
import {
  SubscriptionPlan,
  CompanyInput,
  CountryView,
  OrganizationView,
  CompanyView,
  AttendanceImageConfig,
  TimeZoneInfoView,
  FaceRecognitionConfig,
  ShiftFlowRuleType,
  CompanyRulesInput,
  ShiftScheduleType,
  TriggerOvertimeVerificationOn,
  BreakConfig
} from "../../../models";
import { getCountries, getTimeZones } from "../../../api/Country";
import { GetOrganizations } from "../../../api/Organization";
import { withTranslation } from "react-i18next";
import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import { getRoles } from "../../../api/account";
import { lightOrDark } from "../../../utils";
import PhoneInput from 'react-phone-number-input';
import { CompanyAddEditStore } from "../../../store/companyAddEdit";
import TimePicker from "react-time-picker";
//import { Checkbox } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';



interface IState {
  loading: boolean;
  input: CompanyRulesInput;
  // company: CompanyView;
  success: boolean;
  alertVisible: boolean;
  alertContent: string;
}

interface IProps {
  match: { params: { id?: number } };
  history: any;
  t: any;
  user: UserStore;
  companyAddEdit: CompanyAddEditStore;
}

@inject("user", "companyAddEdit")
@observer
class RulesManagement extends React.Component<IProps, IState> {
  ref: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      input: {
        id: 0,
        attendanceImageConfig: AttendanceImageConfig.Required,
        faceRecognitionConfig: FaceRecognitionConfig.Required,
        shiftFlowRuleType: ShiftFlowRuleType.Default,
        timesheetCreationDay: 0,
        hideClockingImagesFromTimesheet: false,
        disableAllNotifications: false,
        faceRecognitionEnabled: false,
        leavesApprovalLimitedToSupervisor: false,
        companyId: 0,
        shiftScheduleType:ShiftScheduleType.FixedWeekSchedule,
        triggerOvertimeVerificationOn:TriggerOvertimeVerificationOn.ShiftWorkingHoursFullfiled,
        breakConfig:BreakConfig.Required
      },
      // company: {} as CompanyView,
      alertVisible: false,
      alertContent: "",
    };
    this.ref = createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.fetch();
  }

  get id() {
    if(window.location.href.toLowerCase().includes('mycompany'))
      return this.props.user.company?.id
    return Number(this.props.match.params.id);
  }

  fetch = async () => {
    this.setState({ loading: true })
    if (this.id) {
      const companyRules = await getCompanyRules(this.id);
      // this.props.companyAddEdit.setCompanyName(company.payload.name)
      this.setState({
        input: {
          // ...company.payload,
          id: companyRules.payload.id,
          timesheetCreationDay: companyRules.payload.timesheetCreationDay,
          attendanceImageConfig: companyRules.payload.attendanceImageConfig,
          hideClockingImagesFromTimesheet: companyRules.payload.hideClockingImagesFromTimesheet,
          disableAllNotifications: companyRules.payload.disableAllNotifications,
          faceRecognitionEnabled: companyRules.payload.faceRecognitionEnabled,
          faceRecognitionConfig: companyRules.payload.faceRecognitionConfig,
          shiftFlowRuleType: companyRules.payload.shiftFlowRuleType,
          leavesApprovalLimitedToSupervisor: companyRules.payload.leavesApprovalLimitedToSupervisor,
          companyId: companyRules.payload.companyId,
          shiftScheduleType: companyRules.payload.shiftScheduleType,
          triggerOvertimeVerificationOn: companyRules.payload.triggerOvertimeVerificationOn,
          breakConfig : companyRules.payload.breakConfig
        },
        // company: companyRules.payload
      });
    }
    this.setState({loading: false})
  };

  submit = async () => {
    if (this.id) {
      try {
        this.setState({ loading: true })
        var res = await updateCompanyRules(this.id, this.state.input);
        this.setState({ success: true, loading: false });
      } catch (error) {
        console.log(error)
        alert("Something went wrong!");
      }
    }
  };

  render() {
    const { t } = this.props
    const { loading } = this.state

    return (
      <FlexItem grow>
          <Flex column gap="gap.large" padding="padding.medium" hAlign="center">
            {this.state.success && (
              <MessageBar messageBarType={MessageBarType.success}>
              {t("Company Rules Updated Successfully")}
              </MessageBar>
            )}
            
            <Flex column gap="gap.medium" styles={{ width: "500px", maxWidth: "100%" }}>
              <Flex column gap="gap.smaller">
                <Text content={t('Attendance Image Rule')} weight="semibold" />
                <Dropdown
                  defaultSelectedKey={this.state.input.attendanceImageConfig}
                  options={Object.values(AttendanceImageConfig) //.splice(0, Object.values(AttendanceImageConfig).length/2)
                    .map(x => ({
                    key: x.valueOf().toString(),
                    text: t(x.toString()),
                    id: x.valueOf().toString(),
                  }))}
                  onChange={(ev, props) => {
                    var input = {...this.state.input}
                    input.attendanceImageConfig = props?.key ? props.key as AttendanceImageConfig : AttendanceImageConfig.Required;
                    this.setState({input});
                  }} />
              </Flex>
              { // Face Recognition Enabled Input 
                this.props.user.role.toLowerCase() === 'admin' ? 
                <Flex column gap="gap.smaller" >
                  <Text content={t("Face Recognition Enabled")} weight="semibold" />
                  <Checkbox
                    checked={this.state.input.faceRecognitionEnabled}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.faceRecognitionEnabled = props?? false
                      this.setState({input})
                    }}
                    />
                </Flex> 
                : null
              }
              {
                this.state.input.faceRecognitionEnabled ? 
                <Flex column gap="gap.smaller">
                  <Text content={t('Face Recognition Rule')} weight="semibold" 
                  // styles={{paddingBottom : '5px'}}
                  />
                  <Dropdown
                    defaultSelectedKey={this.state.input.faceRecognitionConfig}
                    options={Object.values(FaceRecognitionConfig) //.splice(0, Object.values(FaceRecognitionConfig).length/2)
                      .map(x => ({
                      key: x.valueOf().toString(),
                      text: t(x.toString()),
                      id: x.valueOf().toString(),
                    }))}
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.faceRecognitionConfig = props?.key ? props.key as FaceRecognitionConfig : FaceRecognitionConfig.Required;
                      this.setState({input});
                    }} />
                </Flex>
                : null
              }
              { // Shiftflow Rule type
                this.props.user.role.toLowerCase() === 'admin' ? 
                <Flex column gap="gap.smaller">
                  <Text content={t('Shift Flow Rule Type')} weight="semibold" 
                  // styles={{paddingBottom : '5px'}}
                  />
                  <Dropdown
                    defaultSelectedKey={this.state.input.shiftFlowRuleType}
                    options={Object.values(ShiftFlowRuleType) //.splice(0, Object.values(ShiftFlowRuleType).length/2)
                      .map(x => ({
                      key: x.valueOf().toString(),
                      text: t(x.toString()),
                      id: x.valueOf().toString(),
                    }))}
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.shiftFlowRuleType = props?.key ? props.key as ShiftFlowRuleType : ShiftFlowRuleType.Default;
                      this.setState({input});
                    }} />
                </Flex>
                : null
              }
              {/* <Flex column gap="gap.smaller" >
                <Text content={t("Timesheet creation and notification day")} weight="semibold" />
                <Dropdown
                  selectedKey={this.state.input.timesheetCreationDay}
                  options={[
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ].map((x, index) => ({
                    key: index,
                    text: t(x),
                    data: index,
                  }))}
                  onChange={(ev, props) => {
                    var input = {...this.state.input}
                    input.timesheetCreationDay = props?.data;
                    this.setState({input});
                  }} />
              </Flex> */}
              <Flex column gap="gap.smaller">
                  <Text content={t('Shift Schedule Type')} weight="semibold" 
                  />
                  <Dropdown
                    defaultSelectedKey={this.state.input.shiftScheduleType}
                    options={Object.values(ShiftScheduleType) //.splice(0, Object.values(ShiftFlowRuleType).length/2)
                      .map(x => ({
                      key: x.valueOf().toString(),
                      text: t(x.toString()),
                      id: x.valueOf().toString(),
                    }))}
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.shiftScheduleType = props?.key ? props.key as ShiftScheduleType : ShiftScheduleType.FixedWeekSchedule;
                      this.setState({input});
                    }} />
                </Flex>
                <Flex column gap="gap.smaller">
                  <Text content={t('Trigger Overtime Verification On')} weight="semibold" 
                  />
                  <Dropdown
                    defaultSelectedKey={this.state.input.triggerOvertimeVerificationOn}
                    options={Object.values(TriggerOvertimeVerificationOn) //.splice(0, Object.values(ShiftFlowRuleType).length/2)
                      .map(x => ({
                      key: x.valueOf().toString(),
                      text: t(x.toString()),
                      id: x.valueOf().toString(),
                    }))}
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.triggerOvertimeVerificationOn = props?.key ? props.key as TriggerOvertimeVerificationOn : TriggerOvertimeVerificationOn.ShiftWorkingHoursFullfiled;
                      this.setState({input});
                    }} />
                </Flex>
                { // Hide Clocking Images Input 
                this.props.user.role.toLowerCase() === 'admin' ? 
                <Flex column gap="gap.smaller">
                  <Text content={t('Break Configuration')} weight="semibold" 
                  />
                  <Dropdown
                    defaultSelectedKey={this.state.input.breakConfig}
                    options={Object.values(BreakConfig) //.splice(0, Object.values(ShiftFlowRuleType).length/2)
                      .map(x => ({
                      key: x.valueOf().toString(),
                      text: t(x.toString()),
                      id: x.valueOf().toString(),
                    }))}
                    onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.breakConfig = props?.key ? props.key as BreakConfig : BreakConfig.Required;
                      this.setState({input});
                    }} />
                </Flex>
              :null}
              { // Hide Clocking Images Input 
                this.props.user.role.toLowerCase() === 'admin' ? 
                <Flex column gap="gap.smaller" >
                  <Text content={t("Hide Clocking Images From Timesheet")} weight="semibold" />
                  <Checkbox
                    checked={this.state.input.hideClockingImagesFromTimesheet}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.hideClockingImagesFromTimesheet = props?? false
                      this.setState({input})
                    }}
                    />
                </Flex> 
                : null
              }
              { // Disable All Notifications Input
                this.props.user.role.toLowerCase() === 'admin' ? 
                <Flex column gap="gap.smaller" >
                  <Text content={t("Disable All Notifications")} weight="semibold" />
                  <Checkbox
                    checked={this.state.input.disableAllNotifications}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.disableAllNotifications = props?? false
                      this.setState({input})
                    }}
                    />
                </Flex> 
                : null
              }
              <Flex column gap="gap.smaller" >
                  <Text content={t("Leaves approval limited to supervisor")} weight="semibold" />
                  <Checkbox
                    checked={this.state.input.leavesApprovalLimitedToSupervisor}
                    onChange={(ev, props) => {
                      var input = { ...this.state.input }
                      input.leavesApprovalLimitedToSupervisor = props?? false
                      this.setState({input})
                    }}
                    />
                </Flex> 
              <Button primary fluid content={loading ? <Loader/> : t("Submit")} onClick={loading ? () => {} : this.submit} />
            </Flex>
          </Flex>
      </FlexItem>
    );
  }
}

export default withRouter(withTranslation()(RulesManagement as any) as any);
