import React from "react";

function Badge (props: {count: number; children: JSX.Element}) {

  const badge = props.count ? props.count && (
    <div style={{
      position: "absolute",
      top: 0,
      right: 0,
      backgroundColor: "red",
      borderRadius: "10px",
      color: "white",
      padding: "0px 5px",
      zIndex: 10,
      fontSize: "12px",
    }}>
      {props.count}
    </div>
  ) : undefined;

  return (
    <div style={{ position: "relative" }}>
      {badge}
      <div style={{ padding: "5px" }}>
        {props.children}
      </div>
    </div>
  );
}

export default Badge
