import React from "react";
import { Flex, Divider, Text } from "@fluentui/react-northstar";
import { withRouter } from "react-router-dom";
import { SearchBox, IColumn } from "@fluentui/react";
import { UserStore } from "../../store/user";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import "./index.scss";
import { Grid } from '@fluentui/react-northstar'
import { Avatar, Card } from '@fluentui/react-northstar'
import { getCurrentStatuses } from "../../api/attendance";
import { CountryView, PoolView, UserView } from "../../models";
import { WaveyFlag } from 'use-flags';
import { COUNTRY_C0DE } from "use-flags/dist/types";
import {
	HubConnectionBuilder,
	HubConnectionState,
	HubConnection,
} from '@microsoft/signalr';



interface IProps {
	user: UserStore;
	match: { params: { id: string } };
	history: any;
	t: any;
};

interface IState {
	dashboardTitle: string;
	users: UserView[];
	poolUsers: UserView[];
	loading: boolean;
	items: UserView[];
	error?: string;
	open: boolean;
	countries: CountryView[];
	filter: {
		fullName: string;
	},
	userId:string;


}



@inject("user")
@observer
class Dashboard extends React.Component<IProps, IState> {

	public timer: any;

	constructor(props: IProps) {
		super(props);


		const { t, user } = props;


		this.state = {
			dashboardTitle: "View  Availabilities                    ",
			items: [],
			loading: false,
			users: [],
			poolUsers: [],
			open: false,
			countries: [],
			filter: {
				fullName: "",
			},
			userId:"",

		};
	}


	componentDidMount() {
		this.fetchData();
		// this.timer = setInterval(() => {
		// 	this.fetchData();
		// }, 120000);
		//this.AddPool();
		this.setUpSignalRConnection();
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	// shuffle = (array: any[] = []) => {

	// 	for (let i = array.length - 1; i > 0; i--) {
	// 		const rand = Math.floor(Math.random() * (i + 1));
	// 		[array[i], array[rand]] = [array[rand], array[i]];
	// 	}

	// }

	setUpSignalRConnection = async () => {
		const connection = new HubConnectionBuilder()
			.withUrl('/api/mainhub')
			.withAutomaticReconnect()
			.build();
		connection.on('Message', (message: string) => {
			console.log('Message', message);
		});
		connection.on('UsersAvailability', (message: string) => {
			//console.log("Message", message)
			this.fetchData()
			this.setState({userId:message})
			this.fetchData()
		});
		try {
			await connection.start();
		} catch (err) {
			console.log(err);
		}

		if (connection.state === HubConnectionState.Connected) {
			connection.invoke('SubscribeToAttendancePool').catch((err: Error) => {
				return console.error(err.toString());
			});
		}
		return connection;
	};

	fetchData = async () => {
		const { t } = this.props
		this.setState({ loading: true });
		try {

			const users = await getCurrentStatuses();

			var countries: CountryView[] = [];
			var country: CountryView;

			for (var i = 0; i < users.payload.length; i++) {
				country = users.payload[i].country;
				if (!countries.some(c => c.iso == country.iso)) {
					countries.push(country);
				}
			}

			this.setState({
				users: users.payload,
				items: users.payload,
				open: false,
				countries,
				loading: false,
			});


		} catch (error: any) {
			console.log(error);
			if (error && error.errors && error.errors.length > 0 && error.errors[0] && error.errors[0].description) {
				alert(error.errors[0].description);
			}
		}
	};

	filterDataGlobal(value: string) {
		var valueLower = value.toLowerCase();
		this.setState(state => {
			return {
				items: state.users.filter((u: any) => {
					return u.fullName?.toLowerCase().includes(valueLower)
						|| u.country?.name?.toLowerCase().includes(valueLower)

				})
			}
		});
	}

	render() {
		const { t, user } = this.props;
		const {

			items,
			loading,
			users,
			open,
		} = this.state;

		var countries = [...this.state.countries]
		//this.shuffle(countries);

		return (
			<Flex column  >
				<Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
					<div
						className="mainHeader" style={{ color: user.companyPrimaryColor ?? "#005bab" }}>

						<> {this.state.dashboardTitle} <></></>

					</div>
				</Flex>
				<Divider />
				<Flex className="search">

					<SearchBox
						placeholder={t("Search")}
						iconProps={{ styles: { root: { color: user.companyPrimaryColor } } }}
						onChange={(ev, input) => this.filterDataGlobal(input ?? "")}
					/>
				</Flex>

				<br></br>

				<Flex column style={{ display: "flex", flexWrap: "wrap", overflow: "hidden", alignItems: "space-around", justifyContent: "space-evenly", margin: "20px", overflowX: "hidden" }} >

					{countries.map((country) => {
						var countryUsers = this.state.items.filter((u) => {
							return country.name == u.country.name;
						})
						//this.shuffle(countryUsers);
						if ((countryUsers.length > 0)) {
							return <fieldset className="fieldset" style={{ overflow: "hidden", borderColor: user.companyPrimaryColor, backgroundColor: user.companyPrimaryColor + "10", borderWidth: "1px", marginBottom: "18px" }} >

								<br></br>

								<legend style={{ color: user.companyPrimaryColor, overflow: "hidden" }}><b> <WaveyFlag country={country.iso.toLowerCase() as COUNTRY_C0DE} fileType='webp' ratio='16x12' /> {country.name}   </b></legend>

								<Grid
									style={{
										display: "flex",
										flexWrap: "wrap",
										alignContent: "flex-start",
										overflow: "hidden",
										justifyContent: "center",

									}}
									content={countryUsers.map((u: any) => {

										return <Card
											style={{
												display: "flex",
												backgroundColor: "transparent",
												width: "266px",
												flexWrap: "wrap",
												alignContent: "flex-start",
												overflow: "hidden",
												justifyContent: "flex-start"

											}}
										>
											<Card.Header >
												<Flex gap="gap.small" >
													<Avatar
														image={u.imageUrl}
														name={u.fullName}
														size="large"
														status={{
															
															color : (() => {
															var currentCustomStatusText = Boolean(u.currentCustomStatusText) ?u.currentCustomStatusText : u.currentStatus;
															switch (currentCustomStatusText) {
																case 'Available':
																	return 'green'
																  case 'Break':
																	return 'orange'
																  case 'Unavailable':
																	return 'grey'
																  case 'Leave':
																  case 'HalfDayLeave':
																	return 'red'
																  case 'Holiday':
																	return 'blue'
																  case 'Unknown':
																	return 'grey'
																  default:
																	return 'orange'

																}
															})()
														}}

													/>
													<Flex column >

														<Text content={u.fullName} weight="bold" style={{ color: user.companyPrimaryColor, fontSize: "15px" }}></Text>
														{(() => {
															var currentCustomStatusText = Boolean(u.currentCustomStatusText) ? u.currentCustomStatusText : u.currentStatus;
															
															switch (currentCustomStatusText) {
																case 'Available':
																	return (u.id == this.state.userId?<Text content={t(currentCustomStatusText)} style={{ color: "green", fontSize: "11px", fontWeight:"bolder" }} ></Text>
																	:<Text content={t(currentCustomStatusText)} style={{ color: "green", fontSize: "11px"}} ></Text>)

																case 'Break':
																	return (u.id == this.state.userId? <Text content={t(currentCustomStatusText)} style={{ color: "rgb(160, 124, 61)", fontSize: "11px", fontWeight:"bolder" }}></Text>
																	:<Text content={t(currentCustomStatusText)} style={{ color: "rgb(160, 124, 61)", fontSize: "11px" }}></Text>)

																case 'Unavailable':
																	return (u.id == this.state.userId? <Text content={t(currentCustomStatusText)} style={{ color: "rgb(175, 174, 171)", fontSize: "11px", fontWeight:"bolder" }}></Text>
																	:<Text content={t(currentCustomStatusText)} style={{ color: "rgb(175, 174, 171)", fontSize: "11px" }}></Text>)

																case 'Leave':
																case 'HalfDayLeave':
																	return (u.id == this.state.userId? <Text content={t(currentCustomStatusText)} style={{ color: "rgb(194, 13, 52)", fontSize: "11px", fontWeight:"bolder"  }}></Text>
																	:<Text content={t(currentCustomStatusText)} style={{ color: "rgb(194, 13, 52)", fontSize: "11px"  }}></Text>)

																case 'Holiday':
																	return (u.id == this.state.userId? <Text content={t(currentCustomStatusText)} style={{ color: "rgb(56, 105, 196)", fontSize: "11px", fontWeight:"bolder" }}></Text>
																	:<Text content={t(currentCustomStatusText)} style={{ color: "rgb(56, 105, 196)", fontSize: "11px" }}></Text>)

																case 'Unknown':
																	return (u.id == this.state.userId? <Text content={t(currentCustomStatusText)} style={{ color: "rgb(0, 0, 0)", fontSize: "11px", fontWeight:"bolder" }}></Text>
																	:<Text content={t(currentCustomStatusText)} style={{ color: "rgb(0, 0, 0)", fontSize: "11px" }}></Text>)

																default:
																	return (u.id == this.state.userId? <Text content={t(currentCustomStatusText)} style={{ color: "rgb(160, 124, 61)", fontSize: "11px", fontWeight:"bolder" }}></Text>
																	:<Text content={t(currentCustomStatusText)} style={{ color: "rgb(160, 124, 61)", fontSize: "11px" }}></Text>)
															}

														})()}

													</Flex>
												</Flex>
											</Card.Header>
										</Card>
									}

									)}
								/>
							</fieldset>
						}

					}

					)}
				</Flex>

			</Flex>


		);

	}
}

export default withRouter(withTranslation()(Dashboard as any) as any);
