import request from "../utils/request";
import { Result, LeaveRequestView, LeaveBalanceView, LeaveType, LeavesConfigView, LeavesConfigInput, LeaveRequestInput, LeaveRequestsView, LeaveRequestApprovalInput, QuotaUnit, LeaveAttachmentsInput, LeaveRequestAttachmentView, LeaveTypeView } from "../models";

export async function getLeaveTypes(userId?: string) {
  const response = await request.get<Result<LeaveTypeView[]>>(`Leave/LeaveTypes/${userId??''}`);
  return response.data;
}

export async function getLeavesConfig(companyId: number, userId?: string) {
  const response = await request.get<Result<LeavesConfigView>>(`Leave/LeavesConfig/${companyId}${userId? "/" + userId : ""}`);
  return response.data;
}

export async function updateLeavesConfig(input: LeavesConfigInput) {
  const response = await request.post<Result<LeavesConfigView>>("Leave/UpdateLeavesConfig", input);
  return response.data;
}

export async function getLeaveRequestsPerUser(userId?: string) {
  const response = await request.get<Result<LeaveRequestsView>>(`Leave/${userId?? ''}`);
  return response.data;
}

export async function getLeaveRequestsAssignedToUser(userId?: string) {
  const response = await request.get<Result<LeaveRequestsView>>(`Leave/LeaveRequestsAssignedToUser/${userId?? ''}`);
  return response.data;
}

export async function getLeaveTypeQuotaUnit(leaveTypeId: number){
  const response = await request.get<Result<QuotaUnit>>(`Leave/QuotaUnit/${leaveTypeId}`);
  return response.data;
}


export async function getBalancePerUser(userId: string) {
  const response = await request
  .get<Result<LeaveBalanceView[]>>(`Leave/GetBalancePerUser/${userId}`);
  return response.data;
}

export async function requestLeave(input: LeaveRequestInput) {
  const response = await request.post<Result<LeaveRequestView>>("Leave/RequestLeave", input);
  return response.data;
}

export async function getLeaveRequest(leaveRequestId: number) {
  const response = await request.get<Result<LeaveRequestView>>(`Leave/GetLeaveRequest/${leaveRequestId}`);
  return response.data;
}

export async function leaveRequestApproval(input : LeaveRequestApprovalInput){
  const response = await request.post<Result<LeaveRequestView>>("Leave/LeaveRequestApproval", input);
  return response.data;
}

export async function leaveRequestUndoApproval(input : LeaveRequestApprovalInput){
  const response = await request.post<Result<LeaveRequestView>>("Leave/LeaveRequestUndoApproval", input);
  return response.data;
}

export async function createLeaveType(input : LeaveType){
  const response = await request.post<Result<LeaveRequestView>>("Leave/CreateLeaveType", input);
  return response.data;
}

export async function addLeaveAttachments(input: LeaveAttachmentsInput) {
  var formData = new FormData();
  formData.append('leaveRequestId', input.leaveRequestId.toString())
  for (var i = 0; i < input.attachments.length; i++)
    formData.append('attachments', input.attachments[i])

  const response = await request.post<Result<LeaveRequestAttachmentView[]>>(`Leave/AddLeaveAttachments`,
    formData);
  return response.data;
}

export async function getLeaveRequestAttachments(leaveRequestId: number) {
  const response = await request
    .get<Result<LeaveRequestAttachmentView[]>>(`Leave/GetLeaveRequestAttachments/${leaveRequestId}`);
  return response.data;
}

export async function removeLeaveAttachment(leaveRequestId : number, leaveAttachmentId : number) {
  const response = await request.post<Result<LeaveRequestAttachmentView[]>>(`Leave/RemoveLeaveAttachment/${leaveRequestId}/${leaveAttachmentId}`);
  return response.data;
}

// export async function getCompanyLeaveTypesDictionary(companyId: number) {
//   const response = await request.get<Result<CompanyLeaveType[]>>(`Leave/CompanyLeaveTypes/dictionary/${companyId}`);
//   return response.data;
// }

// export async function assignLeavesToCompany(companyLeaveTypeInput: CompanyLeaveTypeInput) {
//   const response = await request.post<Result<CompanyLeaveTypeInput>>("Leave/AssignLeavesTocompany", companyLeaveTypeInput);
//   return response.data;
// }

// export async function assignLeavesToUser(input:AllowedLeaveInput) {
//   const response = await request.post<Result<AllowedLeave>>("Leave/AssignLeavesTouser", input


//   );
//   return response.data;
// }