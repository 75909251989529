import React, { useState } from "react"
import { I18nextProvider, useTranslation } from "react-i18next"
import {
  Provider, teamsTheme, Avatar, Layout, mergeThemes, Button, Popup, Flex, LockIcon, UserFriendsIcon, Label, BellIcon, Text
} from "@fluentui/react-northstar"
import { Icon } from "@fluentui/react"
import { ThemeProvider as FluentUIProvider } from '@fluentui/react'
import { BrowserRouter, Route, Switch, Redirect, withRouter, useHistory } from "react-router-dom"
import * as MobxReact from "mobx-react"
import Navbar, { NavbarItemProps } from "./components/navbar"
import NotificationList from "./components/notifications"
import Footer from "./components/footer"
import AuthRoute, { Roles } from "./components/auth-route"
import Attendance from "./views/attandence"
import store from "./store"
import Timesheet from "./views/timesheet/tabs"
import Login from "./views/account/login"
import NotFound from "./views/error/404"
import LeavesAndHolidays from "./views/leavesAndHolidays"
import Organizations from "./views/Organizations"
import ResetPassword from "./views/account/reset-password"
import Profile from "./views/account/profile"
import Users from "./views/users"
import Companies from "./views/Companies"
import UserDetails from "./views/users/UserDetails"
import AddUsers from "./views/users/add-users"
import AddUser from "./views/users/add-user"
import OnDemandNotification from "./views/users/onDemandNotification"
import CompanyTabs from "./views/Companies/CompanyAddEdit"
import AddOrganization from "./views/Organizations/add-organization"
import Notifications from "./views/notifications"
import Requests from "./views/Requests"
import Reports from "./views/Reports"
import SignupTrial from "./views/Signup/signuptrial"
import PrivacyPolicy from "./views/privacyPolicy"
import AddRequest from "./views/Requests/add-request"
import Maps from './views/map'
import Widget from './views/widget'
import 'moment/min/locales'
import { getLocale, changeLocale } from "./api/account"
import LeavesBalanceReportExport from './components/reportsDownload/LeavesBalance'
import UsersStatusesReportExport from './components/reportsDownload/UsersStatuses'
import ClockingsReportExport from './components/reportsDownload/Clockings'
import ShiftsReportExport from './components/reportsDownload/Shifts'
import LeavesReportExport from './components/reportsDownload/Leaves'
import OvertimeReportExport from './components/reportsDownload/Overtime'
import LeavesBalanceReport from './views/Reports/tabs/LeavesBalance'
import UsersStatusesReport from './views/Reports/tabs/UsersStatuses'
import ClockingsReport from './views/Reports/tabs/Clockings'
import ShiftsReport from './views/Reports/tabs/Shifts'
import LeavesReport from './views/Reports/tabs/Leaves'
import OvertimeReport from './views/Reports/tabs/Overtime'
import NotificationBell from './components/notifications/bell'
import { fluentUINorthStarTheme, fluentUITheme, materialUITheme } from './themes'
import Dashboard from "./views/Dashboard"
import WeekSchedule from "./views/WeekSchedule"
import { Card } from '@fluentui/react-northstar'
import CurrentStatus from "./components/currentStatus";
import { EditIcon } from "@fluentui/react-northstar";
import { ThemeProvider as MaterialUIProvider } from '@mui/material/styles';

// import CompanyDetails from "./views/Companies/details";

declare const baseUrl: string;

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function () {

  const { t, i18n } = useTranslation()
  const [availabilityStatusDialogOpen, setAvailabilityStatusDialogOpen] = useState(false);

  const { user, notification, ui, companyAddEdit } = store.rootStore


  // const rtlLangs = ['ar']

  // reaction
  //       (
  //           () => this.user.locale,
  //           (locale) => {
  //           console.log("reaction fired locale: ", locale)
  //           i18n.changeLanguage(locale);
  //           moment.locale(locale);

  //           if (rtlLangs.indexOf(locale) > -1) {
  //               loadTheme({rtl : true})
  //               user.rtl = true;

  //               if (locale == 'ar') {
  //               moment.defineLocale('ar-arNum', {
  //                   parentLocale: 'ar',
  //                   preparse: function (str : string) {
  //                   return str;
  //                   },
  //                   postformat: function (str : string) {
  //                   return str;
  //                   }
  //               });
  //               moment.locale('ar-arNum');
  //               }
  //           }
  //           else {
  //               user.rtl = false;
  //               loadTheme({rtl : false})
  //           }
  //           }
  //       );

  const Content = withRouter(MobxReact.observer(() => {
    const { push } = useHistory();
    const navBarItems: NavbarItemProps[] = [
      {
        id: "myBluAttend", content: t("My BluAttend"), to: "", roles: Roles.CLOCKING, menu: [
          //{  content: "Attendance", variables: { key: "attendance", to: "/attendance", roles: Roles.CLOCKING } },
          { key: "timesheet", content: t("Timesheet"), variables: { id: "timesheet", to: "/timesheet", roles: Roles.CLOCKING } },
          { key: "leavesAndHolidays", content: t("Holidays & Leaves"), variables: { id: "leavesAndHolidays", to: "/leavesAndHolidays", roles: Roles.CLOCKING } },
          { key: "requests", content: t("Requests"), variables: { id: "requests", to: "/requests", roles: user.isSupervisor ? Roles.CLOCKING : Roles.HR } },
        ]
      },
      { id: "mycompany", content: t("My Company"), to: "/mycompany", roles: Roles.HR },
      {
        id: "users", content: t("Employees"), to: "", roles: user.isSupervisor ? Roles.CLOCKING : Roles.HR, menu: [
          { key: "usersList", content: t("Employees List"), variables: { id: "usersList", to: "/users", roles: user.isSupervisor ? Roles.CLOCKING : Roles.HR } },
          { key: "usersSchedule", content: t("Employees Schedule"), variables: { id: "usersSchedule", to: "/schedule", roles: user.isSupervisor ? Roles.CLOCKING : Roles.HR } },
        ]
      },
      { id: "companies", content: t("Companies"), to: "/companies", roles: Roles.ADMIN },
      { id: "organizations", content: t("Organizations"), to: "/organizations", roles: Roles.ADMIN },
      { id: "maps", content: t("Maps"), to: "/maps", roles: user.isSupervisor ? Roles.CLOCKING : Roles.CHIEF },
      { id: "reports", content: t("Reports"), to: "/reports", roles: Roles.CHIEF },
      { id: "dashboard", content: t("Colleagues Availability"), to: "/dashboard", roles: Roles.NOTADMIN },


    ].filter(x => {
      if (x.menu && x.menu.length > 0)
        x.menu = x.menu.filter(y => (y.variables.roles ?? Roles.ANY).includes(user.role))
      return (x.roles ?? Roles.ANY).includes(user.role)
    });

    var languagesAllowed = ['en', 'ar', 'fr']
    if (user.companyLocales) {
      languagesAllowed = user.companyLocales.split(',')
    }

    const languagesDropDown = {
      id: "language",
      content:
        <Button
          text
          iconOnly
          icon={
            <Icon iconName="LocaleLanguage" style={{ fontSize: "20px" }}></Icon>
          }
          title={t("Change Language")}
        ></Button>
      ,
      menu: languagesAllowed.map(lang => {
        var langName = "English"
        switch (lang) {
          case 'ar':
            langName = "العربية"
            break
          case 'fr':
            langName = "Français"
            break
          default:
            break
        }
        return {
          key: lang,
          content: <Label
            styles={{
              backgroundColor: 'transparent',
              fontWeight: 600,
              color: user.locale === lang ? user.companyPrimaryColor : 'rgb(50, 49, 48)'
            }}
          >
            {langName}
          </Label>,
          onClick: async () => {
            if (user.isLoggedIn) {
              if (lang !== (await getLocale()).payload) {
                await changeLocale(lang);
                user.locale = lang
                notification.loadInitial()
              }
            } else {
              user.locale = lang
            }
          }
        }
      })
      // menu: [
      //   {
      //     key : "en",
      //     content : <Label
      //       styles={{
      //         backgroundColor: 'transparent',
      //         fontWeight: 600,
      //         color : user.locale == 'en' ? user.companyPrimaryColor : 'rgb(50, 49, 48)'
      //       }}
      //     >English</Label>,
      //     onClick : async () => {
      //       if (user.isLoggedIn){
      //         if ("en" != (await getLocale()).payload){
      //           await changeLocale("en");
      //           user.locale = "en"
      //         }
      //       } else {
      //         user.locale = "en"
      //       }
      //     }
      //   },
      //   {
      //     key : "ar",
      //     content : <Label
      //       styles={{
      //         backgroundColor: 'transparent',
      //         fontWeight: 600,
      //         color : user.locale == 'ar' ? user.companyPrimaryColor : 'rgb(50, 49, 48)'
      //       }}
      //     >العربية</Label>,
      //     onClick : async () => {
      //       if (user.isLoggedIn){
      //         if ("ar" != (await getLocale()).payload){
      //           await changeLocale("ar");
      //           user.locale = "ar"
      //         }
      //       } else {
      //         user.locale = "ar"
      //       }
      //     }
      //   },
      //   {
      //     key : "fr",
      //     content : <Label
      //       styles={{
      //         backgroundColor: 'transparent',
      //         fontWeight: 600,
      //         color : user.locale == 'fr' ? user.companyPrimaryColor : 'rgb(50, 49, 48)'
      //       }}
      //     >Français</Label>,
      //     onClick : async () => {
      //       if (user.isLoggedIn){
      //         if ("fr" != (await getLocale()).payload){
      //           await changeLocale("fr");
      //           user.locale = "fr"
      //         }
      //       } else {
      //         user.locale = "fr"
      //       }
      //     }
      //   },
      // ]
    }

    const notificationsMenu = {
      id: "notification",
      content: (
        <Popup
          pointing
          position='below'
          trigger={(
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a>
              <NotificationBell />
            </a>
          )}
          content={(
            <Flex hAlign="end" column>
              <Flex>
                <Button text onClick={() => notification.dimissAll()}>{t("Dismiss all")}</Button>
                <Button text onClick={() => { push(`/${user.companyName}/notifications`) }}>
                  {t("View all")}
                </Button>
              </Flex>
              <Flex styles={{
                maxHeight: "500px",
                width: "500px",
                maxWidth: "100%",
              }}>
                <NotificationList />
              </Flex>
            </Flex>
          )}
        />
      ),
    }

    const loggedOutNavBarItems = !window.location.pathname.includes('signuptrial') ? [
      languagesDropDown,
      { id: "login", content: t("Login"), to: "/login" }
    ] : [{ id: "login", content: t("Login"), to: "/login" }];

    const navBarSideItems = user.isLoggedIn ? [
      // uncomment to show notification list
      notificationsMenu,
      languagesDropDown,
      {
        id: "login",
        //  content: <Layout gap="0.5em" end={user.username} main={ <Avatar image={user.imageUrl ? baseUrl + user.imageUrl : logo} />
        //  } />,

        content:

          <Card
            style={{
              padding: "0px",
              justifyContent: "center",
              width: "max-content",
              paddingTop: "5px"
            }}>
            <Card.Header >
              <Flex gap="gap.small" >
                <Avatar
                  image={user.imageUrl}
                  name={user.fullname}
                  size="medium"
                  status={{
                    color: (() => {
                      var currentCustomStatusText = Boolean(user.currentCustomStatusText) ? user.currentCustomStatusText : user.currentStatus;
                      {console.log(user.currentStatus)}
                      switch (currentCustomStatusText) {
                        case 'Available':
                          return 'green'
                        case 'Break':
                          return 'orange'
                        case 'Unavailable':
                          return 'grey'
                        case 'Leave':
                        case 'HalfDayLeave':                      
                          return 'red'
                        case 'Holiday':
                          return 'blue'
                        case 'Unknown':
                          return 'grey'
                        default:
                          return 'orange'
                      }
                    })()

                  }}
                />
                <Flex column >
                  <Text content={user.fullname} weight="bold" style={{ color: user.companyPrimaryColor, fontSize: "12px" }}></Text>

                  {/* {(() => {
                    var currentCustomStatusText = Boolean(user.currentCustomStatusText) ? " - " + user.currentCustomStatusText : "";

                    switch (user.currentStatus) {
                      case 'Available':
                        return (<Text content={t("Available") + currentCustomStatusText} style={{ color: "green", fontSize: "11px" }} ></Text>)

                      case 'Break':
                        return (<Text content={t("Break") + currentCustomStatusText} style={{ color: "rgb(160, 124, 61)", fontSize: "11px" }}></Text>)

                      case 'Unavailable':
                        return (<Text content={t("Unavailable") + currentCustomStatusText} style={{ color: "rgb(175, 174, 171)", fontSize: "11px" }}></Text>)

                      case 'Leave':
                        return (<Text content={t("Leave") + currentCustomStatusText} style={{ color: "rgb(194, 13, 52)", fontSize: "11px" }}></Text>)

                      case 'Holiday':
                        return (<Text content={t("Holiday") + currentCustomStatusText} style={{ color: "rgb(56, 105, 196)", fontSize: "11px" }}></Text>)

                      case 'Unknown':
                        return (<Text content={t("Unknown") + currentCustomStatusText} style={{ color: "rgb(0, 0, 0)", fontSize: "11px" }}></Text>)
                    }

                  })()} */}

                  {(() => {
                    var currentCustomStatusText = Boolean(user.currentCustomStatusText) ? user.currentCustomStatusText : user.currentStatus;

                    switch (currentCustomStatusText) {
                      case 'Available':
                        return (<Text content={t(currentCustomStatusText)} style={{ color: "green", fontSize: "11px" }} ></Text>)

                      case 'Break':
                        return (<Text content={t(currentCustomStatusText)} style={{ color: "rgb(160, 124, 61)", fontSize: "11px" }}></Text>)

                      case 'Unavailable':
                        return (<Text content={t(currentCustomStatusText)} style={{ color: "rgb(175, 174, 171)", fontSize: "11px" }}></Text>)

                      case 'Leave':
                      case 'HalfDayLeave':
                        return (<Text content={t(currentCustomStatusText)} style={{ color: "rgb(194, 13, 52)", fontSize: "11px" }}></Text>)

                      case 'Holiday':
                        return (<Text content={t(currentCustomStatusText)} style={{ color: "rgb(56, 105, 196)", fontSize: "11px" }}></Text>)

                      case 'Unknown':
                        return (<Text content={t(currentCustomStatusText)} style={{ color: "rgb(0, 0, 0)", fontSize: "11px" }}></Text>)

                      default:
                        return (<Text content={t(currentCustomStatusText)} style={{ color: "rgb(160, 124, 61)", fontSize: "11px" }}></Text>)
                    }

                  })()}

                </Flex>
              </Flex>
            </Card.Header>
          </Card>,
        menu: [
          {
            key: "profile",
            content: t("Profile"),
            icon: <UserFriendsIcon />,
            onClick: () => push("/account/profile"),
          },
          {
            key: "customstatus",
            // disabled: true,
            content: t("Set Status Message"),
            icon: <EditIcon />, //styles={{color:"rgb(72, 70, 68)"}}
            onClick: () => { setAvailabilityStatusDialogOpen(true) }
          },
          { key: "logout", content: t("Logout"), icon: <LockIcon />, onClick: () => user.logout() },
        ],
      },
    ] : loggedOutNavBarItems;

    const closeAvailabilityStatusDialog = () => {
      setAvailabilityStatusDialogOpen(false);
    }

    // eslint-disable-next-line no-return-assign
    return (

      <>
        <MobxReact.Provider root={store.rootStore} user={user} ui={ui} notification={notification} companyAddEdit={companyAddEdit}>
          <MaterialUIProvider theme={materialUITheme(user)}>
            <FluentUIProvider applyTo="body" theme={fluentUITheme(user)}>
              <Provider rtl={user.rtl} theme={(window as any).themes = mergeThemes(teamsTheme, fluentUINorthStarTheme(user))}>
                <I18nextProvider i18n={i18n}>
                  <Flex column styles={{
                    height: "100vh",
                    width: "100%"
                  }}>
                    <CurrentStatus
                      user={user}
                      semibold={false}
                      asDialog={true}
                      dialogIsOpen={availabilityStatusDialogOpen}
                      closeAvailabilityStatusDialog={closeAvailabilityStatusDialog}
                    ></CurrentStatus>
                    {window.location.href.indexOf("widget") == -1 ? 
                      <Navbar title={user.companyName ?? "BluAttend"} logo={user.companyLogo}
                        items={navBarItems} sideItems={navBarSideItems} primaryColor={user.companyPrimaryColor} />
                    : null
                    }
                    <div style={{ flex: 1 }}>
                      <Switch>
                        <Route path="/:company?/login" component={Login} />/account/profile
                        <Route exact path="/" component={Login}>
                          <Redirect to="/attendance" />
                        </Route>
                        <Route path="/signuptrial" component={SignupTrial} />
                        <Route path="/privacypolicy" component={PrivacyPolicy} />
                        <Route path="/reset-password" component={ResetPassword} />
                        <Route path="/account" component={AuthRoute(Profile, Roles.CLOCKING)} />
                        <Route path="/:company?/attendance/:action?/:shiftId?" component={AuthRoute(Attendance, Roles.CLOCKING)} />
                        <Route path="/:company?/timesheet/:timesheetId?/:isGCC?" component={AuthRoute(Timesheet, Roles.CLOCKING)} />
                        <Route path="/:company?/leavesAndHolidays" component={AuthRoute(LeavesAndHolidays, Roles.CLOCKING)} />
                        <Route path="/:company?/users/addmultiple" component={AuthRoute(AddUsers, Roles.ADMIN)} />
                        <Route path="/:company?/users/add" component={AuthRoute(AddUser, Roles.HR)} />
                        <Route path="/:company?/users/sendNotification" component={AuthRoute(OnDemandNotification, Roles.CLOCKING)} />
                        <Route path="/:company?/users/:id/:isGCC?/timesheet/:action?/:shiftId?/:date?/:time?" component={AuthRoute(Timesheet, Roles.CLOCKING)} />
                        <Route path="/:company?/users/:id/leavesAndHolidays" component={AuthRoute(LeavesAndHolidays, Roles.CLOCKING)} />
                        <Route path="/:company?/users/:id" component={AuthRoute(UserDetails, Roles.HR)} />
                        <Route path="/:company?/users" component={AuthRoute(Users, Roles.CLOCKING)} />
                        <Route path="/companies/add" component={AuthRoute(CompanyTabs, Roles.ADMIN)} />
                        <Route path="/companies/:id/users" component={AuthRoute(Users, Roles.ADMIN)} />
                        <Route path="/companies/:id" component={AuthRoute(CompanyTabs, Roles.ADMIN)} />
                        <Route path="/mycompany" component={AuthRoute(CompanyTabs, Roles.HR)} />
                        <Route path="/companies" component={AuthRoute(Companies, Roles.ADMIN)} />
                        <Route path="/organizations/:id/companies" component={AuthRoute(Companies, Roles.ADMIN)} />
                        <Route path="/organizations/add" component={AuthRoute(AddOrganization, Roles.ADMIN)} />
                        <Route path="/organizations" component={AuthRoute(Organizations, Roles.ADMIN)} />
                        <Route path="/:company?/notifications" component={AuthRoute(Notifications, Roles.CLOCKING)} />
                        <Route path="/:company?/maps" component={AuthRoute(Maps, Roles.CLOCKING)} />
                        <Route path="/:company?/requests/:id" component={AuthRoute(AddRequest, Roles.CLOCKING)} />
                        <Route path="/:company?/requests/add-request" component={AuthRoute(AddRequest, Roles.CLOCKING)} />
                        <Route path="/:company?/requests" component={AuthRoute(Requests, Roles.CLOCKING)} />
                        <Route path="/reports/LeavesBalance/:parameters/:format" component={AuthRoute(LeavesBalanceReportExport, Roles.CHIEF)} />
                        <Route path="/reports/UsersStatuses/:from/:to/:parameters/:format" component={AuthRoute(UsersStatusesReportExport, Roles.CHIEF)} />
                        <Route path="/reports/Clockings/:from/:to/:parameters/:format" component={AuthRoute(ClockingsReportExport, Roles.CHIEF)} />
                        <Route path="/reports/Leaves/:from/:to/:parameters/:format" component={AuthRoute(LeavesReportExport, Roles.CHIEF)} />
                        <Route path="/reports/Shifts/:from/:to/:parameters/:format" component={AuthRoute(ShiftsReportExport, Roles.CHIEF)} />
                        <Route path="/reports/Overtime/:from/:to/:parameters/:format" component={AuthRoute(OvertimeReportExport, Roles.CHIEF)} />
                        <Route path="/reports/LeavesBalance" component={AuthRoute(LeavesBalanceReport, Roles.CHIEF)} />
                        <Route path="/reports/UsersStatuses" component={AuthRoute(UsersStatusesReport, Roles.CHIEF)} />
                        <Route path="/reports/Clockings" component={AuthRoute(ClockingsReport, Roles.CHIEF)} />
                        <Route path="/reports/Leaves" component={AuthRoute(LeavesReport, Roles.CHIEF)} />
                        <Route path="/reports/Shifts" component={AuthRoute(ShiftsReport, Roles.CHIEF)} />
                        <Route path="/reports/Overtime" component={AuthRoute(OvertimeReport, Roles.CHIEF)} />
                        <Route path="/reports" component={AuthRoute(Reports, Roles.CHIEF)} />
                        <Route path="/dashboard" component={AuthRoute(Dashboard, Roles.NOTADMIN)} />
                        <Route path="/schedule" component={AuthRoute(WeekSchedule, user.isSupervisor ? Roles.CLOCKING : Roles.HR)} />
                        <Route path="/widget" component={AuthRoute(Widget, Roles.CLOCKING)} />

                        <Route path="/" component={NotFound} />
                      </Switch>
                    </div>

                    {/* <Dialog
                    open={ui.open}
                    content={ui.content}
                    header={ui.title}
                    onCancel={() => ui.open = false}
                    onConfirm={() => ui.open = false}
                    confirmButton="Ok"
                  /> */}
                    {window.location.href.indexOf("widget") == -1 ? 
                    <Footer>
                      <Text>{t("Copyrights Powered by BluGrass Technologies All Rights Reserved")}</Text>
                    </Footer>
                    : null
                    }
                  </Flex>
                </I18nextProvider>
              </Provider>
            </FluentUIProvider>
          </MaterialUIProvider>
        </MobxReact.Provider>
      </>
    );

  }));

  return (
    <BrowserRouter>
      <Content />
    </BrowserRouter>
  );
}

// by using this application you agree eno ldeveloper ma da5alo b aya shi biseer