import React, { Component, createRef, useEffect, useState } from "react";
import { Flex, Image, Button, Text, Dropdown, CloseIcon, ChevronEndMediumIcon, ChevronStartIcon, ChevronEndIcon, AddIcon, WindowMinimizeIcon, Tooltip } from "@fluentui/react-northstar";
import { useTranslation, withTranslation } from 'react-i18next';
// @ts-ignore
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import { Spinner } from "@fluentui/react";
import {
    Divider,
    ExcelColorIcon,
    FilesPdfColoredIcon,
} from "@fluentui/react-northstar";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Checkbox } from '@fluentui/react-northstar'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

import styles from './styles.scss'

import { useTrail, a } from '@react-spring/web'

interface FilterProps {
    users: any[];
    users_: any[];
    selectedUsers: any[];
    onChangeUser(ev: any, props: any): any;
    children?: React.ReactNode;
    clearAllUsers: any
    dateFrom: Date;
    dateTo: Date;
    onDateChange(time: any, type: any): any;
    loading: boolean;
    fetchData: any;
    exportReport: any;
    setShowFilters: any;
    showFilters: boolean;
    countries: any[];
    selectedCountries: any[];
    selectedCountryNames: any[];
    onChangeCountry(ev: any, props: any): any;
    clearAllCountries: any
    branches: any[]
    selectedBranches: any[];
    selectedBranchNames: any[];
    onChangeBranches(ev: any, props: any): any;
    clearAllBranches: any;
    departments: any[];
    selectedDepartments: any[];
    selectedDepartmentNames: any[];
    onChangeDepartments(ev: any, props: any): any;
    clearAllDepartments: any;
    searchMethod: any;
    setSearchMethod(event: any): any;


}


const Trail: React.FC<{ open: boolean, children: any }> = ({ open, children }) => {
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 2000, friction: 200 },
        opacity: open ? 1 : 0,
        x: open ? 0 : 20,
        height: open ? 70 : 0,
        display: open ? "flex" : "none",
        from: { opacity: 0, x: 200, height: 0 },
    })
    return (
        <>
            {trail.map(({ height, ...style }, index) => (

                <a.div key={index} className={styles.trailsText} style={style}>
                    <a.div style={{ height }}>{items[index]}</a.div>
                </a.div>
            ))}
        </>
    )
}


export default function ReportsFilter({users,
    users_,
    selectedUsers,
    onChangeUser,
    children,
    clearAllUsers,
    dateFrom,
    dateTo,
    onDateChange,
    loading,
    fetchData,
    exportReport,
    setShowFilters,
    showFilters,
    countries,
    selectedCountries,
    selectedCountryNames,
    onChangeCountry,
    clearAllCountries,
    branches,
    selectedBranches,
    selectedBranchNames,
    onChangeBranches,
    clearAllBranches,
    departments,
    selectedDepartments,
    selectedDepartmentNames,
    onChangeDepartments,
    clearAllDepartments,
    searchMethod,
    setSearchMethod} : FilterProps) {

    const { t } = useTranslation()

    return (
        <Flex>
        <Flex styles={{ width: "100%", flexWrap: "wrap" }}>
            <Flex gap="gap.small" padding="padding.medium" styles={{ flexWrap: "wrap" }} >

                <Flex gap="gap.small">
                    <Flex column gap="gap.small">
                        <Text content={t("From")} weight="bold" />
                        <DateTimePicker
                            key='fromDate'
                            onChange={(e: any) => {onDateChange(e, 'from') }}
                            value={dateFrom}
                            format="y-MM-dd"
                            calendarIcon={null}
                            clearIcon={null}
                            disableClock
                            className='dateTimePickerCustomStyles'
                        />
                    </Flex>
                    <Flex column gap="gap.small">
                        <Text content={t("To")} weight="bold" />
                        <DateTimePicker
                            key='toDate'
                            onChange={(e: any) => { onDateChange(e, 'to') }}
                            value={dateTo}
                            format="y-MM-dd"
                            calendarIcon={null}
                            clearIcon={null}
                            disableClock
                            className='dateTimePickerCustomStyles'
                        />
                    </Flex>
                </Flex>
                <Flex column gap="gap.small">
                    <Text content={t("Users")} weight="bold" />
                    <Flex>
                        <Dropdown
                            multiple
                            search
                            items={users_}
                            value={selectedUsers}
                            onChange={(ev, props: any) => {
                               onChangeUser(ev, props)
                            }}
                        ></Dropdown>
                        {selectedUsers.length > 0 ?
                            <Button text iconOnly icon={<CloseIcon title='clear all' />} onClick={() => {
                                // this.setState({
                                //     selectedUsers: []
                                // });
                               clearAllUsers()

                            }}></Button> : <></>}
                    </Flex>
                </Flex>
                {/* <Flex gap="gap.small" styles={{ direction: "row", display: "flex", flexWrap: "wrap", overflowX: "hidden" }}> */}
                {/* {this.props.showFilters &&
                    // (<Flex gap="gap.small" styles={{ direction: "row", display: "flex", flexWrap: "wrap", overflowX: "hidden" }}>
                    (<> */}

                <Trail open={showFilters}>
                    <Flex column gap="gap.small">
                        <Text content={t("Countries")} weight="bold" />
                        <Flex>
                            <Dropdown
                                multiple
                                search
                                items={countries}
                                value={selectedCountries}
                                onChange={(ev, props: any) => {
                                  onChangeCountry(ev, props);
                                }} />
                            {selectedCountries.length > 0 ? <Button text iconOnly icon={<CloseIcon title="clear all" />} onClick={() => {
                              clearAllCountries();
                            }}></Button> : <></>}
                        </Flex>
                    </Flex>
                    <Flex column gap="gap.small">
                        <Text content={t("Branches")} weight="bold" />
                        <Flex>
                            <Dropdown
                                multiple
                                search
                                items={branches}
                                value={selectedBranches}
                                onChange={(ev, props: any) => {
                                    onChangeBranches(ev, props);
                                }} />
                            {selectedBranches.length > 0 ? <Button text iconOnly icon={<CloseIcon title='clear all' />} onClick={() => {
                               clearAllBranches();
                            }}></Button> : <></>}
                        </Flex>
                    </Flex>
                    <Flex column gap="gap.small">
                        <Text content={t("Departments")} weight="bold" />
                        <Flex>
                            <Dropdown
                                multiple
                                search
                                items={departments}
                                value={selectedDepartments}
                                onChange={(ev, props: any) => {
                                   onChangeDepartments(ev, props);
                                }} />

                            {selectedDepartments.length > 0 ? <Button text iconOnly icon={<CloseIcon title='clear all' />} onClick={() => {
                             clearAllDepartments();
                            }}></Button> : <></>}
                        </Flex>
                    </Flex>
                    <Flex column gap="gap.small">
                        <Flex gap="gap.small" styles={{ direction: "row" }}>
                            <Text content={t("Search Method")} weight="bold" />
                            <Tooltip trigger={<InfoIcon color="primary" />}
                                content="When you select the AND option, the filtering will only include records that meet ALL of the specified filter conditions concurrently.
                                         When you select the OR option, the filtering will include records that satisfy ANY of the specified filter conditions. " />
                        </Flex>
                        <Flex>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="AND-OR"
                                    name={t("AND-OR")}
                                    value={searchMethod}
                                    onChange={(ev) => { setSearchMethod(ev); }}
                                >
                                    <Tooltip trigger={<FormControlLabel value="AND" control={<Radio />} label={t("AND")} />}
                                        content="When you select the AND option, the filtering will only include records that meet ALL of the specified filter conditions concurrently." />
                                    <Tooltip trigger={<FormControlLabel value="OR" control={<Radio />} label={t("OR")} />}
                                        content="When you select the OR option, the filtering will include records that satisfy ANY of the specified filter conditions." />

                                    {/* <FormControlLabel value="AND" control={<Radio />} label="AND" />
                                    <FormControlLabel value="OR" control={<Radio />} label="OR" /> */}
                                </RadioGroup>
                            </FormControl>

                        </Flex>
                    </Flex>
                </Trail>

                {/* </>
                        // </Flex>
                    )} */}
                <Flex column gap="gap.small">
                    <Button
                        styles={{ marginTop: "30px" }}
                        icon={!loading ? showFilters ? <WindowMinimizeIcon /> : <AddIcon /> : <Spinner />}
                        content={t("Filters")}
                        iconPosition="after"
                        onClick={() => setShowFilters()}>
                    </Button>
                </Flex>
            </Flex>
            {/* </Flex> */}

        </Flex>
        <Flex vAlign="end" hAlign="end" gap="gap.medium" styles={{ justifyContent: "flex-end", alignItems: "flex-end", margin: "10px" }} >
            <Flex vAlign="end" gap="gap.medium" styles={{ justifyContent: "end" }}>
                <Button onClick={() => !loading ? fetchData() : {}}>{!loading ? t('Search') : <Spinner />}</Button>
                <Button iconOnly
                    icon={!loading ? <ExcelColorIcon title={t('Export Exce')} /> : <Spinner />}
                    onClick={() => !loading ? exportReport('xlsx') : {}}></Button>
                <Button iconOnly
                    icon={!loading ? <FilesPdfColoredIcon title={t('Export PDF')} /> : <Spinner />}
                    onClick={() => !loading ?exportReport('pdf') : {}}></Button>
                {/* <Button iconOnly
                        icon={!this.props.loading ? <FilterAltOutlinedIcon /> : <Spinner />}
                        onClick={() => this.props.setShowFilters()}></Button> */}
            </Flex>
        </Flex>
    </Flex>
    );
  }