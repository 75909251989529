import React from "react";
import { DetailsList, CommandBar, Dropdown, TextField, ComboBox, DatePicker } from "@fluentui/react";
import { Flex, Button, Dialog, TrashCanIcon, Text, Divider } from "@fluentui/react-northstar";
import shortid from "shortid";
import { withRouter } from "react-router-dom";
import { SignUpInput, CompanyView, Country, Branch, Department } from "../../models";
import { addUpdateBranches, addUpdateDepartments, getBranches, getCompanies, getDepartments } from "../../api/Company";
import { getCountries } from "../../api/Country";
import { addUsers, getRoles } from "../../api/account";
import { shadeBlend } from "../../utils";
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './phoneNumberInput.scss';
import { inject, observer } from "mobx-react";
import { UserStore } from "../../store/user";
import * as XLSX from "xlsx";
import { Roles } from "../../components/auth-route";


const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

interface IUserInput extends SignUpInput {
  weekends: number[];
  id: string;
  // company?: CompanyView;
  country?: Country;
}

interface IState {
  items: IUserInput[];
  companies: CompanyView[];
  lastSelectedCompany?: CompanyView;
  countries: Country[];
  lastSelectedCountry?: Country;
  dialogVisible: boolean;
  roles: string[];
  branches: Branch[];
  departments: Department[];
  errorMessage: string;
}

interface IProps {
  history: any,
  t: any,
  user: UserStore
}

@inject("user")
@observer
class AddUsers extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      dialogVisible: false,
      items: [
        {
          companyId: 0,
          id: shortid.generate(),
          employeeId: "",
          email: "",
          password: `${shortid.generate()}@Abc123`,
          countryId: 0,
          role: "",
          branchId: 0,
          departmentId: 0,
          title: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          weekends: [],
          //workingHours: 9,
          //startTime: "",
          //endTime: "",
          //weekendsDelimited: "",
          linkedInUrl:"",
          birthDate:new Date(),
        },
      ],
      companies: [],
      countries: [],
      roles: [],
      branches: [],
      departments: [],
      errorMessage: ""
    };
  }

  componentDidMount() {
    this.fetch();
  }

  onFormatDate = (date?: Date): string => {
     return !date? '' : !date ? '': (date.getFullYear() ) + '-' + (date.getMonth() + 1) + '-' +date.getDate();
  };


  fetch = async () => {
    await this.fetchCompanies();
    await this.fetchCountries();
    await this.fetchRoles();
    await this.fetchBranchesAndDepartments();
  }

  fetchRoles = async () => {
    const { payload } = await getRoles();
    this.setState({ roles: payload });
  }

  fetchCountries = async () => {
    const { payload } = await getCountries();
    this.setState({ countries: payload });
  };

  fetchCompanies = async () => {
    const { payload } = await getCompanies();
    this.setState({ companies: payload });
  };

  fetchBranchesAndDepartments = async () => {
    var companyId = this.props.user.company?.id ?? 0
    if (this.state.lastSelectedCompany?.id) {
      companyId = this.state.lastSelectedCompany?.id
    }
    const branches = await getBranches(companyId)
    this.setState({ branches: branches.payload });
    const departments = await getDepartments(companyId)
    this.setState({ departments: departments.payload });
  };

  addUser = () => {
    var companyId = this.props.user.company?.id ?? 0
    // var company = this.props.user.company
    if (this.state.lastSelectedCompany?.id) {
      companyId = this.state.lastSelectedCompany?.id
      // company = this.state.lastSelectedCompany
    }
    this.setState((state) => ({
      items: state.items.concat([
        {
          companyId: companyId,
          // company: state.lastSelectedCompany,
          id: shortid.generate(),
          employeeId: "",
          email: "",
          password: `${shortid.generate()}@Abc123`,
          role: "",
          branchId: 0,
          departmentId: 0,
          title: "",
          firstName: "",
          lastName: "",
          countryId: 0,
          phoneNumber: "",
          weekends: [],
          //workingHours: 9,
          //startTime: "",
          //endTime: "",
          //weekendsDelimited: "",
          linkedInUrl: "",
          birthDate:new Date()
        },
      ]),
    }));
  };

  addUserFromXlsx = (userArr : any[]) => {
    var companyId = this.props.user.company?.id ?? 0
    // var company = this.props.user.company
    if (this.state.lastSelectedCompany?.id) {
      companyId = this.state.lastSelectedCompany?.id
      // company = this.state.lastSelectedCompany
    }
    var branch = this.state.branches.find( b => b.name == userArr[5])
    var dpt = this.state.departments.find( d => d.name == userArr[4])
    var weekends = userArr[11].split(',')
    var weekendIds : number[] = []
    weekends.forEach((day : string) => {
      if (days.indexOf(day.trim()) != -1){
        weekendIds.push(days.indexOf(day.trim()))
      }
    });
    var alreadyAvailable = this.state.items.find( item => item.email == userArr[6])
    if (alreadyAvailable != undefined)
      console.log("this is the alreadyAvailable result: ", alreadyAvailable)
      
    if (userArr[0] && userArr[6] && userArr[9] && alreadyAvailable == undefined) {
      var item : IUserInput = {
        companyId: companyId,
        // company: state.lastSelectedCompany,
        id: shortid.generate(),
        employeeId: userArr[0],
        firstName: userArr[1],
        lastName: userArr[2],
        email: userArr[6],
        password: `${shortid.generate()}@Abc123`,
        role: userArr[9].charAt(0).toUpperCase() + userArr[9].slice(1).toLowerCase() ,
        branchId: branch?.id?? 0,
        departmentId: dpt?.id?? 0,
        title: "",
        countryId: 187,
        phoneNumber: "+966" + userArr[7],
        weekends: weekendIds,
        //workingHours: userArr[12],
        //startTime: userArr[13],
        //endTime: userArr[14],
        //weekendsDelimited: "",
        linkedInUrl:userArr[15],
        birthDate:userArr[16],
        
      }
      this.setState((state) => ({
        items: state.items.concat([
          item,
        ]),
      }));
    }
  };

  submit = async () => {
    const { t, user } = this.props
    //check required fields
    try {
      //var filteredItems = this.state.items.filter(item => !(item.countryId && item.email && item.lastName && item.role && item.workingHours));
      var filteredItems = this.state.items.filter(item => !(item.countryId && item.email && item.lastName && item.role));

      if (filteredItems.length > 0) {
        this.setState({ dialogVisible: true, errorMessage: "Please fill all required fields!" });
        return;
      }
      if (user.role.toLowerCase() !== 'admin') {
        var items = this.state.items.map(item => {
          item.companyId = user.company?.id ?? 0
          return item
        })
        this.setState({ items });
      }

      await addUsers(this.state.items);
      this.props.history.goBack();
    } catch (error: any) {
      console.log("error: ", error)
      this.setState({ dialogVisible: true, errorMessage: t(error.errors && error.errors[0].description) });
    }
  };

  addBranches = async (branches : string[]) => {
    console.log(branches)
    var companyId = this.props.user.company?.id ?? 0
    // var company = this.props.user.company
    if (this.state.lastSelectedCompany?.id) {
      companyId = this.state.lastSelectedCompany?.id
      // company = this.state.lastSelectedCompany
    }
    var branchesObjs = branches.map(branch => {return { name : branch, companyId} as Branch})
    try {
      var branchesResult = await addUpdateBranches(companyId, branchesObjs);
      this.setState({ branches : branchesResult.payload})
    } catch (e) {
      console.log(e)
    }
  }

  addDepartments = async (dpts : string[]) => {
    console.log(dpts)
    var companyId = this.props.user.company?.id ?? 0
    // var company = this.props.user.company
    if (this.state.lastSelectedCompany?.id) {
      companyId = this.state.lastSelectedCompany?.id
      // company = this.state.lastSelectedCompany
    }
    var dptsObjs = dpts.map(dpt => {return { name : dpt, companyId} as Department})
    try {
      var departmentsResult = await addUpdateDepartments(companyId, dptsObjs);
      this.setState({ departments : departmentsResult.payload})
    } catch (e) {
      console.log(e)
    }
  }

  onXlsxInputChange = async (e: any) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = async (evt) => {
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, {FS: ';'});// { header: 1 }
      // console.log(data);
      const lines = data.split('\n')
      // console.log("lines:", lines);
      var branches : string[] = []
      var dpts : string[] = []
      lines.forEach((line, i) => {
        if (i != 0){
          const columns = line.split(';')
          if (!branches.includes(columns[5])){
            branches.push(columns[5])
          }
          if (!dpts.includes(columns[4])){
            dpts.push(columns[4])
          }
        }
      })
      await this.addBranches(branches)
      await this.addDepartments(dpts)

      lines.forEach( (line, i) => {
        if (i != 0){
          const columns = line.split(';')
          // console.log(columns[0], " data : ", columns )
          this.addUserFromXlsx(columns)
        }
      })
      console.log(this.state.items)
    };
    reader.readAsBinaryString(file);
  }

  render() {

    const { t, user } = this.props;

    return (
      <>
        <Dialog content={t("Failed to add users. ") + this.state.errorMessage}
          confirmButton={t("Ok")}
          open={this.state.dialogVisible}
          onConfirm={() => this.setState({ dialogVisible: false })}
          onCancel={() => this.setState({ dialogVisible: false })} />
        <Flex column>

          <Flex hAlign="end">
            {Roles.ADMIN.includes(this.props.user.role) ?
              <input type="file" onChange={this.onXlsxInputChange} />
              : <></>
            }
            <CommandBar items={[
              {
                key: "add.user.new",
                name: t("Add"),
                iconProps: { iconName: "add" },
                buttonStyles: {
                  icon: { color: user.companyPrimaryColor },
                  iconHovered: { color: shadeBlend(-0.2, user.companyPrimaryColor, undefined) },
                },
                onClick: () => this.addUser(),
              },
              {
                key: "add.user.submit",
                iconProps: { iconName: "send" },
                buttonStyles: {
                  icon: { color: user.companyPrimaryColor },
                  iconHovered: { color: shadeBlend(-0.2, user.companyPrimaryColor, undefined) },
                },
                name: t("Submit"),
                onClick: () => this.submit(),
              },
            ]} />
          </Flex>
          <Flex fill hAlign="stretch" styles={{ root: { width: '100vw' } }}>
            {user.role.toLowerCase() === 'admin' ? <Flex column styles={{ padding: '0.625rem 0px 0.625rem 0.625rem' }}>
              <Text content={t("Company")} weight='semibold' styles={{ padding: '15px 0px 14px 0px' }} />
              <Divider styles={{ padding: '0px 0px 10px 0px' }} />
              <Dropdown
                styles={{ root: { minWidth: '150px', maxWidth: '200px', padding: '1px 8px 0px 0px' } }}
                defaultSelectedKey={this.state.lastSelectedCompany?.id}
                options={this.state.companies.map((x) => ({
                  key: x.id,
                  data: x,
                  text: x.name,
                }))}
                onChange={(ev, props) => {
                  var items = this.state.items.map(item => {
                    // item.company = props?.data
                    item.companyId = props?.data.id
                    return item
                  })
                  this.setState({ lastSelectedCompany: props?.data, items }, () => {
                    this.fetchBranchesAndDepartments()
                  });
                }} />
            </Flex> : <></>}
            <Flex fill hAlign="stretch">
              <DetailsList
                // detailsListStyles={{ root: { width: "100%", overflowY: "auto" } }}
                // styles={{ root: { width: user.role.toLowerCase() === 'admin' ? '90vw' : '100vw' } }}
                styles={{ root:{ width: "100%", overflowY: "auto"}}}
                selectionMode={0}
                items={this.state.items} columns={[
                  {
                    key: "employeeId",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("EmployeeId"),
                    onRender: (item: IUserInput, i) => (
                      <TextField value={item.employeeId}
                        onChange={(ev, props) => {
                          console.log(item, i, props)
                          if (typeof i == "number") {
                            var items = [...this.state.items]
                            items[i].employeeId = props ?? "";
                            this.setState({ items });
                          }
                        }} />
                    ),
                  },
                  {
                    key: "title",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("Title"),
                    onRender: (item: IUserInput, i) => (
                      <TextField value={item.title}
                        onChange={(ev, props) => {
                          item.title = props ?? "";
                          this.setState({});
                        }} />
                    ),
                  },
                  {
                    key: "firstName",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("First Name*"),
                    onRender: (item: IUserInput, i) => (
                      <TextField value={item.firstName}
                        onChange={(ev, props) => {
                          item.firstName = props ?? "";
                          this.setState({});
                        }} />
                    ),
                  },
                  {
                    key: "lastName",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("Last Name*"),
                    onRender: (item: IUserInput) => (
                      <TextField value={item.lastName}
                        onChange={(ev, props) => {
                          item.lastName = props ?? "";
                          this.setState({});
                        }} />
                    ),
                  },
                  {
                    key: "email",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("Email*"),
                    onRender: (item: IUserInput) => (
                      <TextField value={item.email}
                        onChange={(ev, props) => {
                          item.email = props ?? "";
                          this.setState({});
                        }} />
                    ),
                  },
                  {
                    key: "linkedInUrl",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("LinkedIn Url"),
                    onRender: (item: IUserInput) => (
                      <TextField value={item.linkedInUrl}
                        onChange={(ev, props) => {
                          item.linkedInUrl = props ?? "";
                          this.setState({});
                        }} />
                    ),
                  },
                  {
                    key: "birthDate",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("Birth Date"),
                    onRender: (item: IUserInput) => (
                          <DatePicker 
                       value={item.birthDate}
                        onSelectDate={(date:any) => {
                          item.birthDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000)
                          console.log(item.birthDate)
                          this.setState({});
                        }} 
                        formatDate={this.onFormatDate}

                        />                            
                    ),
                  },
                
                  // {
                  //   key: "password",
                  //   minWidth: 150,
                  //   maxWidth: 250,
                  //   name: "Password",
                  //   onRender: (item: IUserInput) => (
                  //     <Input type="password"
                  //       disabled
                  //       fluid value={item.password}
                  //       onChange={(ev, props) => {
                  //         item.password = props?.value ?? "";
                  //         this.setState({});
                  //       }} />
                  //   ),
                  // },
                  {
                    key: "country",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("Country*"),
                    onRender: (item: IUserInput) => (
                      <ComboBox
                        selectedKey={item.countryId}
                        style={{ direction: 'ltr' }}
                        styles={{ optionsContainerWrapper: { maxHeight: "250px" } }}
                        options={this.state.countries.map((x) => ({
                          key: x.id,
                          text: x.name,
                          data: x,
                        }))}
                        onChange={(ev, props) => {
                          item.country = props?.data;
                          item.countryId = props?.data.id;
                          if (!item.phoneNumber || item.phoneNumber.length < 6) {
                            item.phoneNumber = '+' + props?.data.phoneCode
                            this.setState({}, () => {
                              // setTimeout(() => {
                              //   item.phoneNumber = item.phoneNumber.slice(0, (item.phoneNumber.length - 3))
                              //   this.setState({})
                              // }, 200)
                            });
                          }
                        }} />
                    ),
                  },
                  {
                    key: "phonenumber",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("Phone Number"),
                    onRender: (item: IUserInput) => (
                      <PhoneInput
                        international
                        style={{ direction: 'ltr' }}
                        value={item.phoneNumber}
                        onChange={(ev: any) => {
                          item.phoneNumber = ev;
                          this.setState({});
                        }} />
                    ),
                  },
                  // {
                  //   key: "company",
                  //   minWidth: 150,
                  //   maxWidth: 250,
                  //   name: "Company",
                  //   onRender: (item: IUserInput) => (
                  //     <Dropdown
                  //       defaultSelectedKey={this.state.lastSelectedCompany?.id}
                  //       options={this.state.companies.map((x) => ({
                  //         key: x.id,
                  //         data: x,
                  //         text: x.name,
                  //       }))}
                  //       onChange={(ev, props) => {
                  //         item.company = props?.data;
                  //         item.companyId = props?.data.id;
                  //         this.setState({ lastSelectedCompany: props?.data });
                  //       }} />
                  //   ),
                  // },
                  // {
                  //   key: "company",
                  //   minWidth: 150,
                  //   maxWidth: 250,
                  //   name: "Company",
                  //   onRender: (item: IUserInput) => (
                  //   <Text >{item.company?.name}</Text>
                  //   ),
                  // },
                  {
                    key: "role",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("Role*"),
                    onRender: (item: IUserInput) => (
                      <Dropdown
                        selectedKey={item.role}
                        options={this.state.roles.map(x => (
                          {
                            key: x,
                            text: x,
                            data: x,
                          }))}
                        onChange={(ev, props) => {
                          item.role = props?.data;
                          this.setState({})
                        }} />
                    ),
                  },
                  {
                    key: "branch",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("Branch"),
                    onRender: (item: IUserInput, i) => (
                      <Dropdown
                        selectedKey={item.branchId}
                        options={this.state.branches.map(x => (
                          {
                            key: x.id,
                            text: x.name,
                            data: x,
                          }))}
                        onChange={(ev, props) => {
                          if (typeof i == "number" && typeof props?.key == "number") {
                            var items = [...this.state.items]
                            items[i].branchId = props?.key;
                            this.setState({ items });
                          }
                        }} />
                    ),
                  },
                  {
                    key: "department",
                    minWidth: 150,
                    maxWidth: 250,
                    name: t("Department"),
                    onRender: (item: IUserInput, i) => (
                      <Dropdown
                        selectedKey={item.departmentId}
                        options={this.state.departments.map(x => (
                          {
                            key: x.id,
                            text: x.name,
                            data: x,
                          }))}
                        onChange={(ev, props) => {
                          if (typeof i == "number" && typeof props?.key == "number") {
                            var items = [...this.state.items]
                            items[i].departmentId = props?.key;
                            this.setState({ items });
                          }
                        }} />
                    ),
                  },
                  // {
                  //   key: "weekends",
                  //   minWidth: 150,
                  //   maxWidth: 250,
                  //   name: t("Weekend Days"),
                  //   onRender: (item: IUserInput) => (
                  //     <Dropdown
                  //       multiSelect
                  //       selectedKeys={item.weekends}
                  //       options={days.map((x, index) => ({
                  //         key: index,
                  //         text: t(x),
                  //         data: index,
                  //       }))}
                  //       onChange={(ev, props) => {

                  //         item.weekends = props?.selected
                  //           ? [...item.weekends, props.data]
                  //           : item.weekends.filter((x) => x !== props?.data);
                  //         // item.weekdays = props?.data;
                  //         // item.weekdays = props?.data;
                  //         this.setState({})
                  //       }} />
                  //   ),
                  // },
                  // {
                  //   key: "workingHours",
                  //   minWidth:150,
                  //   maxWidth: 250,
                  //   name: t("Working Hours*"),
                  //   onRender: (item: IUserInput) => (
                  //     <TextField
                  //       type="number"
                  //       max="24"
                  //       min="0"
                  //       value={item.workingHours.toString()}
                  //       onChange={(ev, props) => {
                  //         if (parseFloat(props ? props : "9") > 24)
                  //           props = "24"
                  //         else if (parseFloat(props ? props : "9") < 0)
                  //           props = "0"

                  //         item.workingHours = parseFloat(props ? props : "9") ?? "9";
                  //         this.setState({});
                  //       }} />
                  //   ),
                  // },
                  // {
                  //   key: "empty",
                  //   name: "",
                  //   minWidth: 1,
                  // },
                  // {
                  //   key: "options",
                  //   minWidth: 100,
                  //   name: t("Options"),
                  //   onRender: (item: IUserInput) => (
                  //     <Button icon={<TrashCanIcon />} iconOnly onClick={() => {
                  //       const items = this.state.items.filter((x) => x.id !== item.id);
                  //       this.setState({ items });
                  //     }} />
                  //   ),
                  // },
                ]} />
            </Flex>
          </Flex>
        </Flex>
      </>
    );
  }
}

export default withRouter(withTranslation()(AddUsers as any) as any);
