import React from "react";
import { Flex, Button, Text } from "@fluentui/react-northstar";
import { TextField } from "@fluentui/react";
import { OrganizationInput } from "../../models";
import { addOrganization } from "../../api/Organization";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

interface IProps {
  history: any;
  t: any;
}

interface IState {
    input: OrganizationInput;
}

class AddOrganization extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      input: {
        name: "",
      },
    };
  }

  addOrganization = async () => {
    const { t } = this.props
    try {
      await addOrganization(this.state.input);
      this.setState({
        input: { name: "" },
      });
    } catch (error) {
      alert(t("Something went wrong!"));
    }
  };

  render() {
    const { t } = this.props
    return (
      <Flex column gap="gap.large" padding="padding.medium" hAlign="center">
        <Text content={t("Add Organization")} size="large" weight="bold" />
        <Flex column gap="gap.medium" styles={{ width: "500px", maxWidth: "100%" }}>
          <TextField label={t("Organization Name")}
            onChange={(ev, props) => {
              var input = {...this.state.input}
              input.name = props ?? "";
              this.setState({ input });
            }}
            value={this.state.input.name} />
          <Button primary fluid content={t("Submit")} onClick={this.addOrganization} />
        </Flex>
      </Flex>
    );
  }
}

export default withRouter(withTranslation()(AddOrganization as any) as any);