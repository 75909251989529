import { Alert, Flex } from '@fluentui/react-northstar'
import { inject, observer } from 'mobx-react'
import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import * as ReportsAPI from '../../api/reports'
import { LeavesBalanceReportInput } from '../../models'
import { UserStore } from '../../store/user'
import Shared from './Shared'

interface IProps {
    user: UserStore;
    match: { params: { parameters: string ,format: string } };
    history: any;
    t: any;
};
interface IState{
    invalidURL: boolean;
}

@inject("user")
@observer
class LeavesBalanceReport extends Component<IProps, IState>{

    state = {
        invalidURL: false
    }

    async componentDidMount(){
        await this.downloadFile();
    }

    async downloadFile(){
        try{
            let format = this.props.match.params.format;

            let reportInput = {
                userId: this.props.user.userId
            } as LeavesBalanceReportInput
            
            let parameters = this.props.match.params.parameters.split("&");
            parameters.forEach(param => {
                var keyValuePair = param.split('=')
                var key = keyValuePair[0];
                var value = keyValuePair[1];
                switch(key){
                    case "leaveTypeIds" :
                        reportInput.leaveTypeIds = value.split(',').map(val => parseInt(val))
                        break;
                    case "showSupervisor" :
                        reportInput.showSupervisor = parseInt(value) ? true : false
                        break;
                }
            });

            await ReportsAPI.exportLeavesBalance(reportInput, format);
        }
        catch(ex){
            this.setState({ invalidURL: true })
            //this.props.history.push("/attendance");
        }
    }

    render(){
        let { t } = this.props;
        return (<>
            {this.state.invalidURL ? <Flex hAlign="center" padding="padding.medium">
                <Alert danger content={t("Invalid Report")} />
            </Flex> : <Shared downloadFile={() => this.downloadFile()} />}
            
        </>)
    }
}

export default withRouter(withTranslation()(LeavesBalanceReport as any) as any);