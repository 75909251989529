import request from "../utils/request";
import { Result, Request, RequestInput, RequestModel, RequestStatus, Priority } from "../models";

export async function getRequestsPerUser(userId: string) {
  const response = await request.get<Result<RequestModel[]>>(`Request/GetRequestsPerUser/${userId}`);
  return response.data;
}
export async function getRequestsAssignedToUser(userId: string) {
  const response = await request.get<Result<RequestModel[]>>(`Request/GetRequestsAssignedToUser/${userId}`);
  return response.data;
}

export async function getStatuses() {
    const response = await request.get<Result<RequestStatus[]>>(`Request/GetRequestsStatuses`);
    return response.data;
  }

  export async function getPriorities() {
    const response = await request.get<Result<Priority[]>>(`Request/GetPriorities`);
    return response.data;
  }
  export async function getRequest(id: number) {
    const response = await request.get<Result<RequestModel>>(`Request/${id}`);
    return response.data;
  }

export async function addRequest(input: RequestInput) {
  const response = await request.post<Result<Request>>("Request/AddRequest", input);
  return response.data;
}


export async function UpdateStatus(statusId:number) {
  const response = await request.post<Result<Request>>(`Request/UpdateStatus?RequestStatus=${statusId}`);
  return response.data;
}
