import React from "react";
import moment from "moment";
import { Pivot, PivotItem, IPivotStyleProps, IPivotStyles, AnimationVariables, TextField, Icon } from "@fluentui/react";
import {
  Layout,
  Flex,
  Divider,
  Button,
  FlexItem,
  Dialog,
  Dropdown,
  Text,
  ChevronStartIcon,
  ChevronEndIcon,
  Alert
} from "@fluentui/react-northstar"
import { dayStatusColor } from "../../assests/styles/colors"
import { inject, observer } from "mobx-react"
import { withRouter } from "react-router-dom"
import TimesheetTable from "../../components/timesheet-table"
import YearMonthPicker from '../../components/yearMonthPicker'
import { UserStore } from "../../store/user"
import * as AttendanceApi from "../../api/attendance"
import * as TimesheetApi from "../../api/timesheet"
import * as AccountApi from "../../api/account"
import * as CompanyApi from "../../api/Company"
import * as Models from "../../models"
import { getFormattedDuration, getInitialDay, getYearMonthPickerFromMonth, getYearMonthPickerToMonth } from "../../utils"
import "./index.scss"
import { StringIfPlural, withTranslation } from "react-i18next"
import { UiStore } from "../../store/ui"
import { TimesheetApprovalInput } from "../../models"
import OvertimeApprovalForm from "../../components/overtimeApprovalForm"

type Props = {
  t: any;
  ui: UiStore;
  user: UserStore;
  match: {
    params: {
      id?: string,
      isGCC?: number,
      timesheetId?: string,
      action?: string,
      shiftId?: number,
      date?: string,
      time?: string
    }
  }
};

type State = {
  timesheetTableData: Models.TimesheetTableView;
  clockingStatus: Models.ClockingPaginationView;
  clockings: Models.ClockingView[][];
  loading: boolean;
  from: Date;
  to: Date;
  selectedDay: Date;
  status?: Models.TimesheetStatus;
  timesheet?: Models.TimesheetView;
  approveDialogOpen: boolean;
  overtimeApprovalDialogOpen: boolean;
  timesheetStatuses: Models.TimesheetStatus[];
  statusId?: number;
  statusName?: string;
  timebarExpended: boolean;
  isrequired: boolean;
  selectedPivotKey: string;
  user?: Models.UserView;
  timesheetUserId?: string;
  firstLoad: boolean;
  approvalFormAlertVisible: boolean;
  approvalFormAlertContent: string;
  userId: string;
  BreakConfig: Models.BreakConfig;
  shiftHistory: Models.ShiftHistoryView[];
  clockType: Models.ClockingType;
  shiftId:number;
};

@inject("user", "ui")
@observer
class Timesheet extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    var today = new Date();

    this.state = {
      clockType:Models.ClockingType.ClockIn,
      shiftId:0,
      clockings: [],
      timebarExpended: false,
      timesheetStatuses: [],
      approveDialogOpen: false,
      overtimeApprovalDialogOpen: false,
      isrequired: false,
      clockingStatus: {
        from: new Date(),
        results: [],
        to: new Date(),
        totalWorkTime: "",
      },
      timesheetTableData: {} as Models.TimesheetTableView,
      loading: true,
      from: getInitialDay(today, this.isGCC),
      to: (() => {
        const date = getInitialDay(today, this.isGCC);
        date.setDate(date.getDate() + 6);
        return date;
      })(),
      // currentday: (() => {
      //   if ( !props.user.isGCCCountry && new Date().getDay() === 0) { // sunday
      //     return "6";
      //   }
      //   return props.user.isGCCCountry ? (new Date().getDay() + 1).toString() : (new Date().getDay() - 1).toString();
      // })(),
      selectedPivotKey: (new Date().getDay() + (this.isGCC ? 1 : 0)).toString(),
      firstLoad: true,
      approvalFormAlertVisible: false,
      approvalFormAlertContent: "",
      userId: "",
      selectedDay: new Date(),
      BreakConfig: Models.BreakConfig.NotRequired,
      shiftHistory:[]
    };
  }

  get userId() {
    if (this.state && this.state.timesheetUserId)
      return this.state.timesheetUserId
    return this.props.match.params.id ?? this.props.user.userId
  }

  get isGCC() {
    if (typeof this.props.match.params.isGCC != 'undefined') {
      console.log("isgcc from url : ", this.props.match.params.isGCC)
      return this.props.match.params.isGCC != 0 ? true : false
    }
    else {
      console.log("isgcc from props")
      return this.props.user.isGCCCountry
    }
  }

  componentDidMount() {
    window.dispatchEvent(new Event("resize"));
    var today = new Date();
    this.getCompanyBreakConfig();
    // AccountApi.getUser(this.userId).then(res => {
    //   var user = res.payload
    //   this.setState({user});
    this.setState({
      from: getInitialDay(today, this.isGCC),
      to: (() => {
        const date = getInitialDay(today, this.isGCC);
        date.setDate(date.getDate() + 6); return date;
      })(),
    })
    this.fetch();
    // });
    var linkUrl = this.props.match.params
    if (linkUrl.action === "overtimeApprovalRequest") {
      AttendanceApi.checkIsSupervisorAndShiftNotArchived(this.props.match.params.id ?? "", this.props.match.params.shiftId ?? 0).then(res => {
        if (res.payload) {
          this.setState({ overtimeApprovalDialogOpen: true });
        }
      })
    }

  }

  getCompanyBreakConfig = async () => {
    var breakConfig = (await CompanyApi.getCompanyRules(this.props.user.company?.id as number)).payload.breakConfig;
    this.setState({ BreakConfig: breakConfig });
  }

  handleYearMonthChange = (date: Date) => {
    date.setHours(date.getHours() - (date.getTimezoneOffset() / 60))

    var fromDate = new Date(date)
    fromDate.setDate(fromDate.getDate() + 6)
    fromDate = getInitialDay(fromDate, this.isGCC)

    var toDate = new Date(fromDate)
    toDate.setDate(toDate.getDate() + 6)
    this.setState({
      from: fromDate,
      to: toDate
    }, () => this.fetch())
  }

  fetch = async () => {
    var { from, to, firstLoad } = this.state
    var fromDate = from.toJSON().split('T')[0];
    var toDate = to.toJSON().split('T')[0];

    this.setState({ loading: true });
    const timesheetId = this.props.match.params.timesheetId;
    let id = '';
    if (timesheetId && firstLoad) {
      var timesheet = await TimesheetApi.getTimesheet(timesheetId);
      id = timesheet.payload.userId;
      from = new Date(timesheet.payload.from)
      to = new Date(timesheet.payload.to)
      from.setHours(from.getHours() - (from.getTimezoneOffset() / 60))
      to.setHours(to.getHours() - (to.getTimezoneOffset() / 60))
      fromDate = from.toJSON().split('T')[0];
      toDate = to.toJSON().split('T')[0];
      this.setState({ from, to, timesheetUserId: id, firstLoad: false });
    } else {
      id = this.userId;
    }
    this.setState({ userId: id })

    // const { payload: user } = await AccountApi.getUser(id);
    // const { payload: daysSummaryView } = await AttendanceApi.getClockings(id, fromDate, toDate);
    // const { payload: clockings } = await AttendanceApi.getClockingsPerDay(id, fromDate, toDate);
    const { payload } = await AttendanceApi.getTimesheetTableData(id, fromDate, toDate);
    // this.setState({ user, clockingStatus: payload, loading: false, clockings });
    this.setState({ timesheetTableData: payload, loading: false, user: { fullName: payload.fullName } as Models.UserView });
    let status;
    try {
      const { payload: statuses } = await TimesheetApi.getTimesheetStatuses();
      // eslint-disable-next-line react/no-access-state-in-setstate
      const result = await TimesheetApi.getTimesheetStatus(id, fromDate, toDate);
      status = result.payload.timesheetStatus;

      this.setState({
        timesheet: result.payload,
        timesheetStatuses: statuses,
        status,
        statusId: status.id,
        statusName: status.name
      });
    } catch (err) {
      status = undefined;
      this.setState({
        loading: false,
        timesheet: undefined,
        // clockingStatus: {
        //   from: new Date(),
        //   results: [],
        //   to: new Date(),
        //   totalWorkTime: "",
        // }, 
        status,
        timesheetStatuses: []
      });
    }
  };

  approveTimesheet = async () => {
    const { t } = this.props
    if (this.state.timesheet && this.state.statusId) {
      var timesheetApprovalInput: TimesheetApprovalInput = {
        timesheetId: this.state.timesheet.id,
        userId: this.state.timesheet.userId,
        timesheetStatusId: this.state.statusId,
        comment: this.state.timesheet.comment
      }
      if (this.state.statusId === 2 && this.state.timesheet.comment === "") {
        this.setState({ approvalFormAlertVisible: true, approvalFormAlertContent: t("Comment is required") });
        return
      }
      try {
        await TimesheetApi.approveTimesheet(timesheetApprovalInput);
      } catch (error: any) {
        if (error && error.errors && error.errors.length > 0 && error.errors[0] && error.errors[0].description) {
          this.setState({ approvalFormAlertVisible: true, approvalFormAlertContent: t(error.errors[0].description) });
        }
      }
      await this.fetch();
      this.setState({ approveDialogOpen: false, approvalFormAlertVisible: false, approvalFormAlertContent: "" });
    }
  };

  onClockTimeChange = async (recordToEdit: any, editedClockTimes: any[]) => {
    const { t } = this.props
    var newTime;
    if (editedClockTimes.length > 0) {
      editedClockTimes.forEach(element => {
        if (element.id === recordToEdit.id && element.type === recordToEdit.type)
          recordToEdit.time = element.time
      });

      recordToEdit.time.setHours(recordToEdit.time.getHours() - (recordToEdit.time.getTimezoneOffset() / 60))
      newTime = recordToEdit.time.toJSON().split('Z')[0];
      var recordUpdate: Models.EditClockingInput = {
        latitude: recordToEdit.latitude,
        longitude: recordToEdit.longitude,
        fullAddress: recordToEdit.fullAddress,
        time: newTime,
        userId: recordToEdit.userId,
        comments: recordToEdit.comments,
        clientType: recordToEdit.clientType,
        image: null,
        type: recordToEdit.type
      }

      await AttendanceApi.editClockTime(recordToEdit.id, recordUpdate).then(res => {
        this.fetch();
      }).catch((e) => {
        console.log(e)
        if (e && e.errors && e.errors.length > 0 && e.errors[0].description)
          alert(t(e.errors[0].description));
      })
    }
  }

  getShiftHistory = async(recortToGetHistory: any) => {
    var historyView = await AttendanceApi.getShiftHistory(recortToGetHistory.id,recortToGetHistory.type);
    this.setState({shiftHistory : historyView.payload})
  }
  render() {

    const { t, user } = this.props;

    // const dialogs = (
    //   <>
    //     <Dialog 
    //       open={this.state.approveDialogOpen}
    //       header={t("Approval form")}
    //       content={(
    //         <Flex column gap="gap.medium">
    //           {this.state.approvalFormAlertVisible ? <Alert 
    //                 visible={this.state.approvalFormAlertVisible}
    //                 content={this.state.approvalFormAlertContent}
    //                 danger
    //               /> : <></>}
    //           <Dropdown fluid
    //             itemToString={(item: any) => item?.content}
    //             items={this.state.timesheetStatuses.map((x) => ({
    //               key: x.id,
    //               content: t(x.name),
    //             }))}
    //             disabled={this.state.status?.id === 1 
    //               || (!this.state.timesheet?.isSupervisor 
    //                 && this.props.user.role !== Models.Roles.Admin 
    //                 && this.props.user.role !== Models.Roles.Hr)}
    //             value={{key: this.state.statusId, content: this.state.statusName}}
    //             onChange={(ev, props: any) => {
    //               if (props.value.key === 2)//rejected
    //                 this.setState({ isrequired: true })
    //               else 
    //                 this.setState( (state) => {
    //                   if (state.timesheet) {
    //                     return {
    //                       isrequired: false,
    //                       timesheet: { ...state.timesheet, comment: "" },
    //                     };
    //                   }
    //                   return {isrequired: false};
    //                 })
    //               this.setState({ statusId: props.value.key, statusName: props.value.content });
    //             }} />
    //           <TextField 
    //             required={this.state.isrequired} 
    //             disabled={this.state.status?.id === 1 
    //               || (!this.state.timesheet?.isSupervisor 
    //                 && this.props.user.role !== Models.Roles.Admin 
    //                 && this.props.user.role !== Models.Roles.Hr)}
    //             resizable={false}
    //             multiple={true} 
    //             multiline={true}
    //             placeholder={t("Comment")}
    //             value={this.state.timesheet?.comment}
    //             onChange={(event, props) => this.setState((state) => {
    //               if (state.timesheet) {
    //                 return {
    //                   timesheet: { ...state.timesheet, comment: props ?? "" },
    //                 };
    //               }
    //               return {};
    //             })} />
    //         </Flex>
    //       )}
    //       confirmButton={this.state.status 
    //         && this.state.status.id !== 1 
    //         && (this.props.user.role === Models.Roles.Admin 
    //           || this.props.user.role === Models.Roles.Hr 
    //           || this.state.timesheet?.isSupervisor) ? t("Submit") : undefined}
    //       cancelButton={t("Cancel")}
    //       onCancel={() => this.setState({ approveDialogOpen: false })}
    //       onConfirm={this.approveTimesheet} 
    //     />
    //     <Dialog
    //       open={this.state.overtimeApprovalDialogOpen}
    //       header={t("Overtime Approval Form")}
    //       content={
    //         <OvertimeApprovalForm
    //           shiftId={this.props.match.params.shiftId ?? 0}
    //           date={this.props.match.params.date ?? ""}
    //           time={this.props.match.params.time ?? ""}
    //           closeDialog={() => {
    //               this.setState({overtimeApprovalDialogOpen : false})
    //               this.fetch();
    //             }
    //           }
    //         />
    //       }
    //     />
    //   </>
    // );

    return (
      <>
        {/* {dialogs} */}
        <Flex hAlign="stretch" column styles={{ height: "100%" }}>
          <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
            {/* <div
            className="mainHeader" style={{color: user.companyPrimaryColor?? "#005bab"}}
          >
            {t('Timesheet')} <br/> {this.state.user?.fullName}
          </div> */}
          </Flex>
          <Flex styles={{ direction: "row", justifyContent: "space-between" }}>
            <Flex gap="gap.medium" padding="padding.medium" vAlign="center">

              {/* <FlexItem grow>
              <Text size="medium" align="start">
                <span>{t("TimeSheet Status-")}</span>
                  <span
                    title={this.state.timesheet?.comment ?? ""}
                    >
                    {t(this.state.status?.name) ?? (
                      <>
                        <span>{t("Is not approved yet.")}</span>
                        {/* <u>
                          <a style={{ display: "inline" }} href="/#">Learn more</a>
                        </u> 
                      </>
                    )}
                  </span>
              </Text>
              
            </FlexItem> */}
              {/* {
              this.state.status
              && (
                <div>
                  <Flex>
                    <Button
                      icon={<Icon  iconName="DocumentApproval"/>}
                      content={t("Approval form")}
                      iconPosition="after"
                      onClick={() => this.setState({ approveDialogOpen: true })}
                    ></Button>
                  </Flex>
                  </div>
              )
            } */}
              {/* {
              this.state.status
              && (
                <div>
                  <CommandBar items={ [
                      {
                        key: "approve",
                        name: t("Approval form"),
                        iconProps: { iconName: "checkMark" },
                        onClick: () => this.setState({ approveDialogOpen: true }),
                      },
                    ]} />
                </div>
              )
            } */}
              <div>{t("Total Hours Worked This Week-")}</div>
              <div>
                {getFormattedDuration(moment.duration(this.state.timesheetTableData.totalWorkTime)
                  .asSeconds())}
              </div>
              {/* <DatePicker label="From" value={this.state.from}
            onSelectDate={(date) => this.setState((state) => ({ from: date ?? state.from }))} />
          <DatePicker label="To" value={this.state.to}
            onSelectDate={(date) => this.setState((state) => ({ to: date ?? state.to }))} /> */}

            </Flex>
            <Flex padding="padding.medium" gap="gap.medium" hAlign="end">
              <Layout
                gap="8px"
                start={(
                  <div style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: dayStatusColor.sickLeave,
                  }} />
                )}
                main={t("Sick Leave")} />
              <Layout
                gap="8px"
                start={(
                  <div style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: dayStatusColor.leave,
                  }} />
                )}
                main={t("Leave")} />
              <Layout
                gap="8px"
                start={(
                  <div style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: dayStatusColor.holiday,
                  }} />
                )}
                main={t("Holiday")} />
              <Layout
                gap="8px"
                start={(
                  <div style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: dayStatusColor.normal,
                  }} />
                )}
                main={t("Normal Day")} />
              <Layout
                gap="8px"
                start={(
                  <div style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: dayStatusColor.weekend,
                  }} />
                )}
                main={t("Week Day Off")} />
            </Flex>
          </Flex>
          <Divider />
          <Flex>
            <YearMonthPicker
              date={this.state.from}
              onChange={this.handleYearMonthChange}
              fromMonth={getYearMonthPickerFromMonth()}
              toMonth={getYearMonthPickerToMonth()}
              isRtl={user.rtl}
            />
          </Flex>
          <Pivot
            selectedKey={this.state.selectedPivotKey}
            onLinkClick={(pItem) => {
              const item: any = pItem;
              const keySelected = parseInt(item.key.split('$')[1]) < 0 ? 1 : parseInt(item.key.split('$')[1]) + 1
              const tmp = new Date(this.state.from);
              tmp.setDate(tmp.getDate() + keySelected - 1);
              this.setState({
                selectedPivotKey: keySelected.toString(),
                selectedDay: tmp
              })
            }}
            styles={this._pivotStyles}
            style={{ width: "100vw" }}
          >
            <PivotItem key="-1" onRenderItemLink={(props) => {
              return (
                <Button iconOnly icon={<ChevronStartIcon />} title="Previous week" onClick={() => {
                  this.setState((state) => {
                    state.from.setDate(state.from.getDate() - 7);
                    state.to.setDate(state.to.getDate() - 7);
                    return state;
                  }, () => this.fetch());
                }} />
              );
            }}>
            </PivotItem>

            {this.state.timesheetTableData && this.state.timesheetTableData.daysSummaryView && this.state.timesheetTableData.daysSummaryView.map((day, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <PivotItem key={i}
                  headerText={(() => {
                    const tmp = new Date(this.state.from);
                    tmp.setDate(tmp.getDate() + i);

                    // this.setState({currentday : })
                    return moment(tmp);
                  })().format("ddd Do MMM")
                  }
                  onRenderItemLink={(props) => {
                    // let color = "lightgrey";
                    let color = dayStatusColor.normal;
                    let colorWithOpacity = dayStatusColor.normalWithOpacity
                    if (day.dayStatus === Models.DayStatus.Leave) {
                      // color = "blue";
                      color = dayStatusColor.leave;
                      colorWithOpacity = dayStatusColor.leaveWithOpacity;
                    }
                    if (day.dayStatus === Models.DayStatus.SickLeave) {
                      // color = "red";
                      color = dayStatusColor.sickLeave;
                      colorWithOpacity = dayStatusColor.sickLeaveWithOpacity;
                    }
                    if (day.dayStatus === Models.DayStatus.Holiday) {
                      // color = "green";
                      color = dayStatusColor.normal;
                      colorWithOpacity = dayStatusColor.holidayWithOpacity;
                    }
                    if (day.dayStatus === Models.DayStatus.WeekOffDay) {
                      // color = "yellow";
                      color = dayStatusColor.weekend;
                      colorWithOpacity = dayStatusColor.weekendWithOpacity;
                    }


                    return (
                      <Layout
                        gap="8px"
                        alignItems="center"
                        start={(
                          <div style={{
                            width: "5px",
                            height: "20px",
                            backgroundColor: color,
                          }} />
                        )}
                        styles={{ padding: "0px 5px 0px 5px", backgroundColor: colorWithOpacity, borderRadius: '3px' }}
                        main={props?.headerText}
                      />
                    );
                  }}>

                  <div style={{ padding: "20px 10px 0px 10px" }}>
                    <Flex
                      gap="gap.smaller"
                      onClick={() => this.setState({ timebarExpended: !this.state.timebarExpended })}
                      hAlign="stretch"
                      styles={{
                        cursor: "pointer",
                        height: this.state.timebarExpended ? undefined : "8px",
                        overflow: "hidden",
                      }}>
                      <div style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        padding: ".5em",
                        backgroundColor: "#6ab04c",
                        whiteSpace: "nowrap",
                        flex: moment.duration(day.workingTime).asSeconds() + 1,
                      }}>
                        {this.state.timebarExpended
                          && (
                            <span>
                              <b>Working Time: </b>
                              {getFormattedDuration(moment.duration(day.workingTime).asSeconds())}
                            </span>
                          )}
                      </div>
                      {this.state.BreakConfig == Models.BreakConfig.Required ?
                        <div style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: ".5em",
                          textAlign: "center",
                          backgroundColor: "#ffbe76",
                          whiteSpace: "nowrap",
                          flex: moment.duration(day.breaksTime).asSeconds() + 1,
                        }}>
                          {this.state.timebarExpended
                            && (
                              <span>
                                <b>Breaks Time: </b>
                                {getFormattedDuration(moment.duration(day.breaksTime).asSeconds())}
                              </span>
                            )}
                        </div>
                        : null}
                      {this.state.BreakConfig == Models.BreakConfig.Required ?
                        <div style={{
                          display: "flex",
                          textAlign: "center",
                          backgroundColor: "#dff9fb",
                          flex: this.props.user.currentWorkingDuration * 3600
                            - moment.duration(day.breaksTime).asSeconds()
                            - moment.duration(day.workingTime).asSeconds(),
                        }} ></div>
                        : <div style={{
                          display: "flex",
                          textAlign: "center",
                          backgroundColor: "#dff9fb",
                          flex: this.props.user.currentWorkingDuration * 3600
                            - moment.duration(day.workingTime).asSeconds(),
                        }} ></div>}
                    </Flex>
                  </div>
                  <TimesheetTable
                    selectedDay={this.state.selectedDay}
                    Reload={() => this.fetch()}
                    timesheet={true}
                    user={user}
                    userId={this.state.userId}
                    detailsListStyles={{ root: { overflowX: "auto", width: "100vw" } }}
                    clockings={this.state.timesheetTableData.daysClockings[i]}
                    loading={this.state.loading}
                    clockTimesEditable={this.props.user.role === Models.Roles.Admin || this.props.user.role === Models.Roles.Hr
                      || this.state.timesheet?.supervisorId === this.props.user.userId}
                    onClockTimeChange={(recordToEdit: any, editedClockTimes: any[]) => { this.onClockTimeChange(recordToEdit, editedClockTimes) }}
                    showImages={!this.props.user.company?.hideClockingImagesFromTimesheet ?? false}
                    getShiftHistory = {(recordToGetHistory: any) => { this.getShiftHistory(recordToGetHistory) }}
                    shiftHistory={this.state.shiftHistory}
                    timeSheetView = {true}
                  />
                </PivotItem>
              )
            })}

            <PivotItem key="-2" onRenderItemLink={(props) => {
              return (
                <Button iconOnly icon={<ChevronEndIcon />}
                  title="Next week"
                  onClick={() => {
                    this.setState((state) => {
                      state.from.setDate(state.from.getDate() + 7);
                      state.to.setDate(state.to.getDate() + 7);
                      return state;
                    }, () => this.fetch())
                  }} />
              );
            }}>
            </PivotItem>
          </Pivot>
          <div style={{ flex: 1 }} />
          {/* <Divider /> */}
          {/* <Flex padding="padding.medium" gap="gap.large" hAlign="end">
            <Layout
              gap="8px"
              start={(
                <div style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: dayStatusColor.sickLeave,
                }} />
              )}
              main={t("Sick Leave")} />
            <Layout
              gap="8px"
              start={(
                <div style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: dayStatusColor.leave,
                }} />
              )}
              main={t("Leave")} />
            <Layout
              gap="8px"
              start={(
                <div style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: dayStatusColor.holiday,
                }} />
              )}
              main={t("Holiday")} />
            <Layout
              gap="8px"
              start={(
                <div style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: dayStatusColor.normal,
                }} />
              )}
              main={t("Normal Day")} />
            <Layout
              gap="8px"
              start={(
                <div style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: dayStatusColor.weekend,
                }} />
              )}
              main={t("Week Day Off")} />
          </Flex> */}
        </Flex>
      </>
      // <Tabs>
      //   <Tabs.Pane label="Today" name="1">
      //     <TimesheetSteps clockings={this.state.clockings} loading={this.state.loading} />
      //   </Tabs.Pane>
      // </Tabs>
    );
  }

  private _pivotStyles = (props: IPivotStyleProps): Partial<IPivotStyles> => {
    const { user } = this.props
    // const { theme, rootIsTabs } = props;
    const { theme } = props;
    const { palette } = theme;

    return {
      link: [
        // !rootIsTabs && {
        {
          selectors: {
            ':hover': {
              backgroundColor: palette.neutralLighter,
              color: palette.neutralDark
            },
            ':active': {
              backgroundColor: palette.neutralLight
            }
          }
        }
      ],
      linkIsSelected: [
        // !rootIsTabs && {
        {
          selectors: {
            ':hover': {
              backgroundColor: palette.neutralLighter
            },
            ':active': {
              backgroundColor: palette.neutralLight
            },
            ':before': {
              transition: `left ${AnimationVariables.durationValue2} ${AnimationVariables.easeFunction2},
                          right ${AnimationVariables.durationValue2} ${AnimationVariables.easeFunction2}`,
              backgroundColor: user.companyPrimaryColor
            },
            ':hover::before': {
              left: 0,
              right: 0
            }
          }
        }
      ]
    };
  };
}

export default withRouter(withTranslation()(Timesheet as any) as any);
