import { Alert, Flex } from '@fluentui/react-northstar'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import * as ReportsAPI from '../../api/reports'
import { LeavesReportInput } from '../../models'
import { UserStore } from '../../store/user'
import Shared from './Shared'

interface IProps {
    user: UserStore;
    match: { params: { format: string, from: string, to: string } };
    history: any;
    t: any;
};
interface IState {
    invalidURL: boolean;
}

@inject("user")
@observer
class LeavesReport extends Component<IProps, IState>{

    state = {
        invalidURL: false
    }

    async componentDidMount() {
        await this.downloadFile();
    }

    async downloadFile() {
        try {
            let dateToSplit = this.props.match.params.to.split('-');
            let dateFromSplit = this.props.match.params.from.split('-');
            let to = new Date(parseInt(dateToSplit[2]) , parseInt(dateToSplit[0]) - 1, parseInt(dateToSplit[1]));
            let from = new Date(parseInt(dateFromSplit[2]) , parseInt(dateFromSplit[0]) - 1, parseInt(dateFromSplit[1]));
            //from.setDate(from.getDate() - 7);

            let format = this.props.match.params.format;
            let reportInput = {
                userId: this.props.user.userId,
                to: to,
                from: from
            } as LeavesReportInput
            await ReportsAPI.exportLeavesPerUser(reportInput, format);
        }
        catch (ex) {
            this.setState({ invalidURL: true })
            //this.props.history.push("/attendance");
        }
    }

    render() {
        let { t } = this.props;
        return (<>
            {this.state.invalidURL ? <Flex hAlign="center" padding="padding.medium">
                <Alert danger content={t("Invalid Report")} />
            </Flex> : <Shared downloadFile={() => this.downloadFile()} />}
            
        </>)
    }
}

export default withRouter(withTranslation()(LeavesReport as any) as any);