import React, { createRef, useCallback, useEffect, useState } from "react";
import { Flex, Image, Button, Text, Dropdown, Loader } from "@fluentui/react-northstar";
import { useTranslation } from 'react-i18next';
import { AttendanceImageConfig, ClockingInput, ClockingType, FaceRecognitionConfig, Result, UserAttendanceImageConfig, UserFaceRecognitionConfig, WorkplaceConfig } from "../../models";
import { TextField, updateA } from "@fluentui/react";
import { Dialog } from "@fluentui/react-northstar";
import Icons from "../../assests/images/SVGIcons/svgIcons";
import { getWorkplaces } from "../../api/Company";
import { isLocInsideWorkplaces } from "../../utils";
import * as AttendanceApi from "../../api/attendance";
import GpsInput from "../gps-input";
import * as faceapi from 'face-api.js';
import WebCam from "react-webcam";
import { FaceMatcher } from "face-api.js";
import { dayStatusColor } from "../../assests/styles/colors";


function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(",");
  const mime = (arr[0].match(/:(.*?);/) ?? [])[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export default ({ user, updateParent, gpsRef, closeDialog, dialogVisible,
  clockingType, faceMatcher, primaryColor, faceRecognitionRequired }: any) => {

  const { t } = useTranslation();
  //const [dialogVisible, setDialogVisible] = useState(false);
  const [input, setInput] = useState("" as unknown as ClockingInput);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [errorDialogContent, setErrorDialogContent] = useState("")
  const [errorDialogCancelAllowed, setErrorDialogCancelAllowed] = useState(false)
  const [errorDialogConfirmButtonContent, setErrorDialogConfirmButtonContent] = useState("")
  const [errorDialogCancelButtonContent, setErrorDialogCancelButtonContent] = useState("")
  const [faceDetected, setFaceDetected] = useState("unknown")
  const [count, setCount] = useState(0);
  const webcamRef = React.useRef(null);

  var attendanceImageRequired = true
  if (user.attendanceImageConfig === UserAttendanceImageConfig.InheritedFromCompany) {
    if (user.company?.attendanceImageConfig === AttendanceImageConfig.NotRequired)
      attendanceImageRequired = false
  } else {
    if (user.attendanceImageConfig === UserAttendanceImageConfig.NotRequired)
      attendanceImageRequired = false
  }


  const eyesArray = [<Icons.Down style={{
    position: 'absolute', top: 30, height: "40px", width: "60px",
    backgroundColor: primaryColor, opacity: 0.5, borderRadius: "100%"
  }}
  />,
  <Icons.Right style={{
    position: 'absolute', top: 30, height: "40px", width: "60px",
    backgroundColor: primaryColor, opacity: 0.5, borderRadius: "100%"
  }} />,
  <Icons.Left style={{
    position: 'absolute', top: 30, height: "40px", width: "60px",
    backgroundColor: primaryColor, opacity: 0.5, borderRadius: "100%"
  }} />]

  useEffect(() => {
    const timerId = setInterval(() => {
      // Use a functional state update to correctly increment the count
      setCount(count => count + 1);
    }, 2000);

    return () => clearInterval(timerId);
  }, []);

  const image = eyesArray[count % eyesArray.length];
  const detected = <Icons.Up style={{
    position: 'absolute', top: 30, height: "40px", width: "60px",
    backgroundColor: primaryColor,  borderRadius: "100%"
  }} />;

  const detectAndMatchFaces = async () => {

    try {
      const video: any = document.getElementById('cameraFeed');
      const canvas: any = document.getElementById('reflay');
      if (video && canvas) {
        var faceDescriptions = await faceapi
          .detectAllFaces(video)
          .withFaceLandmarks()
          .withFaceDescriptors();
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

        const results: any = faceDescriptions.map((fd: any) => faceMatcher?.findBestMatch(fd.descriptor));
 
        if(results.length == 0)
        {
          setFaceDetected("unknown")
        }
        results.forEach((bestMatch: any, i: any) => {
            setFaceDetected("unknown")
          const box = faceDescriptions[i].detection.box;
          if (box && box.x !== null && box.y !== null &&
            box.width !== null && box.height !== null) {
            const anchor = { x: box.x, y: box.y - 60 };
            const drawOptions: any = {
              anchorPosition: 'TOP_LEFT',
              backgroundColor: primaryColor
            };
            if (bestMatch != undefined) {
              const text = bestMatch.toString().split(' ')[0];
              const drawText = new faceapi.draw.DrawTextField(text, anchor, drawOptions);
              if (video && canvas) {
                drawText.draw(canvas);
              }

              var label: never = bestMatch.toString().split(' ')[0] as never
              //setFaceDetected("")
              if (label != 'unknown') {
                setFaceDetected(label)
              }
            }
          }
        });
        if (video && canvas) {
          const frameCallback: FrameRequestCallback = (timestamp: DOMHighResTimeStamp) => {
            detectAndMatchFaces();
          };
          requestAnimationFrame(frameCallback);
          return results;
        }
      }
    } catch (error: any) {
      console.log(error.toString())
    }

  }

  const AddListener = async () => {
    const video: any = document.getElementById('cameraFeed');
    video.play();
    if (!video.paused) {
      return detectAndMatchFaces();
    }
  }

  const onErrorDialogCancel = () => {
    user.getUserInfo();
    updateParent()
    setErrorDialogOpen(false)
    setErrorDialogContent('')
    setErrorDialogConfirmButtonContent(t('OK'))
    setErrorDialogCancelButtonContent(t('Cancel'))
  }

  const onErrorDialogOk = () => {
    user.getUserInfo();
    updateParent()
    setErrorDialogOpen(false)
    setErrorDialogContent('')
    setErrorDialogConfirmButtonContent(t('OK'))
    setErrorDialogCancelButtonContent(t('Cancel'))
    closeClockingDialog();
  }

  const closeClockingDialog = () => {
    closeDialog()
  }

  const cancel = async () => {

    try {
      console.log("close dialog")

      user.loading = true;
      await user.getUserInfo();
      await updateParent()
      const video: any = document.getElementById('cameraFeed');
      const canvas: any = document.getElementById('reflay');
      video.remove()
      canvas.remove()
      closeClockingDialog();
    } catch (error: any) {
      console.log(error.toString())
      setLoading(false)
      return

    }

  }

  const clock = async () => {
    setLoading(true)
    try {
      // image
      var showCam = webcamRef.current ? true : false
      if (user.attendanceImageConfig === UserAttendanceImageConfig.InheritedFromCompany) {
        if (user.company?.attendanceImageConfig === AttendanceImageConfig.NotRequired)
          showCam = false
      } else {
        if (user.attendanceImageConfig === UserAttendanceImageConfig.NotRequired)
          showCam = false
      }
      var image = null;
      if (showCam) {
        if (webcamRef && webcamRef.current) {
          if (faceRecognitionRequired == true) {
            if (faceDetected.trim() != user.firstName.trim()) {
              throw new Error("face not recognized");
            }
          }
          const capture = (webcamRef as any).current.getScreenshot();
          if (capture) {
            image = dataURLtoFile(capture, "image.png");
          } else {
            throw new Error("Image was null");
          }
        }

      }

      // workplace
      if (user.workplaceConfig.toString() === WorkplaceConfig.CompanyOrUserSpecific) {
        var workplaces = await getWorkplaces(user?.company?.id ?? 0, user.userId);
        if (workplaces.payload.length > 0) {
          var insideWorkplace = isLocInsideWorkplaces({ lat: input.latitude, lng: input.longitude }, workplaces.payload)
          if (!insideWorkplace) {
            setError(t("Not allowed to clock outside the workplace bounds"))
            setLoading(false)
            return;
          }
        }
      }
      await user.clock(clockingType, { ...input, image });
      setInput({ ...input, comments: "" })
      setLoading(false)
      //hone
      console.log("close dialog")
      closeClockingDialog();
      user.loading = true;
      await user.getUserInfo();
      await updateParent()
    } catch (error: any) {
      console.log(error.toString())
      if (error.toString().trim() == "Error: face not recognized") {
        setError(t("Face not recognized"))
        setLoading(false)
        return
      }
      if (error && error.errors && error.errors.length > 0 && error.errors[0].description) {
        if (error.errors[0].path && error.errors[0].path.toLowerCase() === 'type') {
          setErrorDialogOpen(true)
          setErrorDialogContent(t(error.errors[0].description))
        }
        setError(t(error.errors[0].description))
      } else {
        setError(t("Please allow the browser to use camera"))
      }
      setLoading(false)
    }

  };

  return (
    <>
      <>
        <Dialog
          open={errorDialogOpen}
          header={t("Warning")}
          content={errorDialogContent}
          confirmButton={{ content: errorDialogConfirmButtonContent }}
          cancelButton={errorDialogCancelAllowed ? { content: errorDialogCancelButtonContent } : undefined}
          onCancel={() => onErrorDialogCancel()}
          onConfirm={() => onErrorDialogOk()}
        />
        <Dialog
          open={dialogVisible}
          header={
            <Flex column gap="gap.medium">
              <Flex styles={{ marginTop: "-10px" }} >
                <Flex styles={{ width: '36px', padding: '0px 6px 0px 6px' }}>
                  {(() => {
                    switch (clockingType) {
                      case ClockingType.ClockIn: return <Icons.ClockIn />;
                      case ClockingType.ClockOut: return <Icons.ClockOut />;
                      case ClockingType.BreakIn: return <Icons.BreakIn />;
                      case ClockingType.BreakOut: return <Icons.BreakOut />;
                      default: return <Icons.ClockIn />;
                    }
                  })()}
                </Flex>
                {t(clockingType)}
              </Flex>
              {attendanceImageRequired === true && faceRecognitionRequired === true ? (
                <><Flex styles={{ paddingTop: "20px", marginLeft: "185px" }}>
                  {faceDetected === "unknown" ? image : detected}
                </Flex><Flex styles={{ marginLeft: "175px" }}>
                    <Text
                      content={faceDetected === "unknown" ? "Looking for you..." : "Hello there :)"}
                      styles={{ fontSize: "14px", textAlign: "center" }}
                    ></Text>
                  </Flex></>
              ) : null}
            </Flex>
          }
          cancelButton={loading ? <Loader /> : t("Cancel")}
          confirmButton={loading ? <Loader /> : t("Submit")}
          onConfirm={!loading ? clock : undefined}
          onCancel={!loading ? cancel : undefined}
          content={(
            <Flex column gap="gap.medium" hAlign="stretch">
              <div style={{ position: 'relative' }}>
                {
                  (user.attendanceImageConfig === UserAttendanceImageConfig.InheritedFromCompany && user.company?.attendanceImageConfig === AttendanceImageConfig.NotRequired)
                    || user.attendanceImageConfig === UserAttendanceImageConfig.NotRequired ? null :
                    faceRecognitionRequired == false ? <>
                      <WebCam ref={webcamRef}
                        audio={false}
                        style={{ backgroundColor: "lightgrey", width: "100%" }}
                        id="cameraFeed" />
                    </> :
                      <>
                        <WebCam ref={webcamRef}
                          onLoadedData={AddListener}
                          audio={false}
                          style={{ backgroundColor: "lightgrey", width: "100%" }}
                          id="cameraFeed" />
                        <>
                          <canvas id="reflay" className="overlay" style={{ position: 'absolute', top: 0, left: 0 }}></canvas>
                          {/* <img src={faceDetected == "unknown"? image: down} className="w-100 img-fluid" alt="" style={{ position: 'absolute', top: 10, left: "50%",height:"70px", width : "70px", color:"red" }}/> */}

                        </>

                      </>
                }
                {/* <canvas id="reflay" className="overlay" style={{ position: 'absolute', top: 0, left: 0 }}></canvas> */}
              </div>
              {error && <Text error content={t(error)} />}
              <TextField
                placeholder={t("Enter your comments here if you have any")}
                multiline
                value={input.comments} onChange={(event, textArea) => (
                  setInput({ ...input, comments: textArea ?? "" })
                )} />
            </Flex>
          )} />
      </>
      <GpsInput
        ref={gpsRef}
        //visible={false} 
        onSuccess={(loc) => {
          setInput({ ...input, latitude: loc.coords.latitude, longitude: loc.coords.longitude })
        }}
        onError={(err) => {
          if (!input.latitude && !input.longitude)
            setErrorDialogOpen(true)
            setErrorDialogCancelAllowed(true)
            setErrorDialogCancelButtonContent(t("Proceed anyway"))
            setErrorDialogContent(t("Location could not be detected, kindly check the location permission from the settings."))
            console.log("loc error: ", err)
        }}
      />
    </>
  );

};
