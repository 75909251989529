import axios from "axios"
import rootStore from "../store/rootStore";
import { Result } from "../models/index";
import { errorHandling } from './requestHandling';

declare const baseUrl: string;

const service = axios.create({
    baseURL: baseUrl + "/api", // url = base url + request url
    // timeout: 5000,
    // withCredentials: true // send cookies when cross-domain requests
});

service.interceptors.response.use(
    (response) => {
        if(response.data instanceof Blob){
            return response;
        }
        const data = response.data as Result<any>;
        if (data && data.errors) {
            data.errors.forEach((error) => {
                // Notification({
                //   message: error.description,
                //   type: "warning",
                //   title: error.code,
                // });
            });
        }
        return response;
    },
    async (error) => errorHandling(error)

);

// Request interceptors
service.interceptors.request.use(
    (config) => {
        // Add X-Access-Token header to every request, you can add other custom headers here
        if (rootStore.token && rootStore.token.token) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${rootStore.token.token}`;
        }
        config.headers["timezoneOffset"] = new Date().getTimezoneOffset()
        return config;
    },
    (error) => {
        // Message({
        //   message: "An error occurred while sending request! "
        //   + "Make sure you're connected to the internet",
        //   type: "error",
        // });
        Promise.reject(error);
    },
);

export default service;
