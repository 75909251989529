import React, { createRef } from "react";
import { withRouter } from "react-router-dom";
import { Button, Divider, Flex, Input, itemLayoutClassName, itemLayoutSlotClassNames, Loader, Text } from "@fluentui/react-northstar";
import { Dropdown, IGroup, SelectionMode, ShimmeredDetailsList, TextField, Selection } from "@fluentui/react";
import { OnDemandNotificationInput, Result, UserView } from "../../models";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { UserStore } from "../../store/user";
import { GetUsers } from "../../api/account";
import { SendOnDemandNotification } from "../../api/Notification";
//import { Checkbox } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridCallbackDetails, GridColDef, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';




interface IProps {
  user: UserStore;
  match: { params: { id?: string } };
  history: any;
  t: any;
};

interface IState {
    loading: boolean;
    input: OnDemandNotificationInput;
    items: any[];
    groups: IGroup[];
    showImageInput: boolean;
    ischecked: boolean;
    checkBox: any;
}

@inject("user")
@observer
class OnDemandNotifications extends React.Component<IProps, IState> {

  private imageInputRef = createRef<HTMLInputElement>()

  constructor(props : IProps) {
    super(props);

    this.state = {
      loading: false,
      input: {} as OnDemandNotificationInput,
      items: [],
      groups: [],
      showImageInput: true,
      ischecked:false,
      checkBox: {}
   };
}

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true })
    const { user } = this.props
    var { payload } = await GetUsers(user.company?.id ?? 0);
    this.tableDataGenerator(payload)
    this.setState({ loading: false })
  }

  tableDataGenerator = async (payload : UserView[]) => {
    var { t } = this.props

    let items : any[] = [];
    let groups : IGroup[] = [];

    let currentCompany = 0;
    let usersCountPerCompany = 0;
    payload.map( user => {
      if (currentCompany !== user.company.id)
      {
        currentCompany = user.company.id
        usersCountPerCompany = payload.reduce(function(total,x){return x.company.id === currentCompany ? total+1 : total}, 0);

        groups.push({ 
        key: user.company.id.toString(), 
        name: user.company.name,
        startIndex: items.length, count: usersCountPerCompany, level: 0 })
      }
      if (user) {
        let row : any = user
        items.push(row)
      }
      return user
    })

    this.setState({items, groups})
  }

  onChange = (e : any) => {
    if(e.target.files[0])
      this.setState({input: {...this.state.input, image: e.target.files[0]}});
  }

  sendNotification = async () => {
    this.setState({ loading: true })
    try {
      console.log("sending", this.state.input)
      if (!this.state.input.title || this.state.input.title.length < 1) {
        alert("Please provide a title");
        return
      }

      if (!this.state.input.body || this.state.input.body.length < 1) {
        alert("Please provide a body");
        return
      }

      if (!this.state.input || (this.state.input.to && this.state.input.to.length === 0)) {
        alert("Please select at least one employee")
        return
      }
      console.log(this.state.input.userId)
      await SendOnDemandNotification(this.state.input)
      this.setState({checkBox:false})
      this.fetchData()
      this.setState({showImageInput: false, input: {title: "", body: "", image: this.state.input.image} as OnDemandNotificationInput})
      this.setState({showImageInput: true})
    } catch (error: any) {
      console.log("error: ", error);
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  onCheck = (e:any,checked:any,id :any) => {
    console.log(e,checked);
    const to = this.state.input.to == undefined ?[]:[...this.state.input.to];
    if(checked)
    {
      to.push(id)
       this.setState({ input :{
        ...this.state.input,
        to : to
       }} , () => console.log(this.state.input.to))
       this.setState({
        checkBox: {
          ...this.state.checkBox, ...{[id]: !this.state.checkBox[id]}
        }
      })
    }
    else
    {
       this.setState({ input :{
        ...this.state.input,
        to : to.filter((item) => item != id)
       }}, () => console.log(this.state.input.to))
       this.setState({
        checkBox: {
          ...this.state.checkBox, ...{[id]: !this.state.checkBox[id]}
        }
      })

    }

  }
  StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor:theme.palette.common.white  ,
      color:  this.props.user.companyPrimaryColor?? "#005bab",
      fontSize : "15px"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
   StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor:  this.props.user.companyPrimaryColor?? "#005bab",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

  render() {
    const { t, user } = this.props
    const { loading, items, groups } = this.state
    return (
      <>
        <Flex gap="gap.medium" padding="padding.medium" hAlign="center">
          <div
            className="mainHeader" 
            style={{color: user.companyPrimaryColor?? "#005bab"}} 
            >
            {t("Send Notification")}
          </div>
        </Flex>
        <Divider/>
        <Flex column gap="gap.large" hAlign="center" vAlign="center" padding="padding.medium" style={{}}>  {/* minHeight: '700px', backgroundColor: 'pink'*/}
          <Flex gap="gap.large" hAlign="center">
            <Flex column gap="gap.small" styles={{width: '33vw'}} > {/* minWidth: '450px', maxWidth: '650px'*/}
              {/* <Text content={t("Employees")} weight="semibold" styles={{fontSize :"16px", color : user.companyPrimaryColor?? "#005bab" }}/> */}
               <Flex style={{ height: '33vw'  }}> {/* border: 'solid 1px', maxHeight: '600px', overflowY: 'scroll'*/}
                    <Flex style={{ flexDirection : "row" ,display :"flex" , width: "100%"}}>
                      <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }}  stickyHeader>
                              <TableHead>
                                <this.StyledTableRow style ={{backgroundColor :"black"}}>
                                <this.StyledTableCell padding="checkbox">
                            
                                </this.StyledTableCell>
                                  <this.StyledTableCell><b>{t("Employee Name")}</b></this.StyledTableCell>
                                  <this.StyledTableCell><b>{t("Email")}</b></this.StyledTableCell>
                                </this.StyledTableRow>
                              </TableHead>
                              <TableBody>
                                {items.map((item) => (
                                   <><TableRow
                                    key={item.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      onChange = {(e,a) => this.onCheck(e,a,item.id)}
                                      checked = {this.state.checkBox[item.id] || false}
                                    />
                                     </TableCell>
                                    <TableCell component="th" scope="row">
                                      {item.fullName}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item.email}
                                      </TableCell>
                                  </TableRow></>
                               ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                    </Flex>

              </Flex>
            </Flex>
            <Flex column gap="gap.large" styles={{width: "33vw"}}>
              <Flex column gap="gap.small" styles={{display: "flex",flexDirection: "column"}}>
                  <Text content={t("Title")} weight="semibold" />
                  <TextField
                      onChange={(ev, props) => {
                      var input = {...this.state.input}
                      input.title = props ?? "";
                      this.setState({input});
                      }}
                      value={this.state.input.title} />
              </Flex>
              <Flex column gap="gap.small">
                <Text content={t("Body")} weight="semibold" />
                <TextField
                    placeholder={t("Enter the body of the notification")}
                    multiline
                    value={this.state.input.body} 
                    onChange={(event, textArea) => (
                      this.setState((state) => ({
                        input: {
                          ...state.input,
                          body: textArea?? "",
                        },
                      }))
                    )} />
              </Flex>
              <Flex column gap="gap.small">
                <Text content={t("Image (png, jpeg, jpg)")} weight="semibold" />
                {this.state.showImageInput ? <Input ref={this.imageInputRef} type="file" onChange={this.onChange}/> : null}
              </Flex>
              <Flex vAlign="center">
                <Checkbox
                  style={{color : this.props.user.companyPrimaryColor ?? undefined}}
                  checked={this.state.input.sendEmail}
                  onChange={(ev, props) => {
                    var input = { ...this.state.input }
                    input.sendEmail = props?? false
                    this.setState({input})
                  }}
                />
                <Text content={t("Send email")} weight="semibold" />
              </Flex>
            </Flex>
          </Flex>
          <Button primary disabled={this.state.loading} content={this.state.loading ? <Loader/> : t("Send")} onClick={() => this.sendNotification()} />
        </Flex>
      </>
    );
  }
}

export default withRouter(withTranslation()(OnDemandNotifications as any) as any)
