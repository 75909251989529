import React from 'react';
import { Flex, Divider, Dialog, Alert, ExclamationTriangleIcon } from "@fluentui/react-northstar";
import { ShimmeredDetailsList, IColumn } from "@fluentui/react";
import { withRouter } from 'react-router-dom';
import { LeaveRequestView, RequestModel, Result, Roles } from "../../../models";
import { copyAndSort } from "../../../utils";
import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import { Pagination as FPagination } from "@fluentui/react-experiments/lib/Pagination"
import { withTranslation } from 'react-i18next';
import columns from './columns'
import SubmitLeaveApprovalForm from '../../../components/leaves/submitLeaveApprovalForm';
import * as LeavesApi from '../../../api/leaves';
import LeaveAttachments from '../../../components/leaves/leaveAttachments';

interface IProps {
    user: UserStore;
    match: { params: { id?: string } };
    history: any;
    t: any;
};

interface IState {
    loading: boolean;
    requests: RequestModel[];
    showFilters: boolean;
    leaveRequests: LeaveRequestView[];
    columns: IColumn[];
    count: number;
    offset: number;
    selectedPageIndex: number;

    selectedLeaveId: number;
    approveDialogOpen: boolean;
    rejectDialogOpen: boolean;
    undoDialogOpen: boolean;
    commentDialogData: {
        leaveId: number;
        commentValue: string;
    }
    commentRequiredAlert: boolean;
    leaveAttachmentsDialogOpen: boolean;
}

@inject("user")
@observer
class MyRequests extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        var haveActionsAccessOverMyLeaves = this.props.user.role.toLowerCase() === Roles.Hr.toLowerCase() || this.props.user.role.toLowerCase() === Roles.Admin.toLowerCase()

        this.state = {
            count: 10,
            offset: 0,
            leaveRequests: [],
            requests: [],
            loading: false,
            showFilters: false,
            selectedPageIndex: 0,

            selectedLeaveId: 0,
            leaveAttachmentsDialogOpen: false,
            approveDialogOpen: false,
            rejectDialogOpen: false,
            undoDialogOpen: false,
            commentDialogData: {
                leaveId: 0,
                commentValue: ""
            },
            commentRequiredAlert: false,

            columns: columns(props.t, this.attachmentButtonOnClick, this.approveButtonOnClick, this.rejectButtonOnClick, this.undoButtonOnClick, haveActionsAccessOverMyLeaves).myLeaves
        };

        this.state.columns.forEach((c) => c.onColumnClick = (_: any, column: IColumn) => {
            const newColumns: IColumn[] = this.state.columns.slice();
            const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
            newColumns.forEach((newCol: IColumn) => {
                if (newCol === currColumn) {
                    currColumn.isSortedDescending = !currColumn.isSortedDescending;
                    currColumn.isSorted = true;
                } else {
                    newCol.isSorted = false;
                    newCol.isSortedDescending = true;
                }
            });
            const newItems = copyAndSort(this.state.leaveRequests, currColumn.key, currColumn.isSortedDescending);
            this.setState({ columns: newColumns, leaveRequests: newItems });
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    get userId() {
        return this.props.match.params.id ?? this.props.user.userId;
    }

    fetchData = async () => {
        let { t } = this.props
        this.setState({ loading: true });

        const leaves = await LeavesApi.getLeaveRequestsPerUser(this.userId);
        var haveActionsAccessOverMyLeaves = leaves.payload.isSupervisor || this.props.user.role.toLowerCase() === Roles.Hr.toLowerCase() || this.props.user.role.toLowerCase() === Roles.Admin.toLowerCase()

        this.setState({
            loading: false,
            columns: columns(t, this.attachmentButtonOnClick, this.approveButtonOnClick, this.rejectButtonOnClick, this.undoButtonOnClick, haveActionsAccessOverMyLeaves).myLeaves,
            leaveRequests: leaves.payload.leaveRequests
        });
    };

    requestApproval = async (id: number, isApproved: boolean, supervisorComment: string) => {
        this.setState({loading : true});
        try {
            await LeavesApi.leaveRequestApproval({ id, isApproved, supervisorComment })
            this.setState({
                approveDialogOpen: false,
                rejectDialogOpen: false,
                commentRequiredAlert: false
            })
            this.fetchData();
            
        } catch (error) {
            if (error) {
                const res = error as Result<never>;
                alert(res.errors[0].description);
            }
        }
        this.setState({loading : false});
    }

    undoRequestApproval = async (id: number, supervisorComment: string) => {
        this.setState({loading : true});
        try {
            await LeavesApi.leaveRequestUndoApproval({ id, supervisorComment, isApproved: false })
            this.setState({
                undoDialogOpen: false,
                commentRequiredAlert: false
            })
            this.fetchData();
        } catch (error) {
            if (error) {
                const res = error as Result<never>;
                alert(res.errors[0].description);
            }
        }
        this.setState({loading : false});
    }

    attachmentButtonOnClick = (leave: LeaveRequestView) => {
        this.setState({
            selectedLeaveId: leave.id,
            leaveAttachmentsDialogOpen: true
        })
    }

    approveButtonOnClick = (leave : LeaveRequestView) => {
        this.setState({
            approveDialogOpen: true,
            commentDialogData: {
                leaveId: leave.id,
                commentValue: leave.supervisorComments
            }
        })
    }

    rejectButtonOnClick = (leave : LeaveRequestView) => {
        this.setState({
            rejectDialogOpen: true,
            commentDialogData: {
                leaveId: leave.id,
                commentValue: leave.supervisorComments
            }
        })
    }

    undoButtonOnClick = (leave : LeaveRequestView) => {
        this.setState({
            undoDialogOpen: true,
            commentDialogData: {
                leaveId: leave.id,
                commentValue: leave.supervisorComments
            }
        })
    }

    onPageChange = (index: number): void => {
        this.setState({
            selectedPageIndex: index,
            offset: (index ?? 1) * this.state.count
        })
    }

    render() {
        const {
            loading,
            leaveRequests,
            columns,
            count,
            offset
        } = this.state;

        const { user, t } = this.props

        const dialogs = (
            <>
                <SubmitLeaveApprovalForm
                        headerText="Leave Approval"
                        isRequired={false} 
                        isOpen={this.state.approveDialogOpen}
                        loading={this.state.loading}
                        commentDialogData={this.state.commentDialogData}
                        commentRequiredAlert={this.state.commentRequiredAlert}
                        leaveRequests={this.state.leaveRequests}
                        onCommentChange={(e: any, props: string | undefined) => {
                            this.setState({
                                commentDialogData: {
                                    ...this.state.commentDialogData,
                                    commentValue: props ?? ""
                                }
                            })
                        }}
                        onConfirm={() => this.requestApproval(this.state.commentDialogData.leaveId, true, this.state.commentDialogData.commentValue)}
                        onCancel={() => this.setState({ approveDialogOpen: false })}
                    />
                    <SubmitLeaveApprovalForm
                        headerText="Leave Rejection"
                        isRequired
                        isOpen={this.state.rejectDialogOpen}
                        loading={this.state.loading}
                        commentDialogData={this.state.commentDialogData}
                        commentRequiredAlert={this.state.commentRequiredAlert}
                        leaveRequests={this.state.leaveRequests}
                        onCommentChange={(e: any, props: string | undefined) => {
                            this.setState({
                                commentDialogData: {
                                    ...this.state.commentDialogData,
                                    commentValue: props ?? ""
                                }
                            })
                        }}
                        onConfirm={() => {
                            if (!this.state.commentDialogData.commentValue)
                                this.setState({ commentRequiredAlert: true })
                            else
                                this.requestApproval(this.state.commentDialogData.leaveId, false, this.state.commentDialogData.commentValue)
                        }}
                        onCancel={() => this.setState({ rejectDialogOpen: false })}
                    />
                    <SubmitLeaveApprovalForm
                        headerText="Undo Leave Approval or Rejection"
                        isRequired={false} 
                        isOpen={this.state.undoDialogOpen}
                        loading={this.state.loading}
                        commentDialogData={this.state.commentDialogData}
                        commentRequiredAlert={this.state.commentRequiredAlert}
                        leaveRequests={this.state.leaveRequests}
                        onCommentChange={(e: any, props: string | undefined) => {
                            this.setState({
                                commentDialogData: {
                                    ...this.state.commentDialogData,
                                    commentValue: props ?? ""
                                }
                            })
                        }}
                        onConfirm={() => this.undoRequestApproval(this.state.commentDialogData.leaveId, this.state.commentDialogData.commentValue)}
                        onCancel={() => this.setState({ undoDialogOpen: false })}
                    />
                    <Dialog
                        open={this.state.leaveAttachmentsDialogOpen}
                        onCancel={() => this.setState({ leaveAttachmentsDialogOpen: false })}
                        // onConfirm={() => this.requestLeave()}
                        // confirmButton={t("Request Leave")}
                        cancelButton={t("Cancel")}
                        header={t("Leave Attachments")}
                        //let leave = this.state.leaveRequests.find(l => l.id == this.state.selectedLeaveId);
                        content={
                            <>
                                {this.state.leaveRequests.find(l => l.id === this.state.selectedLeaveId)?.attachmentRequired &&
                                    (this.state.leaveRequests.find(l => l.id === this.state.selectedLeaveId)?.attachmentCount as number) <= 0 &&
                                    <Alert styles={{marginBottom: "20px", padding: "5px"}} warning content={
                                        <>
                                            <ExclamationTriangleIcon />{' '}
                                        {t("This leave type requires an attachment")}
                                        </>
                                    } />}
                                <LeaveAttachments setAttachmentsCount={(n: number) => {}} fetchData={this.fetchData} leaveRequestId={this.state.selectedLeaveId} newRequest={false} submitNow={false} />
                            </>}
                    />
                </>
        )

        return (
            <Flex column styles={{ width : '100%', overflow: "auto"}}>
                {dialogs}
                <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
                    <div
                    className="subHeader" style={{color: this.props.user.companyPrimaryColor?? "#005bab"}}
                    >{t("Leaves")}</div>
                </Flex>

                <Divider />

                <ShimmeredDetailsList
                    enableShimmer={loading}
                    items={leaveRequests.slice(offset, Math.min(offset + count, leaveRequests.length))}
                    columns={columns}
                    selectionMode={0}
                />

                <FPagination
                    selectedPageIndex={this.state.selectedPageIndex}
                    pageCount={Math.ceil(leaveRequests.length / count)}
                    itemsPerPage={count}
                    totalItemCount={leaveRequests.length}
                    format='buttons'
                    previousPageAriaLabel={'previous page'}
                    nextPageAriaLabel={'next page'}
                    firstPageAriaLabel={'first page'}
                    lastPageAriaLabel={'last page'}
                    pageAriaLabel={'page'}
                    firstPageIconProps={{ iconName: user.rtl ? 'DoubleChevronRight' : 'DoubleChevronLeft' }}
                    previousPageIconProps={{ iconName: user.rtl ? 'ChevronRight' : 'ChevronLeft' }}
                    nextPageIconProps={{ iconName: user.rtl ? 'ChevronLeft' : 'ChevronRight' }}
                    lastPageIconProps={{ iconName: user.rtl ? 'DoubleChevronLeft' : 'DoubleChevronRight' }}
                    onPageChange={this.onPageChange}
                />

                            {/* <Pagination
                                currentPage={offset / count + 1}
                                total={items.length}
                                limit={count}
                                onPageChange={(page) => this.setState({ offset: ((page ?? 1) - 1) * count })}>
                                {({ pages, currentPage, totalPages, getPageItemProps }) => (
                                    <div>
                                        {pages.map((page) => (
                                            <Button iconOnly
                                                primary={currentPage === page}
                                                {...getPageItemProps({
                                                    pageValue: page,
                                                    total: totalPages,
                                                    onClick: () => this.setState({ offset: ((page ?? 1) - 1) * count }),
                                                }) as any}>
                                                {page}
                                            </Button>
                                        ))}
                                    </div>
                                )}
                            </Pagination> */}
            </Flex>
        );
    }
}

export default withRouter(withTranslation()(MyRequests as any) as any)