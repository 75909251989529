import { MediaQueryWidth, useMinWidth, useMaxWidth } from "../../hooks/media-query";

export type MediaQueryProps = {
  maxWidth?: MediaQueryWidth;
  minWidth?: MediaQueryWidth;
  callback: (match: boolean) => JSX.Element;
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function ({ minWidth, maxWidth, callback }: MediaQueryProps) {
  const okMin = !minWidth || useMinWidth(minWidth);
  const okMax = !maxWidth || useMaxWidth(maxWidth);

  return callback(okMin && okMax);
}