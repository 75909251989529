import React from "react";
import { Menu, Divider, Flex, MenuBehaviorProps, tabListBehavior, } from "@fluentui/react-northstar";
import {
  Switch, Route, useLocation, useRouteMatch, withRouter,
} from "react-router-dom";
import Profile from "./profile";
import ChangePassword from "./change-password";
import { withTranslation } from 'react-i18next';
import { Separator } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { UserStore } from "../../../store/user";


interface IProfileProps {
  user?: UserStore;
  t: any;
  history: any;
}

const ProfileIndex = (inject("user"))(observer((props: IProfileProps) => {
  const { t, history } = props
  const { pathname } = useLocation()
  const { path } = useRouteMatch()

  return (
    <Flex styles={{ height: "100%" }} column>
      <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
        <div
          className="mainHeader" style={{ color: props.user?.companyPrimaryColor ?? "#005bab" }}
        >{t('My Profile')}</div>
      </Flex>
      <Divider />
      <Flex styles={{ height: "100%" }}>
        <Menu styles={{ width: "200px" }}
          pointing vertical
          accessibility={(props: MenuBehaviorProps) => { return tabListBehavior() }}
          defaultActiveIndex={0}
          activeIndex={`${path}/profile`.startsWith(pathname) ? 0 : 1}
          items={[
            {
              key: "profile",
              content: t("Edit Profile"),
              onClick: () => history.push(`${path}/profile`),
            },
            {
              key: "change.password",
              content: t("Change Password"),
              onClick: () => history.push(`${path}/change-password`),
            },
          ]} />
        <Separator vertical />
        <Flex fill hAlign='center'>
          <Switch>
            <Route path={`${path}/profile`} component={Profile} />
            <Route path={`${path}/change-password`} component={ChangePassword} />
          </Switch>
        </Flex>
      </Flex>
    </Flex>
  );
}));

export default withRouter(withTranslation()(ProfileIndex as any) as any) as unknown as React.FunctionComponent<IProfileProps>;