import React, { useState } from 'react';
import {
    Flex,
    Button,
    Loader,
    Input,
    Alert,
    TrashCanIcon,
    EyeIcon,
    Text
} from "@fluentui/react-northstar";

import { useTranslation } from 'react-i18next';
import * as LeavesApi from '../../api/leaves'
import { LeaveRequestAttachmentView, LeaveRequestView, Result } from '../../models';

export type LeaveAttachmentsProps = {
    leaveRequestId: number,
    newRequest: boolean | undefined,
    submitNow: boolean | undefined,
    fetchData: () => Promise<void>,
    setAttachmentsCount: (n: number) => void
};

export default function LeaveAttachments({leaveRequestId, newRequest = false, submitNow = false, fetchData, setAttachmentsCount} : LeaveAttachmentsProps) {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [leaveRequest, setLeaveRequest] = useState({ id: 0 } as LeaveRequestView)
    const [files,setFiles] = useState([] as File[])
    const [attachments, setAttachments] = useState([] as LeaveRequestAttachmentView[])
    const [alertText, setAlertText] = useState("")
    const [submitedOnce, setSubmitedOnce] = useState(false);

    const fetch = async () => {
        setLoading(true)
        if (leaveRequestId){
            try {
                var leaveRequest = await LeavesApi.getLeaveRequest(leaveRequestId)
                setLeaveRequest(leaveRequest.payload)
            } catch (error) {
                if (error) {
                    const res = error as Result<never>;
                    if (res.errors && res.errors[0] && res.errors[0].description)
                        setAlertText(res.errors[0].description)
                }
            }
            try {
                setAttachments(await (await LeavesApi.getLeaveRequestAttachments(leaveRequestId)).payload)
            } catch (error) {
                if (error) {
                    const res = error as Result<never>;
                    if (res.errors && res.errors[0] && res.errors[0].description)
                        setAlertText(res.errors[0].description)
                }
            }
        }
        setLoading(false)
    }
    
    const submit = async () => {
        if (files.length > 0){
            setAlertText("")
            setUploadLoading(true)
            try {
                setAttachments(await (await LeavesApi.addLeaveAttachments({leaveRequestId, attachments : files})).payload)
                await fetchData();
            } catch (error) {
                if (error) {
                    const res = error as Result<never>;
                    if (res.errors && res.errors[0] && res.errors[0].description)
                        setAlertText(res.errors[0].description)
                }
            }
            setUploadLoading(false)
        } else if (!newRequest) {
            setAlertText(t("Please choose an attachment"))
        }
    };

    // change to useEffect() https://www.udemy.com/course/react-the-complete-guide-incl-redux/learn/lecture/25599212 section 10
    if (leaveRequest.id !== leaveRequestId && !loading){
        fetch()
        // debugger;
        if (submitNow && !submitedOnce){
            setSubmitedOnce(true)
            submit()
        }
    }

    const deleteAttachment = async (id : number) => {
        setAlertText("")
        setLoading(true)
        try {
            setAttachments(await (await LeavesApi.removeLeaveAttachment(leaveRequestId, id)).payload)
            await fetchData();
        } catch (error) {
            if (error) {
                const res = error as Result<never>;
                if (res.errors && res.errors[0] && res.errors[0].description)
                    setAlertText(res.errors[0].description)
            }
        }
        setLoading(false)
    }

    const viewAttachment = async (path : string) => {
        setAlertText("")
        window.open(path, '_blank')
    }

    const onChange = (e : any) => {
        setAlertText("")
        var files : File[] = []
        for(var i = 0; i < e.target.files.length; i++ ){
            files.push(e.target.files[i])
        }
        if(newRequest){
            setAttachmentsCount(e.target.files.length);
        }
        setFiles(files);
    }

    return (
        <Flex column gap='gap.medium'>
            {alertText? <Alert visible={alertText? true : false} content={t(alertText)} danger/> : null}
            <Flex gap='gap.medium' vAlign='center' styles={{ justifyContent: "space-between" }} >
                <Input type="file" onChange={onChange} multiple/>
                {leaveRequestId !== 0 && !newRequest ? <Button onClick={submit}>{
                    uploadLoading ? <Loader/> : t("Upload")
                }</Button> : null}
            </Flex>
            {
                loading? <Loader/> :
                <Flex column gap='gap.small'>
                    {attachments.map((attachment, index) => {
                        return (
                            <Flex gap='gap.medium' key={"attach-" + index}>
                                <Text styles={{ border: 'solid 1px black', padding: '3px' }}>{attachment.name + "." + attachment.extention}</Text>
                                <Button iconOnly icon={<TrashCanIcon/>} onClick={() => deleteAttachment(attachment.id)}></Button>
                                <Button iconOnly icon={<EyeIcon/>} onClick={() => viewAttachment(attachment.path)}></Button>
                            </Flex>
                        )
                    })}
                </Flex>
            }
        </Flex>
    );
  }