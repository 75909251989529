import React, { useEffect, useState } from "react";
import {
  Flex,
  List,
  ListItemProps,
  ShorthandValue,
  Text
} from "@fluentui/react-northstar";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { NotificationStore } from "../../store/notification";
import { Notification } from "../../models";
import { createUrl } from "../../utils";
import { withTranslation } from "react-i18next";
import Icons from '../../assests/images/SVGIcons/svgIcons'
import { UserStore } from "../../store/user";
import { Pagination } from "@fluentui/react-experiments/lib/Pagination";
import dateFormat from "dateformat";

interface INotificationListProps {
  user?: UserStore
  notification?: NotificationStore;
  type?: number;
  t?: any;
  full?: boolean;
}

const NotificationList = (inject("user", "notification"))(observer((props: INotificationListProps) => {
  const { user, notification, type, t, full } = props
  
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);

  useEffect(() => {
    console.log("Type changed to: ", type)
    setSelectedPageIndex(0)
    notification?.load(type, 0, notification.notificationsPageCount)
    return () => {}
  }, [type])

  function createListItem(item: Notification): ShorthandValue<ListItemProps> {
    const width = "24px", height = "24px", color = user?.companyPrimaryColor
    var icon = <Icons.About fill={color} width={width} height={height} />
    if (item && item.notificationType)
      switch (item.notificationType.name){
        case "ClockInReminder" :
          icon = <Icons.ClockIn fill={color} width={width} height={height} />
          break
        case "ClockOutReminder" :
          icon = <Icons.ClockOut fill={color} width={width} height={height} />
          break
        case "TimeSheetsCreated" :
          icon = <Icons.Timesheet fill={color} width={width} height={height} />
          break
        case "OvertimeVerification" :
          icon = <Icons.ClockIn fill={color} width={width} height={height} />
          break 
        case "BreakInNotification" :
          icon = <Icons.BreakIn fill={color} width={width} height={height} />
          break 
        case "BreakOutNotification" :
          icon = <Icons.BreakOut fill={color} width={width} height={height} />
          break 
        case "LeaveRequest" :
          icon = <Icons.Vacation fill={color} width={width} height={height} />
          break 
        case "LeaveRequestResponse" :
          icon = <Icons.Vacation fill={color} width={width} height={height} />
          break 
        case "HolidayNotification" :
          icon = <Icons.Holiday fill={color} width={width} height={height} />
          break 
        case "OvertimeApprovalRequest" :
          icon = <Icons.ClockIn fill={color} width={width} height={height} />
          break 
        case "OvertimeApprovalResponse" :
          icon = <Icons.ClockIn fill={color} width={width} height={height} />
          break 
        case "UsersMissingClockInNotification" :
          icon = <Icons.LateAlarm fill={color} width={width} height={height} />
          break 
        case "EarlyClockOutNotification" :
          icon = <Icons.Exit fill={color} width={width} height={height} />
          break
        case "StillInBreakVerification" :
          icon = <Icons.BreakIn fill={color} width={width} height={height} />
          break
        case "LeaveRequestUndoResponse" :
          icon = <Icons.Vacation fill={color} width={width} height={height} />
          break
      }
    return {
      key: item.id,
      media: <Flex styles={{padding: '0px'}}>{icon}</Flex>,
      header: item.title,
      headerMedia:  dateFormat(new Date(new Date(item.createdOn).getTime() - (new Date().getTimezoneOffset() * 60 * 1000)), full ? 'dd/mm/yyyy h:MM:ss TT' : 'dd mmm h:MM TT'),
      // headerMedia: new Intl.DateTimeFormat("en-US").format(new Date(item.createdOn)),
      content: item.body,
      styles: !item.isRead ? { backgroundColor: "#EDF2FA" } : undefined,
      onClick: async () => {
        await notification?.markAsRead(item.id);
        var url = createUrl(item);
        if (url) {
          window.location.href = url;
        }
      },
    };
  }

  const onPageChange = (index: number): void => {
    setSelectedPageIndex(index)
    notification?.load(type, index + 1, notification.notificationsPageCount)    
  }

  return (
    <>
      <List 
        selectable 
        styles={{
          overflowY: "auto",
          padding: "0px",
          margin: "0px",
        }}
        items={full ? notification?.fullListNotifications.map((x) => createListItem(x)) : notification?.notifications.map((x) => createListItem(x))}
      />
      {
        full ? 
        <Pagination
          selectedPageIndex={selectedPageIndex}
          pageCount={Math.ceil((notification?.notificationsTotalCount?? 0) / (notification?.notificationsPageCount?? 15))}
          itemsPerPage={notification?.notificationsPageCount}
          totalItemCount={notification?.notificationsTotalCount?? 0}
          format='buttons'
          previousPageAriaLabel={'previous page'}
          nextPageAriaLabel={'next page'}
          firstPageAriaLabel={'first page'}
          lastPageAriaLabel={'last page'}
          pageAriaLabel={'page'}
          firstPageIconProps={{ iconName: user?.rtl ? 'DoubleChevronRight' : 'DoubleChevronLeft' }}
          previousPageIconProps={{ iconName: user?.rtl ? 'ChevronRight' : 'ChevronLeft' }}
          nextPageIconProps={{ iconName: user?.rtl ? 'ChevronLeft' : 'ChevronRight' }}
          lastPageIconProps={{ iconName: user?.rtl ? 'DoubleChevronLeft' : 'DoubleChevronRight' }}
          onPageChange={onPageChange}
        /> : null
      } 
      {notification?.notifications?.length ? null : <Text align="center" disabled content={t("Nothing here")} />}
    </>
  );
}));

export default withRouter(withTranslation()(NotificationList as any) as any) as unknown as React.FunctionComponent<INotificationListProps>;
