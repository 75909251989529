import React from "react";
import { DetailsList, CommandBar, Dropdown, TextField, ComboBox, DatePicker } from "@fluentui/react";
import { Flex, Button, Dialog, TrashCanIcon, Text, Divider,FlexItem } from "@fluentui/react-northstar";
import shortid from "shortid";
import { withRouter } from "react-router-dom";
import { SignUpInput, CompanyView, Country, Branch, Department } from "../../models";
import { addUpdateBranches, addUpdateDepartments, getBranches, getCompanies, getDepartments } from "../../api/Company";
import { getCountries } from "../../api/Country";
import { addUsers, getRoles} from "../../api/account";
import { shadeBlend } from "../../utils";
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './phoneNumberInput.scss';
import { inject, observer } from "mobx-react";
import { UserStore } from "../../store/user";
import * as XLSX from "xlsx";
import { Roles } from "../../components/auth-route";
import { throws } from "assert";


const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

interface IUserInput extends SignUpInput {
  weekends: number[];
  id: string;
  // company?: CompanyView;
  country?: Country;
}

interface IState {
  item: IUserInput;
  companies: CompanyView[];
  lastSelectedCompany?: CompanyView;
  countries: Country[];
  lastSelectedCountry?: Country;
  dialogVisible: boolean;
  roles: string[];
  branches: Branch[];
  departments: Department[];
  errorMessage: string;
}

interface IProps {
  history: any,
  t: any,
  user: UserStore
}

@inject("user")
@observer
class AddUser extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      dialogVisible: false,
      item: 
      {
          companyId: 0,
          id: shortid.generate(),
          employeeId: "",
          email: "",
          password: `${shortid.generate()}@Abc123`,
          countryId: 0,
          role: "",
          branchId: 0,
          departmentId: 0,
          title: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          weekends: [],
          //workingHours: 9,
          //startTime: "",
          //endTime: "",
          //weekendsDelimited: "",
          linkedInUrl:"",
          birthDate:new Date(),
      },
      companies: [],
      countries: [],
      roles: [],
      branches: [],
      departments: [],
      errorMessage: ""
    };
  }

  componentDidMount() {
    this.fetch();
  }

  onFormatDate = (date?: Date): string => {
     return !date? '' : !date ? '': (date.getFullYear() ) + '-' + (date.getMonth() + 1) + '-' +date.getDate();
  };


  fetch = async () => {
    await this.fetchCompanies();
    await this.fetchCountries();
    await this.fetchRoles();
    await this.fetchBranchesAndDepartments();
  }

  fetchRoles = async () => {
    const { payload } = await getRoles();
    this.setState({ roles: payload });
  }

  fetchCountries = async () => {
    const { payload } = await getCountries();
    this.setState({ countries: payload });
  };

  fetchCompanies = async () => {
    const { payload } = await getCompanies();
    this.setState({ companies: payload });
  };

  fetchBranchesAndDepartments = async () => {
    var companyId = this.props.user.company?.id ?? 0
    if (this.state.lastSelectedCompany?.id) {
      companyId = this.state.lastSelectedCompany?.id
    }
    const branches = await getBranches(companyId)
    this.setState({ branches: branches.payload });
    const departments = await getDepartments(companyId)
    this.setState({ departments: departments.payload });
  };

  submit = async () => {
  const { t, user } = this.props
  //check required fields
  try {
    var items = []
    items.push(this.state.item)
   
    //var filteredItems = items.filter(item => !(item.countryId && item.email && item.lastName && item.role && item.workingHours));
    var filteredItems = items.filter(item => !(item.countryId && item.email && item.lastName && item.role));

    if (filteredItems.length > 0) {
      this.setState({ dialogVisible: true, errorMessage: "Please fill all required fields!" });
      return;
    }
    if (user.role.toLowerCase() !== 'admin') {
      var itemss = items.map(item => {
        item.companyId = user.company?.id ?? 0
        this.setState({ item });
      })
    }
 
    
    await addUsers(items);
    this.props.history.goBack();
  } catch (error: any) {
    console.log("error: ", error)
    this.setState({ dialogVisible: true, errorMessage: t(error.errors && error.errors[0].description) });
  }
  };

  addBranches = async (branches : string[]) => {
    console.log(branches)
    var companyId = this.props.user.company?.id ?? 0
    // var company = this.props.user.company
    if (this.state.lastSelectedCompany?.id) {
      companyId = this.state.lastSelectedCompany?.id
      // company = this.state.lastSelectedCompany
    }
    var branchesObjs = branches.map(branch => {return { name : branch, companyId} as Branch})
    try {
      var branchesResult = await addUpdateBranches(companyId, branchesObjs);
      this.setState({ branches : branchesResult.payload})
    } catch (e) {
      console.log(e)
    }
  }

  addDepartments = async (dpts : string[]) => {
    console.log(dpts)
    var companyId = this.props.user.company?.id ?? 0
    // var company = this.props.user.company
    if (this.state.lastSelectedCompany?.id) {
      companyId = this.state.lastSelectedCompany?.id
      // company = this.state.lastSelectedCompany
    }
    var dptsObjs = dpts.map(dpt => {return { name : dpt, companyId} as Department})
    try {
      var departmentsResult = await addUpdateDepartments(companyId, dptsObjs);
      this.setState({ departments : departmentsResult.payload})
    } catch (e) {
      console.log(e)
    }
  }
  
  render() {
    const { t, user } = this.props;

    return (
        <>
        <Dialog content={t("Failed to add users. ") + this.state.errorMessage}
          confirmButton={t("Ok")}
          open={this.state.dialogVisible}
          onConfirm={() => this.setState({ dialogVisible: false })}
          onCancel={() => this.setState({ dialogVisible: false })} />
        <FlexItem grow>
        <Flex column gap="gap.large" padding="padding.medium" hAlign="center">
                <Text content={t("New Employee")} size="large" weight="bold" className="mainHeader" style={{color: user.companyPrimaryColor?? "#005bab"}} />
                <Flex column gap="gap.medium">
                  <Flex  styles={{  display: "flex" ,flexWrap :"wrap",maxWidth:"800px" ,justifyContent:"center" ,alignItems :"center"}}>
                  <Flex  style ={{ margin :"15px" }}>
                     <TextField 
                        style={{width : "300px"}}
                         label={t("First Name*")}
                        onChange={(ev, props) =>{
                          var item ={...this.state.item}
                          item.firstName = props ?? "";
                          this.setState({item});
                        }}
                      />
                     </Flex>
                     <Flex style ={{ margin :"15px"}}>
                     <TextField 
                        style={{width : "300px"}}
                        label={t("Last Name*")}
                        onChange={(ev,props) =>{
                          var item = {...this.state.item}
                          item.lastName = props ?? "";
                          this.setState({item});
                        }}
                     />
                    </Flex>
                  <Flex style ={{margin :"15px" }}>
                   <TextField
                       style={{width : "300px"}}
                       label={t("Employee Id")}
                       onChange={(ev,props) =>{
                        var item ={...this.state.item}
                        item.employeeId = props ??"";
                        this.setState({item})
                      }}
                     />
                    </Flex>
                    <Flex style ={{ margin :"15px" }}>
                    <TextField 
                        style={{width : "300px"}}
                        label={t("Title")}
                        onChange={(ev,props) =>{
                          var item = {...this.state.item}
                          item.title= props ?? "";
                          this.setState({item})
                        }}
                    />
                    </Flex>
                    <Flex style ={{ margin :"15px" }} >
                    <TextField 
                        style={{width : "300px"}}
                        label={t("Email*")}
                        onChange={(ev,props) =>{
                          var item ={...this.state.item}
                          item.email = props ?? "";
                          this.setState({item})
                        }}
                    />
                    </Flex>
                    <Flex style ={{ margin :"15px"}}>
                    <TextField
                       style={{width : "300px"}} 
                       label={t("LinkedIn Url")}
                       onChange={(ev,props) =>{
                        var item = {...this.state.item}
                        item.linkedInUrl = props ?? "";
                        this.setState({item});
                       }}
                    />
                    </Flex>
                    <Flex style ={{margin :"15px" }}>
                    <ComboBox
                    label={t("Country*")}
                    styles={{ optionsContainerWrapper: { maxHeight: "250px" } }}
                    style={{ direction: 'ltr',width : "300px" }}
                    selectedKey={this.state.item.countryId}
                    options={this.state.countries.map((x) => ({
                      key: x.id,
                      text: x.name,
                      data: x,
                    }))}
                    onChange={(ev, props) => {
                      var item = {...this.state.item}
                      item.country = props?.data;
                      item.countryId = props?.data.id;
                      if (!item.phoneNumber || item.phoneNumber.length < 6) {
                        item.phoneNumber = '+' + props?.data.phoneCode
                        this.setState({}, () => {
                          // setTimeout(() => {
                          //   item.phoneNumber = item.phoneNumber.slice(0, (item.phoneNumber.length - 3))
                          //   this.setState({})
                          // }, 200)
                        });
                      }
                      this.setState({item})
                    }}
                    />
                    </Flex>
                    <Flex style ={{ margin :"15px" }}>
                    <PhoneInput
                    title={t("Phone Number")}
                    name={t("Phone Number")}
                    labels={t("Phone Number")}
                    international
                    style={{ direction: 'ltr',width : "300px",paddingTop:"30px",height:"62px"}}
                    // ,height:"61px",paddingTop:"30px"}}
                    value={this.state.item.phoneNumber}
                      onChange={(ev:any) =>{
                        var item = {...this.state.item}
                        item.phoneNumber = ev
                        this.setState({item})
                      }}
                    />
                    </Flex>
                    <Flex style ={{ margin :"15px" }}>
                    <DatePicker 
                    style={{width : "300px"}} 
                    label={t("Birth Date")}
                    onSelectDate={(date:any) =>{
                      var item= {...this.state.item}
                      item.birthDate =new Date(date.getTime() - date.getTimezoneOffset()*60*1000)
                      // new Date(date.getTime() - date.getTimezoneOffset()*60*1000)
                      //new Date(date)
                      this.setState({item})
                    }}
                    formatDate={this.onFormatDate}
                   />
                    </Flex>
                    <Flex style ={{ margin :"15px" }} >
                    <Dropdown
                    label={t("Role*")}
                    style={{width : "300px"}} 
                    //selectedKey={this.state.items.role}
                    options={this.state.roles.map(x => ({
                      key: x,
                      text: x,
                      id: x,
                    }))}
                    onChange = {(ev,props) =>{
                      var item = {...this.state.item}
                      item.role = props?.key ? props.key.toString() : "clocking";
                      this.setState({item})
                    }}
                     />
                    </Flex>
                    
                    {user.role.toLowerCase() === 'admin' ? 
                      <Flex style ={{ margin :"15px" }}>
                     <>
                     <Dropdown 
                     style={{width : "300px"}} 
                     label={t("Company")}
                     defaultSelectedKey={this.state.lastSelectedCompany?.id}
                     options={this.state.companies.map((x) => ({
                      key: x.id,
                      data: x,
                      text: x.name,
                      }))}
                        onChange= {(ev,props) =>{
                          var item = {...this.state.item}
                          item.companyId = props?.data.id
                          this.setState({ lastSelectedCompany: props?.data, item}, () => {
                            this.fetchBranchesAndDepartments()
                          });
                        }}
                        
                     /> </>  
                     </Flex>
                        :<></>
                       }
               
               
                    <Flex style ={{ margin :"15px" }}>
                    <Dropdown
                    style={{width : "300px"}} 
                     label={t("Branch")}
                    // selectedKey={this.state.items.branchId}
                     options={this.state.branches.map((x) => ({
                      key: x.id,
                      text: x.name,
                      data: x,
                    }))}
                    onChange={(ev, props) => {
                      var item = { ...this.state.item }
                      item.branchId = props?.data.id;
                      this.setState({item});
                    }} 
                    />
                    </Flex>
                    <Flex style ={{ margin :"15px" }}>
                    <Dropdown
                    style={{width : "300px"}} 
                    label={t("Department")}
                    //selectedKey={this.state.items.departmentId}
                    options={this.state.departments.map((x) => ({
                      key: x.id,
                      text: x.name,
                      data: x,
                    }))}
                      onChange = {(ev,props) => {
                        var item = {...this.state.item}
                        item.departmentId = props?.data.id;
                        this.setState({item})
                      }}
                    />
                    </Flex>
                    {/* <Flex style ={{ margin :"15px" }}>
                    <Dropdown
                    style={{width : "300px"}} 
                    label={t("Weekend Days")}
                   //selectedKeys={this.state.items.weekends}
                   options={days.map((x, index) => ({
                    key: index,
                    text: t(x),
                    data: index,
                  }))}
                   multiSelect
                    onChange={(ev, props) => {
                      var item = {...this.state.item}
                      if(props?.selected){
                        item.weekends.push(props.data);
                      }  
                      else{
                        item.weekends = item.weekends.filter((x:any) => x !== props?.data);
                      }
                      this.setState({item})
                    }}
                    />
                    </Flex> */}
                    {/* <Flex style ={{ margin :"15px" }}>
                    <TextField placeholder="9"
                     style={{width : "300px"}} 
                      type="number" max="24" min="0"
                      label={t("Working Hours")}
                      onChange={(ev, props) => {
                      var item = {...this.state.item}
                      item.workingHours = parseFloat(props ? props : "9") ?? "9";
                      this.setState({item});
                      }}
                    />
                    </Flex> */}
                  </Flex>
                </Flex>
                <Button primary fluid content={t("Submit")} onClick={this.submit} style={{width :"300px"}}/>
            </Flex>
         </FlexItem>
         </>
    );
  }
}

export default withRouter(withTranslation()(AddUser as any) as any);
