import React from "react";
import { Flex, FlexItem, Menu, Divider, MenuBehaviorProps, tabListBehavior } from "@fluentui/react-northstar";
import { withRouter } from "react-router-dom";

import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import { Separator } from "@fluentui/react";
import { withTranslation } from "react-i18next";
import GeneralDetails from "./generalDetails";
import WorkplaceMap from "./workplaceMap";
import UserLeaves from "./userLeaves";
import { CompanyView, ShiftScheduleType, UserShiftScheduleType, UserView } from "../../../models";
import { getUser } from "../../../api/account";
import RulesManagement from "./rulesManagement";
import WeekSchedule from "./weekschedule"
import { getCompany } from "../../../api/Company";
import { UiStore } from "../../../store/ui";

type Props = {
  match: {
    params: {
      id: string;
    };
  };
  t: any;
  user: UserStore;
};

interface IState {
  currentTab: number | string;
  userInView?: UserView;
}

@inject("user")
@observer
class UserDetailsTabs extends React.Component<Props, IState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      currentTab: 0
    };

  }

  get id() {
    return this.props.match.params.id;
  }

  componentDidMount(): void {
    this.fetch()
  }

  
  fetch = async () => {
    const user = await getUser(this.id);
    const company = await getCompany(user.payload.company.id);
    this.setState({userInView: user.payload})
  }

  render() {
    const { t, match, user } = this.props;
    const { userInView } = this.state
    return (
      <Flex styles={{ height: "100%" }} column>

        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
          <div
            className="mainHeader" style={{ color: user.companyPrimaryColor ?? "#005bab" }}
          >
            {this.id ? <><>{t("Edit employee")}</><br /><>{userInView ? userInView.fullName : ""}</></> : <></>}
          </div>
        </Flex>
        <Divider />

        <Flex styles={{ height: "100%" }}>
          <Menu pointing vertical
            accessibility = {(props: MenuBehaviorProps)=>{return tabListBehavior()} }
            defaultActiveIndex={0}
            onActiveIndexChange={(e, i) => this.setState({ currentTab: i?.activeIndex ?? 0 })}
            styles={{ width: "200px", minWidth: "200px" }}

            items={this.id ?
              [
                {
                  key: "generalDetails",
                  content: t('General Details')
                },
                {
                  key: "workplaceMap",
                  content: t('Workplace Map')
                },
                {
                  key: "leavesConfig",
                  content: t('Leaves Management')
                },
                {
                  key: "rulesManagement",
                  content: t('Rules Management')
                },
                userInView?.userRules?.shiftScheduleType == UserShiftScheduleType.FixedWeekSchedule ?
                  {
                    key: "weekschedule",
                    content: t('Week Schedule')
                  }
                 : undefined
              ] :
              [
                {
                  key: "generalDetails",
                  content: t('General Details')
                },
              ]} />
          <Separator vertical />
          <FlexItem grow>
            {( (i) => {
              switch (i) {
                case 0:
                case "0":
                  return <GeneralDetails />;
                case 1:
                case "1":
                  return <WorkplaceMap userInView={userInView} match={match} t={t} user={user} />;
                case 2:
                case "2":
                  return <UserLeaves userInView={userInView} match={match} t={t} user={user} />;
                case 3:
                case "3":
                  return <RulesManagement userInView={userInView} match={match} t={t} user={user} refreshParent={this.fetch}/>;
                case 4:
                case "4":
                  return <WeekSchedule />
              }
            })(this.state.currentTab)}
          </FlexItem>
        </Flex>

      </Flex>
    );
  }
}

export default withRouter(withTranslation()(UserDetailsTabs as any) as any);
