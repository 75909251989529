import { serialize } from "object-to-formdata";
import request from "../utils/request";

import {
  Result, ClockingType, ClockingInput, ClockingView, ClockingStatusView, ClockingPaginationView, EditClockingInput, OvertimeShiftApprovalInput, TimesheetTableView,UserView, ShiftInput, ShiftView, ClockOutInput, PoolView, PoolInput, ProfileInput, ShiftHistoryView
} from "../models";

export async function getAllowedActions() {
  const response = await request.get<Result<ClockingType[]>>("attendance/allowedactions");
  return response.data;
}

export async function clock(type: ClockingType, input: ClockingInput) {
  console.log(input)

  const response = await request.post<Result<ClockingView>>(`attendance/${type}`,
    serialize(input));
  return response.data;
}

export async function verifyOvertime(shiftId : number) {
  const response = await request.post<Result<never>>(`attendance/VerifyOvertime/${shiftId}`);
  return response.data;
}
export async function verifyInBreak(shiftId : number) {
  const response = await request.post<Result<never>>(`attendance/VerifyInBreak/${shiftId}`);
  return response.data;
}

export async function editClockTime(id: number, input: EditClockingInput) {
  const response = await request.post<Result<ClockingView>>(`attendance/edit/${id}`,
    serialize(input));
  return response.data;
}

export async function getStatus() {
  const response = await request.get<Result<ClockingStatusView>>("attendance/status");
  return response.data;
}

export async function getClockings(id: string, from: string, to: string) {
  const response = await request.get<Result<ClockingPaginationView>>("attendance", {
    params: { from, to, userId: id },
  });
  return response.data;
}

export async function getClockingsPerDay(id: string, from: string, to: string) {
  const response = await request.get<Result<ClockingView[][]>>("attendance/perday", {
    params: { from, to, userId: id },
  });
  return response.data;
}

export async function getTimesheetTableData(id: string, from: string, to: string) {
  const response = await request.get<Result<TimesheetTableView>>("attendance/TimesheetTableData", {
    params: { from, to, userId: id },
  });
  return response.data;
}

export async function overtimeApproval(input: OvertimeShiftApprovalInput) {
  const response = await request.post<Result<ClockingView>>(`attendance/OvertimeShiftApproval`, input);
  return response.data;
}

export async function checkIsSupervisorAndShiftNotArchived(userId: string, shiftId: number) {
  const response = await request.get<Result<boolean>>(`attendance/CheckIsSupervisorAndShiftNotArchived/${userId}/${shiftId}`);
  return response.data;
}


export async function getCurrentStatuses() {
  const response = await request.get<Result<UserView[]>>("attendance/UsersCurrentStatus");
  return response.data;
}

export async function addShift(input : ShiftInput, hoursOffset : number) {
  // var formData = new FormData();
  // formData.append('ClockInTime', input.clockInTime.toISOString())
  // formData.append('ClockOutTime', input.clockOutTime?.toDateString()?input.clockOutTime?.toDateString():"")
  // formData.append('UserId',input.userId)
  // const response = await request.post<Result<ShiftView>>(`attendance/addShift`,
  //   formData);
  // return response.data;
  console.log(input)
  const response = await request.post<Result<ShiftView>>(`attendance/addShift/${hoursOffset}`, input);
  return response.data;
}
export async function getShiftHistory(id : number, type: ClockingType) {
  const response = await request.get<Result<ShiftHistoryView[]>>(`attendance/ShiftHistory/${id}/${type}`);
  return response.data;
}

