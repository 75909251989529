import rootStore from "./rootStore";
// import userStore from "./user";
// import tokenStore from "./token";
// import notificationStore from "./notification";
// import uiStore from "./ui";
// import companyAddEditStore from "./companyAddEdit";

const stores = {
  rootStore: rootStore,
  // user: userStore,
  // because userStore uses the request object, same, the request uses the store to get the token
  // so now they both use a third tokenStore to eliminate circular dependencies
  // using cookies would work
  // however we don't want to make the website not functional if the browser had cookies disabled
  // token: tokenStore,
  // notification: notificationStore,
  // ui: uiStore,
  // companyAddEdit: companyAddEditStore
};

export default stores