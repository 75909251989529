import React from 'react';
import { Icon } from '@fluentui/react';
import { AcceptIcon, Button, CloseIcon, ExclamationTriangleIcon, Flex, Text, UndoIcon } from '@fluentui/react-northstar';
import dateformat from 'dateformat';
import { HalfDayPart, LeaveRequestStatus, LeaveRequestView, QuotaUnit } from '../../../models';

const columns = (t : any, attachmentButtonOnClick: any, approveButtonOnClick: any, rejectButtonOnClick: any, undoButtonOnClick: any, haveActionsAccessOverMyLeaves: boolean = false) => ({
    assignedLeaves : [
        {
            key: "employee",
            name: t("Employee"),
            minWidth: 200,
            maxWidth: 200,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                <Text styles={{ whiteSpace: "break-spaces" }} content={leave.user.fullName} />
            </Flex>,
        },
        {
            key: "leaveType",
            name: t("Leave type"),
            minWidth: 200,
            maxWidth: 200,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                <Text styles={{ whiteSpace: "break-spaces" }} content={t(leave.leaveType)} />
                {(leave.attachmentRequired && leave.attachmentCount <= 0) && <ExclamationTriangleIcon title={t("This leave requires an attachment")} />}
            </Flex>,
        },
        {
            key: "startDate",
            name: t("Start Date"),
            minWidth: 125,
            maxWidth: 125,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                <div>{dateformat(leave.startDate, leave.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Hours].toLowerCase() ? 'dd/mm/yyyy h:MM:ss TT' : 'dd/mm/yyyy')}</div>
                <div>{leave.fromHalfDay ? <Icon 
                    iconName={leave.fromHalfDayPart.toString() === HalfDayPart[HalfDayPart.AM] ? 'Sunny' : 'ClearNight'} 
                    title={t("Half day " + leave.fromHalfDayPart)} /> : null}
                </div>
            </Flex>,
        },
        {
            key: "endDate",
            name: t("End Date"),
            minWidth: 125,
            maxWidth: 125,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.smaller">
                <div>{dateformat(leave.endDate, leave.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Hours].toLowerCase() ? 'dd/mm/yyyy h:MM:ss TT' : 'dd/mm/yyyy')}</div>
                <div>{leave.toHalfDay ? <Icon 
                    iconName={leave.toHalfDayPart.toString() === HalfDayPart[HalfDayPart.AM] ? 'Sunny' : 'ClearNight'} 
                    title={t("Half day " + leave.toHalfDayPart)} /> : null}
                </div>
            </Flex>,
        },
        {
            key: "Status",
            name: t("Status"),
            minWidth: 100,
            maxWidth: 100,
            onRender: (leave: LeaveRequestView) => {
                return <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">{t(leave.status)}</Flex>
            },
        },
        {
            key: "lastUpdate",
            name: t("Last Update"),
            minWidth: 125,
            maxWidth: 125,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                {dateformat(leave.lastUpdate, 'dd/mm/yyyy h:MM:ss TT')}
            </Flex>,
        },
        // {
        //     key: "numberOfDays",
        //     name: t("Number of days"),
        //     minWidth: 200,
        //     maxWidth: 200,
        //     onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">{leave.numberOfDays}</Flex>,
        // },
        {
            key: "comment",
            name: t("Comment"),
            minWidth: 200,
            maxWidth: 200,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                {leave.comments
                    ? <Text styles={{ whiteSpace: "break-spaces" }} content={leave.comments} />
                    : <Text disabled content={t("Not available")} />}
            </Flex>,
        },
        {
            key: "supervisor",
            name: t("Supervisor"),
            minWidth: 100,
            maxWidth: 100,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                {leave.supervisorName
                    ? <Text styles={{ whiteSpace: "break-spaces" }} content={leave.supervisorName} />
                    : <Text disabled content={t("Not available")} />}
            </Flex>,
        },
        {
            key: "supervisorComments",
            name: t("Supervisor Comments"),
            minWidth: 200,
            maxWidth: 200,
            onRender: (leave: LeaveRequestView) => {
                // if (this.state.isSupervisor || this.props.user.role.toLowerCase() == Models.Roles.Hr.toLowerCase() || this.props.user.role.toLowerCase() == Models.Roles.Admin.toLowerCase())
                //     return (
                //         <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                //             <TextField
                //                 value={leave.supervisorComments}
                //                 onChange={(e, props) => {
                //                     leave.supervisorComments = props ?? ""
                //                     this.setState({})
                //                 }}
                //             />
                //         </Flex>
                //     )
                // else
                return (
                    leave.supervisorComments
                        ? <Text styles={{ whiteSpace: "break-spaces" }} content={leave.supervisorComments} />
                        : <Text disabled content={t("Not available")} />
                )
            },
        },
        {
            key: "actions",
            name: t("Actions"),
            minWidth: 150,
            maxWidth: 150,
            onRender: (leave: LeaveRequestView) => {
                let attachmentButton = <Button
                    onClick={() => attachmentButtonOnClick(leave)}
                    icon={<Icon iconName="Attach" style={{ fontSize: "15px" }} />}
                    title={t("Attachments")}
                    iconOnly
                />
                
                    return (
                        <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                            {leave.status.toString().toLowerCase() === LeaveRequestStatus[LeaveRequestStatus.Pending].toLowerCase() ?
                                <>
                                    <Button
                                        onClick={() => approveButtonOnClick(leave)}
                                        icon={<AcceptIcon />}
                                        title={t("Approve")}
                                        iconOnly
                                    />
                                    <Button
                                        onClick={() => rejectButtonOnClick(leave)}
                                        icon={<CloseIcon />}
                                        title={t("Reject")}
                                        iconOnly
                                    />
                                </> :
                                <>
                                    <Button
                                        onClick={() => undoButtonOnClick(leave)}
                                        icon={<UndoIcon />}
                                        title={t("Undo")}
                                        iconOnly
                                    />
                                </>
                            }
                            {attachmentButton}
                            {(leave.attachmentRequired && leave.attachmentCount <= 0) && <ExclamationTriangleIcon title={t("This leave requires an attachment")} />}
                        </Flex>
                    )
            }
        },
    ],
    myLeaves : [
        // {
        //     key: "employee",
        //     name: t("Employee"),
        //     minWidth: 200,
        //     maxWidth: 200,
        //     onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
        //         <Text styles={{ whiteSpace: "break-spaces" }} content={leave.user.fullName} />
        //     </Flex>,
        // },
        {
            key: "leaveType",
            name: t("Leave type"),
            minWidth: 200,
            maxWidth: 200,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                <Text styles={{ whiteSpace: "break-spaces" }} content={t(leave.leaveType)} />
                {(leave.attachmentRequired && leave.attachmentCount <= 0) && <ExclamationTriangleIcon title={t("This leave requires an attachment")} />}
            </Flex>,
        },
        {
            key: "startDate",
            name: t("Start Date"),
            minWidth: 125,
            maxWidth: 125,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                <div>{dateformat(leave.startDate, leave.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Hours].toLowerCase() ? 'dd/mm/yyyy h:MM:ss TT' : 'dd/mm/yyyy')}</div>
                <div>{leave.fromHalfDay ? <Icon 
                    iconName={leave.fromHalfDayPart.toString() === HalfDayPart[HalfDayPart.AM] ? 'Sunny' : 'ClearNight'} 
                    title={t("Half day " + leave.fromHalfDayPart)} /> : null}
                </div>
            </Flex>,
        },
        {
            key: "endDate",
            name: t("End Date"),
            minWidth: 125,
            maxWidth: 125,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.smaller">
                <div>{dateformat(leave.endDate, leave.quotaUnit.toString().toLowerCase() === QuotaUnit[QuotaUnit.Hours].toLowerCase() ? 'dd/mm/yyyy h:MM:ss TT' : 'dd/mm/yyyy')}</div>
                <div>{leave.toHalfDay ? <Icon 
                    iconName={leave.toHalfDayPart.toString() === HalfDayPart[HalfDayPart.AM] ? 'Sunny' : 'ClearNight'} 
                    title={t("Half day " + leave.toHalfDayPart)} /> : null}
                </div>
            </Flex>,
        },
        {
            key: "Status",
            name: t("Status"),
            minWidth: 100,
            maxWidth: 100,
            onRender: (leave: LeaveRequestView) => {
                return <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">{t(leave.status)}</Flex>
            },
        },
        {
            key: "lastUpdate",
            name: t("Last Update"),
            minWidth: 125,
            maxWidth: 125,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                {dateformat(leave.lastUpdate, 'dd/mm/yyyy h:MM:ss TT')}
            </Flex>,
        },
        // {
        //     key: "numberOfDays",
        //     name: t("Number of days"),
        //     minWidth: 200,
        //     maxWidth: 200,
        //     onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">{leave.numberOfDays}</Flex>,
        // },
        {
            key: "comment",
            name: t("Comment"),
            minWidth: 200,
            maxWidth: 200,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                {leave.comments
                    ? <Text styles={{ whiteSpace: "break-spaces" }} content={leave.comments} />
                    : <Text disabled content={t("Not available")} />}
            </Flex>,
        },
        {
            key: "supervisor",
            name: t("Supervisor"),
            minWidth: 100,
            maxWidth: 100,
            onRender: (leave: LeaveRequestView) => <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                {leave.supervisorName
                    ? <Text styles={{ whiteSpace: "break-spaces" }} content={leave.supervisorName} />
                    : <Text disabled content={t("Not available")} />}
            </Flex>,
        },
        {
            key: "supervisorComments",
            name: t("Supervisor Comments"),
            minWidth: 200,
            maxWidth: 200,
            onRender: (leave: LeaveRequestView) => {
                // if (this.state.isSupervisor || this.props.user.role.toLowerCase() == Models.Roles.Hr.toLowerCase() || this.props.user.role.toLowerCase() == Models.Roles.Admin.toLowerCase())
                //     return (
                //         <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                //             <TextField
                //                 value={leave.supervisorComments}
                //                 onChange={(e, props) => {
                //                     leave.supervisorComments = props ?? ""
                //                     this.setState({})
                //                 }}
                //             />
                //         </Flex>
                //     )
                // else
                return (
                    leave.supervisorComments
                        ? <Text styles={{ whiteSpace: "break-spaces" }} content={leave.supervisorComments} />
                        : <Text disabled content={t("Not available")} />
                )
            },
        },
        {
            key: "actions",
            name: t("Actions"),
            minWidth: 150,
            maxWidth: 150,
            onRender: (leave: LeaveRequestView) => {
                let attachmentButton = <Button
                    onClick={() => attachmentButtonOnClick(leave)}
                    icon={<Icon iconName="Attach" style={{ fontSize: "15px" }} />}
                    title={t("Attachments")}
                    iconOnly
                />
                if (haveActionsAccessOverMyLeaves)
                    return (
                        <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                            {leave.status.toString().toLowerCase() === LeaveRequestStatus[LeaveRequestStatus.Pending].toLowerCase() ?
                                <>
                                    <Button
                                        onClick={() => approveButtonOnClick(leave)}
                                        icon={<AcceptIcon />}
                                        title={t("Approve")}
                                        iconOnly
                                    />
                                    <Button
                                        onClick={() => rejectButtonOnClick(leave)}
                                        icon={<CloseIcon />}
                                        title={t("Reject")}
                                        iconOnly
                                    />
                                </> :
                                <>
                                    <Button
                                        onClick={() => undoButtonOnClick(leave)}
                                        icon={<UndoIcon />}
                                        title={t("Undo")}
                                        iconOnly
                                    />
                                </>
                            }
                            {attachmentButton}
                            {(leave.attachmentRequired && leave.attachmentCount <= 0) && <ExclamationTriangleIcon title={t("This leave requires an attachment")} />}
                        </Flex>
                    )
                else
                    return (
                        <Flex styles={{ height: "100%" }} vAlign="center" gap="gap.medium">
                            {attachmentButton}
                            {(leave.attachmentRequired && leave.attachmentCount <= 0) && <ExclamationTriangleIcon title={t("This leave requires an attachment")} />}
                        </Flex>
                    )
            }
        },
    ]
})

export default columns