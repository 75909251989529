import { ReactComponent as ClockIn } from './ClockInIcon.svg'
import { ReactComponent as ClockOut } from './ClockOutIcon.svg'
import { ReactComponent as BreakIn } from './BreakInIcon.svg'
import { ReactComponent as BreakOut } from './BreakOutIcon.svg'
import { ReactComponent as About } from './AboutIcon.svg'
import { ReactComponent as Holiday } from './HolidaysIcon.svg'
import { ReactComponent as Vacation } from './VacationsViewIcon.svg'
import { ReactComponent as Attach } from './AttachIcon.svg'
import { ReactComponent as Timesheet } from './TimesheetViewIcon.svg'
import { ReactComponent as CircleCheck } from './circleCheck.svg'
import { ReactComponent as DefaultProfile } from './defaultProfile.svg'
import { ReactComponent as Exit } from './Exit.svg'
import { ReactComponent as LateAlarm } from './LateAlarm.svg'
import {ReactComponent as Up} from './up.svg'
import {ReactComponent as Down} from './down.svg'
import {ReactComponent as Left } from './left.svg'
import {ReactComponent as Right} from './right.svg'

const Icons = {
    ClockIn,
    ClockOut,
    BreakIn,
    BreakOut,
    Vacation,
    Holiday,
    About,
    Attach,
    Timesheet,
    CircleCheck,
    DefaultProfile,
    Exit,
    LateAlarm,
    Up,
    Down,
    Left,
    Right
}

export default Icons