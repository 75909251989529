import request from "../utils/request";
import { Result, ScheduledShiftInput, ScheduledShiftView} from "../models";


export async function setScheduledShift(details: ScheduledShiftInput) {
    const response = await request.post<Result<ScheduledShiftView>>(`shift/SetScheduledShift`,details);
    return response.data;
  }


  export async function getScheduledShift(userId: string | number, from : string, to: string ) {
    const response = await request.get<Result<ScheduledShiftView[]>>(`shift/GetScheduledShifts/${userId}/${from}/${to}`);
    return response.data;
  }

  export async function removeScheduledShift(id : number ) {
    const response = await request.delete<Result<{}>>(`shift/${id}`);
    return response.data;
  }