import React from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Flex, Text } from "@fluentui/react-northstar";
import Clock from "react-live-clock";
import { withRouter } from "react-router-dom";
import WebCam from "react-webcam";
import { ClockingType, ClientType, ClockingInput, ClockingView } from "../../models";
import Timer from "../../components/timer";
import { UserStore } from "../../store/user";
import * as AttendanceApi from "../../api/attendance";
import "./index.scss";
import { getFormattedDuration } from "../../utils";
import { withTranslation } from "react-i18next";
import { UiStore } from "../../store/ui";



type Props = Readonly<{ 
  t : any; 
  ui : UiStore;
  user: UserStore;
  match: { params: { action?: string, shiftId?: number } } 
}>;

type State = {
  dialogVisible: boolean;
  input: ClockingInput;
  clockingType: ClockingType;
  loading: boolean;
  clockingStatus?: ClockingView[];
  error?: string;
};

@inject("user")
@observer
class Widget extends React.Component<Props, State> {
  interval: NodeJS.Timeout | null;
  webcamRef = React.createRef<WebCam>() as React.RefObject<WebCam> & string;
  gpsRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    const { t } = props
    this.interval = null;
    this.state = {
      dialogVisible: false,
      clockingType: ClockingType.ClockIn,
      input: {
        userId: props.user.userId,
        comments: "",
        clientType: ClientType.Web,
        time: new Date(),
        fullAddress: "",
        hoursOffset: 0,
        image: null,
      },
      loading: false,
    };
  }
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    if (!this.props.user.firstName)
      this.props.user.getUserInfo();
    else
      this.props.user.getStatus();
    const updateEvery = 1000;
    this.interval = setInterval(() => {
      this.update(updateEvery);
    }, updateEvery);
    if (!this.state.clockingStatus){
      this.fetch();
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  update = (interval : number) => {
    const { user } = this.props;
    user.now = moment(new Date());
    user.incrementWorkingTimer = user.isTimeRunning && !user.isBreakTimeRunning? user.incrementWorkingTimer + (interval / 1000) : user.incrementWorkingTimer;
    user.incrementBreakTimer = user.isBreakTimeRunning? user.incrementBreakTimer + (interval / 1000) : user.incrementBreakTimer;
  };

  fetch = async () => {
    this.setState({ loading: true });
    const today = new Date().toJSON().split('T')[0];
    const { payload } = await AttendanceApi.getClockingsPerDay(this.props.user.userId, today, today);
    if (payload)
      this.setState({ loading: false, clockingStatus: payload[0] });
    else
      this.setState({ loading: false })
  };

  render() {

    const { user, t } = this.props;
    const {
      breaksTime, totalBreaksTime, totalWorkingTime, workingTime, currentWorkingDuration,
    } = user as UserStore;
    
    const timeTxt = workingTime ? getFormattedDuration(workingTime.asSeconds()) : "00:00:00";
    const breakTxt = breaksTime ? getFormattedDuration(breaksTime.asSeconds()) : "00:00:00";
    const totalTimeTxt = totalWorkingTime
      ? getFormattedDuration(totalWorkingTime.asSeconds()) : "00:00:00";
    const totalBreakTxt = totalBreaksTime
      ? getFormattedDuration(totalBreaksTime.asSeconds()) : "00:00:00";

    const fullTime = moment.duration(currentWorkingDuration * 3600000);
    const progress = workingTime ? workingTime?.asSeconds() / fullTime.asSeconds() : 0;

    return (
      <>
        <Flex vAlign="stretch">
          <Flex column styles={{
            justifyContent: "start",
            alignItems: "center",
            flex: 1,
          }} gap="gap.small" padding="padding.medium">
            <div style={{
              width: "100%",
              minWidth: "250px",
              maxWidth: "500px",
              marginBottom: "-70px"
            }}>
              <Timer text={timeTxt} progress={progress} secondaryText={totalTimeTxt} primaryColor={user.companyPrimaryColor} />
            </div>
            <Text size="larger" align="center">
              <Clock format={"dddd, Do MMMM, h:mm:ss A"} ticking />
            </Text>
            <Text size="medium" align="center">
              <div>
                <b>{t("Break(shift)-")}</b>
                <span>{breakTxt}</span>
              </div>
              <div>
                <b>{t("Break(today)-")}</b>
                <span>{totalBreakTxt}</span>
              </div>
            </Text>
          </Flex>
        </Flex>
      </>
    );
  }
}

export default withRouter(withTranslation()(Widget as any) as any);
