import { serialize } from "object-to-formdata";
import request from "../utils/request";
import { Notification, Result, NotificationType, NotificationsResponseView, OnDemandNotificationInput } from "../models/index";


export async function GetNotificationsFirstPagePerUser(userId: string) {
  const response = await request
    .get<Result<NotificationsResponseView>>(`Notification/GetNotificationsPerUser/${userId}/0/1/15`);
  return response.data;
}

export async function GetNotificationsPerUser(userId: string, notificationType: number, pageNumber: number, pageSize: number) {
  const response = await request
    .get<Result<NotificationsResponseView>>(`Notification/GetNotificationsPerUser/${userId}/${notificationType?? 0}/${pageNumber?? 1}/${pageSize?? 15}`);
  return response.data;
}

export async function markNotificationAsRead(id: number) {
  const response = await request
    .put<Result<Notification[]>>(`Notification/SetNotificationAsRead/${id}`);
  return response.data;
}

export async function markAllNotificationsAsRead(userId: string) {
  const response = await request
    .put<Result<boolean>>(`Notification/SetAllNotificationsAsRead/${userId}`);
  return response.data;
}

export async function getNotifictionTypes() {
  const response = await request
    .get<Result<NotificationType[]>>(`Notification/Types`);
  return response.data;
}

export async function SendQueuedEmails() {
  const response = await request
    .post<Result<undefined>>(`Notification/SendQueuedEmails`);
  return response.data;
}

export async function SendOnDemandNotification(input : OnDemandNotificationInput) {
  const response = await request
    .post<Result<boolean>>(`Notification/SendOnDemandNotification`, serialize(input));
  return response.data;
}