import React from "react"
import { Flex, FlexItem, Menu, Divider, MenuBehaviorProps,AccessibilityDefinition, tabListBehavior} from "@fluentui/react-northstar"
import { withRouter } from "react-router-dom"
import { UserStore } from "../../../store/user"
import { inject, observer } from "mobx-react"
import { Separator } from "@fluentui/react"
import { withTranslation } from "react-i18next"
import GeneralDetails from "./generalDetails"
import WorkplaceMap from "./workplaceMap"
import CompanyLeaves from "./companyLeaves"
import CompanyHolidays from "./companyHolidays"
import { CompanyAddEditStore } from "../../../store/companyAddEdit"
import RulesManagement from "./rulesManagement"
import WeekSchedule from "./weekschedule"


type Props = {
  user: UserStore;
  companyAddEdit: CompanyAddEditStore;
  match: { params: { id?: string } };
  history: any;
  t: any;
};

interface IState {
  currentTab: number | string;
}

@inject("user", "companyAddEdit")
@observer
class CompanyTabs extends React.Component<Props, IState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      currentTab: 0
    };
  }

  get id() {
    if (window.location.href.toLowerCase().includes('mycompany'))
      return this.props.user.company?.id
    return Number(this.props.match.params.id);
  }

  render() {
    const { t } = this.props;
    return (
      <Flex styles={{ height: "100%" }} column>

        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
          <div
            className="mainHeader" style={{ color: this.props.user.companyPrimaryColor ?? "#005bab" }}
          >
            {
              this.id ?
                <><>{t('Edit company')}</><br /><>{(this.props.companyAddEdit.companyName !== "" ? this.props.companyAddEdit.companyName : "")}</></>
                : t('Add company')
            }
          </div>
        </Flex>
        <Divider />

        <Flex styles={{ height: "100%" }}>
          <Menu vertical pointing
            accessibility = {(props: MenuBehaviorProps)=>{return tabListBehavior()} }
            defaultActiveIndex={0}
            onActiveIndexChange={(e, i) => this.setState({ currentTab: i?.activeIndex ?? 0 })}
            styles={{ width: "200px", minWidth: "200px" }}
            items={this.id ?
              [
                {
                  key: "generalDetails",
                  content: t('General Details')
                },
                {
                  key: "workplaceMap",
                  content: t('Workplace Map')
                },
                {
                  key: "leavesConfig",
                  content: t('Leaves Management')
                },
                {
                  key: "holidaysConfig",
                  content: t('Holidays Management')
                },
                {
                  key: "rulesManagement",
                  content: t('Rules Management')
                },
                {
                  key: "weekschedule",
                  content: t('Week Schedule')
                },
              ] :
              [
                {
                  key: "generalDetails",
                  content: t('General Details')
                },
              ]} />
          <Separator vertical />
          <FlexItem grow>
            {(function (i) {
              switch (i) {
                case 0:
                case "0":
                  return <GeneralDetails />
                case 1:
                case "1":
                  return <WorkplaceMap />
                case 2:
                case "2":
                  return <CompanyLeaves />
                case 3:
                case "3":
                  return <CompanyHolidays />
                case 4:
                case "4":
                  return <RulesManagement />
                case 5:
                case "5":
                  return <WeekSchedule />
                default: 
                  return <GeneralDetails />
              }
            })(this.state.currentTab)}
          </FlexItem>
        </Flex>

      </Flex>
    );
  }
}

export default withRouter(withTranslation()(CompanyTabs as any) as any);
