import React from 'react';
import {
    Flex, Divider, Button,
    Text,
    Checkbox,
    Loader,
    Dialog,
} from "@fluentui/react-northstar";
import { ShimmeredDetailsList, CommandBar, IDetailsGroupRenderProps, GroupHeader, Dropdown, TextField, IGroup, fitContentToBounds, } from "@fluentui/react";
import { LeaveConfigInput, LeavesConfigView, LeaveType, QuotaType, QuotaUnit, Result } from "../../models";
import { UserStore } from "../../store/user";
import { inject, observer } from "mobx-react";
import * as LeavesApi from "../../api/leaves";
import { shadeBlend } from '../../utils';

interface IProps {
    companyId: number;
    userId?: string;
    user: UserStore;
    t: any;
};

interface LeaveConfigInputDTO extends LeaveConfigInput
{
    balanceText: string;
}

interface IState {
    loading: boolean;
    columnNames: any[];
    items: LeaveConfigInputDTO[];
    count: number;
    offset: number;
    options: any;
    groups: any[];
    users: any[];
    leavesConfigPayload: LeavesConfigView;
    selectedUsers: any[];
    createLeaveDialogOpen: boolean;
    newLeaveTypeName: string;
}

@inject("user")
@observer
class LeavesConfig extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const { t, userId } = props;
        this.state = {
            loading: false,
            users: [],
            selectedUsers: [],
            count: 5,
            offset: 0,
            leavesConfigPayload: {
                leavesConfig: [],
                countriesCount: 0,
                leaveTypesCount: 0
            },
            columnNames: userId ? ["", t('Country'), t('Quota'), t('Quota type'), t("Cummulative"), t('Annually resets'), t("Count working days only"), t("Overlaps Other Leaves"),t("Requires Attachment")] :
                ["", t("Leave type"), t('Quota'), t('Quota type'), t("Cummulative"), t('Annually resets'), t("Count working days only"), t("Overlaps Other Leaves"), t('Override company rules'), t("Current user balance"),t("Requires Attachment")],
            options: {
                filter: true,
                filterType: "dropdown",
                responsive: true,
            },
            items: [],
            groups: [],
            createLeaveDialogOpen: false,
            newLeaveTypeName: ""
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        const { companyId, userId } = this.props
        this.setState({ loading: true, items: [], groups: [], offset: 0 });

        const { payload } = await LeavesApi.getLeavesConfig(companyId, userId);
        await this.tableDataGenerator(payload.leaveTypesCount, payload.countriesCount, payload.leavesConfig)

        this.setState({
            loading: false,
            leavesConfigPayload: payload
        });
    };

    tableDataGenerator = async (leaveTypesCount: number, countriesCount: number, leavesConfigList: LeaveConfigInput[]) => {
        const { t } = this.props
        let body: LeaveConfigInputDTO[] = [];
        let groups: IGroup[] = [];

        let currentType = "";
        leavesConfigList.map(leaveConfig => {
            if (currentType !== leaveConfig.leaveTypeId.toString()) {
                currentType = leaveConfig.leaveTypeId.toString()
                
                groups.push({
                    key: leaveConfig.leaveTypeId.toString(),
                    name: t(leaveConfig.leaveType.name),
                    startIndex: body.length,
                    count: countriesCount,
                    level: 0,
                    isCollapsed: true
                })
            }
            var leaveConfigInputDTO : LeaveConfigInputDTO = {...leaveConfig, balanceText: leaveConfig.balance.toString()}
            body.push(leaveConfigInputDTO)
            return leaveConfig
        })
        this.setState({ items: body, groups })
    }

    submit = async () => {
        this.setState({ loading: true })
        var itemsToUpdate: LeaveConfigInput[] = [];
        this.state.items.forEach(item => {
            if (item && (item.id > 0 || item.enabled)) {
                itemsToUpdate.push(item)
            }
        });

        if (itemsToUpdate) {
            const { payload } = await LeavesApi.updateLeavesConfig({
                companyId: this.props.companyId,
                userId: this.props.userId?? "",
                leaveConfigInputList: itemsToUpdate
            })

            await this.tableDataGenerator(payload.leaveTypesCount, payload.countriesCount, payload.leavesConfig)

            this.setState({
                loading: false,
                leavesConfigPayload: payload
            });
        }
    }

    createLeaveType = async () => {
        this.setState({ loading: true });
        const { companyId } = this.props
        try {
            await LeavesApi.createLeaveType({ name: this.state.newLeaveTypeName, companyId: companyId } as LeaveType);
            this.fetchData()
        } catch (error) {
            if (error) {
                const res = error as Result<never>;
                alert(res.errors[0].description);
            }
        }
        this.setState({ loading: false, createLeaveDialogOpen: false, newLeaveTypeName: "" })
    }

    _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
        return <GroupHeader {...props} />;
    };

    render() {
        const {
            loading,
            items,
            groups,
        } = this.state;

        const { t, userId, user } = this.props

        const dialogs = <>
            <Dialog
                open={this.state.createLeaveDialogOpen}
                header={t("Create leave type")}
                confirmButton={t("Submit")}
                onConfirm={() => this.createLeaveType()}
                cancelButton={t("Cancel")}
                onCancel={() => this.setState({ createLeaveDialogOpen: false })}
                content={(
                    <Flex column gap="gap.medium"
                        hAlign="stretch"
                        styles={{ width: "100%" }}
                    >
                        <TextField
                            placeholder={t("Leave type name")}
                            onChange={(e, props) => this.setState({ newLeaveTypeName: props ?? "" })} />
                    </Flex>
                )}
            />
        </>

        const submitButton = (<Button
            primary
            disabled={loading}
            styles={{ margin: "0px 10px 0px 10px" }}
            onClick={() => {
                this.submit()
            }}
        >
            {loading ? <Loader /> : t("Submit")}
        </Button>)

        return (
            <Flex column styles= {{overflowX:"hidden"}}  >
                {dialogs}
                {/* <Flex hAlign="end" styles={{ paddingBottom: '10px'}}>
                    {userId ? <></> : <Button
                        styles={{ margin: "0px 10px 0px 10px"}}
                        icon={<AddIcon/>}
                        text
                        content={t("Add Leave Type")}
                        onClick={() => {
                            this.setState({createLeaveDialogOpen: true})
                        }}
                        >
                    </Button>}
                    {submitButton}
                </Flex> */}
               
                <CommandBar
                    items={[]}
                    farItems={
                        [
                            ...(userId ? [] : [{
                                key: "Add Leave Type",
                                text: t("Create leave type"),
                                iconProps: { iconName: "add" },
                                buttonStyles: {
                                    icon: { color: user.companyPrimaryColor },
                                    iconHovered: { color: shadeBlend(-0.2, user.companyPrimaryColor, undefined) },
                                },
                                onClick: () => this.setState({ createLeaveDialogOpen: true }),
                            }]),
                            {
                                key: "submit",
                                onRender: () => <Flex vAlign="center">{submitButton}</Flex>
                            }
                        ]
                    }
                />
             
                <Divider />
                <Flex>
                <Flex >
                <ShimmeredDetailsList
                    selectionMode={0}
                    detailsListStyles={{ root: { width: "100%"} }}
                    enableShimmer={loading}
                    items={items}
                    groups={userId ? undefined : groups}
                    groupProps={{
                        showEmptyGroups: true,
                        onRenderHeader: this._onRenderGroupHeader,
                        isAllGroupsCollapsed: true
                    }}
                    columns={[
                        {
                            key: "enabled",
                            name: "",
                            onRender: (item: LeaveConfigInput) => {
                                return (
                                    <Flex hAlign="center" >
                                        <Checkbox
                                            title={t("Enabled")}
                                            checked={item.enabled}
                                            disabled={userId !== undefined && userId !== "" && item.inheritedFromCompany}
                                            onChange={(ev, props) => {
                                                item.enabled = props?.checked ?? false;
                                                this.setState({})
                                            }}
                                        />
                                    </Flex>
                                )
                            },
                            minWidth: user.locale=='ar' || user.locale == 'en'?10:10,
                            maxWidth: 60
                        },
                        {
                            key: "CountryOrType",
                            name: userId ? t("Leave type") : t("Country"),
                            onRender: (item: LeaveConfigInput) => (<Flex vAlign="center"><Text>{userId ? item.leaveType.name : item.country.name}</Text></Flex>),
                            minWidth:user.locale =='ar'  || user.locale == 'en' ?150:200,
                            maxWidth: 250,
                        },
                        {
                            key: "QuotaUnit",
                            name: t("Quota Unit"),
                            onRender: (item: LeaveConfigInput) => {
                                return (<Dropdown
                                    defaultSelectedKey={item.quotaUnit ? item.quotaUnit as QuotaUnit : QuotaUnit.Days}
                                    options={Object.values(QuotaUnit) //.splice(0, Object.values(QuotaUnit).length / 2)
                                        .map(x => ({
                                            key: x.valueOf().toString(),
                                            text: t(x.toString()),
                                            id: x.valueOf().toString(),
                                        }))}
                                    disabled={userId !== undefined && userId !== "" && item.inheritedFromCompany}
                                    onChange={(ev, props) => {
                                        item.quotaUnit = props?.key ? props.key as QuotaUnit : QuotaUnit.Days;
                                        this.setState({});
                                    }}
                                />)
                            },
                            minWidth:user.locale =='ar'?90:100,
                            maxWidth: 120,
                        },
                        {
                            key: "Quota",
                            name: t("Quota"),
                            onRender: (item: LeaveConfigInput) => {
                                return (
                                    <Flex>
                                        <TextField
                                            type="number"
                                            min={0.0}
                                            max={365.0}
                                            value={item.quota.toString()}
                                            disabled={userId !== undefined && userId !== "" && item.inheritedFromCompany}
                                            onChange={(ev, props) => {

                                                if (parseFloat(props ? props : "0") > 365)
                                                    props = "365"
                                                else if (parseFloat(props ? props : "0") < 0)
                                                    props = "0"

                                                item.quota = parseFloat(props ? props : "0") ?? "0";
                                                this.setState({});
                                            }}
                                        />
                                    </Flex>
                                )
                            },
                            minWidth:user.locale =='ar'?60:60,
                            maxWidth: 100,
                        },
                        {
                            key: "Quota type",
                            name: t("Quota type"),
                            onRender: (item: LeaveConfigInput) => {
                                return (<Dropdown
                                    defaultSelectedKey={item.quotaType ? item.quotaType as QuotaType : QuotaType.Annual}
                                    options={Object.values(QuotaType) //.splice(0, Object.values(QuotaType).length / 2)
                                        .map(x => ({
                                            key: x.valueOf().toString(),
                                            text: t(x.toString()),
                                            id: x.valueOf().toString(),
                                        }))}
                                    disabled={userId !== undefined && userId !== "" && item.inheritedFromCompany}
                                    onChange={(ev, props) => {
                                        item.quotaType = props?.key ? props.key as QuotaType : QuotaType.Annual;
                                        this.setState({});
                                    }}
                                />)
                            },
                            minWidth:user.locale =='ar' || user.locale == 'en' ?155:225,
                            maxWidth: 265,
                        
                          
                        },
                        {
                            key: "Cummulative",
                            name: t("Cummulative"),
                            onRender: (item: LeaveConfigInput) => {
                                return (
                                    <Flex hAlign="center">
                                        <Checkbox
                                            title={t("Cummulative")}
                                            checked={item.isCummulative}
                                            disabled={userId !== undefined && userId !== "" && item.inheritedFromCompany}
                                            onChange={(ev, props) => {
                                                item.isCummulative = props?.checked ?? false;
                                                this.setState({})
                                            }}
                                        />
                                    </Flex>
                                )
                            },
                            minWidth:user.locale =='ar'?60:90,
                            maxWidth: 180,
                        },
                        {
                            key: "Annually resets",
                            name: t("Annually resets"),
                            onRender: (item: LeaveConfigInput) => {
                                var isAnnual = item.quotaType.toString().toLowerCase() === QuotaType[QuotaType.Annual].toLowerCase()
                                return isAnnual ? (<></>) : (
                                    <Flex hAlign="center" >
                                        <Checkbox
                                            title={t("Annually resets")}
                                            checked={item.annuallyResets}
                                            disabled={userId !== undefined && userId !== "" && item.inheritedFromCompany}
                                            onChange={(ev, props) => {
                                                item.annuallyResets = props?.checked ?? true;
                                                this.setState({})
                                            }}
                                        />
                                    </Flex>
                                )
                            },
                            minWidth:user.locale =='ar'  || user.locale == 'en'?120:155,
                            maxWidth: 190,
                        },
                        {
                            key: "Count working days only",
                            name: t("Count working days only"),
                            onRender: (item: LeaveConfigInput) => {
                                return (
                                    <Flex hAlign={"center"} >
                                        <Checkbox
                                            title={t("Count working days only")}
                                            checked={!item.includeNonWorkingrDays}
                                            disabled={userId !== undefined && userId !== "" && item.inheritedFromCompany}
                                            onChange={(ev, props) => {
                                                item.includeNonWorkingrDays = !props?.checked ?? true;
                                                this.setState({})
                                            }}
                                        />
                                    </Flex>
                                )
                            },
                            minWidth:user.locale =='ar'  || user.locale == 'en'?160:230,
                            maxWidth: 280,
                        },
                        {
                            key: "Overlaps Other Leaves",
                            name: t("Overlaps Other Leaves"),
                            onRender: (item: LeaveConfigInput) => {
                                return (
                                    <Flex hAlign="center">
                                        <Checkbox
                                            title={t("Overlaps Other Leaves")}
                                            checked={item.leaveOverlapsOtherLeaves}
                                            disabled={userId !== undefined && userId !== "" && item.inheritedFromCompany}
                                            onChange={(ev, props) => {
                                                item.leaveOverlapsOtherLeaves = props?.checked ?? false;
                                                this.setState({})
                                            }}
                                        />
                                    </Flex>
                                )
                            },
                            minWidth:user.locale =='ar'  || user.locale == 'en'?150:190,
                            maxWidth: 220,
                        },
                        {
                            key: "Requires Attachment",
                            name: t("Requires Attachment"),
                            onRender: (item: LeaveConfigInput) => {
                                return (
                                    <Flex hAlign={userId ? "center" : "start"} styles={!userId ? { margin: "0px 30px 0px 30px" } : {}}>
                                        <Checkbox
                                            title={t("Requires Attachment")}
                                            // styles={{margin: "0px 30px 0px 30px"}}
                                            checked={item.attachmentRequired}
                                            disabled={userId !== undefined && userId !== "" && item.inheritedFromCompany}
                                            onChange={(ev, props) => {
                                                if (typeof (props?.checked) == "boolean") {
                                                    item.attachmentRequired = props?.checked;
                                                } else {
                                                    item.attachmentRequired = false;
                                                }
                                                this.setState({})
                                            }}
                                        />
                                    </Flex>
                                )
                            },
                            minWidth:user.locale =='ar' || user.locale == 'en'?150:180,
                            maxWidth: 200,
                            // minWidth:60,
                            // maxWidth:100,

                        },
                        ...(userId ? [
                            {
                                key: "Override company rules",
                                name: t("Override company rules"),
                                onRender: (item: LeaveConfigInput) => {
                                    return (
                                        <Flex hAlign="center">
                                            <Checkbox
                                            
                                                title={t("Override company rules")}
                                                // styles={{margin: "0px 30px 0px 30px"}}
                                                checked={!item.inheritedFromCompany}
                                                onChange={(ev, props) => {
                                                    if (typeof (props?.checked) == "boolean") {
                                                        item.inheritedFromCompany = !props?.checked;
                                                    } else {
                                                        item.inheritedFromCompany = true
                                                    }
                                                    this.setState({})
                                                }}
                                            />
                                        </Flex>
                                    )
                                },
                                minWidth:user.locale =='ar'  || user.locale == 'en'?160:230,
                                maxWidth: 290,
                                // minWidth:60,
                                // maxWidth:100,
                            },
                            {
                                key: "Balance",
                                name: t("Current user balance"),
                                onRender: (item: LeaveConfigInputDTO) => {
                                    return (
                                        <Flex>
                                            <TextField
                                                type="number"
                                                min={-365.0}
                                                max={365.0}
                                                value={item.balanceText.toString()}
                                                onChange={(ev, props) => {
                                                    
                                                    if (parseFloat(props ? props : "0") > 365)
                                                        props = "365"
                                                    else if (parseFloat(props ? props : "0") < -365)
                                                        props = "-365"

                                                    item.balanceText = props ?? "0"
                                                    item.balance = parseFloat(props ? props : "0") ?? "0";
                                                    this.setState({});
                                                }}
                                            />
                                        </Flex>
                                    )
                                },
                                minWidth:user.locale =='ar'?145:145,
                                maxWidth: 190,
                                // minWidth: 60,
                                // maxWidth: 100,
                                
                            }
                        ] : []),
                    ]}


                />
                </Flex>
                </Flex>
                {/* <Flex hAlign="end">
                    {submitButton}
                </Flex> */}
            </Flex>

        );
    }
}

export default LeavesConfig;