import request from "../utils/request";
import { Result, OrganizationInput, OrganizationView } from "../models/index";

// export async function getLeavePerUser(userId: string) {
//    const response = await request.get<Result<LeavesModel[]>>(`Leave/GetLeavePerUser/${userId}`);
//    return response.data;
// }

// export async function getBalancePerUser(userId: string) {
//    const response = await request
//        .get<Result<LeaveBalanceModel[]>>(`Leave/GetBalancePerUser/${userId}`);
//    return response.data;
// }

export async function GetOrganizations() {
  const response = await request.get<Result<OrganizationView[]>>("Organization/Organizations");
  return response.data;
}

export async function addOrganization(input: OrganizationInput) {
  const response = await request
    .post<Result<OrganizationView>>("Organization/AddOrganization", input);
  return response.data;
}

export async function deleteOrganization(id: number) {
  const response = await request.delete(`Organization/${id}`);
  return response.data;
}
