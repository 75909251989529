import React from 'react';
import { ShimmeredDetailsList, IColumn } from '@fluentui/react';
import { Holiday, HolidayView } from "../../../models";
import { inject, observer } from "mobx-react";
import { UserStore } from '../../../store/user';
import * as HolidaysApi from '../../../api/holidays';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import dateformat from 'dateformat'
import moment from 'moment';

interface IState {
    assignDialogOpen: boolean;
    holidaysDialogOpen: boolean;
    assignInput: number[];
    holidaysLoading: boolean;
    userHolidays: Holiday[];
    holidays: Holiday[]
    // holidaysInput: (HolidayInput & { id: string })[];
    holidayInputError?: string;
}

interface IProps {
    user: UserStore;
    match: { params: { id?: string } };
    t: any;
}

@inject("user")
@observer
class Holidays extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            assignDialogOpen: false,
            assignInput: [],
            holidays: [],
            holidaysDialogOpen: false,
            holidaysLoading: false,
            userHolidays: []
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    get userId() {
        return this.props.match.params.id ?? this.props.user.userId;
    }

    fetchData = async () => {
        this.setState({ holidaysLoading: true });
        const userHolydays = await HolidaysApi.getUserHolidays(this.userId);
        // const holidays = await HolidaysApi.getHolidays();
        this.setState({
            holidaysLoading: false,
            userHolidays: userHolydays.payload
        });
    }



    // assignHoliday = async () => {
    //     await HolidaysApi.assignHolidays({
    //         userId: this.userId,
    //         holidayId: this.state.assignInput,
    //     });
    //     this.fetchData();
    //     this.setState({ assignDialogOpen: false });
    // };

    // unassignHoliday = async (id : number) => {
    //     await HolidaysApi.unassignHolidays({
    //         userId: this.userId,
    //         holidayId: [id],
    //     });
    //     this.fetchData();
    // };


    render() {
        const { t } = this.props;

        const holidayCols: IColumn[] = [
            {
                key: "name",
                name: t("Name"),
                minWidth: 250,
                maxWidth: 300,
                onRender: (holiday: HolidayView) => t(holiday.name),
            },
            {
                key: "date",
                name: t("Date"),
                minWidth: 200,
                maxWidth: 200,
                onRender: (holiday: HolidayView) => moment(new Date(holiday.date)).format('dddd, Do MMM, yyyy'),
            },
            {
                key: "description",
                name: t("Description"),
                minWidth: 200,
                maxWidth: 200,
                onRender: (holiday: HolidayView) => holiday.description,
            }
        ];

        return (
            <>
                <div style={{ overflow: "hidden" }}>
                    <ShimmeredDetailsList
                        detailsListStyles={{ root: { overflowX: "auto", maxWidth: "100%" } }}
                        enableShimmer={this.state.holidaysLoading}
                        items={this.state.userHolidays} columns={holidayCols} selectionMode={0} />
                </div>
            </>
        );
    }


}

export default withRouter(withTranslation()(Holidays as any) as any);