import React from 'react';
import { ShimmeredDetailsList, IColumn } from '@fluentui/react';
import { Text } from '@fluentui/react-northstar';
import { LeaveBalanceView } from '../../../models';
import * as LeavesApi from '../../../api/leaves';
import { UserStore } from '../../../store/user';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

interface IState {
    balanceLoading: boolean;
    balance: LeaveBalanceView[];
}

interface IProps {
    t : any;
    user: UserStore;
    match: { params: { id?: string } };
}

@inject("user")
@observer
class Balance extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            balance: [],
            balanceLoading: false
        }
    }

    get userId() {
        return this.props.match.params.id ?? this.props.user.userId;
    }

    fetchData = async () => {
        this.setState({ balanceLoading: true });
        const balance = await LeavesApi.getBalancePerUser(this.userId);
        this.setState({ balanceLoading: false, balance: balance.payload });
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { t } = this.props
        const balanceCols: IColumn[] = [
            {
                key: "leaveType",
                name: t("Leave type"),
                minWidth: 200,
                maxWidth: 200,
                onRender: (balance: LeaveBalanceView) => <Text content={t(balance.leaveType)} />,
            },
            {
                key: "quota",
                name: t("Quota"),
                minWidth: 100,
                maxWidth: 100,
                onRender: (balance: LeaveBalanceView) => balance.quota,
            },
            {
                key: "quotaUnit",
                name: t("Quota Unit"),
                minWidth: 100,
                maxWidth: 100,
                onRender: (balance: LeaveBalanceView) => t(balance.quotaUnit),
            },
            {
                key: "balance",
                name: t("Balance Remaining"),
                minWidth: 100,
                maxWidth: 100,
                onRender: (balance: LeaveBalanceView) => balance.balance,
            },
        ];
        return (
            <ShimmeredDetailsList
                detailsListStyles={{ root: { overflowX: "auto", maxWidth: "100%" } }}
                enableShimmer={this.state.balanceLoading}
                items={this.state.balance} columns={balanceCols} selectionMode={0} />
        );
    }
}

export default withRouter(withTranslation()(Balance as any) as any);