import { action, observable } from 'mobx';
import { RootStore } from './rootStore';

export class CompanyAddEditStore {

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }
    
    @observable companyName: string = "";
    @observable generalDetailsChangesUnsaved: boolean = false;

    @action
    setCompanyName(companyName : string){
        this.companyName = companyName
    }
}

// export default (window as any).companyAddEditStore = new CompanyAddEditStore();