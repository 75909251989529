export const dayStatusColor = {
    sickLeave: "#F44336", // red
    abscent: "#F44336", // red
    normal: "#4CAF50", // green
    holiday: "#9C27B0", // purple
    leave: "#2196F3", // blue
    weekend: "#777777", // grey
    clockIn:"#4CAF50", // green
    clockOut:"#F44336", // red
    breakIn:"#f6ad56", // yellow
    breakOut:"#e97c0b", // orange
    workingHoursNotFulfilled:" #E9EC6B",
    sickLeaveWithOpacity: "#F4433655",
    abscentWithOpacity: "#F4433655",
    normalWithOpacity: "#4CAF5055",
    holidayWithOpacity: "#9C27B055",
    leaveWithOpacity: "#2196F355",
    weekendWithOpacity: "#77777755",
    workingHoursNotFulfilledWithOpacity:" #E9EC6B",
    expectedWorkingHours: "#BED1DF"
}