import { useEffect, useState } from "react";

export type MediaQueryWidth = "576" | "765" | "992" | "1200";

export const useMediaQuery = (query: string) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

export const useMaxWidth = (num: MediaQueryWidth | number) => useMediaQuery(`(max-width:${num}px)`);

export const useMinWidth = (num: MediaQueryWidth | number) => useMediaQuery(`(min-width:${num}px)`);
