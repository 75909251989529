import React, { createRef } from "react";
import { Flex, Text, Button, Layout, FlexItem, TrashCanIcon, Label, Image, Grid, Alert, Loader } from "@fluentui/react-northstar";
import { TextField, Dropdown, ComboBox, MessageBar, MessageBarType } from "@fluentui/react";
import { withRouter } from "react-router-dom";
import { ChromePicker } from 'react-color';
import { getPlans, addCompany, updateCompany, getCompany } from "../../../api/Company";
import {
  SubscriptionPlan,
  CompanyInput,
  CountryView,
  OrganizationView,
  CompanyView,
  AttendanceImageConfig,
  TimeZoneInfoView,
  FaceRecognitionConfig,
  ShiftFlowRuleType
} from "../../../models";
import { getCountries, getTimeZones } from "../../../api/Country";
import { GetOrganizations } from "../../../api/Organization";
import { withTranslation } from "react-i18next";
import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import { getRoles } from "../../../api/account";
import { lightOrDark } from "../../../utils";
import PhoneInput from 'react-phone-number-input';
import { CompanyAddEditStore } from "../../../store/companyAddEdit";
import TimePicker from "react-time-picker";



interface IState {
  loading: boolean;
  plans: SubscriptionPlan[];
  countries: CountryView[];
  timeZones: TimeZoneInfoView[];
  input: CompanyInput;
  company: CompanyView;
  organizations: OrganizationView[];
  success: boolean;
  roles: string[];
  alertVisible: boolean;
  alertContent: string;
}

interface IProps {
  match: { params: { id?: number } };
  history: any;
  t: any;
  user: UserStore;
  companyAddEdit: CompanyAddEditStore;
}

@inject("user", "companyAddEdit")
@observer
class GeneralDetails extends React.Component<IProps, IState> {
  ref: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      plans: [],
      countries: [],
      timeZones: [],
      organizations: [],
      input: {
        organizationId: 0,
        websiteLink: "",
        address: "",
        email: "",
        //fax: "",
        rolesMaxUsers: {},
        phoneNumber: "",
        image: null,
        name: "",
        planId: 0,
        primaryColor: "#005AAB",
        //attendanceImageConfig: AttendanceImageConfig.Required,
        //faceRecognitionConfig: FaceRecognitionConfig.Required,
        //shiftFlowRuleType: ShiftFlowRuleType.Default,
        timeZone: "",
        //defaultStartTime: "09:00",
        //defaultEndTime: "18:00",
        //timesheetCreationDay: 0,
        //hideClockingImagesFromTimesheet: false,
        //disableAllNotifications: false,
        //faceRecognitionEnabled: false,
        locales: ""
      },
      company: {} as CompanyView,
      roles: [],
      alertVisible: false,
      alertContent: "",
    };
    this.ref = createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.fetch();
  }

  get id() {
    if(window.location.href.toLowerCase().includes('mycompany'))
      return this.props.user.company?.id
    return Number(this.props.match.params.id);
  }

  fetch = async () => {
    this.setState({ loading: true })
    if (this.id) {
      const company = await getCompany(this.id);
      this.props.companyAddEdit.setCompanyName(company.payload.name)
      this.setState({
        input: {
          ...company.payload,
          image: null,
          planId: company.payload.subscriptionPlanView?.id,
          organizationId: company.payload.organization?.id,
          countryId: company.payload.country?.id,
          // websiteLink: company.payload.websiteLink,
          // rolesMaxUsers: company.payload.rolesMaxUsers,
          // phoneNumber: company.payload.phoneNumber,
          // primaryColor: company.payload.primaryColor,
          // timeZone: company.payload.timeZone,
          // defaultStartTime: company.payload.defaultStartTime ? company.payload.defaultStartTime : "09:00",
          // defaultEndTime: company.payload.defaultEndTime ? company.payload.defaultEndTime : "18:00",
          // timesheetCreationDay: company.payload.timesheetCreationDay,
          // attendanceImageConfig: company.payload.attendanceImageConfig,
          // hideClockingImagesFromTimesheet: company.payload.hideClockingImagesFromTimesheet,
          // disableAllNotifications: company.payload.disableAllNotifications,
          // faceRecognitionEnabled: company.payload.faceRecognitionEnabled,
          // locales: company.payload.locales,
        },
        company: company.payload
      });
    }
    const plans = await getPlans();
    this.setState({ plans: plans.payload })

    const countries = await getCountries()
    this.setState({ countries: countries.payload })

    const timeZones = await getTimeZones();
    this.setState({ timeZones: timeZones.payload })

    var organizations;
    if (this.props.user.role.toLowerCase() === 'admin'){
      organizations = await GetOrganizations();
    }
    this.setState({ organizations: organizations ? organizations.payload : [] })

    var roles = [] as string[];
    var rolesRes = await getRoles();
    if (rolesRes.payload){
      roles = rolesRes.payload
    }
    this.setState({
      roles,
      loading: false
    });
  };

  handleImageChange = (event: React.FormEvent<HTMLInputElement>) => {
    const f = (event.target as any).files[0];
    var input = {...this.state.input}
    input.image = f;
    this.setState({input});
  };

  addCompany = async () => {
    try {
      this.setState({ loading: true })
      const result = await addCompany(this.state.input);
      this.setState({
        input: {
          websiteLink: "",
          address: "",
          email: "",
          //fax: "",
          phoneNumber: "",
          image: null,
          rolesMaxUsers: {},
          name: "",
          planId: 1,
          organizationId: 1,
          primaryColor: "#005AAB",
          //attendanceImageConfig: AttendanceImageConfig.Required,
          //faceRecognitionConfig: FaceRecognitionConfig.Required,
          //shiftFlowRuleType: ShiftFlowRuleType.Default,
          timeZone: "",
          //defaultStartTime: "09:00",
          //defaultEndTime: "18:00",
          //timesheetCreationDay: 0,
          //hideClockingImagesFromTimesheet: false,
          //disableAllNotifications: false,
          //faceRecognitionEnabled: false,
          locales: ""
        },
        loading: false
      });
      return result.payload.id;
    } catch (error) {
      alert("Something went wrong!");
    }
    return undefined;
  };

  updateCompany = async () => {
    if (this.id) {
      try {
        this.setState({ loading: true })
        var res = await updateCompany(this.id, this.state.input);
        this.props.user.companyPrimaryColor = res.payload.primaryColor
        this.setState({ success: true, loading: false });
        // window.location.reload();
      } catch (error) {
        alert("Something went wrong!");
      }
    }
  };

  validateInputs = () => {
    const {t} = this.props;
    if (this.state.input.countryId == null
        || this.state.input.countryId === 0
        || this.state.input.organizationId == null
        || this.state.input.organizationId === 0
        || this.state.input.name == null
        || this.state.input.name === ""
      )
    {
      alert(t("Please input the required fields!"))
      return false
    }
    return true
  }

  addOrUpdate = async () => {
    if (this.validateInputs()) {
      if (this.id) {
        await this.updateCompany()
      } else {
        await this.addCompany()
        this.props.history.goBack()
      }
    }
  };

  render() {

    const { t } = this.props;
    
    const { rolesMaxUsers } = this.state.input
    const { rolesUsersAvailable } = this.state.company
    const { loading } = this.state

    return (
      <FlexItem grow>
          <Flex column gap="gap.large" padding="padding.medium" hAlign="center">
            {this.state.success && (
              <MessageBar messageBarType={MessageBarType.success}>
              {t("Company Updated Successfully")}
              </MessageBar>
            )}
            
            <Flex column gap="gap.medium" styles={{ width: "500px", maxWidth: "100%" }}>
              { this.props.user.role.toLowerCase() === 'admin' ? <TextField required={true} label={t("Company Name")}
                onChange={(ev, props) => {
                  var input = {...this.state.input}
                  input.name = props ?? "";
                  this.setState({input});
                }}
                value={this.state.input.name} /> : <></>}
              <Flex gap="gap.medium" column>
                { this.id ? <Image src={this.props.user.companyLogo}
                  styles={{
                    width: "300px",
                    height: "100px",
                    objectFit: "contain",
                  }} /> : <></> }
                  <Flex gap="gap.medium">
                    <Button onClick={() => this.ref.current?.click()}>
                      {t("Upload Image")}
                    </Button>
                    {this.state.input.image && (
                      <FlexItem grow>
                        <Layout main={this.state.input.image.name}
                          end={(
                            <Button text iconOnly icon={<TrashCanIcon />} 
                              onClick={() => {
                                var input = {...this.state.input}
                                input.image = null;
                                this.setState({input});
                              }} 
                            />
                          )} />
                      </FlexItem>
                    )}
                  </Flex>
              </Flex>
              <input ref={this.ref} onChange={this.handleImageChange} type="file"
                style={{ display: "none" }}
                accept="image/*" />
              {/* <TextField type="text" label="Website Link" placeholder="Website Link"
                onChange={(ev, props) => {
                  this.state.input.websiteLink = props ?? "";
                  this.setState({});
                }}
                value={this.state.input.websiteLink} /> */}
              { // Organization Input
                this.props.user.role.toLowerCase() === 'admin' ? 
                <Dropdown required={true} label={t("Organization")}
                  selectedKey={this.state.input.organizationId}
                  options={this.state.organizations.map((x) => ({
                    key: x.id,
                    text: x.name,
                    data: x,
                  }))}
                  onChange={(ev, props) => {
                    var input = {...this.state.input}
                    input.organizationId = props?.data.id;
                    this.setState({input});
                  }} />
                : null
              }
              <TextField type="text" label={t("Email")}
                onChange={(ev, props) => {
                  var input = {...this.state.input}
                  input.email = props ?? "";
                  this.setState({input});
                }}
                value={this.state.input.email} />
              <Flex column space="between">
              <Text content={t("Country")} weight="semibold" styles={{paddingBottom : '5px'}} />
                <ComboBox required={true}
                  styles={{ optionsContainerWrapper: { maxHeight: "250px" } }}
                  style={{direction: 'ltr'}}
                  selectedKey={this.state.input.countryId}
                  options={this.state.countries.map((x) => ({
                    key: x.id,
                    text: x.name,
                    data: x,
                  }))}
                  onChange={(ev, props) => {
                    var input = {...this.state.input}
                    input.countryId = props?.data.id;
                    if (!input.phoneNumber || input.phoneNumber.length < 6){
                      input.phoneNumber = '+' + props?.data.phoneCode
                      this.setState({input});
                    }
                    this.setState({input});
                  }} />
              </Flex>
              <Flex column gap="gap.smaller">
                <Text content={t('Phone Number')} weight="semibold" 
                // styles={{paddingBottom : '5px'}} 
                />
                <PhoneInput
                  international
                  style={{direction: 'ltr', height : '30px'}}
                  value={this.state.input.phoneNumber}
                  onChange={(ev : any) => {
                    var input = {...this.state.input}
                    input.phoneNumber = ev;
                    this.setState({input});
                  }}
                />
              </Flex>
              {/* <TextField type="text" label="Phone Number"
                onChange={(ev, props) => {
                  this.state.input.phoneNumber = props ?? "";
                  this.setState({});
                }}
                value={this.state.input.phoneNumber} /> */}
              {/* <TextField type="text" label="Fax Number" placeholder="Fax number"
                onChange={(ev, props) => {
                  this.state.input.fax = props ?? "";
                  this.setState({});
                }}
              value={this.state.input.fax} /> */}
              <Flex column gap="gap.smaller" >
                <Text content={t("TimeZone")} weight="semibold" />
                <Dropdown
                  selectedKey={this.state.input.timeZone}
                  options={this.state.timeZones.map((x, index) => ({
                    key: x.id,
                    text: x.displayName,
                    data: x.id,
                  }))}
                  onChange={(ev, props) => {
                    var input = {...this.state.input}
                    input.timeZone = props?.data;
                    this.setState({input});
                  }} />
              </Flex>
              {/* <Flex column gap="gap.smaller" >
                    <Text content={t("Default start time")} weight="semibold" />
                    <TimePicker 
                      value={this.state.input.defaultStartTime ? this.state.input.defaultStartTime : "09:00"}
                      onChange={(e) => {
                        var input = { ...this.state.input }
                        input.defaultStartTime = e.toString()
                        this.setState({input})
                      }}
                    />
                  </Flex> */}
                  {/* <Flex column gap="gap.smaller" >
                    <Text content={t("Default end time")} weight="semibold" />
                    <TimePicker 
                      value={this.state.input.defaultEndTime ? this.state.input.defaultEndTime : "18:00"}
                      onChange={(e) => {
                        var input = { ...this.state.input }
                        input.defaultEndTime = e.toString()
                        this.setState({input})
                      }}
                    />
                  </Flex> */}
              { // Licesnses Input
                <Flex column gap="gap.small">
                  {/* <Label
                    styles={{
                        backgroundColor: 'transparent',
                        fontWeight: 600,
                        color : 'rgb(50, 49, 48)',
                        marginBottom : '7px'
                      }}
                  >{t('Licensed Users')}</Label> */}
                  <Text content={t("Licensed Users")} weight="semibold" />
                  <Grid
                    columns={2}
                    styles={{ border : '1px solid lightgrey', borderRadius: '3px', padding: '5px'}}
                    style={{direction : 'ltr'}}
                    content={
                        this.state.roles.map( role => {
                          return <Flex key={role} column>
                              <Label
                          styles={{
                              backgroundColor: 'transparent',
                              fontWeight: 600,
                              color : 'rgb(50, 49, 48)',
                              marginBottom : '10px'
                            }}
                        >{ `${role}`}</Label>
                            <Flex vAlign="end">
                            {this.id ?<Label
                          styles={{
                              backgroundColor: 'transparent',
                              fontWeight: 400,
                              color : 'rgb(50, 49, 48)',
                              marginBottom : '10px'
                            }}
                        >{ `${rolesUsersAvailable[role]}  /`}</Label> : <></>}
                        {this.props.user.role.toLowerCase() === 'admin' ? <TextField key={'RL-' + role} type="number" placeholder={t("Licensed Users")}
                          min="0"
                          styles={{ root : {margin : '5px'}}}
                          onChange={(ev, props) => {
                            var input = {...this.state.input}
                            var rolesMaxUsers = input.rolesMaxUsers
                            rolesMaxUsers[role] = parseInt(props?? '0')
                            input.rolesMaxUsers = rolesMaxUsers
                            this.setState({ input });
                          }}
                          value={rolesMaxUsers[role] as any} /> :
                          <Label
                          styles={{
                              backgroundColor: 'transparent',
                              fontWeight: 400,
                              color : 'rgb(50, 49, 48)',
                              marginBottom : '10px'
                            }}
                        >{`${rolesMaxUsers[role]}`}</Label>
                          }
                          </Flex>
                          </Flex>
                      })}
                      />
                </Flex>
              }
              { 
                this.props.user.role.toLowerCase() === 'admin' ? 
                <TextField 
                  required={true} 
                  label={t("Locales")}
                  onChange={(ev, props) => {
                    var input = {...this.state.input}
                    input.locales = props ?? "";
                    this.setState({input});
                  }}
                  value={this.state.input.locales} /> 
                : null
              }
              { 
                // <Dropdown label="Plan" placeholder="Plan"
                //   selectedKey={this.state.input.planId}
                //   options={this.state.plans.map((x) => ({
                //     key: x.id,
                //     text: x.name,
                //     data: x,
                //   }))}
                //   onChange={(ev, props) => {
                //     this.state.input.planId = props?.data.id;
                //     this.setState({});
                //   }} />
              }
                <Label
                  styles={{
                      backgroundColor: 'transparent',
                      fontWeight: 600,
                      color : 'rgb(50, 49, 48)'
                    }}
                >{t("Primary Color")}</Label>
                <ChromePicker
                color={ this.state.input.primaryColor }
                onChangeComplete={(color) => {
                  if (this.state.input.primaryColor !== color.hex && lightOrDark(color.hex) === 'light'){
                    this.setState({
                      alertVisible : true,
                      alertContent : t("Please choose a darker color")
                    })
                  } else {
                    this.setState({ alertVisible : false, input: { ...this.state.input, primaryColor: color.hex } });
                  }
                }}
                />
                {this.state.alertVisible ? <Alert 
                  visible={this.state.alertVisible}
                  content={this.state.alertContent}
                  danger
                /> : <></>}
              <Button primary fluid content={loading ? <Loader/> : t("Submit")} onClick={loading ? () => {} : this.addOrUpdate} />
            </Flex>
          </Flex>
      </FlexItem>
    );
  }
}

export default withRouter(withTranslation()(GeneralDetails as any) as any);
