import React from "react"
import { Flex, FlexItem, Menu, Divider, Button, Popup, Layout } from "@fluentui/react-northstar"
import { withRouter } from "react-router-dom"
import { UserStore } from "../../store/user"
import { inject, observer } from "mobx-react"
import { withTranslation } from "react-i18next"
import Schedule from "../../components/schedule";
import { threadId } from "worker_threads"
import { Color } from "react-color"
import { TextField } from "@fluentui/react"
import { DayHours, ProcessedEvent, ViewEvent } from "@aldabil/react-scheduler/types"
import { ifError } from "assert"
import "./index.scss"
import { ElementSize } from "@mui/x-data-grid"
import { eachDayOfIntervalWithOptions } from "date-fns/fp"
import { Popover, Typography } from "@mui/material"
import { minutesToSeconds } from "date-fns"
import { dayStatusColor } from "../../assests/styles/colors"
import Icons from "../../assests/images/SVGIcons/svgIcons";
import { UiStore } from "../../store/ui"
import * as Models from "../../models"
import { getUser, getUserRules } from "../../api/account"
import { getWeekSchedule as getUserSchedule } from "../../api/account"
import { getTimeSheetWeekSchedule } from "../../api/account"
import { getCompany, getWeekSchedule } from "../../api/Company"
import { getClockingsPerUserPerDay } from "../../api/reports"
import { getScheduledShift } from "../../api/shift"
import moment from "moment"
import { TimesheetTableView } from "../../models"
import { getTimesheetTableData } from "../../api/attendance"
import { getFormattedDuration } from "../../utils"



type Props = {
  t: any;
  ui: UiStore;
  user: UserStore;
  match: {
    params: {
      id?: string,
      isGCC?: number,
      timesheetId?: string,
      action?: string,
      shiftId?: number,
      date?: string,
      time?: string
    }
  }
};
interface Event {
  eventId: string,
  title: string,
  start: Date,
  end: Date,
  disabled?: boolean,
  editable?: boolean,
  deletable?: boolean,
  draggable?: boolean,
  color: string,
  userId?: string,
  eventType?: string,
}

type State = {
  timesheetTableData: TimesheetTableView;
  Title: string;
  items: Event[];
  clockings: Event[];
  startHour: DayHours;
  endHour: DayHours;
  isVisible: boolean;
  fromTo: {
    from: string;
    to: string;
  }
  userFullName: string;
  userId: string;

}



@inject("user", "ui")
@observer
class WeekSchedule extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      timesheetTableData: {} as TimesheetTableView,
      Title: "Week Schedule ",
      items: [],
      clockings: [],
      startHour: 0,
      endHour: 23,
      isVisible: false,
      fromTo: {
        from: "",
        to: "",
      },
      userId: "",
      userFullName: "",
    };
  }


  componentDidMount() {
    setTimeout(this.ChangeClassName, 1000);
  }

  componentDidUpdate() {
    setTimeout(this.ChangeClassName, 1000);
  }


  ChangeClassName() {
    var elements = document.getElementsByClassName("clocks")
    for (let i = 0; i < elements.length; i++) {
      const div = elements[i].parentElement ? elements[i].parentElement : null
      if (div != null) {

        const div2 = div.parentElement ? div.parentElement : null
        if (div2 != null) {
          div2.style.visibility = "visible"
          const div3 = div2.parentElement ? div2.parentElement : null
          if (div3 != null) {
            div3.style.overflow = "visible"
            div3.style.visibility = "hidden"
            const div4 = div3.parentElement ? div3.parentElement : null
            if (div4 != null) {
              div4.className = " rs__event__item elevate disabled"
              div4.ariaReadOnly = "true"
            }
          }
        }
      }
    }
    var elements = document.getElementsByClassName("shifts")
    for (let i = 0; i < elements.length; i++) {
      const div = elements[i].parentElement ? elements[i].parentElement : null
      if (div != null) {
        const div2 = div.parentElement ? div.parentElement : null
        if (div2 != null) {
          const div3 = div2.parentElement ? div2.parentElement : null
          if (div3 != null) {
            const div4 = div3.parentElement ? div3.parentElement : null
            if (div4 != null) {
              div4.className = " rs__event__item disabledshift"
            }
          }
        }
      }
    }
  }


  fetchRemote = async (query: ViewEvent): Promise<ProcessedEvent[]> => {
    var datefrom = new Date(query.start)
    var dateto = new Date(query.end)

    var fromYear = datefrom.getFullYear()
    var fromMonth = datefrom.getMonth() + 1
    var fromDate = datefrom.getDate()

    var toYear = dateto.getFullYear()
    var toMonth = dateto.getMonth() + 1
    var toDate = dateto.getDate()

    var from = fromYear + "-" + fromMonth + "-" + fromDate

    var to = toYear + "-" + toMonth + "-" + toDate
    var fromTo = { ...this.state.fromTo }
    fromTo.from = from
    fromTo.to = to

    this.fetch(from, to)
    return []

  };


  fetch = async (from: string, to: string) => {
    const id = this.props.match.params.id ?? this.props.user.userId
    const user = await getUser(id)
    this.setState({ userFullName: user.payload.fullName })
    const shiftType = user.payload.userRules.shiftScheduleType
    const companyShiftType = user.payload.company.shiftScheduleType
    const companyid = user.payload.company.id
    const { payload } = await getTimesheetTableData(id, from, to);
    console.log(payload);
    this.setState({ timesheetTableData: payload })
    //, () => console.log(getFormattedDuration(moment.duration(this.state.timesheetTableData.totalWorkTime)
    // .asSeconds())))
    var events: Event[] = []
    var clocks: Event[] = []
    const clockings = await getClockingsPerUserPerDay({
      userId: id,
      from: new Date(from),
      to: new Date(to),
      userIds: [id],
      searchMethod: "",
      departmentIds: [],
      branchIds: [],
      countryIds: []
    });

    for (let i = 0; i < clockings.payload.length; i++) {
      var clockingsperday = clockings.payload[i]
      for (let j = 0; j < clockingsperday.length; j++) {
        var newClocking: Event = {
          eventId: clockingsperday[j].type == "ClockIn" ? (clockingsperday[j].id + "-" + 0)
            : clockingsperday[j].type == "ClockOut" ? (clockingsperday[j].id + "-" + 1)
              : clockingsperday[j].type == "BreakIn" ? (clockingsperday[j].id + "-" + 2)
                : clockingsperday[j].type == "BreakOut" ? clockingsperday[j].id + "-" + 3 : "ma baarif shu aam b sir ",

          title: " ",
          start: new Date(clockingsperday[j].time),
          end: new Date(new Date(clockingsperday[j].time).setMinutes(new Date(clockingsperday[j].time).getMinutes())),
          disabled: false,
          editable: false,
          deletable: true,
          draggable: false,
          color: "transparent",
          eventType: clockingsperday[j].type
        }
        clocks.push(newClocking)

      }
    }

    payload.daysSummaryView.map((day, i) => {
      if (day.shiftScheduleType == Models.ShiftScheduleType.FixedWeekSchedule) {
        var shifts: any = day.dayStartEndHoursAndMinutes;
        var days = shifts ? shifts : undefined
        if (days != undefined) {
          //console.log(i)
          var starthour = days[0]
          var startminute = days[1]
          var endhour = days[2]
          var endminute = days[3]
          var j = i == 0 ? 7 : i
          //var startDate = moment(from).add(i, 'days').add(starthour, 'hours').add(startminute, 'minutes').toDate()
          //var endDate = moment(from).add(i, 'days').add(endhour, 'hours').add(endminute, 'minutes').toDate()

          var startDate = new Date(from)
          startDate.setTime(startDate.getTime() + i * 86400000)
          startDate.setHours(starthour)
          startDate.setMinutes(startminute)

          var endDate = new Date(from)
          endDate.setTime(endDate.getTime() + i * 86400000)
          endDate.setHours(endhour)
          endDate.setMinutes(endminute)

          var newShift: Event = {
            eventId: i.toString(),
            title: " ",
            start: startDate,
            end: endDate,
            disabled: false,
            editable: false,
            deletable: true,
            draggable: false,
            color: "#BED1DF",
            eventType: "shift"
          }
          events.push(newShift)
        }
        events = events.concat(clocks)
        this.setState({ items: events })
      }
      else {

        day.scheduledShifts.map(shift => {
          var newShift: Event = {
            eventId: shift.id.toString(),
            title: "",
            start: new Date(shift.startTime),
            end: new Date(shift.endTime),
            disabled: false,
            editable: false,
            deletable: true,
            draggable: false,
            color: "#BED1DF",
            userId: this.state.userId,
            eventType: "shift"
          }
          events.push(newShift)
        })
        events = events.concat(clocks)
        this.setState({ items: events })
      }

    })
  }

  render() {
    const { t, user } = this.props;


    return (

      <FlexItem grow>
        <Flex column gap="gap.large" padding="padding.medium" hAlign="center">
          <Flex column gap="gap.medium" styles={{ width: "1500px", maxWidth: "100%" }}>
            <Flex styles={{ direction: "row", justifyContent: "space-between" }}>
              <Flex gap="gap.medium" padding="padding.medium" vAlign="center">
                <div>{t("Total Hours Worked This Week-")}</div>
                <div>
                  {getFormattedDuration(moment.duration(this.state.timesheetTableData.totalWorkTime)
                    .asSeconds())}
                </div>
              </Flex>
              <Flex padding="padding.medium" gap="gap.large" hAlign="end">
                <Layout
                  gap="8px"
                  start={(
                    <div style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: dayStatusColor.clockIn,
                    }} />
                  )}
                  main={t("Clock In")} />
                <Layout
                  gap="8px"
                  start={(
                    <div style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: dayStatusColor.clockOut,
                    }} />
                  )}
                  main={t("Clock Out")} />
                <Layout
                  gap="8px"
                  start={(
                    <div style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: dayStatusColor.breakIn,
                    }} />
                  )}
                  main={t("Break In")} />
                <Layout
                  gap="8px"
                  start={(
                    <div style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: dayStatusColor.breakOut,
                    }} />
                  )}
                  main={t("Break Out")} />
                <Layout
                  gap="8px"
                  start={(
                    <div style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: dayStatusColor.expectedWorkingHours,
                    }} />
                  )}
                  main={t("Expected Working Hours")} />
              </Flex>
            </Flex>
            <Schedule
              userId=""
              getRemoteEvents={this.fetchRemote}
              timesheet={true}
              company={false}
              direction="ltr"
              allEmployees={false}
              startHour={this.state.startHour}
              endHour={this.state.endHour}
              events={this.state.items}
              resources={{}}
              eventRenderer={(event: Event) => {
                switch (event.eventType) {
                  case "shift":
                    {
                      var AMPMStart = event.start.toLocaleTimeString().split(" ")[1] == undefined ? event.start.toLocaleTimeString().split(" ")[1] : event.start.toLocaleTimeString().split(" ")[1]
                      var AMPMEnd = event.end.toLocaleTimeString().split(" ")[1] == undefined ? event.end.toLocaleTimeString().split(" ")[1] : event.end.toLocaleTimeString().split(" ")[1]
                      var startTime = event.start.toLocaleTimeString().split(":")[0] +
                        ":" + event.start.toLocaleTimeString().split(":")[1] + " "
                        + AMPMStart
                      var endTime = event.end.toLocaleTimeString().split(":")[0] + ":"
                        + event.end.toLocaleTimeString().split(":")[1] + " "
                        + AMPMEnd


                      return <div className="shifts">
                        <div style={{
                          backgroundColor: "rgba(211, 223, 232,0.6)", fontSize: "11px", fontWeight: "semibold", padding: "3px",
                          marginTop: "10px"
                        }}>
                          {startTime}-{endTime}
                        </div>
                      </div>;
                    }
                  case "BreakOut":
                    {
                      var AMPMStart = event.start.toLocaleTimeString().split(" ")[1] == undefined ? event.start.toLocaleTimeString().split(" ")[1] : event.start.toLocaleTimeString().split(" ")[1]
                      var hours = event.start.getHours()
                      var mins = event.start.getMinutes() == 0 ? "00" : event.start.getMinutes()
                      var time = event.start.toLocaleTimeString().split(":")[0] +
                        ":" + event.start.toLocaleTimeString().split(":")[1] + " "
                        + AMPMStart
                      return (
                        <Popup
                          trigger={
                            <div className="clocks" style={{ display: "flex", flexDirection: "row", overflow: "visible" }}>
                              <div style={{
                                backgroundColor: dayStatusColor.breakOut, height: "2px", width: "400px", marginTop: "-15px", opacity: "0.7"
                                , boxShadow: "0 1px 3px 0 rgba(0, 0, 0,0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.12)"
                              }}></div>
                              <Icons.BreakOut style={{ width: "140px", height: "15px", opacity: "0.7", marginTop: "-24px" }} fill={dayStatusColor.breakOut}></Icons.BreakOut>
                              <div style={{
                                backgroundColor: dayStatusColor.breakOut, height: "2px", width: "400px", marginTop: "-15px", opacity: "0.7"
                                , boxShadow: "0 1px 3px 0 rgba(0, 0, 0,0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.12)"
                              }}></div>
                            </div>
                          }
                          content={<div onClick={(e) => { e.preventDefault() }}>Break Out Time  {time}</div>}
                          //{"Break Out Time " + time}
                          on="hover"
                        />

                      );
                    }
                  case "ClockIn":
                    {
                      var AMPMStart = event.start.toLocaleTimeString().split(" ")[1] == undefined ? event.start.toLocaleTimeString().split(" ")[1] : event.start.toLocaleTimeString().split(" ")[1]
                      var hours = event.start.getHours()
                      var mins = event.start.getMinutes() == 0 ? "00" : event.start.getMinutes()
                      var time = event.start.toLocaleTimeString().split(":")[0] +
                        ":" + event.start.toLocaleTimeString().split(":")[1] + " "
                        + AMPMStart
                      return (
                        <Popup
                          trigger={
                            <div className="clocks" style={{ display: "flex", flexDirection: "row", overflow: "visible" }}>
                              <div style={{
                                backgroundColor: dayStatusColor.clockIn, height: "2px", width: "400px", marginTop: "-15px", opacity: "0.7"
                                , boxShadow: "0 1px 3px 0 rgba(0, 0, 0,0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.12)"
                              }}></div>
                              <Icons.ClockIn style={{ width: "140px", height: "20px", opacity: "0.7", marginTop: "-25px" }} fill={dayStatusColor.clockIn}></Icons.ClockIn>
                              <div style={{
                                backgroundColor: dayStatusColor.clockIn, height: "2px", width: "400px", marginTop: "-15px", opacity: "0.7"
                                , boxShadow: "0 1px 3px 0 rgba(0, 0, 0,0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.12)"
                              }}>
                              </div>
                            </div>
                          }
                          content={<div onClick={(e) => { e.preventDefault() }}>Clock In Time  {time}</div>}
                          //{"Clock In Time " + time}
                          on="hover"
                        />
                      );
                    }

                  case "ClockOut":
                    {
                      var AMPMStart = event.start.toLocaleTimeString().split(" ")[1] == undefined ? event.start.toLocaleTimeString().split(" ")[1] : event.start.toLocaleTimeString().split(" ")[1]
                      var hours = event.start.getHours()
                      var mins = event.start.getMinutes() == 0 ? "00" : event.start.getMinutes()
                      var time = event.start.toLocaleTimeString().split(":")[0] +
                        ":" + event.start.toLocaleTimeString().split(":")[1] + " "
                        + AMPMStart
                      return (
                        <Popup
                          trigger={
                            //<div className="clocks" style={{ backgroundColor: dayStatusColor.clockOut, height: "2px" }} ></div>
                            <div className="clocks" style={{ display: "flex", flexDirection: "row", overflow: "visible" }}>
                              <div style={{
                                backgroundColor: dayStatusColor.clockOut, height: "2px", width: "400px", marginTop: "-15px", opacity: "0.7",
                                boxShadow: "0 1px 3px 0 rgba(0, 0, 0,0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.12)"
                              }}></div>
                              <Icons.ClockOut style={{ width: "140px", height: "20px", opacity: "0.7", marginTop: "-25px" }} fill={dayStatusColor.clockOut}></Icons.ClockOut>
                              <div style={{
                                backgroundColor: dayStatusColor.clockOut, height: "2px", width: "400px", marginTop: "-15px", opacity: "0.7"
                                , boxShadow: "0 1px 3px 0 rgba(0, 0, 0,0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.12)"
                              }}></div>
                            </div>
                          }
                          content={<div onClick={(e) => { e.preventDefault() }}>Clock Out Time  {time}</div>}
                          //{"Clock Out Time " + time}
                          on="hover"
                        />
                      );
                    }
                  case "BreakIn":
                    {
                      var AMPMStart = event.start.toLocaleTimeString().split(" ")[1] == undefined ? event.start.toLocaleTimeString().split(" ")[1] : event.start.toLocaleTimeString().split(" ")[1]
                      var hours = event.start.getHours()
                      var mins = event.start.getMinutes() == 0 ? "00" : event.start.getMinutes()
                      var time = event.start.toLocaleTimeString().split(":")[0] +
                        ":" + event.start.toLocaleTimeString().split(":")[1] + " "
                        + AMPMStart
                      return (
                        <Popup
                          trigger={
                            // <div className="clocks" style={{ backgroundColor: dayStatusColor.breakIn, height: "2px" }} ></div>
                            <div className="clocks" style={{ display: "flex", flexDirection: "row", overflow: "visible" }}>
                              <div style={{
                                backgroundColor: dayStatusColor.breakIn, height: "2px", width: "400px", marginTop: "-15px", opacity: "0.7",
                                boxShadow: "0 1px 3px 0 rgba(0, 0, 0,0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.12)"
                              }}></div>
                              <Icons.BreakIn style={{ width: "140px", height: "20px", opacity: "0.7", marginTop: "-25px" }} fill={dayStatusColor.breakIn}></Icons.BreakIn>
                              <div style={{
                                backgroundColor: dayStatusColor.breakIn, height: "2px", width: "400px", marginTop: "-15px", opacity: "0.7"
                                , boxShadow: "0 1px 3px 0 rgba(0, 0, 0,0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.12)"
                              }}></div>
                            </div>
                          }
                          content={<div onClick={(e) => { e.preventDefault() }}>Break In Time  {time}</div>}
                          //{"Break In Time " + time}
                          on="hover"
                        />
                      );
                    }
                  default:
                    {
                      return <label>NULLL</label>;
                    }
                }
              }
              }
            ></Schedule>
          </Flex>
        </Flex>
      </FlexItem >

    );

  }
}

export default withRouter(withTranslation()(WeekSchedule as any) as any);



