import React, { createRef, Fragment, useEffect, useState } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { CalendarPickerProps, CalendarPickerView, DesktopTimePicker } from "@mui/x-date-pickers";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Button, Tabs, Typography } from "@mui/material";
import { UserStore } from "../../store/user";
import { CellRenderedProps, DayHours, DefaultRecourse, EventActions, ProcessedEvent, SchedulerHelpers, SchedulerProps, ViewEvent } from "@aldabil/react-scheduler/types";
import { CalendarPicker } from '@mui/x-date-pickers';
import { on, TimePicker } from "@fluentui/react";
import { TextField, DialogActions } from "@mui/material";
//import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';


declare const baseUrl: string;

export type CalendarProps = {
  events?: any;
  allEmployees: boolean;
  company: boolean;
  resources: any;
  startHour: DayHours;
  endHour: DayHours;
  direction?: "rtl" | "ltr" | undefined;
  eventRenderer?: (event: any) => JSX.Element;
  cellRenderer?(props: CellRenderedProps): JSX.Element;
  onConfirm?: (event: ProcessedEvent, action: EventActions) => Promise<ProcessedEvent>;
  handleConfirm?: (event: ProcessedEvent, action: EventActions) => Promise<ProcessedEvent>;
  recourseHeaderComponent?: (user: any) => JSX.Element;
  timesheet?: boolean | undefined;
  getRemoteEvents?(params: ViewEvent): Promise<ProcessedEvent[] | void>;
  onDelete?(deletedId: string | number): Promise<string | number | void>;
  userId: string;
};

interface CustomEditorProps {
  scheduler: SchedulerHelpers;
  handleConfirm: any;
  userId: string;
}

const EmployeesCustomEditor = ({ scheduler, handleConfirm, userId }: CustomEditorProps) => {

  const action = scheduler.edited;

  const [start, setStart] = useState(scheduler.state.start.value)
  const [end, setEnd] = useState(scheduler.state.end.value)
  const handleSubmit = async () => {

    try {
      scheduler.loading(true);

      const newEvent = {
        event_id: action?.event_id || " ",
        title: " ",
        start: start,
        end: end,
        userId: userId,
        color: "#BED1DF"
      };
      var result = await handleConfirm(newEvent, action ? "edit" : "create")
      scheduler.close();
    } 
    finally {
      scheduler.loading(false);
    }
  };
  return (
    <div>
      <div style={{ width: "400px", padding: "1rem" }}>
        <p style={{ fontSize: "16px" }}>Add Shift</p>
        <br></br>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Start Time"
              value={start}
              onChange={(newValue) => {
                setStart(new Date(newValue))
              }}
            />
            <DateTimePicker

              renderInput={(props) => <TextField {...props} />}
              label="End Time"
              value={end}
              onChange={(newValue) => {
                setEnd(new Date(newValue))
              }}
            />
          </Stack>
        </LocalizationProvider>
      </div>
      <DialogActions>
        <Button onClick={scheduler.close}>Cancel</Button>
        <Button onClick={handleSubmit}>Confirm</Button>
      </DialogActions>
    </div>
  );
};


const WeekScheduleCustomEditor = ({ scheduler, handleConfirm }: CustomEditorProps) => {

  const action = scheduler.edited;

  const [startTime, setStartTime] = useState(scheduler.state.start.value)
  const [endTime, setEndTime] = useState(scheduler.state.end.value)

  const handleSubmit = async () => {

    try {
      scheduler.loading(true);

      const newEvent = {
        event_id: action?.event_id || " ",
        title: " ",
        start: startTime,
        end: endTime,
        color: "#BED1DF"
      };
      var result = await handleConfirm(newEvent, action ? "edit" : "create")
      scheduler.close();
    } finally {
      scheduler.loading(false);
    }
  };
  return (
    <div>
      <div style={{ width: "400px", padding: "1rem" }}>
        <p style={{ fontSize: "16px" }}>Add Shift</p>
        <br></br>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
           <DesktopTimePicker
            label="Start Time"
            value={startTime}
            onChange={(newValue) => {
              setStartTime(new Date(newValue))
            }}
            renderInput={(params : any) => <TextField {...params} />}
          />
            <DesktopTimePicker
            label="End Time"
            value={endTime}
            onChange={(newValue) => {
              setEndTime(new Date(newValue))
            }}
            renderInput={(params : any) => <TextField {...params} />}
          />
          </Stack>
        </LocalizationProvider>
      </div>
      <DialogActions>
        <Button onClick={scheduler.close}>Cancel</Button>
        <Button onClick={handleSubmit}>Confirm</Button>
      </DialogActions>
    </div>
  );
};


function Schedule(this: any, { events, allEmployees, resources, startHour, endHour, eventRenderer,
  onDelete, direction, company, onConfirm, recourseHeaderComponent, timesheet, getRemoteEvents, handleConfirm, userId }: CalendarProps) {
  if (allEmployees) {
    return (
      <Fragment>
        <Scheduler
          customEditor={(scheduler) => <EmployeesCustomEditor scheduler={scheduler} handleConfirm={handleConfirm} userId={userId} />}
          recourseHeaderComponent={recourseHeaderComponent}
          //onConfirm={onConfirm}
          view="week"
          navigation={true}
          week={{
            weekDays: [0, 1, 2, 3, 4, 5, 6],
            weekStartOn: 1,
            startHour: startHour,
            endHour: endHour,
            step: 60,
            navigation: true,
          }}
          getRemoteEvents={getRemoteEvents}
          events={events}
          resources={resources}
          resourceFields={{
            idField: "userId",
            textField: "fullName",
            subTextField: "jobTitle",
            colorField: "color"
          }}
          resourceViewMode="tabs"
          fields={[
            {
              name: "userId",
              type: "select",
              //default: resources[0].userId.? ? resources[0].userId : null ,
              options: resources.map((res: any) => {
                return {
                  id: res.userId,
                  text: `${res.fullName}`,
                  value: res.userId
                };
              }),
              config: { label: "Assignee", required: true }
            }
          ]}
          onDelete={onDelete}
          eventRenderer = {eventRenderer}
        />
      </Fragment>
    )
  }

  else if (company) {
    return (

      <Scheduler
        customEditor={(scheduler) => <WeekScheduleCustomEditor scheduler={scheduler} handleConfirm={handleConfirm} userId={userId} />}
        events={events}
        height={300}
        view="week"
        day={null}
        month={null}
        navigation={false}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 1,
          startHour: startHour,
          endHour: endHour,
          step: 60,
          navigation: false
        }}
        eventRenderer={eventRenderer}
        direction={direction}
        translations={{
          navigation: {
            month: "Month",
            week: "Week",
            day: "Day",
            today: "Today"
          },
          form: {
            addTitle: "Add Week Schedule",
            editTitle: "Edit Shift",
            confirm: "Confirm",
            delete: "Delete",
            cancel: "Cancel"
          },
          event: {
            title: "Title",
            start: "Start",
            end: "End",
            allDay: "All Day"
          },
          moreEvents: "More..."
        }}
        onConfirm={onConfirm}
        onDelete={onDelete}
       


      />
    );
  }
  else {


    return (
      <Scheduler
        events={events}
        height={300}
        view="week"
        day={null}
        month={null}
        navigation={timesheet}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 1,
          startHour: startHour,
          endHour: endHour,
          step: 60,
          navigation: timesheet,
          cellRenderer: ({ height, start, onClick, ...props }) => {
            const hour: any = start.getHours();
            const disabled: any = hour;
            const restProps = disabled ? {} : props;
            return (
              <Button
                style={{
                  height: "100%",
                  background: disabled ? "#transparent" : "transparent",
                }}
                disabled={disabled}
                {...restProps}
              ></Button>
            );
          }
        }}
        eventRenderer={eventRenderer}
        direction={direction}
        getRemoteEvents={getRemoteEvents}
        onDelete={onDelete}
      />

    )
  }

};
export default Schedule
