import React from 'react';
import {
    Flex, Button, Divider, Dropdown,
    Text,
    ExcelColorIcon,
    CloseIcon,
    FilesPdfColoredIcon,
} from "@fluentui/react-northstar";
import { ShimmeredDetailsList, IColumn, IDetailsGroupRenderProps, GroupHeader, Icon, Spinner } from "@fluentui/react";
import { withRouter } from 'react-router-dom';
import Pagination from "react-paginating";
import { BreakConfig, ShiftReportView } from "../../../models";

import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import * as ReportsApi from "../../../api/reports";
import * as AccountApi from "../../../api/account";
import * as CompanyApi from "../../../api/Company";
import { RowInput } from 'jspdf-autotable'
// @ts-ignore
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle'
import './Shifts.scss'
import dateformat from 'dateformat';
import moment from 'moment';
import { gridSortModelSelector } from '@mui/x-data-grid';
import { getBranches, getDepartments } from '../../../api/Company';
import { getCountries, getUsersCountries } from '../../../api/Country';
import ReportsFilter from '../../../components/reportsFilter';

interface IProps {
    user: UserStore;
    match: { params: { id?: string } };
    history: any;
    t: any;
};

interface IState {
    loading: boolean;
    columnNamesWithBreak: any[];
    columnNamesWithOutBreak: any[];
    items: any[];
    columnsWithBreak: IColumn[];
    columnsWithOutBreak: IColumn[];
    count: number;
    offset: number;
    groups: any[];
    users: any[];
    shiftsPayload: ShiftReportView[];
    selectedUsers: any[];
    breakConfig: BreakConfig;
    options: any;
    users_: any[];
    dateFrom: Date;
    dateTo: Date;
    branches: any[];
    departments: any[];
    countries: any[];
    selectedDepartments: any[];
    selectedBranches: any[];
    selectedCountries: any[];
    showFilters: boolean;
    ANDOR: boolean;
    loggedInUser: any,
    selectedDepartmentNames: any[],
    selectedBrancheNames: any[],
    selectedCountryNames: any[],
    searchMethod: string;


}

@inject("user")
@observer
class Shifts extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        var { t } = props;
        this.state = {
            searchMethod: "AND",
            loggedInUser: "",
            ANDOR: true,
            showFilters: false,
            loading: false,
            users: [],
            items: [],
            users_: [],
            selectedUsers: [],
            selectedDepartments: [],
            count: 5,
            offset: 0,
            branches: [],
            departments: [],
            countries: [],
            selectedCountries: [],
            selectedBranches: [],
            selectedDepartmentNames: [],
            selectedBrancheNames: [],
            selectedCountryNames: [],
            shiftsPayload: [],
            columnNamesWithBreak: [t('Date'), t('Clock In'), t('Clock Out'), t('Work Duration'), t('Breaks Duration'), t("Overtime")],
            columnNamesWithOutBreak: [t('Date'), t('Clock In'), t('Clock Out'), t('Breaks Duration'), t("Overtime")],
            options: {
                filter: true,
                filterType: "dropdown",
                responsive: true,
            },

            breakConfig: BreakConfig.NotRequired,
            columnsWithBreak: [
                {
                    key: "date",
                    name: t("Date"),
                    onRender: (item: any) => item.totalDurations ?
                        <Text styles={{ fontWeight: "bold" }} > {t("Total hours")} </Text>
                        :
                        dateformat(new Date(item.clockInTime), 'ddd, mmm dS, yyyy'), //new Date(item.clockInTimeUtc).toISOString().split('T')[0],
                    minWidth: 100,
                    maxWidth: 140,
                },
                {
                    key: "clockIn",
                    name: t("Clock In"),
                    onRender: (item: any) => item.totalDurations ? "" : dateformat(new Date(item.clockInTime), 'h:MM TT'), //new Date(item.clockInTimeUtc).toISOString().split('T')[1].split('.')[0],
                    minWidth: 60,
                    maxWidth: 100,
                },
                {
                    key: "clockOut",
                    name: t("Clock Out"),
                    onRender: (item: any) => {
                        if (item.totalDurations) {
                            return ""
                        } else {
                            if (new Date(item.clockOutTime ?? new Date()).getDate() === new Date(item.clockInTime).getDate())
                                return dateformat(new Date(item.clockOutTime ?? new Date()), 'h:MM TT') //.toISOString().split('T')[1].split('.')[0],
                            else
                                return dateformat(new Date(item.clockOutTime ?? new Date()), 'h:MM TT ddd, mmm dS, yyyy')
                        }
                    },
                    minWidth: 150,
                    maxWidth: 200,
                },
                {
                    key: "workDuration",
                    name: t("Work Duration"),
                    onRender: (item: any) => item.totalDurations ?
                        (
                            <Text styles={{ fontWeight: "bold" }} >{(Math.round(moment.duration(item.totalWorkingDuration).asHours() * 10) / 10)}</Text>
                            //(item.totalWorkingDuration.split('.').length) <= 2 ? item.totalWorkingDuration.split('.')[0] : item.totalWorkingDuration.split('.')[0] + ' days, ' + item.totalWorkingDuration.split('.')[1]
                        )
                        :
                        (
                            (item.workingDuration.split('.').length) <= 2 ? item.workingDuration.split('.')[0] : item.workingDuration.split('.')[0] + ' days, ' + item.workingDuration.split('.')[1]
                        ),
                    minWidth: 100,
                    maxWidth: 150,
                },
                {
                    key: "breakDuration",
                    name: t("Breaks Duration"),
                    onRender: (item: any) => {
                        return item.totalDurations ?
                            (
                                <Text styles={{ fontWeight: "bold" }} >{(Math.round(moment.duration(item.totalBreaksDuration).asHours() * 10) / 10)}</Text>
                                //(item.totalBreaksDuration.split('.').length) <= 2 ? item.totalBreaksDuration.split('.')[0] : item.totalBreaksDuration.split('.')[0] + ' days, ' + item.totalBreaksDuration.split('.')[1]
                            )
                            :
                            (
                                (item.breaksDuration.split('.').length) <= 2 ? item.breaksDuration.split('.')[0] : item.breaksDuration.split('.')[0] + ' days, ' + item.breaksDuration.split('.')[1]
                            )
                    },
                    minWidth: 60,
                    maxWidth: 100,
                },
                {
                    key: "overtime",
                    name: t("Overtime"),
                    onRender: (item: any) => item.totalDurations ?
                        (
                            <Text styles={{ fontWeight: "bold" }} >{(Math.round(moment.duration(item.totalOvertimeDuration).asHours() * 10) / 10)}</Text>
                            //(item.totalOvertimeDuration.split('.').length) <= 2 ? item.totalOvertimeDuration.split('.')[0] : item.totalOvertimeDuration.split('.')[0] + ' days, ' + item.totalOvertimeDuration.split('.')[1]
                        )
                        :
                        (
                            (item.overtimeDuration.split('.').length) <= 2 ? item.overtimeDuration.split('.')[0] : item.overtimeDuration.split('.')[0] + ' days, ' + item.overtimeDuration.split('.')[1]
                        ),
                    minWidth: 60,
                    maxWidth: 100,
                },
            ],
            columnsWithOutBreak: [
                {
                    key: "date",
                    name: t("Date"),
                    onRender: (item: any) => item.totalDurations ?
                        <Text styles={{ fontWeight: "bold" }} > {t("Total hours")} </Text>
                        :
                        dateformat(new Date(item.clockInTime), 'ddd, mmm dS, yyyy'), //new Date(item.clockInTimeUtc).toISOString().split('T')[0],
                    minWidth: 100,
                    maxWidth: 140,
                },
                {
                    key: "clockIn",
                    name: t("Clock In"),
                    onRender: (item: any) => item.totalDurations ? "" : dateformat(new Date(item.clockInTime), 'h:MM TT'), //new Date(item.clockInTimeUtc).toISOString().split('T')[1].split('.')[0],
                    minWidth: 60,
                    maxWidth: 100,
                },
                {
                    key: "clockOut",
                    name: t("Clock Out"),
                    onRender: (item: any) => {
                        if (item.totalDurations) {
                            return ""
                        } else {
                            if (new Date(item.clockOutTime ?? new Date()).getDate() === new Date(item.clockInTime).getDate())
                                return dateformat(new Date(item.clockOutTime ?? new Date()), 'h:MM TT') //.toISOString().split('T')[1].split('.')[0],
                            else
                                return dateformat(new Date(item.clockOutTime ?? new Date()), 'h:MM TT ddd, mmm dS, yyyy')
                        }
                    },
                    minWidth: 150,
                    maxWidth: 200,
                },
                {
                    key: "workDuration",
                    name: t("Work Duration"),
                    onRender: (item: any) => item.totalDurations ?
                        (
                            <Text styles={{ fontWeight: "bold" }} >{(Math.round(moment.duration(item.totalWorkingDuration).asHours() * 10) / 10)}</Text>
                            //(item.totalWorkingDuration.split('.').length) <= 2 ? item.totalWorkingDuration.split('.')[0] : item.totalWorkingDuration.split('.')[0] + ' days, ' + item.totalWorkingDuration.split('.')[1]
                        )
                        :
                        (
                            (item.workingDuration.split('.').length) <= 2 ? item.workingDuration.split('.')[0] : item.workingDuration.split('.')[0] + ' days, ' + item.workingDuration.split('.')[1]
                        ),
                    minWidth: 100,
                    maxWidth: 150,
                },
                {
                    key: "overtime",
                    name: t("Overtime"),
                    onRender: (item: any) => item.totalDurations ?
                        (
                            <Text styles={{ fontWeight: "bold" }} >{(Math.round(moment.duration(item.totalOvertimeDuration).asHours() * 10) / 10)}</Text>
                            //(item.totalOvertimeDuration.split('.').length) <= 2 ? item.totalOvertimeDuration.split('.')[0] : item.totalOvertimeDuration.split('.')[0] + ' days, ' + item.totalOvertimeDuration.split('.')[1]
                        )
                        :
                        (
                            (item.overtimeDuration.split('.').length) <= 2 ? item.overtimeDuration.split('.')[0] : item.overtimeDuration.split('.')[0] + ' days, ' + item.overtimeDuration.split('.')[1]
                        ),
                    minWidth: 60,
                    maxWidth: 100,
                },
            ],
            groups: [],
            dateFrom: new Date(new Date().getFullYear(), 0, 1),
            dateTo: new Date()
        }
    }

    componentDidMount() {
        this.getCompanyBreakConfig();
        this.fetchData();
    }

    getCompanyBreakConfig = async () => {
        var breakConfig = (await CompanyApi.getCompanyRules(this.props.user.company?.id as number)).payload.breakConfig;
        this.setState({ breakConfig: breakConfig }, () => { console.log(this.state.breakConfig) });
    }

    get userId() {
        return this.props.match.params.id ?? this.props.user.userId;
    }

    onChangeUser(ev: any, props: any) {
        var filteredList = props.value.find((i: any) => i.key === 0);
        var allUsersSelected = filteredList ? filteredList.key === 0 : false;

        if (allUsersSelected) {
            var allUsersList = this.state.users.filter((u: any) => u.key !== 0);
            this.setState({
                selectedUsers: allUsersList
            });
        } else {
            this.setState({
                selectedUsers: [...props.value]
            });
        }
    }
    clearAllUsers() {
        this.setState({
            selectedUsers: []
        }, () => {
            this.getBranches()
            this.getDepartments()
        })
    }

    onChangeCountry = async (ev: any, props: any) => {
        var filteredList = props.value.find((i: any) => i.key === 0);
        var allCountriesSelected = filteredList ? filteredList.key === 0 : false;

        if (allCountriesSelected) {
            var allCountriesList = this.state.countries.filter((u: any) => u.key !== 0);
            this.setState({
                selectedCountries: allCountriesList,
                selectedCountryNames: allCountriesList
            }, () => {
                this.filterData()
            });
        } else {
            this.setState({
                selectedCountries: [...props.value]
            }, () => {

                var names: string[] = []
                this.state.selectedCountries.map(((selectedCountry) => {
                    names.push(selectedCountry.name)
                }))
                this.setState({ selectedCountryNames: names }, () => {
                    this.filterData()
                })
                var countryIds: any[] = []
                const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
                if (this.state.searchMethod == "AND") {
                    this.state.selectedCountries.map((country) => { countryIds.push(country.id) })
                    this.getBranchesDepartment(countryIds)
                }
            });
        }
    }
    clearAllCountries() {
        this.setState({
            selectedCountries: [],
            selectedCountryNames: []
        }, () => {
            this.filterData()
            this.getBranches()
            this.getDepartments()
        });
    }
    getBranches = async () => {
        const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
        let branchesRequest: any = await getBranches(id as number);
        if (branchesRequest && branchesRequest.payload) {
            var i;
            for (i = 0; i < branchesRequest.payload.length; i++) {
                branchesRequest.payload[i].header = branchesRequest.payload[i]['name'];
                branchesRequest.payload[i].key = branchesRequest.payload[i]['id'];
            }
            branchesRequest.payload.unshift({
                header: 'All Branches',
                key: 0
            });
            this.setState({ branches: branchesRequest.payload })
        }
    }

    getDepartments = async () => {
        const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
        let departmentsRequest: any = await getDepartments(id as number)
        if (departmentsRequest && departmentsRequest.payload) {
            var i;
            for (i = 0; i < departmentsRequest.payload.length; i++) {
                departmentsRequest.payload[i].header = departmentsRequest.payload[i]['name'];
                departmentsRequest.payload[i].key = departmentsRequest.payload[i]['id'];
            }
            departmentsRequest.payload.unshift({
                header: 'All Departments',
                key: 0
            });
            this.setState({ departments: departmentsRequest.payload })
        }
    }
    onChangeBranch(ev: any, props: any) {

        var filteredList = props.value.find((i: any) => i.key === 0);
        var allBranchesSelected = filteredList ? filteredList.key === 0 : false;

        if (allBranchesSelected) {
            var allBranchesList = this.state.branches.filter((u: any) => u.key !== 0);
            this.setState({
                selectedBranches: allBranchesList,
                selectedBrancheNames: [],
            }, () => { this.filterData() });
        } else {
            this.setState({
                selectedBranches: [...props.value]
            }, () => {

                var names: string[] = []
                this.state.selectedBranches.map(((selectedBranch) => {
                    names.push(selectedBranch.name)
                }))
                this.setState({ selectedBrancheNames: names }, () => { this.filterData() })
                var branchIds: any[] = []
                if (this.state.searchMethod == " AND") {
                    this.state.selectedBranches.map((branch) => { branchIds.push(branch.id) })
                    this.getBranchesByDepartment(branchIds)
                }
            });
        }

    }
    clearAllBranches() {
        this.setState({
            selectedBranches: [],
            selectedBrancheNames: [],
        }, () => {
            this.filterData()
            if (this.state.selectedCountries != null && this.state.searchMethod == "AND") {
                var countryIds: any[] = []
                this.state.selectedCountries.map((country) => { countryIds.push(country.id) })
                this.getBranchesDepartment(countryIds)
            }
            else {
                this.getBranches();
                this.getDepartments();
            }
        });
    }
    onChangeDepartments(ev: any, props: any) {

        var filteredList = props.value.find((i: any) => i.key === 0);
        var allDepartmentsSelected = filteredList ? filteredList.key === 0 : false;

        if (allDepartmentsSelected) {
            var allDepartmentsList = this.state.departments.filter((u: any) => u.key !== 0);
            this.setState({
                selectedDepartments: allDepartmentsList,
                selectedDepartmentNames: allDepartmentsList,
            }, () => { this.filterData() });
        } else {
            this.setState({
                selectedDepartments: [...props.value],

            }, () => {

                var names: string[] = []
                this.state.selectedDepartments.map(((selectedDep) => {
                    names.push(selectedDep.name)
                }))
                this.setState({ selectedDepartmentNames: names }, () => { this.filterData() })
            });
        }
    }

    clearAllDepartment() {
        this.setState({
            selectedDepartments: [],
            selectedDepartmentNames: [],
        }, () => { this.filterData() });
    }
    getBranchesDepartment = (countryIds: any[]) => {
        var users: any[] = []
        var branchNames: any[] = []
        var branches: any[] = []
        var departmentNames: any[] = []
        var departments: any[] = []
        countryIds.map((id) => {
            this.state.users.map((user) => {
                if (user.country != undefined) {
                    if (user.country.id == id) {
                        users.push(user);
                    }
                }
            })
        })
        users.forEach(user => {
            var branch = user.branch
            var department = user.department
            if (branch != null) {
                if (branchNames.length > 0) {
                    if (!branchNames.includes(branch.name)) {
                        branchNames.push(branch.name)
                        branches.push(branch)
                    }
                }
                else {
                    branchNames.push(branch.name)
                    branches.push(branch)
                }
            }
            if (department != null) {
                if (departmentNames.length > 0) {
                    if (!departmentNames.includes(department.name)) {
                        departmentNames.push(department.name)
                        departments.push(department)
                    }
                }
                else {
                    departmentNames.push(department.name)
                    departments.push(department)
                }
            }
        });

        for (var i = 0; i < branches.length; i++) {
            branches[i].header = branches[i]['name'];
            branches[i].key = branches[i]['id'];
        }
        branches.unshift({
            header: 'All Branches',
            key: 0
        });
        this.setState({ branches: branches })

        for (var i = 0; i < departments.length; i++) {
            departments[i].header = departments[i]['name'];
            departments[i].key = departments[i]['id'];
        }
        departments.unshift({
            header: 'All Departments',
            key: 0
        });
        this.setState({ departments: departments })
    }
    getBranchesByDepartment = (branchIds: any[]) => {
        var users: any[] = []
        var departmentNames: any[] = []
        var departments: any[] = []
        branchIds.map((id) => {
            this.state.users_.map((user) => {
                if (user.branch != undefined) {
                    if (user.branch.id == id) {
                        users.push(user);
                    }
                }
            })
        })
        users.forEach(user => {
            var department = user.department
            if (department != null) {
                if (departmentNames.length > 0) {
                    if (!departmentNames.includes(department.name)) {
                        departmentNames.push(department.name)
                        departments.push(department)
                    }
                }
                else {
                    departmentNames.push(department.name)
                    departments.push(department)
                }
            }
        });
        for (var i = 0; i < departments.length; i++) {
            departments[i].header = departments[i]['name'];
            departments[i].key = departments[i]['id'];
        }
        departments.unshift({
            header: 'All Departments',
            key: 0
        });
        this.setState({ departments: departments })
    }
    filterData() {
        this.state.searchMethod == "AND" ?
            this.setState({
                users_:
                    this.state.users.filter(u =>
                        ((this.state.selectedCountryNames.length == 0)
                            || (u.country != undefined && this.state.selectedCountryNames.includes(u.country.name)))
                        && ((this.state.selectedBrancheNames.length == 0)
                            || (u.branch != undefined && this.state.selectedBrancheNames.includes(u.branch.name)))
                        && ((this.state.selectedDepartmentNames.length == 0)
                            || (u.department != undefined && this.state.selectedDepartmentNames.includes(u.department.name)))

                    )
            }, () => { this.setState({ selectedUsers: this.state.users_ }, () => { console.log("AND") }) }) :
           this.setState({
                users_: this.state.users
            }, () => { this.setState({ selectedUsers: [] }) })
    }

    fetchData = async () => {
        const { t } = this.props
        this.setState({ loading: true, items: [], groups: [], offset: 0 });

        // users dropdown
        if (this.state.users.length === 0) {
            let usersRequest: any = await AccountApi.GetUsers(this.props.user.company?.id ?? 0)

            var i;
            for (i = 0; i < usersRequest.payload.length; i++) {
                // usersRequest.payload[i].content = usersRequest.payload[i]['fullName'];
                usersRequest.payload[i].header = usersRequest.payload[i]['fullName'];
                usersRequest.payload[i].key = usersRequest.payload[i]['id'];
                // delete usersRequest.payload[i].key1;
            }
            usersRequest.payload.unshift({
                header: t("All Users"),
                key: 0
            });
            this.setState({ users: usersRequest.payload, users_: usersRequest.payload })
        }

        const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;

        if (this.state.departments.length == 0) {
            this.getDepartments()
        }

        if (this.state.branches.length == 0) {
            this.getBranches()
        }
        if (this.state.countries.length == 0) {
            let countriesRequest: any = await getUsersCountries(this.props.user.userId)
            if (countriesRequest && countriesRequest.payload) {
                var i;
                for (i = 0; i < countriesRequest.payload.length; i++) {
                    countriesRequest.payload[i].header = countriesRequest.payload[i]['name'];
                    countriesRequest.payload[i].key = countriesRequest.payload[i]['id'];
                }
                countriesRequest.payload.unshift({
                    header: 'All Countries',
                    key: 0
                });
                this.setState({ countries: countriesRequest.payload })
            }
        }

        var selectedUsers = this.state.selectedUsers.map(selectedUser => (selectedUser.id))
        var selectedCountries = this.state.selectedCountries.map(selectedCountry => (selectedCountry.id))
        var selectedDepartments = this.state.selectedDepartments.map(selectedDepartment => (selectedDepartment.id))
        var selectedBranches = this.state.selectedBranches.map(selectedBranch => (selectedBranch.id))

        // report data
        const { payload } = await ReportsApi.getShiftsPerUser({
            userId: this.props.user.userId,
            from: this.state.dateFrom,
            to: this.state.dateTo,
            userIds: selectedUsers,
            departmentIds:selectedDepartments,
            countryIds:selectedCountries,
            branchIds:selectedBranches,
            searchMethod:this.state.searchMethod,
        });

        await this.tableDataGenerator(payload)

        this.setState({
            loading: false,
            shiftsPayload: payload
        });
    };

    tableDataGenerator = async (payload: any[]) => {
        // var {t} = this.props

        let body: any[] = [];
        let groups: any[] = [];

        let currentUser = "";
        let shiftsCountPerUser = "";
        let firstUser = true;
        payload.map((shift, i) => {
            if (currentUser !== shift.user.id) {
                if (!firstUser) {
                    if (this.state.breakConfig == BreakConfig.Required) {
                        let row: RowInput = {
                            totalDurations: true,
                            totalWorkingDuration: payload[i - 1].totalWorkingDuration,
                            totalBreaksDuration: payload[i - 1].totalBreaksDuration,
                            totalOvertimeDuration: payload[i - 1].totalOvertimeDuration
                        };
                        body.push(row)
                    }
                    else {
                        let row: RowInput = {
                            totalDurations: true,
                            totalWorkingDuration: payload[i - 1].totalWorkingDuration,
                            totalOvertimeDuration: payload[i - 1].totalOvertimeDuration
                        };
                        body.push(row)
                    }
                }
                firstUser = false
                currentUser = shift.user.id
                shiftsCountPerUser = payload.reduce(function (total, x) { return x.user.id === currentUser ? total + 1 : total }, 0);

                // var currentlyUnavailable = payload.some(shift => {
                //     return shift.user.id && shift.isApproved && new Date(shift.startDate) <= new Date() && new Date(shift.endDate) >= new Date()
                // });

                groups.push({
                    key: shift.user.id,
                    name: shift.user.fullName,
                    startIndex: body.length,
                    count: shiftsCountPerUser + 1,
                    level: 0
                })
            }
            if (shift) {
                let row: RowInput = shift;
                body.push(row)
            }
            return shift
        })

        // last user total durations
        if (payload.length > 0) {
            if (this.state.breakConfig == BreakConfig.Required) {
                var shift = payload[payload.length - 1]
                let row: RowInput = {
                    totalDurations: true,
                    totalWorkingDuration: shift.totalWorkingDuration,
                    totalBreaksDuration: shift.totalBreaksDuration,
                    totalOvertimeDuration: shift.totalOvertimeDuration
                };
                body.push(row)
            }
            else {
                var shift = payload[payload.length - 1]
                let row: RowInput = {
                    totalDurations: true,
                    totalWorkingDuration: shift.totalWorkingDuration,
                    totalOvertimeDuration: shift.totalOvertimeDuration
                };
                body.push(row)
            }
        }
        this.setState({ items: body, groups })
    }

    _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
        // if (props?.group?.level == 0) {
        //   return (
        //       <Flex styles={{
        //           backgroundColor: user.companyPrimaryColor,
        //           color: 'white',
        //           fontSize: '16px',
        //           fontWeight: 'bold',
        //           borderRadius: '3px',
        //           padding: '10px 50px'
        //       }} padding='padding.medium' hAlign='start'>{`${props.group!.name}`}</Flex>
        //   );
        // }

        return <GroupHeader {...props} />;
    };

    exportReport = async (format: string) => {
        var selectedUsers = this.state.selectedUsers.map(selectedUser => (selectedUser.id))
        var selectedCountries = this.state.selectedCountries.map(selectedCountry => (selectedCountry.id))
        var selectedDepartments = this.state.selectedDepartments.map(selectedDepartment => (selectedDepartment.id))
        var selectedBranches = this.state.selectedBranches.map(selectedBranch => (selectedBranch.id))

        await ReportsApi.exportShiftsPerUser({
            userId: this.props.user.userId,
            from: this.state.dateFrom,
            to: this.state.dateTo,
            userIds: selectedUsers.length > 0 ? selectedUsers : [this.props.user.userId],
            searchMethod: this.state.searchMethod,
            departmentIds: selectedDepartments,
            branchIds: selectedBranches,
            countryIds: selectedCountries
        }, format)

        // const type = response.headers['content-type']
        // const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        // const link = document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.download = 'file.xlsx'
        // link.click()
    }

    onDateChange = (time: any, type: string) => {
        const timeSelected = new Date(time)
        console.log(timeSelected)
        if (type === 'from')
            this.setState({ dateFrom: timeSelected })
        else
            this.setState({ dateTo: timeSelected })
    }
    setShowFilters() {
        this.setState({
            showFilters: !this.state.showFilters
        })
    }
    setSearchMethod = (event: any) => {
        var searchMethod = event.target.value == undefined ? "AND" : event.target.value
        this.setState({ searchMethod: searchMethod }, () => { this.filterData() })
    }
    render() {
        const {
            loading,
            columnsWithBreak,
            columnsWithOutBreak,
            items,
            count,
            offset,
            groups,
        } = this.state;

        const { t, user } = this.props

        return (
            <Flex column gap="gap.medium" padding="padding.medium" fill>
                {/* <Flex gap="gap.medium" padding="padding.medium">
                    <Flex column gap="gap.small">
                        <Text content={t("Users")} weight="bold" />
                        <Flex>
                            <Dropdown
                                multiple
                                search
                                items={this.state.users}
                                value={this.state.selectedUsers}
                                // defaultSelectedKey={0}
                                // options={this.state.users}
                                onChange={(ev, props: any) => {

                                    var filteredList = props.value.find((i: any) => i.key === 0);
                                    var allUsersSelected = filteredList ? filteredList.key === 0 : false;

                                    if (allUsersSelected) {
                                        var allUsersList = this.state.users.filter((u: any) => u.key !== 0);
                                        this.setState({
                                            selectedUsers: allUsersList
                                        });
                                    } else {
                                        this.setState({
                                            selectedUsers: [...props.value]
                                        });
                                    }
                                }}
                            />
                            {this.state.selectedUsers.length > 0 ? <Button text iconOnly icon={<CloseIcon title={t('clear all')} />} onClick={() => {
                                this.setState({
                                    selectedUsers: []
                                });
                            }}></Button> : <></>}
                        </Flex>
                    </Flex>
                    <Flex column gap="gap.small">
                        <Text content={t("From")} weight="bold" />
                        <DateTimePicker
                            key='fromDate'
                            onChange={(e: any) => this.onDateChange(e, 'from')}
                            value={this.state.dateFrom}
                            format="y-MM-dd"
                            calendarIcon={null}
                            clearIcon={null}
                            disableClock
                            className='dateTimePickerCustomStyles'
                        />
                    </Flex>
                    <Flex column gap="gap.small">
                        <Text content={t("To")} weight="bold" />
                        <DateTimePicker
                            key='toDate'
                            onChange={(e: any) => this.onDateChange(e, 'to')}
                            value={this.state.dateTo}
                            format="y-MM-dd"
                            calendarIcon={null}
                            clearIcon={null}
                            disableClock
                            className='dateTimePickerCustomStyles'
                        />
                    </Flex>
                    <Flex vAlign="end" gap="gap.medium">
                        <Button onClick={() => !this.state.loading ? this.fetchData() : {}}>{!this.state.loading ? t('Search') : <Spinner />}</Button>
                        <Button iconOnly
                            icon={!this.state.loading ? <ExcelColorIcon title={t('Export Excel')} /> : <Spinner />}
                            onClick={() => !this.state.loading ? this.exportReport('xlsx') : {}}></Button>
                        <Button iconOnly
                            icon={!this.state.loading ? <FilesPdfColoredIcon title={t('Export Excel')} /> : <Spinner />}
                            // icon={<Icon iconName='PDF' title={t('Export Pdf')} ariaLabel={t('Export Pdf')} />} 
                            onClick={() => !this.state.loading ? this.exportReport('pdf') : {}}></Button>
                    </Flex> */}
                {/* </Flex> */}
                <ReportsFilter
                    users={this.state.users}
                    users_={this.state.users_}
                    selectedUsers={this.state.selectedUsers}
                    onChangeUser={this.onChangeUser.bind(this)}
                    clearAllUsers={this.clearAllUsers.bind(this)}
                    dateFrom={this.state.dateFrom}
                    dateTo={this.state.dateTo}
                    onDateChange={this.onDateChange.bind(this)}
                    loading={this.state.loading}
                    exportReport={this.exportReport.bind(this)}
                    fetchData={this.fetchData.bind(this)}
                    setShowFilters={this.setShowFilters.bind(this)}
                    showFilters={this.state.showFilters}
                    countries={this.state.countries}
                    selectedCountries={this.state.selectedCountries}
                    selectedCountryNames={this.state.selectedCountryNames}
                    onChangeCountry={this.onChangeCountry.bind(this)}
                    clearAllCountries={this.clearAllCountries.bind(this)}
                    branches={this.state.branches}
                    selectedBranches={this.state.selectedBranches}
                    selectedBranchNames={this.state.selectedBrancheNames}
                    onChangeBranches={this.onChangeBranch.bind(this)}
                    clearAllBranches={this.clearAllBranches.bind(this)}
                    departments={this.state.departments}
                    selectedDepartments={this.state.selectedDepartments}
                    selectedDepartmentNames={this.state.selectedDepartmentNames}
                    onChangeDepartments={this.onChangeDepartments.bind(this)}
                    clearAllDepartments={this.clearAllDepartment.bind(this)}
                    searchMethod={this.state.searchMethod}
                    setSearchMethod={this.setSearchMethod.bind(this)}
                >
                </ReportsFilter>
                <Divider />


                <ShimmeredDetailsList
                    selectionMode={0}
                    detailsListStyles={{ root: { width: "100%", overflowY: "auto" } }}
                    enableShimmer={loading}
                    items={items}
                    columns={this.state.breakConfig == BreakConfig.Required ? columnsWithBreak : columnsWithOutBreak}
                    groups={groups.slice(offset, Math.min(offset + count, groups.length))}
                    groupProps={{
                        showEmptyGroups: true,
                        onRenderHeader: this._onRenderGroupHeader,
                    }}


                />
                <div style={{ padding: "1em" }}>

                    <Pagination
                        currentPage={offset / count + 1}
                        total={groups.length}
                        limit={count}
                        onPageChange={(page) => this.setState({ offset: ((page ?? 1) - 1) * count })}>
                        {({ pages, currentPage, hasNextPage, hasPreviousPage, nextPage, previousPage, totalPages, getPageItemProps }) => (
                            <div>

                                {(currentPage !== 1) && (
                                    <Button iconOnly
                                        {...getPageItemProps({
                                            pageValue: 1,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: 0 })
                                        }) as any}
                                    >
                                        {user.rtl ? ">>" : "<<"}
                                    </Button>
                                )}

                                {hasPreviousPage && (
                                    <Button iconOnly
                                        {...getPageItemProps({
                                            pageValue: previousPage,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((previousPage ?? 1) - 1) * count })
                                        }) as any}
                                    >
                                        {user.rtl ? ">" : "<"}
                                    </Button>
                                )}

                                {pages.map((page) => (
                                    <Button key={"ListPageButton-" + page} iconOnly
                                        primary={currentPage === page}
                                        {...getPageItemProps({
                                            pageValue: page,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((page ?? 1) - 1) * count }),
                                        }) as any}>
                                        {page}
                                    </Button>
                                ))}

                                {hasNextPage && (
                                    <Button iconOnly
                                        {...getPageItemProps({
                                            pageValue: nextPage,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((nextPage ?? 1) - 1) * count })
                                        }) as any}
                                    >
                                        {user.rtl ? "<" : ">"}
                                    </Button>
                                )}

                                {(currentPage !== totalPages) && (
                                    <Button iconOnly
                                        {...getPageItemProps({
                                            pageValue: totalPages,
                                            total: totalPages,
                                            onClick: () => this.setState({ offset: ((totalPages ?? 1) - 1) * count })
                                        }) as any}
                                    >
                                        {user.rtl ? "<<" : ">>"}
                                    </Button>
                                )}

                            </div>
                        )}
                    </Pagination>
                </div>
            </Flex>
        );
    }
}

export default withRouter(withTranslation()(Shifts as any) as any);