import React, { useState, FormEvent } from "react";
import { Flex, Button, Segment, Dialog, Box, EyeSlashIcon, EyeIcon } from "@fluentui/react-northstar";
import { TextField } from "@fluentui/react";
import { Error } from "../../../models";
import { changePassword } from "../../../api/account";
import { UserStore } from "../../../store/user";
import { useTranslation } from 'react-i18next';
import { inject, observer } from "mobx-react";

export default (inject("user"))(observer(({ user }: { user: UserStore }) => {

  const { t } = useTranslation();
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassVerify, setNewPassVerify] = useState("");
  const [success, setSuccess] = useState(false);
  const [oldPassError, setoldPassError] = useState("");
  const [error, setError] = useState("");
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setoldPassError("")
      setError("")
      await changePassword({
        oldPassword: oldPass,
        newPassword: newPass,
        newPasswordVerify: newPassVerify,
      });
      setSuccess(true);
      user.logout()
    } catch (data : any) {
      const errors = data.errors as Error[];
      if(errors[0]?.description && errors[0]?.description.includes('Incorrect password')){
        setoldPassError(errors[0]?.description);
      } else {
        setError(t(errors && errors[0]?.description) ?? t("Failed to change password"));
      }
    }
  };

  return (
    <>
      <Dialog open={success} header={t("Success")} confirmButton={{ content: t("Ok") }}
        onConfirm={() => window.location.href = "/"} />
      <Flex
        styles={{ justifyContent: "center", alignItems: "center" }}>
        <Segment color="brand" styles={{
          width: "100%",
          maxWidth: "500px",
          minWidth: "350px"
        }}>
          <form onSubmit={submit}>
            <Flex column gap="gap.medium">
              <Flex gap="gap.medium" column>

                <TextField type={showOldPass ? "text" : "password"} value={oldPass}
                  errorMessage={oldPassError}
                  label={t("Old Password")} onChange={(e, value) => setOldPass(value ?? "")}
                  
                  onRenderSuffix={() => (
                    <Box
                      styles={{ cursor: "pointer" }}
                      onClick={() => setShowOldPass( !showOldPass )}>
                      {showOldPass ? <EyeSlashIcon /> : <EyeIcon />}
                    </Box>
                  )}/>

                <TextField type={showNewPass ? "text" : "password"} value={newPass}
                  label={t("New Password")} onChange={(e, value) => setNewPass(value ?? "")}
                  errorMessage={error} 
                  onRenderSuffix={() => (
                    <Box
                      styles={{ cursor: "pointer" }}
                      onClick={() => setShowNewPass( !showNewPass )}>
                      {showNewPass ? <EyeSlashIcon /> : <EyeIcon />}
                    </Box>
                  )}/>

                <TextField type={showRePass ? "text" : "password"}
                  label={t("Re-enter New Password")} value={newPassVerify}
                  onChange={(e, value) => setNewPassVerify(value ?? "")} 
                  onRenderSuffix={() => (
                    <Box
                      styles={{ cursor: "pointer" }}
                      onClick={() => setShowRePass( !showRePass )}>
                      {showRePass ? <EyeSlashIcon /> : <EyeIcon />}
                    </Box>
                  )}/>

                <Button type="submit" primary fluid>
                  {t("Change Password")}
                </Button>

              </Flex>
            </Flex>
          </form>
        </Segment>
      </Flex>
    </>
  );
}))
