import request from "../utils/request";
import { Country, Result, TimeZoneInfoView } from "../models/index";

// export async function getLeavePerUser(userId: string) {
//    const response = await request.get<Result<LeavesModel[]>>(`Leave/GetLeavePerUser/${userId}`);
//    return response.data;
// }

// export async function getBalancePerUser(userId: string) {
//    const response = await request
//        .get<Result<LeaveBalanceModel[]>>(`Leave/GetBalancePerUser/${userId}`);
//    return response.data;
// }

export async function getCountries() {
  const response = await request.get<Result<Country[]>>("Country/GetCountries");
  return response.data;
}
export async function getUsersCountries(userId :string) {
  const response = await request.get<Result<Country[]>>(`Country/GetUsersCountries/${userId}`);
  return response.data;
}
export async function getTimeZones() {
  const response = await request.get<Result<TimeZoneInfoView[]>>("Country/GetTimeZones");
  return response.data;
}
