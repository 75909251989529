import React from "react";
import { Flex, FlexItem} from "@fluentui/react-northstar";
import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import LeavesConfig from "../../../components/leaves/leavesConfig"
import { UserView } from "../../../models";

interface IState {
}

interface IProps {
  match: { params: { id: string } };
  t: any;
  user: UserStore;
  userInView?: UserView;
}

@inject("user")
@observer
class UserLeaves extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
  }

  get id() {
    return this.props.match.params.id;
  }

  render() {

    const { t, user, userInView } = this.props;
    
    return (
      <FlexItem grow>
        {this.id && userInView ? 
              (
                <Flex column padding="padding.medium">                  
                    <LeavesConfig
                      t={t}
                      user={user}
                      companyId={userInView.company.id}
                      userId={this.id} 
                    />
                </Flex>
              ) : <></>
          }
      </FlexItem>
    );
  }
}

export default UserLeaves;
