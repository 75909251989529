import React, { FormEvent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { TextField, PrimaryButton, ActionButton } from "@fluentui/react";
import { Divider, Avatar, Segment, Flex, Dialog, EyeSlashIcon, EyeIcon, Box, Loader } from "@fluentui/react-northstar";
import { UserStore } from "../../store/user";
import logo from "../../assests/images/logo.png";
import { Error, LoginInput } from "../../models";
import { recoverPassword } from "../../api/account";
import { NotificationStore } from "../../store/notification";
import { withTranslation } from "react-i18next";
import BackgroundArt from '../../assests/images/watermark-cover-art.png';


interface ILoginState {
  email: string;
  password: string;
  company: string;
  hasLoggedIn: boolean;
  emailError: string;
  passwordError: string;
  passwordDialogOpen: boolean;
  showPass: boolean;
  firstLogin: boolean;
  loading: boolean;
}

type Props = Readonly<{ user?: UserStore; notification?: NotificationStore; match?: any, t : any }>;

@inject("user", "ui")
class Login extends React.Component<Props, ILoginState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      showPass: false,
      passwordDialogOpen: false,
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      company: props.match?.params?.company || "BluGrass",
      hasLoggedIn: false,
      firstLogin: false,
      loading: false
    };
  }

  validateForm = () => {
    const { email, password } = this.state;
    return email.length > 0 && password.length > 0;
  };

  submit = async (e: FormEvent) => {
    e.preventDefault();
    this.setState({loading : true})
    var firstLogin = false;
    try {
      var loginResponse = await this.props.user?.login({
        email: this.state.email,
        password: this.state.password,
      } as LoginInput);
      firstLogin = loginResponse?.payload.firstLogin || false;
      await this.props.notification?.load();
    } catch (data: any) {
      const errors = data.errors as Error[];
      if (errors) {
        this.setState({
          emailError: errors.filter((x) => x.code === "NotFound")[0]?.description ?? "",
          passwordError: errors.filter((x) => x.code === "IncorrectPassword")[0]?.description ?? "",
        });
      }
    }
    this.setState({ hasLoggedIn: this.props.user?.isLoggedIn || false, firstLogin, loading : false });
  };

  recoverPassword = async () => {
    const { t } = this.props
    try {
     // const params = new URLSearchParams(window.location.h);
      await recoverPassword({ email: this.state.email});
      this.setState({ passwordDialogOpen: true });
    } catch (data) {
      this.setState({ emailError: t("Please enter a valid email") });
    }
  };

  render() {
    const { loading } = this.state
    const { user, t } = this.props;
    const companyName = this.props.match?.params?.company;
    if (this.props.user?.isLoggedIn) {
      if (this.props.user?.firstLogin) {
        return <Redirect to={`/account/change-password`} />;
      } else {
        const params = new URLSearchParams(window.location.search);
        const param = params.get("redirect");
        const redirect = param ? decodeURI(param) : null;
        return <Redirect to={redirect ?? `${user?.companyName ? "/" + user?.companyName : "" }/attendance`} />;
      }
    }

    return (
      <>
        <Dialog open={this.state.passwordDialogOpen}
          onConfirm={() => this.setState({ passwordDialogOpen: false })}
          confirmButton={{ content: "Ok" }}
          content={t("Please follow instructions to reset your password")}
          header={`${t("An email has been sent to")} ${this.state.email}`} />
        <Flex vAlign="center" hAlign="center" styles={{
          height: "100%",
          backgroundImage: `url(${BackgroundArt})`
        }}>
          <Segment color="brand" styles={{
            maxWidth: "350px",
            width: "80%",
          }}>
            <form onSubmit={(e) => this.submit(e)}>
              <Flex column gap="gap.medium" styles={{
                width: "100%",
                root: { width: "100%" },
              }}>
                <Divider content={
                  <Avatar image={logo} size="largest" square />
                } />

                <TextField type="text" label={t("Company")}
                  style={{ width: "100%" }}
                  defaultValue={companyName} value={this.state.company}
                  onChange={(e, val) => this.setState({ company: val ?? "" })}
                  disabled={true} />

                <TextField type="email" label={t("Email")} value={this.state.email}
                  onChange={(e, val) => this.setState({ email: val ?? "", emailError: "" })}
                  errorMessage={this.state.emailError} />

                <TextField type={this.state.showPass ? "text" : "password"} label={t("Password")}
                  onRenderSuffix={() => (
                    <Box
                      styles={{ cursor: "pointer" }}
                      onClick={() => this.setState({ showPass: !this.state.showPass })}>
                      {this.state.showPass ? <EyeSlashIcon /> : <EyeIcon />}
                    </Box>
                  )}
                  value={this.state.password}
                  onChange={(e, val) => this.setState({
                    password: val ?? "", passwordError: "",
                  })}
                  errorMessage={this.state.passwordError} />

                <ActionButton onClick={this.recoverPassword}>{t("Forgot password?")}</ActionButton>

                <PrimaryButton 
                  style={{ width: "100%" }} 
                  type="submit"
                  disabled={!this.validateForm() || loading}>
                  {loading ? <Loader/> : t("Submit")}
                </PrimaryButton>
              </Flex>

            </form>
          </Segment>
        </Flex>
      </>
    );
  }
}

export default withRouter(withTranslation()(Login as any) as any);
