import React from "react"
import { inject, observer } from "mobx-react"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
//import { BellIcon, Button } from "@fluentui/react-northstar"
import { NotificationStore } from "../../store/notification"
import IconButton from '@mui/material/IconButton';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

import Badge from "../badge"

interface INotificationBellProps {
    notification?: NotificationStore;
  }

const NotificationBell = (inject("notification"))(observer((props: INotificationBellProps) => {
    const { notification } = props;
    return (
            <Badge count={notification? notification.notificationsTotalUnreadCount : 0}>
                {/* <Button iconOnly text icon={<BellIcon />} /> */}
                <IconButton aria-label="notification" color="default">
                    <NotificationsNoneOutlinedIcon />
                </IconButton>
            </Badge>
        )
}));

export default withRouter(withTranslation()(NotificationBell as any) as any) as unknown as React.FunctionComponent<INotificationBellProps>;