import { observable, action, computed, reaction } from "mobx";
import { GetNotificationsFirstPagePerUser, GetNotificationsPerUser, markAllNotificationsAsRead, markNotificationAsRead } from "../api/Notification";
import { RootStore } from "./rootStore";
import { Notification } from "../models";

export class NotificationStore {

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    reaction(() => this.userId, (userId) => {
      if (userId) {
        this.loadInitial();
      }
    });
  }

  @computed get userId() {
    return this.rootStore.user.userId
  }

  // constructor(rootStore: RootStore) {
  //   this.rootStore = rootStore
  //   this.userStore = user;
  //   reaction(() => userStore.userId, (userId) => {
  //     if (userId) {
  //       this.load();
  //     }
  //   });
  // }

  @observable notifications: Notification[] = []
  @observable fullListNotifications: Notification[] = []
  @observable notificationsTotalCount: number = 0
  @observable notificationsTotalUnreadCount: number = 0
  @observable notificationsPageCount: number = 15

  @action
  async loadInitial() {
    // console.log("load()")
    const { payload } = await GetNotificationsFirstPagePerUser(this.userId)
    this.fullListNotifications = payload.notificationsList
    this.notifications = payload.notificationsList
    this.notificationsTotalCount = payload.notificationsTotalCount
    this.notificationsTotalUnreadCount = payload.notificationsTotalUnreadCount
  }

  @action
  async load(notificationType?: number, pageNumber?: number, pageSize?: number) {
    // console.log("load()")
    const { payload } = (typeof pageNumber !== "undefined" && pageSize) ? await GetNotificationsPerUser(this.userId, notificationType?? 0, pageNumber?? 1, pageSize?? 15) : await GetNotificationsFirstPagePerUser(this.userId)
    this.fullListNotifications = payload.notificationsList
    this.notificationsTotalCount = payload.notificationsTotalCount
    this.notificationsTotalUnreadCount = payload.notificationsTotalUnreadCount
  }

  // @computed
  // get unreadCount() {
  //   try {
  //     return this.notifications.filter((x) => !x.isRead).length;
  //   } catch {
  //     return 0
  //   }
  // }

  @action
  async dimissAll() {
    var response = await markAllNotificationsAsRead(this.userId);
    if (response.payload) {
      this.notificationsTotalUnreadCount = 0
      var mutableNotifications = [ ...this.notifications ]
      mutableNotifications.map((x) => {
        x.isRead = true;
        return x;
      });
      this.notifications = mutableNotifications;
    }
    // this.notifications.forEach(async (x) => await this.markAsRead(x.id));
  }

  @action
  async markAsRead(id: number) {
    await markNotificationAsRead(id);
    var mutableNotifications = [ ...this.notifications ]
    mutableNotifications.map((x) => {
      if (x.id === id) x.isRead = true;
      return x;
    });
    this.notifications = mutableNotifications;
  }
}

// export default (window as any).notificationStore = new NotificationStore(userStore);
