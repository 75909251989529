import React from "react";
import { withRouter } from "react-router-dom";
import NotificationList from "../../components/notifications";
import { Flex } from "@fluentui/react-northstar";
import { Dropdown } from "@fluentui/react";
import { NotificationType } from "../../models";
import { getNotifictionTypes } from "../../api/Notification";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { UserStore } from "../../store/user";

interface IProps {
  user: UserStore;
  match: { params: { id?: string } };
  history: any;
  t: any;
};

@inject("user")
@observer
class Notifications extends React.Component<IProps, { selected?: number, options: NotificationType[] }> {

  constructor(props : IProps) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    var { payload } = await getNotifictionTypes();
    this.setState({ options: payload });
  }

  render() {
    const { t } = this.props
    return (
      <Flex column hAlign="stretch" >
        <Flex padding="padding.medium" hAlign="start" vAlign="end" gap="gap.medium">
          <Dropdown
            styles={{ root: { width: "200px" } }}
            style={{ flex: 1 }}
            selectedKey={this.state.selected ?? 0}
            onChange={(_ev, input) => {
              var key = 0
              try{
                if (input)
                  key = parseInt(input?.key.toString())
              } catch (e) {
                key = 0
              }
              this.setState({ selected: key })
            }}
            label={t("Notification Type")} options={([{ id: 0, name: t('All') }].concat(this.state.options)).map(x => ({
              key: x.id,
              text: t(x.name)
            }))} />
        </Flex>
        <NotificationList type={this.state.selected ?? undefined} full/>
      </Flex>
    );
  }
}

export default withRouter(withTranslation()(Notifications as any) as any)
