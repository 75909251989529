import React from "react";
import { Flex, FlexItem } from "@fluentui/react-northstar";
import { withRouter } from "react-router-dom";
import {
  UserView,
} from "../../../models";
import Maps from '../../map';
import { withTranslation } from "react-i18next";
import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";

interface IState {
}

interface IProps {
  match: { params: { id?: number } };
  history: any;
  t: any;
  user: UserStore
}

@inject("user")
@observer
class WorkplaceMap extends React.Component<IProps, IState> {

  get id() {
    if(window.location.href.toLowerCase().includes('mycompany'))
      return this.props.user.company?.id
    return Number(this.props.match.params.id);
  }

  render() {
    // const { t } = this.props;
    return (
      this.id ? <FlexItem grow>
                <Flex column padding="padding.medium"
                  styles={{
                    height: '100%',
                    width: '100%',
                  }}>
                   <Maps companySpecific companyId={this.id} userInView={{} as UserView} user={this.props.user} editable /> 
                </Flex>
              
              </FlexItem>
              : <></>
            
    );
  }
}

export default withRouter(withTranslation()(WorkplaceMap as any) as any);
