import React from "react";
import { Flex } from "@fluentui/react-northstar";
import { Text } from "@fluentui/react";

export default function NotFound() {
  return (
    <Flex vAlign="center" hAlign="center">
      <Text variant="mega">404</Text>
    </Flex>
  );
}
