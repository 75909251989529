import React, { createRef, useEffect, useState } from "react";
import {Flex, Image, Button, Text,Dropdown} from "@fluentui/react-northstar";
import { useTranslation } from 'react-i18next';
import { Result } from "../../models";
import { Icon } from "@fluentui/react";
import { UpdateCustomStatus,GetStatusHistory } from "../../api/account";
import "./index.scss";
import { StatusHistory } from "../../models";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { fontSize } from "@mui/system";

declare const baseUrl: string;

export default ({ user, semibold, asDialog, dialogIsOpen, closeAvailabilityStatusDialog}: any) => {


    const { t } = useTranslation();
    const [statusInputText, setStatusInputText] = useState(user.currentCustomStatusText);
    const [open, setOpen] = useState(false);
    const [history,setHistory]=useState<StatusHistory[]>([]);

    useEffect(() => {
        console.log("dialogIsOpen: ", dialogIsOpen)
        getStatusHistory();
        if (dialogIsOpen)
            setOpen(true)
    }, [dialogIsOpen]);

    const handleChange = (e: any) => {
        setStatusInputText(e.target.value);
        console.log(statusInputText);
    };

    const handleSubmit = async () => {
        //request call to update current....
        console.log(statusInputText);
        try {
            await UpdateCustomStatus({
                currentCustomStatus: statusInputText,
            });
            user.currentCustomStatusText = statusInputText;
        } catch (error: any) {
            console.log("error: ", error);
            if (error && error.errors && error.errors[0]) {
                const res = error as Result<never>;
                alert(res.errors[0].description);
            }
        }
        if(asDialog)
        {
            setOpen(false);
            closeAvailabilityStatusDialog()
        }
        console.log(user.currentCustomStatusText)
    };


    const clear = async () => {
        //request call to update current....
        try {
            setStatusInputText("");
            await UpdateCustomStatus({
                currentCustomStatus:""
                //statusInputText,
            });
            user.currentCustomStatusText = "";
        } catch (error: any) {
            console.log("error: ", error);
            if (error && error.errors && error.errors[0]) {
                const res = error as Result<never>;
                alert(res.errors[0].description);
            }
        }
        if(asDialog)
        {
            setOpen(false);
            closeAvailabilityStatusDialog()
        }
    };


    const getStatusHistory = async () => {
        try {
           
			const statuses = await GetStatusHistory();
           //console.log("hi",statuses.payload)
            setHistory(statuses.payload); 
            //console.log(statuses.payload);

		} catch (error: any) {

			console.log(error);
			if (error && error.errors && error.errors.length > 0 && error.errors[0] && error.errors[0].description) {
				alert(error.errors[0].description);
			}
		}
    }
    

    if(asDialog)
    {
        return(
                    <Dialog
                    open={open}
                    fullWidth = {true}
                    maxWidth = 'sm'
                    >
                    <DialogTitle>
                    <b>{t("Availability Status")}</b>
                    </DialogTitle>
                    <DialogContent>
                       <Flex>
                        <Autocomplete
                        //disablePortal
                        id="status-history"
                        options={history != null?history.map((h)=>h.status):[]}
                        sx={{ width: "85%"}}
                        renderInput={(params) => <TextField {...params} variant="standard" inputProps = {{...params.inputProps, style: { fontSize: 14 }}} />}
                        onSelect = {handleChange}
                        value = {statusInputText}
                        style ={{paddingRight :"35px"}}
                        />
                        <Button onClick={handleSubmit} styles={{ backgroundColor: user.companyPrimaryColor, color: "white" }} iconOnly icon={<Icon iconName="Accept" />}></Button>
                        <Button onClick={clear} iconOnly icon={<Icon iconName="Clear" />}></Button>
                        </Flex>
                    </DialogContent>
                </Dialog>
            )
    }
    else
    {
     
        return (
            <Flex style={{ flexDirection: "column" }}>
                <Text content={t("Availability Status")} weight={semibold ? "semibold" : "regular"} style={{ color: "#252423", paddingBottom: " 5px" }}></Text>
                <Flex styles={{ alignContent: "center", justifyContent: "space-between"}}>
                    <Autocomplete
                    id="status-history"
                    size = {"small"}
                    options={history != null?history.map((h)=>h.status):[]}
                    sx={{ width: "85%"}}
                    renderInput={(params) => <TextField {...params} variant="standard"  inputProps = {{...params.inputProps, style: { fontSize: 14 }}}/>}
                    onSelect = {handleChange}
                    value = {statusInputText}
                    style ={{paddingRight :"25px"}}
                        />
                    <Button onClick={handleSubmit} styles={{ backgroundColor: user.companyPrimaryColor, color: "white" }} iconOnly icon={<Icon iconName="Accept" />}></Button>
                    <Button onClick={clear} iconOnly icon={<Icon iconName="Clear" />}></Button>
                </Flex>
            </Flex>
        );
     }
};
