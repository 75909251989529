import React from "react";
import { Flex, FlexItem } from "@fluentui/react-northstar";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import LeavesConfig from "../../../components/leaves/leavesConfig"

interface IState {
}

interface IProps {
  match: { params: { id?: number } };
  history: any;
  t: any;
  user: UserStore
}

@inject("user")
@observer
class CompanyLeaves extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
  }

  get id() {
    if(window.location.href.toLowerCase().includes('mycompany'))
      return this.props.user.company?.id
    return Number(this.props.match.params.id);
  }

  render() {

    const { t, user } = this.props;

    return (
      <FlexItem grow>
        {this.id ? 
              (
                <Flex column padding="padding.medium">                  
                    <LeavesConfig
                      t={t}
                      user={user}
                      companyId={this.id} 
                    />
                </Flex>
              ) : <></>
          }
      </FlexItem>
    );
  }
}

export default withRouter(withTranslation()(CompanyLeaves as any) as any);
