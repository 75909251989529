import React from "react";
import { Flex, FlexItem } from "@fluentui/react-northstar";
import {
  UserView,
} from "../../../models";
import Maps from '../../map';
import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";

interface IState {
}

interface IProps {
  match: { params: { id: string } };
  t: any;
  user: UserStore;
  userInView?: UserView;
}

@inject("user")
@observer
class WorkplaceMap extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }
  
  get id() {
    return this.props.match.params.id;
  }


  render() {
    const { userInView } = this.props;
    return (
      <FlexItem grow>
        {this.id && userInView ? 
              (
                <Flex column padding="padding.medium"
                  styles={{
                    height: '100%',
                    width: '100%',
                  }}>
                      <Maps userInView={userInView} user={this.props.user} companyId={userInView.company.id} editable userSpecific />
                </Flex>
              ) : <></>
          }
      </FlexItem>
    );
  }
}

export default WorkplaceMap;
