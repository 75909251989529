import React from 'react';
import { Flex, Divider, Dialog, Alert, ExclamationTriangleIcon, Input } from "@fluentui/react-northstar";
import { ShimmeredDetailsList, IColumn, CommandBar, SearchBox, Dropdown } from "@fluentui/react";
import { withRouter } from 'react-router-dom';
import { LeaveRequestStatus, LeaveRequestView, LeaveType, LeaveTypeView, RequestModel, Result, Roles } from "../../../models";
import { copyAndSort, shadeBlend } from "../../../utils";
import { UserStore } from "../../../store/user";
import { inject, observer } from "mobx-react";
import { Pagination as FPagination } from "@fluentui/react-experiments/lib/Pagination"
import { withTranslation } from 'react-i18next';
import columns from './columns'
import { getLeaveRequestsAssignedToUser } from '../../../api/leaves';
import SubmitLeaveApprovalForm from '../../../components/leaves/submitLeaveApprovalForm';
import * as LeavesApi from '../../../api/leaves';
import LeaveAttachments from '../../../components/leaves/leaveAttachments';

interface IProps {
    user: UserStore;
    match: { params: { id?: string } };
    history: any;
    t: any;
};

interface IState {
    loading: boolean;
    requests: RequestModel[];
    items: LeaveRequestView[];
    leaveRequests: LeaveRequestView[];
    count: number;
    offset: number;
    selectedPageIndex: number;
    
    selectedLeaveId: number;
    leaveAttachmentsDialogOpen: boolean;
    approveDialogOpen: boolean;
    rejectDialogOpen: boolean;
    undoDialogOpen: boolean;
    commentDialogData: {
        leaveId: number;
        commentValue: string;
    }
    commentRequiredAlert: boolean;
    
    columns: IColumn[];

    showFilters: boolean;
    selectedStatus: string;
    selectedLeaveType: string;
    filter: {
        leaveType: string;
        fullName: string;
        status: string;
    }

    leaveTypes: LeaveTypeView[];
}

@inject("user")
@observer
class RequestsAssignedToUser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            count: 10,
            offset: 0,
            items: [],
            leaveRequests: [],
            requests: [],
            loading: false,
            selectedPageIndex: 0,
            
            selectedLeaveId: 0,
            leaveAttachmentsDialogOpen: false,
            approveDialogOpen: false,
            rejectDialogOpen: false,
            undoDialogOpen: false,
            commentDialogData: {
                leaveId: 0,
                commentValue: ""
            },
            commentRequiredAlert: false,

            columns: columns(props.t, this.attachmentButtonOnClick, this.approveButtonOnClick, this.rejectButtonOnClick, this.undoButtonOnClick).assignedLeaves,

            showFilters: false,
            selectedStatus: "",
            selectedLeaveType: "",
            filter: {
                leaveType: "",
                fullName: "",
                status: ""
            },

            leaveTypes: []
        };

        this.state.columns.forEach((c) => c.onColumnClick = (_: any, column: IColumn) => {
            const newColumns: IColumn[] = this.state.columns.slice();
            const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
            newColumns.forEach((newCol: IColumn) => {
                if (newCol === currColumn) {
                    currColumn.isSortedDescending = !currColumn.isSortedDescending;
                    currColumn.isSorted = true;
                } else {
                    newCol.isSorted = false;
                    newCol.isSortedDescending = true;
                }
            });
            const newItems = copyAndSort(this.state.leaveRequests, currColumn.key, currColumn.isSortedDescending);
            this.setState({ columns: newColumns, leaveRequests: newItems });
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    get userId() {
        return this.props.match.params.id ?? this.props.user.userId;
    }

    fetchData = async () => {
        this.setState({ loading: true });

        const leaveTypes = await LeavesApi.getLeaveTypes(this.userId);

        const leaves = await getLeaveRequestsAssignedToUser(this.userId);
        this.setState({
            loading: false,
            // requests: assigned.payload,
            leaveTypes: leaveTypes.payload,
            leaveRequests: leaves.payload.leaveRequests,
            items: leaves.payload.leaveRequests
        });
    };

    filterDataGlobal = (value: string) => {
        var valueLower = value.toLowerCase();
        this.setState(state => {
            return {
                items: state.leaveRequests.filter(l => {
                    return l.user?.fullName.toLowerCase().includes(valueLower)
                    || l.leaveType.toLowerCase().includes(valueLower)
                    || l.status.toString().toLowerCase().includes(valueLower)
                })
            }
        });
    }

    filterData(newState: any){
        this.setState({
          filter: {
            ...this.state.filter,
            ...newState
          }
        }, () => {
          this.setState(state => {
            return {
              items: state.leaveRequests.filter(l => 
                (!state.filter.fullName || l.user.fullName?.toLowerCase().includes(state.filter.fullName.toLowerCase()))
                && (!state.filter.leaveType || l.leaveType?.toLowerCase().includes(state.filter.leaveType.toLowerCase()))
                && (!state.filter.status || l.status.toString().toLowerCase().includes(state.filter.status.toLowerCase()))
              )
            }
          })
        })
    }

    requestApproval = async (id: number, isApproved: boolean, supervisorComment: string) => {
        this.setState({loading : true});
        try {
            await LeavesApi.leaveRequestApproval({ id, isApproved, supervisorComment })
            this.setState({
                approveDialogOpen: false,
                rejectDialogOpen: false,
                commentRequiredAlert: false
            })
            this.fetchData();
            
        } catch (error) {
            if (error) {
                const res = error as Result<never>;
                alert(res.errors[0].description);
            }
        }
        this.setState({loading : false});
    }

    undoRequestApproval = async (id: number, supervisorComment: string) => {
        this.setState({loading : true});
        try {
            await LeavesApi.leaveRequestUndoApproval({ id, supervisorComment, isApproved: false })
            this.setState({
                undoDialogOpen: false,
                commentRequiredAlert: false
            })
            this.fetchData();
        } catch (error) {
            if (error) {
                const res = error as Result<never>;
                alert(res.errors[0].description);
            }
        }
        this.setState({loading : false});
    }

    attachmentButtonOnClick = (leave: LeaveRequestView) => {
        this.setState({
            selectedLeaveId: leave.id,
            leaveAttachmentsDialogOpen: true
        })
    }

    approveButtonOnClick = (leave : LeaveRequestView) => {
        this.setState({
            approveDialogOpen: true,
            commentDialogData: {
                leaveId: leave.id,
                commentValue: leave.supervisorComments
            }
        })
    }

    rejectButtonOnClick = (leave : LeaveRequestView) => {
        this.setState({
            rejectDialogOpen: true,
            commentDialogData: {
                leaveId: leave.id,
                commentValue: leave.supervisorComments
            }
        })
    }

    undoButtonOnClick = (leave : LeaveRequestView) => {
        this.setState({
            undoDialogOpen: true,
            commentDialogData: {
                leaveId: leave.id,
                commentValue: leave.supervisorComments
            }
        })
    }

    onPageChange = (index: number): void => {
        this.setState({
            selectedPageIndex: index,
            offset: (index ?? 1) * this.state.count
        })
    }

    render() {
        const {
            loading,
            items,
            columns,
            count,
            offset,
            showFilters
        } = this.state;

        const { user, t } = this.props

        const leaveStatuses = Object.values(LeaveRequestStatus).splice(0, Object.values(LeaveRequestStatus).length/2).map( x => x.toString())

        const dialogs = (
            <>
                <SubmitLeaveApprovalForm
                        headerText="Leave Approval"
                        isRequired={false} 
                        isOpen={this.state.approveDialogOpen}
                        loading={this.state.loading}
                        commentDialogData={this.state.commentDialogData}
                        commentRequiredAlert={this.state.commentRequiredAlert}
                        leaveRequests={this.state.leaveRequests}
                        onCommentChange={(e: any, props: string | undefined) => {
                            this.setState({
                                commentDialogData: {
                                    ...this.state.commentDialogData,
                                    commentValue: props ?? ""
                                }
                            })
                        }}
                        onConfirm={() => this.requestApproval(this.state.commentDialogData.leaveId, true, this.state.commentDialogData.commentValue)}
                        onCancel={() => this.setState({ approveDialogOpen: false })}
                    />
                    <SubmitLeaveApprovalForm
                        headerText="Leave Rejection"
                        isRequired
                        isOpen={this.state.rejectDialogOpen}
                        loading={this.state.loading}
                        commentDialogData={this.state.commentDialogData}
                        commentRequiredAlert={this.state.commentRequiredAlert}
                        leaveRequests={this.state.leaveRequests}
                        onCommentChange={(e: any, props: string | undefined) => {
                            this.setState({
                                commentDialogData: {
                                    ...this.state.commentDialogData,
                                    commentValue: props ?? ""
                                }
                            })
                        }}
                        onConfirm={() => {
                            if (!this.state.commentDialogData.commentValue)
                                this.setState({ commentRequiredAlert: true })
                            else
                                this.requestApproval(this.state.commentDialogData.leaveId, false, this.state.commentDialogData.commentValue)
                        }}
                        onCancel={() => this.setState({ rejectDialogOpen: false })}
                    />
                    <SubmitLeaveApprovalForm
                        headerText="Undo Leave Approval or Rejection"
                        isRequired={false} 
                        isOpen={this.state.undoDialogOpen}
                        loading={this.state.loading}
                        commentDialogData={this.state.commentDialogData}
                        commentRequiredAlert={this.state.commentRequiredAlert}
                        leaveRequests={this.state.leaveRequests}
                        onCommentChange={(e: any, props: string | undefined) => {
                            this.setState({
                                commentDialogData: {
                                    ...this.state.commentDialogData,
                                    commentValue: props ?? ""
                                }
                            })
                        }}
                        onConfirm={() => this.undoRequestApproval(this.state.commentDialogData.leaveId, this.state.commentDialogData.commentValue)}
                        onCancel={() => this.setState({ undoDialogOpen: false })}
                    />
                    <Dialog
                        open={this.state.leaveAttachmentsDialogOpen}
                        onCancel={() => this.setState({ leaveAttachmentsDialogOpen: false })}
                        // onConfirm={() => this.requestLeave()}
                        // confirmButton={t("Request Leave")}
                        cancelButton={t("Cancel")}
                        header={t("Leave Attachments")}
                        //let leave = this.state.leaveRequests.find(l => l.id == this.state.selectedLeaveId);
                        content={
                            <>
                                {this.state.leaveRequests.find(l => l.id === this.state.selectedLeaveId)?.attachmentRequired &&
                                    (this.state.leaveRequests.find(l => l.id === this.state.selectedLeaveId)?.attachmentCount as number) <= 0 &&
                                    <Alert styles={{marginBottom: "20px", padding: "5px"}} warning content={
                                        <>
                                            <ExclamationTriangleIcon />{' '}
                                        {t("This leave type requires an attachment")}
                                        </>
                                    } />}
                                <LeaveAttachments setAttachmentsCount={(n: number) => {}} fetchData={this.fetchData} leaveRequestId={this.state.selectedLeaveId} newRequest={false} submitNow={false} />
                            </>}
                    />
                </>
        )

        return (
            <Flex column styles={{ width : '100%', overflow: 'auto'}}>
                {dialogs}
                <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
                    <div
                    className="subHeader" style={{color: this.props.user.companyPrimaryColor?? "#005bab"}}
                    >{t("Leaves")}</div>
                </Flex>

                <Divider />

                <CommandBar
                    items={[
                        {
                            key: "search",
                            onRender: () => (
                                <Flex vAlign="center" styles={{ paddingRight: "10px" }}>
                                <SearchBox
                                    placeholder={t("Search")}
                                    iconProps={{styles: {root: {color: user.companyPrimaryColor}}}}
                                    onChange={(ev, input) => this.filterDataGlobal(input ?? "")}
                                />
                                </Flex>
                            ),
                        },
                        {
                            key: "filters",
                            iconProps: { iconName: "filter" },
                            buttonStyles: {
                                icon: { color: user.companyPrimaryColor },
                                iconHovered: { color: shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) },
                            },
                            onClick: () => this.setState(state => ({ showFilters: !state.showFilters })),
                        },
                    ]}
                />

                {showFilters && (
                    <Flex gap="gap.medium" padding="padding.medium">
                        <Input
                            placeholder={t("Employee")}
                            //onChange={(_ev, input) => this.filterData(x => x.userName ?? "", input?.value ?? "")}
                            onChange={(_ev, input) => this.filterData({fullName: input?.value?? ""})}
                        />
                        <Dropdown
                            options={[{key:"", text: t("Select Leave Type")}, ...this.state.leaveTypes.map((item : LeaveTypeView) => ({key: item.name, text: t(item.name)}))]}

                            onChange={(_ev, input) => {
                                this.filterData({leaveType: input?.key});
                                this.setState({selectedLeaveType: input?.key ? input?.key.toString() : "" });
                            }}
                            selectedKey={this.state.selectedLeaveType}
                            styles={{dropdown: {width: "150px",  borderWidth: "0px"}, title: {backgroundColor: "rgb(243 ,242, 241)", borderWidth: "0px"}}}
                        />
                        <Dropdown
                            options={[{key:"", text: t("Select Status")}, ...leaveStatuses.map((item) => ({key: item, text: t(item)}))]}
                            onChange={(_ev, input) => {
                                this.filterData({status: input?.key?? ""});
                                this.setState({selectedStatus: input?.key as string});
                            }}
                            selectedKey={this.state.selectedStatus}
                            styles={{dropdown: {width: "150px",  borderWidth: "0px"}, title: {backgroundColor: "rgb(243 ,242, 241)", borderWidth: "0px"}}}
                        />
                    </Flex>
                )}

                <Divider />

                <ShimmeredDetailsList
                    enableShimmer={loading}
                    items={items.slice(offset, Math.min(offset + count, items.length))}
                    columns={columns}
                    selectionMode={0}
                />

                <FPagination
                    selectedPageIndex={this.state.selectedPageIndex}
                    pageCount={Math.ceil(items.length / count)}
                    itemsPerPage={count}
                    totalItemCount={items.length}
                    format='buttons'
                    previousPageAriaLabel={'previous page'}
                    nextPageAriaLabel={'next page'}
                    firstPageAriaLabel={'first page'}
                    lastPageAriaLabel={'last page'}
                    pageAriaLabel={'page'}
                    firstPageIconProps={{ iconName: user.rtl ? 'DoubleChevronRight' : 'DoubleChevronLeft' }}
                    previousPageIconProps={{ iconName: user.rtl ? 'ChevronRight' : 'ChevronLeft' }}
                    nextPageIconProps={{ iconName: user.rtl ? 'ChevronLeft' : 'ChevronRight' }}
                    lastPageIconProps={{ iconName: user.rtl ? 'DoubleChevronLeft' : 'DoubleChevronRight' }}
                    onPageChange={this.onPageChange}
                />

                            {/* <Pagination
                                currentPage={offset / count + 1}
                                total={items.length}
                                limit={count}
                                onPageChange={(page) => this.setState({ offset: ((page ?? 1) - 1) * count })}>
                                {({ pages, currentPage, totalPages, getPageItemProps }) => (
                                    <div>
                                        {pages.map((page) => (
                                            <Button iconOnly
                                                primary={currentPage === page}
                                                {...getPageItemProps({
                                                    pageValue: page,
                                                    total: totalPages,
                                                    onClick: () => this.setState({ offset: ((page ?? 1) - 1) * count }),
                                                }) as any}>
                                                {page}
                                            </Button>
                                        ))}
                                    </div>
                                )}
                            </Pagination> */}
            </Flex>
        );
    }
}

export default withRouter(withTranslation()(RequestsAssignedToUser as any) as any)