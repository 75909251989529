import React, { Suspense,StrictMode } from "react";
//import ReactDOM from "react-dom";
import {createRoot} from 'react-dom/client';
import "./assests/styles/index.scss";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './i18n';

// https://github.com/microsoft/fluentui/wiki/Using-icons
initializeIcons();


//const rootElement : HTMLElement =document.getElementById("root") != null? document.getElementById("root") :   ;
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

root.render(<Suspense  fallback={<></>}><App /></Suspense>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

