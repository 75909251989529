import { observable } from 'mobx';
import { RootStore } from './rootStore';
// import { getLocale } from '../api/account';
// import user from './user';

export class UiStore {

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable open: boolean = false;
    @observable content?: JSX.Element | string = "";
    @observable title?: JSX.Element | string = "";
    // @observable locale: string = user.locale?? "en";
    // @observable rtl: boolean = false;

    // @action
    // getUsersLocale = async () => {
    //     var locale = (await getLocale()).payload
    //     if (locale && this.locale != locale)
    //         this.locale = locale
    //     return this.locale
    // }

    showAlert({ content, title }: { content?: JSX.Element | string, title?: JSX.Element | string }) {
        this.content = content;
        this.title = title;
        this.open = true;
    }

    
}

// export default (window as any).ui = new UiStore();