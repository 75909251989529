import { serialize } from "object-to-formdata";
import request from "../utils/request";

import {
  Result, ClockingsPerUserPerDayView, ClockingsReportInput, LeavesReportView, LeavesReportInput, ShiftReportView, LeavesBalanceReportInput, UsersStatusesReportInput, UsersStatusesReportView,
} from "../models";


export async function getClockingsPerUserPerDay(input: ClockingsReportInput) {
  const response = await request.post<Result<ClockingsPerUserPerDayView[][]>>(`reports/Clockings/`,
    serialize(input));
  return response.data;
}

export async function exportClockingsPerUserPerDay(input: ClockingsReportInput, format: string) {
  const response = await request.post<ArrayBuffer>(`reports/Clockings/${format}`,
    serialize(input),
    {responseType: 'blob',
       headers: {
         'Content-Type': 'application/json'
       }});
       let fileName = response.headers["content-disposition"].split("filename=\"")[1];
       fileName = fileName.split("\"; filename")[0];
      //  let fileName = "clockings-" + Date.now();
      //  if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
      //      window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
      //          fileName);
      //  } else {
           const url = window.URL.createObjectURL(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', fileName);
           document.body.appendChild(link);
           link.click();
      //  }
}

export async function getLeavesPerUser(input: LeavesReportInput) {
  const response = await request.post<Result<LeavesReportView[]>>(`reports/Leaves/`,
    serialize(input));
  return response.data;
}

export async function exportLeavesPerUser(input: LeavesReportInput, format: string) {
  const response = await request.post<ArrayBuffer>(`reports/Leaves/${format}`,
    serialize(input),
    {responseType: 'blob',
       headers: {
         'Content-Type': 'application/json'
       }});
       let fileName = response.headers["content-disposition"].split("filename=\"")[1];
       fileName = fileName.split("\"; filename")[0];
      //  let fileName = "leaves-" + Date.now();
      //  if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
      //      window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
      //          fileName);
      //  } else {
           const url = window.URL.createObjectURL(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', fileName);
           document.body.appendChild(link);
           link.click();
      //  }
}

export async function getShiftsPerUser(input: LeavesReportInput) {
  const response = await request.post<Result<ShiftReportView[]>>(`reports/Shifts/`,
    serialize(input));
  return response.data;
}

export async function exportShiftsPerUser(input: LeavesReportInput, format: string) {
  const response = await request.post<ArrayBuffer>(`reports/Shifts/${format}`,
    serialize(input),
    {responseType: 'blob',
       headers: {
         'Content-Type': 'application/json'
       }});
       let fileName = response.headers["content-disposition"].split("filename=\"")[1];
       fileName = fileName.split("\"; filename")[0];
      // let fileName = "shifts-" + Date.now();
      //  if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
      //      window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
      //          fileName);
      //  } else {
           const url = window.URL.createObjectURL(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', fileName);
           document.body.appendChild(link);
           link.click();
      //  }
}

export async function exportLeavesBalance(input: LeavesBalanceReportInput, format: string) {
  const response = await request.post<ArrayBuffer>(`reports/LeavesBalance/${format}`,
    serialize(input),
    {responseType: 'blob',
       headers: {
         'Content-Type': 'application/json'
       }});
       let fileName = response.headers["content-disposition"].split("filename=\"")[1];
       fileName = fileName.split("\"; filename")[0];
      //  let fileName = "balance-" + Date.now();
      //  if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
      //      window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
      //          fileName);
      //  } else {
           const url = window.URL.createObjectURL(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', fileName);
           document.body.appendChild(link);
           link.click();
      //  }
}

export async function getUsersStatuses(input: LeavesReportInput) {
  const response = await request.post<Result<UsersStatusesReportView[]>>(`reports/UsersStatuses/`,
    serialize(input));
  return response.data;
}

export async function exportUsersStatuses(input: UsersStatusesReportInput, format: string) {
  const response = await request.post<ArrayBuffer>(`reports/UsersStatuses/${format}`,
    serialize(input),
    {responseType: 'blob',
       headers: {
         'Content-Type': 'application/json'
      }
    }
  );
  let fileName = response.headers["content-disposition"].split("filename=\"")[1];
  fileName = fileName.split("\"; filename")[0];
  //  let fileName = "balance-" + Date.now();
  // if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
  //     window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
  //         fileName);
  // } else {
      const url = window.URL.createObjectURL(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
  // }
}

export async function exportOvertimePerUser(input: LeavesReportInput, format: string) {
  const response = await request.post<ArrayBuffer>(`reports/Overtime/${format}`,
    serialize(input),
    {responseType: 'blob',
       headers: {
         'Content-Type': 'application/json'
       }});
       let fileName = response.headers["content-disposition"].split("filename=\"")[1];
       fileName = fileName.split("\"; filename")[0];
      // let fileName = "shifts-" + Date.now();
      //  if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
      //      window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
      //          fileName);
      //  } else {
           const url = window.URL.createObjectURL(new Blob([response.data], {type: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', fileName);
           document.body.appendChild(link);
           link.click();
      //  }
}