import React from "react";
import { inject, observer } from "mobx-react";
import { Flex, FlexItem, Divider, Button, TrashCanIcon, TableIcon } from "@fluentui/react-northstar";
import { CommandBar, SearchBox, ShimmeredDetailsList, IColumn, TooltipHost } from "@fluentui/react";
import { withRouter, Link } from "react-router-dom";
import { GetOrganizations, deleteOrganization } from "../../api/Organization";
import Pagination from "react-paginating";
import { OrganizationView } from "../../models";
import { copyAndSort, shadeBlend } from "../../utils";
// import user from "../../store/user";
import { withTranslation } from "react-i18next";
import { SendQueuedEmails } from "../../api/Notification";
import { UserStore } from "../../store/user";

interface IProps {
  user: UserStore
  history: any;
  t: any;
}

interface IState {
  loading: boolean;
  organizations: OrganizationView[];
  showFilters: boolean;
  items: OrganizationView[];
  columns: IColumn[];
  count: number;
  offset: number;
}

@inject("user")
@observer
class Organizations extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    const { t } = this.props

    this.state = {
      count: 10,
      offset: 0,
      items: [],
      organizations: [],
      loading: false,
      showFilters: false,
      columns: [
        {
          key: "name",
          name: t("Name"),
          onRender: (item: OrganizationView) => item.name,
          minWidth: 80,
          maxWidth: 200,
        },
        {
          key: "NumberOfUsers",
          name: t("Number of companies"),
          onRender: (item: OrganizationView) => item.numberofUsers,
          minWidth: 80,
          maxWidth: 200,
        },
        {
          key: "options",
          name: t("Options"),
          onRender: (item: OrganizationView) => (
            <Flex gap="gap.medium">
              <TooltipHost content={t("Companies")}>
                <Link to={`/organizations/${item.id}/companies`}>
                  <div>
                    <Button size="small" icon={<TableIcon />} iconOnly />
                  </div>
                </Link>
              </TooltipHost>
              <TooltipHost content={t("Archive")}>
                <Button size="small" icon={<TrashCanIcon />} iconOnly onClick={async () => {
                  if (window.confirm(t("Are you sure you want to delete this company?"))) {
                    await deleteOrganization(item.id);
                    this.fetchData();
                  }
                }} />
              </TooltipHost>
            </Flex>
          ),
          minWidth: 80,
          maxWidth: 200,
        },
      ]
    };

    this.state.columns.forEach((c) => c.onColumnClick = (_: any, column: IColumn) => {
      const newColumns: IColumn[] = this.state.columns.slice();
      const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newItems = copyAndSort(this.state.items, currColumn.key, currColumn.isSortedDescending);
      this.setState({ columns: newColumns, items: newItems });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });
    const organizations = await GetOrganizations();
    this.setState({
      loading: false,
      organizations: organizations.payload,
      items: organizations.payload
    });
  };

  filterData(callbackfn: (value: OrganizationView) => string, value: string) {
    this.setState(state => {
      return {
        items: state.organizations.filter(u => callbackfn(u).toLowerCase().includes(value.toLowerCase()))
      }
    });
  }

  render() {
    const { t, user } = this.props;

    const {
      loading,
      showFilters,
      items,
      columns,
      count,
      offset
    } = this.state;

    return (
      <Flex column>
        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
          <div
          className="mainHeader" style={{color: user.companyPrimaryColor?? "#005bab"}}
          >{t("Organizations")}</div>
        </Flex>
        <Divider/>
        <CommandBar
          items={[
            {
              key: "search",
              onRender: () => (
                <Flex vAlign="center" styles={{ paddingRight: "10px" }}>
                  <SearchBox
                    placeholder={t("Search")}
                    iconProps={{styles: {root: {color: user.companyPrimaryColor}}}}
                    onChange={(ev, input) => this.filterData(x => x.name ?? "", input ?? "")}
                  />
                </Flex>
              ),
            },
            {
              key: "filters",
              iconProps: { iconName: "filter" },
              buttonStyles: {
                icon: { color: user.companyPrimaryColor },
                iconHovered: { color: shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) },
              },
              onClick: () => {
                this.setState(state => ({ showFilters: !state.showFilters }));
              },
            },
          ]}
          farItems={[
            {
              key: "add.organization",
              name: t("Add Organization"),
              iconProps: { iconName: "add" },
              buttonStyles: {
                icon: { color: user.companyPrimaryColor },
                iconHovered: { color: shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) },
              },
              onClick: () => {
                this.props.history.push("/organizations/add");
              },
            },
            {
              key: "sendEmails",
              name: t("Send Queued Emails"),
              iconProps: { iconName: "send" },
              buttonStyles: {
                icon: { color: user.companyPrimaryColor },
                iconHovered: { color: shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) },
              },
              onClick: () => {
                SendQueuedEmails()
              },
            },
          ]} />
        <Divider />
        <FlexItem grow>
          <Flex column>
            {showFilters && (<div />)}

            <ShimmeredDetailsList
              selectionMode={0}
              detailsListStyles={{ root: { width: "100vw", overflowY: "auto" } }}
              enableShimmer={loading}
              items={items.slice(offset, Math.min(offset + count, items.length))}
              columns={columns} />
            <div style={{ padding: "1em" }}>

              <Pagination
                currentPage={offset / count + 1}
                total={items.length}
                limit={count}
                onPageChange={(page) => this.setState({ offset: ((page ?? 1) - 1) * count })}>
                {({ pages, currentPage, totalPages, getPageItemProps }) => (
                  <div>
                    {pages.map((page) => (
                      <Button iconOnly
                        primary={currentPage === page}
                        {...getPageItemProps({
                          pageValue: page,
                          total: totalPages,
                          onClick: () => this.setState({ offset: ((page ?? 1) - 1) * count }),
                        }) as any}>
                        {page}
                      </Button>
                    ))}
                  </div>
                )}
              </Pagination>
            </div>
          </Flex>
        </FlexItem>
      </Flex>
    );
  }
}

export default withRouter(withTranslation()(Organizations as any) as any);
