// ..\BluAttendance.Core\InputModels\ActivateTrialCompanyInput.cs
export interface ActivateTrialCompanyInput {
    companyId: number;
    organizationId: number;
}

// ..\BluAttendance.Core\InputModels\AllowedLeaveInput.cs
export interface AllowedLeaveInput {
    leaveTypeId: number;
    userId: string;
    allowedCount: number;
}

// ..\BluAttendance.Core\InputModels\BreakInInput.cs
export interface BreakInInput {
    breakInTime: Date;
    breakInTimeUtc: Date;
    breakInLatitude?: number;
    breakInLongitude?: number;
    breakInClientType: ClientType;
    breakInAddress: string;
    breakInCountry: string;
    breakInComments: string;
    breakInImageUrl: string;
}

// ..\BluAttendance.Core\InputModels\BreakInput.cs
export interface BreakInput {
    shiftId: number;
    breakInTime: Date;
    breakInTimeUtc: Date;
    breakInLatitude?: number;
    breakInLongitude?: number;
    breakInClientType: ClientType;
    breakInComments: string;
    breakInImageUrl: string;
    breakInAddress: string;
    breakInCountry: string;
    breakOutTime?: Date;
    breakOutTimeUtc?: Date;
    breakOutLatitude?: number;
    breakOutLongitude?: number;
    breakOutClientType: ClientType;
}

// ..\BluAttendance.Core\InputModels\BreakOutInput.cs
export interface BreakOutInput {
    breakOutTime?: Date;
    breakOutTimeUtc?: Date;
    breakOutLatitude?: number;
    breakOutLongitude?: number;
    breakOutClientType: ClientType;
    breakOutComments: string;
    breakOutImageUrl: string;
    breakOutAddress: string;
    breakOutCountry: string;
}

// ..\BluAttendance.Core\InputModels\ChangePasswordInput.cs
export interface ChangePasswordInput {
    oldPassword: string;
    newPassword: string;
    newPasswordVerify: string;
}

// ..\BluAttendance.Core\InputModels\ClockingInput.cs
export interface ClockingInput {
    latitude?: number;
    longitude?: number;
    fullAddress: string;
    time: Date;
    hoursOffset: number;
    userId: string;
    comments: string;
    clientType: ClientType;
    image: File | null;
}

// ..\BluAttendance.Core\InputModels\ClockingPaginationInput.cs
export interface ClockingPaginationInput {
    from: Date;
    to: Date;
}

// ..\BluAttendance.Core\InputModels\ClockingsReportInput.cs
export interface ClockingsReportInput {
    userId: string;
    from: Date;
    to: Date;
    userIds: string[];
    departmentIds: number[];
    countryIds: number[];
    branchIds: number[];
    searchMethod: string;
}

// ..\BluAttendance.Core\InputModels\ClockInInput.cs
export interface ClockInInput {
    clockInTime: Date;
    clockInTimeUtc: Date;
    clockInLongitude?: number;
    clockInLatitude?: number;
    clockInClientType: ClientType;
    clockInComments: string;
    clockInAddress: string;
    clockInCountry: string;
    clockInImageUrl: string;
}

// ..\BluAttendance.Core\InputModels\ClockOutInput.cs
export interface ClockOutInput {
    clockOutTime?: Date;
    clockOutTimeUtc?: Date;
    clockOutLongitude?: number;
    clockOutLatitude?: number;
    clockOutClientType: ClientType;
    clockOutComments: string;
    clockOutImageUrl: string;
    clockOutAddress: string;
    clockOutCountry: string;
}

// ..\BluAttendance.Core\InputModels\CompanyInput.cs
export interface CompanyInput {
    name: string;
    image: File | null;
    planId: number;
    rolesMaxUsers: any;
    countryId?: number;
    address: string;
    phoneNumber: string;
    email: string;
    websiteLink: string;
    organizationId: number;
    primaryColor: string;
    timeZone: string;
    locales: string;
}

// ..\BluAttendance.Core\InputModels\CompanyRulesInput.cs
export interface CompanyRulesInput {
    id: number;
    attendanceImageConfig: AttendanceImageConfig;
    faceRecognitionConfig: FaceRecognitionConfig;
    shiftFlowRuleType: ShiftFlowRuleType;
    timesheetCreationDay: number;
    hideClockingImagesFromTimesheet: boolean;
    disableAllNotifications: boolean;
    faceRecognitionEnabled: boolean;
    leavesApprovalLimitedToSupervisor: boolean;
    shiftScheduleType: ShiftScheduleType;
    triggerOvertimeVerificationOn: TriggerOvertimeVerificationOn;
    breakConfig: BreakConfig;
    companyId: number;
}

// ..\BluAttendance.Core\InputModels\EarlyClockOutNotificationTemplateModel.cs
export interface EarlyClockOutNotificationTemplateModel {
    timesheetUrl: string;
    userId: string;
    email: string;
    fullName: string;
    date: string;
    time: string;
}

// ..\BluAttendance.Core\InputModels\EditClockingInput.cs
export interface EditClockingInput {
    latitude?: number;
    longitude?: number;
    fullAddress: string;
    time: string;
    userId: string;
    comments: string;
    clientType: ClientType;
    image: File | null;
    type: ClockingType;
}

// ..\BluAttendance.Core\InputModels\ExtendTrialInput.cs
export interface ExtendTrialInput {
    companyId: number;
    days: number;
}

// ..\BluAttendance.Core\InputModels\HolidayConfigInput.cs
export interface HolidayConfigInput {
    id: number;
    enabled: boolean;
    date: string;
    holidayId: number;
    holiday: Holiday;
    companyId: number;
    company: Company;
    countryId: number;
    country: Country;
}

// ..\BluAttendance.Core\InputModels\HolidayInput.cs
export interface HolidayInput {
    id: number;
    name: string;
    defaultDate: string;
    description: string;
}

// ..\BluAttendance.Core\InputModels\HolidaysConfigInput.cs
export interface HolidaysConfigInput {
    companyId: number;
    holidayConfigInputList: HolidayConfigInput[];
}

// ..\BluAttendance.Core\InputModels\HolidaysInput.cs
export interface HolidaysInput {
    companyId: number;
    holidayInputList: HolidayInput[];
}

// ..\BluAttendance.Core\InputModels\ImageInput.cs
export interface ImageInput {
    image: File | null;
}

// ..\BluAttendance.Core\InputModels\InputRecoveryTemplateModel.cs
export interface InputRecoveryTemplateModel {
    basUrl: string;
    token: string;
    email: string;
}

// ..\BluAttendance.Core\InputModels\LabeledFaceDescriptorsInput.cs
export interface LabeledFaceDescriptorsInput {
    labeledFaceDescriptors: string;
    imageId: number;
}

// ..\BluAttendance.Core\InputModels\LeaveAttachmentsInput.cs
export interface LeaveAttachmentsInput {
    leaveRequestId: number;
    attachments: File[];
}

// ..\BluAttendance.Core\InputModels\LeaveConfigInput.cs
export interface LeaveConfigInput {
    id: number;
    enabled: boolean;
    quota: number;
    quotaUnit: QuotaUnit;
    balance: number;
    quotaType: QuotaType;
    isCummulative: boolean;
    annuallyResets: boolean;
    includeNonWorkingrDays: boolean;
    leaveOverlapsOtherLeaves: boolean;
    inheritedFromCompany: boolean;
    leaveTypeId: number;
    attachmentRequired: boolean;
    leaveType: LeaveType;
    companyId: number;
    company: Company;
    userId: string;
    user: User;
    countryId: number;
    country: Country;
}

// ..\BluAttendance.Core\InputModels\LeaveRequestApprovalInput.cs
export interface LeaveRequestApprovalInput {
    id: number;
    isApproved: boolean;
    supervisorComment: string;
}

// ..\BluAttendance.Core\InputModels\LeaveRequestInput.cs
export interface LeaveRequestInput {
    leaveTypeId: number;
    userId: string;
    startDate: string;
    fromHalfDay: boolean;
    fromHalfDayPart: HalfDayPart;
    endDate: string;
    toHalfDay: boolean;
    toHalfDayPart: HalfDayPart;
    comment: string;
}

// ..\BluAttendance.Core\InputModels\LeaveRequestResponseTemplateModel.cs
export interface LeaveRequestResponseTemplateModel {
    baseUrl: string;
    userId: string;
    email: string;
    leaveType: string;
    comment: string;
    fromDate: string;
    toDate: string;
    fullName: string;
    response: string;
    supervisorFullName: string;
    supervisorComment: string;
}

// ..\BluAttendance.Core\InputModels\LeaveRequestTemplateModel.cs
export interface LeaveRequestTemplateModel {
    baseUrl: string;
    userId: string;
    email: string;
    leaveType: string;
    comment: string;
    fromDate: string;
    fromHalfDay: boolean;
    fromHalfDayPart: string;
    toDate: string;
    toHalfDay: boolean;
    toHalfDayPart: string;
    fullName: string;
}

// ..\BluAttendance.Core\InputModels\LeavesBalanceReportInput.cs
export interface LeavesBalanceReportInput {
    userId: string;
    userIds: string[];
    leaveTypeIds: number[];
    showSupervisor: boolean;
    mergeSupervisorCells: boolean;
}

// ..\BluAttendance.Core\InputModels\LeavesConfigInput.cs
export interface LeavesConfigInput {
    companyId: number;
    userId: string;
    leaveConfigInputList: LeaveConfigInput[];
}

// ..\BluAttendance.Core\InputModels\LeavesReportInput.cs
export interface LeavesReportInput {
    userId: string;
    from: Date;
    to: Date;
    userIds: string[];
    departmentIds: number[];
    countryIds: number[];
    branchIds: number[];
    searchMethod: string;
}

// ..\BluAttendance.Core\InputModels\LogAttacmentInput.cs
export interface LogAttachmentInput {
    logId: number;
    attachments: File[];
}

// ..\BluAttendance.Core\InputModels\LogDetailsInput.cs
export interface LogDetailsInput {
    message: string;
    logType: LogType;
    logSource: LogSource;
    clientUserAgent: string;
    attachments: File[];
}

// ..\BluAttendance.Core\InputModels\LoginInput.cs
export interface LoginInput {
    email: string;
    password: string;
    fcmToken: string;
}

// ..\BluAttendance.Core\InputModels\LogoutInput.cs
export interface LogoutInput {
    removeFcmToken: boolean;
}

// ..\BluAttendance.Core\InputModels\NotificationInput.cs
export interface NotificationInput {
    title: string;
    titleParams: string;
    body: string;
    bodyParams: string;
    emailTemplate: string;
    linkId: string;
    screen: string;
    request: string;
    userId: string;
    from: string;
    to: string[];
    cc: string[];
    excludeToAndCc: string[];
    notificationTypeId: number;
    shiftId: number;
}

// ..\BluAttendance.Core\InputModels\OnDemandNotificationInput.cs
export interface OnDemandNotificationInput {
    title: string;
    body: string;
    userId: string;
    to: string[];
    cc: string[];
    image: File | null;
    sendEmail: boolean;
}

// ..\BluAttendance.Core\InputModels\OrganizationInput.cs
export interface OrganizationInput {
    name: string;
}

// ..\BluAttendance.Core\InputModels\OvertimeApprovalRequestTemplateModel.cs
export interface OvertimeApprovalRequestTemplateModel {
    url: string;
    userId: string;
    email: string;
    fullName: string;
    date: string;
    time: string;
}

// ..\BluAttendance.Core\InputModels\OvertimeApprovalResponseTemplateModel.cs
export interface OvertimeApprovalResponseTemplateModel {
    url: string;
    userId: string;
    email: string;
    fullName: string;
    date: string;
    time: string;
    response: string;
    supervisorFullName: string;
    supervisorComment: string;
}

// ..\BluAttendance.Core\InputModels\OvertimeReportInput.cs
export interface OvertimeReportInput {
    userId: string;
    from: Date;
    to: Date;
    userIds: string[];
}

// ..\BluAttendance.Core\InputModels\OvertimeShiftApprovalInput.cs
export interface OvertimeShiftApprovalInput {
    shiftId: number;
    isApproved: boolean;
}

// ..\BluAttendance.Core\InputModels\PaginationFilter.cs
export interface PaginationFilter {
    pageNumber: number;
    pageSize: number;
}

// ..\BluAttendance.Core\InputModels\PasswordRecoveryInput.cs
export interface PasswordRecoveryInput {
    email: string;
}

// ..\BluAttendance.Core\InputModels\PasswordResetInput.cs
export interface PasswordResetInput extends PasswordRecoveryInput {
    token: string;
    newPassword: string;
    newPasswordVerify: string;
}

// ..\BluAttendance.Core\InputModels\PoolInput.cs
export interface PoolInput {
    id: string;
    name: string;
}

// ..\BluAttendance.Core\InputModels\ProfileInput.cs
export interface ProfileInput {
    image: File | null;
    employeeId: string;
    title: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    userName: string;
    linkedInUrl: string;
    birthDate: Date;
    role: string;
    countryId: number;
    companyId: number;
    branchId?: number;
    departmentId?: number;
    phoneNumber: string;
    timeZone: string;
    fiscalYearStartingMonth: number;
    supervisorIds: string[];
}

// ..\BluAttendance.Core\InputModels\ReportBugTemplate.cs
export interface ReportBugTemplate {
    message: string;
    username: string;
    email: string;
    attachments: LogAttachment[];
}

// ..\BluAttendance.Core\InputModels\ReportCreationTemplateModel.cs
export interface ReportCreationTemplateModel {
    baseUrl: string;
    startDate: string;
    endDate: string;
    reportName: string;
    companyName: string;
}

// ..\BluAttendance.Core\InputModels\ReportSchedulingConfigInput.cs
export interface ReportSchedulingConfigInput {
    reportName: string;
    companyId: number;
    usersTo: string;
    usersCc: string;
    cronExpression: string;
    daysBeforeDate: number;
    daysAfterDate: number;
    parameters: string;
}

// ..\BluAttendance.Core\InputModels\RequestInput.cs
export interface RequestInput {
    title: string;
    description: string;
    assignedUsers: string[];
    requestedById: string;
    expectedClosedDate: Date;
    statusId: number;
    priorityId: number;
}

// ..\BluAttendance.Core\InputModels\ScheduledShiftInput.cs
export interface ScheduledShiftInput {
    id: number;
    startTime: string;
    endTime: string;
    userId: string;
}

// ..\BluAttendance.Core\InputModels\ShiftInput.cs
export interface ShiftInput {
    clockInTime: Date;
    clockInTimeUtc: Date;
    clockInLongitude?: number;
    clockInLatitude?: number;
    clockInClientType: ClientType;
    clockInImageUrl: string;
    clockInAddress: string;
    clockInCountry: string;
    clockOutTime?: Date;
    clockOutTimeUtc?: Date;
    clockOutLongitude?: number;
    clockOutLatitude?: number;
    clockOutClientType: ClientType;
    clockOutImageUrl: string;
    clockOutAddress: string;
    clockOutCountry: string;
    clockInComments: string;
    clockOutComments: string;
    userId: string;
}

// ..\BluAttendance.Core\InputModels\ShiftsReportInput.cs
export interface ShiftsReportInput {
    userId: string;
    from: Date;
    to: Date;
    userIds: string[];
    hideBreaks: boolean;
    departmentIds: number[];
    countryIds: number[];
    branchIds: number[];
    searchMethod: string;
}

// ..\BluAttendance.Core\InputModels\SignUpInput.cs
export interface SignUpInput {
    employeeId: string;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: string;
    linkedInUrl: string;
    birthDate?: Date;
    companyId: number;
    branchId: number;
    departmentId: number;
    countryId: number;
    phoneNumber: string;
}

// ..\BluAttendance.Core\InputModels\SignupTrialCompanyInput.cs
export interface SignupTrialCompanyInput {
    name: string;
    countryId?: number;
    phoneNumber: string;
    industry: string;
    size: string;
    reference: string;
}

// ..\BluAttendance.Core\InputModels\SignupTrialInput.cs
export interface SignUpTrialInput {
    company: SignupTrialCompanyInput;
    user: SignupTrialUserInput;
}

// ..\BluAttendance.Core\InputModels\SignupTrialUserInput.cs
export interface SignupTrialUserInput {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    countryId: number;
    phoneNumber: string;
}

// ..\BluAttendance.Core\InputModels\TimesheetApprovalInput.cs
export interface TimesheetApprovalInput {
    timesheetId: number;
    userId: string;
    timesheetStatusId: number;
    comment: string;
}

// ..\BluAttendance.Core\InputModels\TimesheetCreationInput.cs
export interface TimesheetCreationInput {
    companyId: number;
    timesheetCreationDay: number;
    timeZoneId: string;
}

// ..\BluAttendance.Core\InputModels\TimesheetCreationTemplateModel.cs
export interface TimesheetCreationTemplateModel {
    baseUrl: string;
    supervisorFirstName: string;
    timesheetsList: TimeSheetListItem[];
}

// ..\BluAttendance.Core\InputModels\TimesheetCreationTemplateModel.cs
export interface TimeSheetListItem {
    userFullName: string;
    timesheetLink: string;
}

// ..\BluAttendance.Core\InputModels\TimeSheetInput.cs
export interface TimeSheetInput {
    from: Date;
    to: Date;
    userId: string;
}

// ..\BluAttendance.Core\InputModels\TypeInput.cs
export interface TypeInput {
    name: string;
}

// ..\BluAttendance.Core\InputModels\UpdateCustomStatusInput.cs
export interface UpdateCustomStatusInput {
    currentCustomStatus: string;
}

// ..\BluAttendance.Core\InputModels\UserHolidayInput.cs
export interface UserHolidayInput {
    userId: string;
    holidayId: number[];
}

// ..\BluAttendance.Core\InputModels\UserRulesInput.cs
export interface UserRulesInput {
    attendanceImageConfig: UserAttendanceImageConfig;
    faceRecognitionConfig: UserFaceRecognitionConfig;
    workplaceConfig: WorkplaceConfig;
    shiftScheduleType: UserShiftScheduleType;
    userId: string;
}

// ..\BluAttendance.Core\InputModels\UsersMissedClockInNotificationTemplateModel.cs
export interface UsersMissedClockInNotificationTemplateModel {
    userMissedClockInListItems: UserMissedClockInListItem[];
}

// ..\BluAttendance.Core\InputModels\UsersMissedClockInNotificationTemplateModel.cs
export interface UserMissedClockInListItem {
    userFullName: string;
    timesheetLink: string;
}

// ..\BluAttendance.Core\InputModels\UsersStatusesReportInput.cs
export interface UsersStatusesReportInput {
    userId: string;
    from: Date;
    to: Date;
    userIds: string[];
    departmentIds: number[];
    countryIds: number[];
    branchIds: number[];
    searchMethod: string;
}

// ..\BluAttendance.Core\InputModels\WeekScheduleInput.cs
export interface WeekScheduleInput {
    id: number;
    companyId: number;
    userId: string;
    weekStartEndHoursAndMinutes: number[][];
}

// ..\BluAttendance.Core\InputModels\WelcomeTemplateModel.cs
export interface WelcomeTemplateModel {
    baseUrl: string;
    companyName: string;
    token: string;
    email: string;
    password: string;
}

// ..\BluAttendance.Core\ViewModels\AutoBreakOutView.cs
export interface AutoBreakOutView {
    userId: string;
    shiftId: number;
    email: string;
    fullName: string;
    firstName: string;
    lastName: string;
    baseUrl: string;
    timezoneId: string;
}

// ..\BluAttendance.Core\ViewModels\BranchView.cs
export interface BranchView {
    id: number;
    name: string;
}

// ..\BluAttendance.Core\ViewModels\BreakView.cs
export interface BreakView {
    id: number;
    shiftId: number;
    shift: Shift;
    breakInTime: Date;
    breakInTimeUtc: Date;
    breakInLatitude?: number;
    breakInLongitude?: number;
    breakInClientType: ClientType;
    breakInImageUrl: string;
    breakInComments: string;
    breakInAddress: string;
    breakInCountry: string;
    breakOutTime?: Date;
    breakOutTimeUtc?: Date;
    breakOutLatitude?: number;
    breakOutLongitude?: number;
    breakOutClientType: ClientType;
    breakOutImageUrl: string;
    breakOutComments: string;
    breakOutAddress: string;
    breakOutCountry: string;
    createdOn: Date;
    updatedOn?: Date;
    createdById: string;
    updatedById: string;
    isArchived: boolean;
    isEdited: boolean;
    hasEnded: boolean;
    duration: string;
}

// ..\BluAttendance.Core\ViewModels\ClockingNotificationView.cs
export interface ClockingNotificationView {
    userId: string;
    companyId: number;
    countryId: number;
    email: string;
    fullName: string;
    firstName: string;
    lastName: string;
    baseUrl: string;
    timezoneId: string;
    type: ClockingType;
    toHalfDay: boolean;
}

// ..\BluAttendance.Core\ViewModels\ClockingPaginationView.cs
export interface ClockingPaginationView {
    from: Date;
    to: Date;
    results: DaySummaryView[];
    totalWorkTime: string;
}

// ..\BluAttendance.Core\ViewModels\ClockingsPerUserPerDayView.cs
export interface ClockingsPerUserPerDayView {
    id: number;
    time: Date;
    timeUtc: Date;
    userId: string;
    userFullName: string;
    type: ClockingType;
    comments: string;
    latitude?: number;
    longitude?: number;
    createdOn: Date;
    updatedOn?: Date;
    createdById: string;
    updatedById: string;
    isArchived: boolean;
    clientType: ClientType;
    address: string;
    imageUrl: string;
    userTitle: string;
}

// ..\BluAttendance.Core\ViewModels\ClockingStatusView.cs
export interface ClockingStatusView {
    allowedActions: ClockingType[];
    currentShift: ShiftView;
}

// ..\BluAttendance.Core\ViewModels\ClockingView.cs
export interface ClockingView {
    id: number;
    time: Date;
    timeUtc: Date;
    userId: string;
    type: ClockingType;
    comments: string;
    latitude?: number;
    longitude?: number;
    createdOn: Date;
    updatedOn?: Date;
    createdById: string;
    updatedById: string;
    isArchived: boolean;
    clientType: ClientType;
    address: string;
    imageUrl: string;
    isEdited: boolean;
}

// ..\BluAttendance.Core\ViewModels\CompanyLeaveView.cs
export interface CompanyLeaveView {
    id: number;
    quota: number;
    quotaUnit: QuotaUnit;
    quotaType: QuotaType;
    isCummulative: boolean;
    annuallyResets: boolean;
    includeNonWorkingrDays: boolean;
    leaveOverlapsOtherLeaves: boolean;
    leaveTypeId: number;
    attachmentRequired: boolean;
    leaveType: LeaveType;
    companyId: number;
    countryId: number;
}

// ..\BluAttendance.Core\ViewModels\CompanyRulesView.cs
export interface CompanyRulesView {
    id: number;
    attendanceImageConfig: AttendanceImageConfig;
    faceRecognitionConfig: FaceRecognitionConfig;
    shiftFlowRuleType: ShiftFlowRuleType;
    shiftScheduleType: ShiftScheduleType;
    triggerOvertimeVerificationOn: TriggerOvertimeVerificationOn;
    timesheetCreationDay: number;
    hideClockingImagesFromTimesheet: boolean;
    disableAllNotifications: boolean;
    faceRecognitionEnabled: boolean;
    leavesApprovalLimitedToSupervisor: boolean;
    breakConfig: BreakConfig;
    companyId: number;
}

// ..\BluAttendance.Core\ViewModels\CompanyView.cs
export interface CompanyView {
    id: number;
    name: string;
    numberofUsers: number;
    licenseExpireOn: Date;
    plan: string;
    imageUrl: string;
    fax: string;
    address: string;
    email: string;
    phoneNumber: string;
    websiteLink: string;
    rolesMaxUsers: any;
    rolesUsersAvailable: any;
    primaryColor: string;
    attendanceImageConfig: AttendanceImageConfig;
    faceRecognitionConfig: FaceRecognitionConfig;
    shiftFlowRuleType: ShiftFlowRuleType;
    timeZone: string;
    defaultStartTime: string;
    defaultEndTime: string;
    timesheetCreationDay: number;
    status: string;
    trialDays: number;
    hideClockingImagesFromTimesheet: boolean;
    disableAllNotifications: boolean;
    faceRecognitionEnabled: boolean;
    locales: string;
    country: CountryView;
    companyRules: CompanyRulesView;
    organization: OrganizationView;
    subscriptionPlanView: SubscriptionPlanView;
    workplaces: WorkplaceModel[];
}

// ..\BluAttendance.Core\ViewModels\CountryView.cs
export interface CountryView {
    id: number;
    name: string;
    isGcc: boolean;
    iso: string;
    nicename: string;
}

// ..\BluAttendance.Core\ViewModels\DaySummaryView.cs
export interface DaySummaryView {
    shifts: ShiftView[];
    workingTime: string;
    breaksTime: string;
    dayStatus: DayStatus;
    shiftScheduleType: ShiftScheduleType;
    scheduledShifts: ScheduledShiftView[];
    dayStartEndHoursAndMinutes: number[];
}

// ..\BluAttendance.Core\ViewModels\DepartmentView.cs
export interface DepartmentView {
    id: number;
    name: string;
}

// ..\BluAttendance.Core\ViewModels\EmailChangedView.cs
export interface EmailChangedView {
    firstName: string;
}

// ..\BluAttendance.Core\ViewModels\EndShiftView.cs
export interface EndShiftView {
    userId: string;
    email: string;
    fullName: string;
    firstName: string;
    lastName: string;
    baseUrl: string;
    timezoneId: string;
}

// ..\BluAttendance.Core\ViewModels\Error.cs
export interface Error {
    code: string;
    description: string;
    path: string;
    time?: Date;
}

// ..\BluAttendance.Core\ViewModels\FaceRecognitionEmail.cs
export interface FaceRecognitionEmail {
    username: string;
}

// ..\BluAttendance.Core\ViewModels\FaceRecognitionResultView.cs
export interface FaceRecognitionResultView {
    status: number;
    payload: boolean;
    message: string;
}

// ..\BluAttendance.Core\ViewModels\GenericRequestsView.cs
export interface GenericRequestsView {
    list: GenericRequestView[];
}

// ..\BluAttendance.Core\ViewModels\GenericRequestView.cs
export interface GenericRequestView {
    requestType: GenericRequestType;
    user: UserViewSimple;
    id: number;
    userId: string;
    leaveType: string;
    startDate: Date;
    fromHalfDay?: boolean;
    endDate: Date;
    toHalfDay?: boolean;
    lastUpdate: Date;
    status: LeaveRequestStatus;
    comments: string;
    supervisorName: string;
    supervisorComments: string;
    quotaUnit: QuotaUnit;
    attachmentCount: number;
    attachmentRequired: boolean;
    numberOfDays: number;
}

// ..\BluAttendance.Core\ViewModels\GenericRequestView.cs
export enum GenericRequestType {
    LeaveRequest = 'LeaveRequest',
}

// ..\BluAttendance.Core\ViewModels\HolidayConfigView.cs
export interface HolidayConfigView {
    id: number;
    enabled: boolean;
    date: Date;
    holidayId: number;
    holiday: Holiday;
    companyId: number;
    company: Company;
    countryId: number;
    country: Country;
}

// ..\BluAttendance.Core\ViewModels\HolidayNotificationView.cs
export interface HolidayNotificationView {
    companyHolidayId: number;
    holidayName: string;
    date: Date;
    dateString: string;
    baseUrl: string;
    timezoneId: string;
    companyId: number;
    countryId: number;
    currentUserFirstName: string;
}

// ..\BluAttendance.Core\ViewModels\HolidaysConfigView.cs
export interface HolidaysConfigView {
    holidayConfigInputList: HolidayConfigView[];
    holidaysCount: number;
    countriesCount: number;
}

// ..\BluAttendance.Core\ViewModels\HolidayView.cs
export interface HolidayView {
    id: number;
    name: string;
    description: string;
    date: Date;
}

// ..\BluAttendance.Core\ViewModels\InBreakVerificationView.cs
export interface InBreakVerificationView {
    userId: string;
    shiftId: number;
    email: string;
    fullName: string;
    firstName: string;
    lastName: string;
    baseUrl: string;
    timezoneId: string;
}

// ..\BluAttendance.Core\ViewModels\LeaveBalanceView.cs
export interface LeaveBalanceView {
    leaveTypeId: number;
    leaveType: string;
    quota: number;
    quotaUnit: QuotaUnit;
    balance: number;
}

// ..\BluAttendance.Core\ViewModels\LeaveRequestAttachmentView.cs
export interface LeaveRequestAttachmentView {
    id: number;
    name: string;
    extention: string;
    byteLength: number;
    path: string;
    createdOn: Date;
    userLeaveRequestId: number;
}

// ..\BluAttendance.Core\ViewModels\LeaveRequestsView.cs
export interface LeaveRequestsView {
    isSupervisor: boolean;
    leaveRequests: LeaveRequestView[];
}

// ..\BluAttendance.Core\ViewModels\LeaveRequestView.cs
export interface LeaveRequestView {
    id: number;
    userId: string;
    user: UserViewSimple;
    leaveType: string;
    startDate: Date;
    fromHalfDay?: boolean;
    fromHalfDayPart: HalfDayPart;
    endDate: Date;
    toHalfDay?: boolean;
    toHalfDayPart: HalfDayPart;
    lastUpdate: Date;
    status: LeaveRequestStatus;
    comments: string;
    supervisorName: string;
    supervisorComments: string;
    quotaUnit: QuotaUnit;
    attachmentCount: number;
    attachmentRequired: boolean;
    numberOfDays: number;
}

// ..\BluAttendance.Core\ViewModels\LeavesBalanceReportView.cs
export interface LeavesBalanceReportView {
    user: UserView;
    leaveType: string;
    leaveTypeId: number;
    quota: number;
    balance: number;
    leavesRequested: number;
    supervisorUsernames: string[];
}

// ..\BluAttendance.Core\ViewModels\LeavesConfigView.cs
export interface LeavesConfigView {
    leavesConfig: LeaveConfigInput[];
    leaveTypesCount: number;
    countriesCount: number;
}

// ..\BluAttendance.Core\ViewModels\LeavesReportView.cs
export interface LeavesReportView {
    user: UserView;
    comments: string;
    leaveType: string;
    isApproved: boolean;
    startDate: Date;
    endDate: Date;
    numberOfDays: number;
    supervisor: UserView;
    supervisorComments: string;
    attachementsCount: number;
    leaveRequestStatus: LeaveRequestStatus;
    balanceDeducted: number;
    quotaUnit: QuotaUnit;
    leaveTypeId: number;
    status: LeaveRequestStatus;
}

// ..\BluAttendance.Core\ViewModels\LeaveTypeView.cs
export interface LeaveTypeView {
    id: number;
    name: string;
    companyId: number;
    quotaUnit: QuotaUnit;
    attachmentRequired: boolean;
}

// ..\BluAttendance.Core\ViewModels\LogAttachmentView.cs
export interface LogAttachmentView {
    id: number;
    name: string;
    extention: string;
    byteLength: number;
    path: string;
    createdOn: Date;
    logId: number;
}

// ..\BluAttendance.Core\ViewModels\LoginResponse.cs
export interface LoginResponse {
    accessToken: string;
    accessTokenExpiresIn: number;
    refreshTokenExpiresIn: number;
    firstLogin: boolean;
}

// ..\BluAttendance.Core\ViewModels\LogView.cs
export interface LogView {
    id: number;
    message: string;
    logType: string;
}

// ..\BluAttendance.Core\ViewModels\NotificationsResponseView.cs
export interface NotificationsResponseView {
    notificationsTotalCount: number;
    notificationsTotalUnreadCount: number;
    notificationsList: Notification[];
}

// ..\BluAttendance.Core\ViewModels\OrganizationView.cs
export interface OrganizationView {
    id: number;
    name: string;
    numberofUsers: number;
}

// ..\BluAttendance.Core\ViewModels\OvertimeReportView.cs
export interface OvertimeReportView {
    user: UserView;
    date: Date;
    approvedOvertimeHours: number;
    supervisorNames: string[];
}

// ..\BluAttendance.Core\ViewModels\OvertimeVerificationView.cs
export interface OvertimeVerificationView {
    userId: string;
    shiftId: number;
    email: string;
    fullName: string;
    firstName: string;
    lastName: string;
    baseUrl: string;
    timezoneId: string;
}

// ..\BluAttendance.Core\ViewModels\PaginationView.cs
export interface PaginationView<T> {
    results: T[];
    offset: number;
    count: number;
}

// ..\BluAttendance.Core\ViewModels\PoolView.cs
export interface PoolView {
    id: string;
    name: string;
}

// ..\BluAttendance.Core\ViewModels\ReportSchedulerView.cs
export interface ReportSchedulerView {
    companyId: number;
    reportName: string;
    subject: string;
    template: string;
    model: ReportCreationTemplateModel;
    fromDate: Date;
    toDate: Date;
    usersTo: string;
    usersCc: string;
    timezoneId: string;
}

// ..\BluAttendance.Core\ViewModels\RequestModel.cs
export interface RequestModel {
    id: number;
    title: string;
    description: string;
    status: string;
    statusId: number;
    priority: string;
    priorityId?: number;
    createdDate: Date;
    expectedDate: Date;
    closedDate: Date;
    assignedUsers: string[];
}

// ..\BluAttendance.Core\ViewModels\Result.cs
export interface Result<TPayload> {
    payload: TPayload;
    status: number;
    errors: Error[];
}

// ..\BluAttendance.Core\ViewModels\ScheduledShiftView.cs
export interface ScheduledShiftView {
    id: number;
    startTime: Date;
    endTime: Date;
    userId: string;
}

// ..\BluAttendance.Core\ViewModels\ShiftHistoryView.cs
export interface ShiftHistoryView {
    id: number;
    shiftId?: number;
    breakId?: number;
    shiftUserId: string;
    updatedById: string;
    updatedByFullName: string;
    clockingType: ClockingType;
    oldClockTime: Date;
    newClockTime: Date;
}

// ..\BluAttendance.Core\ViewModels\ShiftReportView.cs
export interface ShiftReportView {
    id: number;
    userId: string;
    user: User;
    clockInTime: Date;
    clockInTimeUtc: Date;
    clockInLongitude?: number;
    clockInLatitude?: number;
    clockInClientType: ClientType;
    clockInImageUrl: string;
    clockInComments: string;
    clockInAddress: string;
    clockOutTime?: Date;
    clockOutTimeUtc?: Date;
    clockOutLongitude?: number;
    clockOutLatitude?: number;
    clockOutClientType: ClientType;
    clockOutImageUrl: string;
    clockOutComments: string;
    clockOutAddress: string;
    breaks: BreakView[];
    scheduledShift: ScheduledShift;
    createdOn: Date;
    updatedOn?: Date;
    createdById: string;
    updatedById: string;
    isArchived: boolean;
    hasEnded: boolean;
    workingDuration: string;
    breaksDuration: string;
    overtimeDuration: string;
    totalWorkingDuration: string;
    totalBreaksDuration: string;
    totalOvertimeDuration: string;
}

// ..\BluAttendance.Core\ViewModels\ShiftView.cs
export interface ShiftView {
    id: number;
    userId: string;
    user: User;
    clockInTime: Date;
    clockInTimeUtc: Date;
    clockInLongitude?: number;
    clockInLatitude?: number;
    clockInClientType: ClientType;
    clockInImageUrl: string;
    clockInComments: string;
    clockInAddress: string;
    clockInCountry: string;
    clockOutTime?: Date;
    clockOutTimeUtc?: Date;
    clockOutLongitude?: number;
    clockOutLatitude?: number;
    clockOutClientType: ClientType;
    clockOutImageUrl: string;
    clockOutComments: string;
    clockOutAddress: string;
    clockOutCountry: string;
    breaks: BreakView[];
    scheduledShift: ScheduledShift;
    createdOn: Date;
    updatedOn?: Date;
    createdById: string;
    updatedById: string;
    isArchived: boolean;
    isEdited: boolean;
    hasEnded: boolean;
    workingDuration: string;
    breaksDuration: string;
}

// ..\BluAttendance.Core\ViewModels\SubscriptionPlanView.cs
export interface SubscriptionPlanView {
    id: number;
    name: string;
    monthlyPricePerUser?: number;
    yearlyPricePerUser?: number;
    duration?: string;
}

// ..\BluAttendance.Core\ViewModels\TimesheetTableView.cs
export interface TimesheetTableView {
    fullName: string;
    from: Date;
    to: Date;
    daysSummaryView: DaySummaryView[];
    daysClockings: ClockingView[][];
    totalWorkTime: string;
}

// ..\BluAttendance.Core\ViewModels\TimesheetView.cs
export interface TimesheetView {
    id: number;
    from: Date;
    to: Date;
    createdOn?: Date;
    comment: string;
    isArchived: boolean;
    userId: string;
    user: User;
    supervisorId: string;
    supervisor: User;
    timesheetStatusId: number;
    timesheetStatus: TimesheetStatus;
    isSupervisor: boolean;
}

// ..\BluAttendance.Core\ViewModels\TimeZoneInfoView.cs
export interface TimeZoneInfoView {
    id: string;
    displayName: string;
    baseUtcOffset: string;
    standardName: string;
}

// ..\BluAttendance.Core\ViewModels\Token.cs
export interface Token {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    firstLogin: boolean;
}

// ..\BluAttendance.Core\ViewModels\TrialCreatedView.cs
export interface TrialCreatedView {
    companyName: string;
    baseUrl: string;
    guideUrl: string;
    days: number;
    email: string;
    password: string;
}

// ..\BluAttendance.Core\ViewModels\TrialExpiryView.cs
export interface TrialExpiryView {
    companyId: number;
}

// ..\BluAttendance.Core\ViewModels\UserActivityView.cs
export interface UserActivityView extends UserView {
    shiftId: number;
    latitude: number;
    longitude: number;
    countryName: string;
    lastActivity: ClockingType;
    lastActivityTime: Date;
    lastActivityDuration: string;
    workingDuration: string;
}

// ..\BluAttendance.Core\ViewModels\UserFaceImageView.cs
export interface UserFaceImageView {
    id: number;
    path: string;
    userId: string;
}

// ..\BluAttendance.Core\ViewModels\UserLeaveQuotaAndBalanceView.cs
export interface UserLeaveQuotaAndBalanceView {
    id: number;
    quota: number;
    quotaUnit: QuotaUnit;
    quotaType: QuotaType;
    isCummulative: boolean;
    annuallyResets: boolean;
    includeNonWorkingrDays: boolean;
    leaveOverlapsOtherLeaves: boolean;
    balance: number;
    inheritedFromCompany: boolean;
    attachmentRequired: boolean;
    userId: string;
    leaveTypeId: number;
}

// ..\BluAttendance.Core\ViewModels\UserRulesView.cs
export interface UserRulesView {
    id: number;
    attendanceImageConfig: UserAttendanceImageConfig;
    faceRecognitionConfig: UserFaceRecognitionConfig;
    workplaceConfig: WorkplaceConfig;
    shiftScheduleType: UserShiftScheduleType;
    userId: string;
}

// ..\BluAttendance.Core\ViewModels\UsersMissedClockInNotificationView.cs
export interface UsersMissedClockInNotificationView {
    baseUrl: string;
    timezoneId: string;
    companyId: number;
}

// ..\BluAttendance.Core\ViewModels\UsersStatusesReportView.cs
export interface UsersStatusesReportView {
    user: UserView;
    status: UserStatus;
    clockInTimes: Date[];
    clockOutTimes: Date[];
    totalBreaksHours: number;
    totalWorkHours: number;
    overtimeHours: number;
    date: Date;
    leaveRequestView: LeaveRequestView;
}

// ..\BluAttendance.Core\ViewModels\UserView.cs
export interface UserView {
    id: string;
    employeeId: string;
    userName: string;
    email: string;
    imageUrl: string;
    company: UserCompanyView;
    role: string;
    country: CountryView;
    title: string;
    firstName: string;
    lastName: string;
    fullName: string;
    linkedInUrl: string;
    birthDate?: Date;
    branchId?: number;
    branch: BranchView;
    departmentId?: number;
    department: DepartmentView;
    phoneNumber: string;
    currentWorkingDuration: number;
    timeZone: string;
    locale: string;
    fiscalYearStartingMonth: number;
    isSupervisor: boolean;
    currentStatus: UserCurrentStatus;
    currentCustomStatusText: string;
    userRulesId: number;
    userRules: UserRulesView;
    workplaces: WorkplaceModel[];
    supervisorIds: string[];
    createdOn: Date;
    isArchived: boolean;
    userFaceImage: UserFaceImage;
}

// ..\BluAttendance.Core\ViewModels\UserView.UserCompanyView.cs
export interface UserCompanyView {
    id: number;
    name: string;
    numberofUsers: number;
    licenseExpireOn: Date;
    plan: string;
    imageUrl: string;
    primaryColor: string;
    locales: string;
    attendanceImageConfig: AttendanceImageConfig;
    faceRecognitionConfig: FaceRecognitionConfig;
    shiftScheduleType: ShiftScheduleType;
    hideClockingImagesFromTimesheet: boolean;
    faceRecognitionEnabled: boolean;
}

// ..\BluAttendance.Core\ViewModels\UserViewSimple.cs
export interface UserViewSimple {
    id: string;
    userName: string;
    email: string;
    imageUrl: string;
    companyId: number;
    role: string;
    title: string;
    firstName: string;
    lastName: string;
    fullName: string;
    phoneNumber: string;
    supervisorIds: string[];
}

// ..\BluAttendance.Core\ViewModels\UserWeekendInput.cs
export interface UserWeekendInput {
    userId: string;
    listOfWeekend: number[];
}

// ..\BluAttendance.Core\ViewModels\WeekScheduleView.cs
export interface WeekScheduleView {
    id: number;
    companyId: number;
    userId: string;
    weekStartEndHoursAndMinutes: number[][];
}

// ..\BluAttendance.Core\ViewModels\WorkplaceModel.cs
export interface LatLng {
    lat: number;
    lng: number;
}

// ..\BluAttendance.Core\ViewModels\WorkplaceModel.cs
export interface WorkplaceModel {
    id: number;
    bounds: LatLng[];
}

// ..\BluAttendance.Data\Entities\Branch.cs
export interface Branch extends Record {
    id: number;
    name: string;
    companyId: number;
    company: Company;
}

// ..\BluAttendance.Data\Entities\Break.cs
export interface Break extends Record {
    id: number;
    shiftId: number;
    shift: Shift;
    breakInTime: Date;
    breakInTimeUtc: Date;
    breakInLatitude?: number;
    breakInLongitude?: number;
    breakInClientType: ClientType;
    breakInImageUrl: string;
    breakInComments: string;
    breakInAddress: string;
    breakInCountry: string;
    breakOutTime?: Date;
    breakOutTimeUtc?: Date;
    breakOutLatitude?: number;
    breakOutLongitude?: number;
    breakOutClientType: ClientType;
    breakOutImageUrl: string;
    breakOutComments: string;
    breakOutAddress: string;
    breakOutCountry: string;
    hasEnded: boolean;
    duration?: string;
}

// ..\BluAttendance.Data\Entities\ClientType.cs
export enum ClientType {
    Web = 'Web',
    Mobile = 'Mobile',
}

// ..\BluAttendance.Data\Entities\ClockingType.cs
export enum ClockingType {
    ClockIn = 'ClockIn',
    BreakIn = 'BreakIn',
    BreakOut = 'BreakOut',
    ClockOut = 'ClockOut',
}

// ..\BluAttendance.Data\Entities\Company.cs
export interface Company extends Record {
    id: number;
    name: string;
    imageUrl: string;
    organizationId?: number;
    email: string;
    websiteLink: string;
    address: string;
    phoneNumber: string;
    fax: string;
    primaryColor: string;
    countryId?: number;
    timeZone: string;
    defaultStartTime: string;
    defaultEndTime: string;
    locales: string;
    country: Country;
    organization: Organization;
    companyRules: CompanyRules;
    weekSchedule: WeekSchedule;
    users: User[];
    workplaces: Workplace[];
    companyLeaves: CompanyLeave[];
}

// ..\BluAttendance.Data\Entities\CompanyHoliday.cs
export interface CompanyHoliday extends Record {
    id: number;
    date: Date;
    holidayId: number;
    holiday: Holiday;
    companyId: number;
    company: Company;
    countryId: number;
    country: Country;
}

// ..\BluAttendance.Data\Entities\CompanyLeave.cs
export interface CompanyLeave extends Record {
    id: number;
    quota: number;
    quotaUnit: QuotaUnit;
    quotaType: QuotaType;
    isCummulative: boolean;
    annuallyResets: boolean;
    includeNonWorkingrDays: boolean;
    leaveOverlapsOtherLeaves: boolean;
    leaveTypeId: number;
    attachmentRequired: boolean;
    leaveType: LeaveType;
    companyId: number;
    company: Company;
    countryId: number;
    country: Country;
}

// ..\BluAttendance.Data\Entities\CompanyLeaveType.cs
export interface CompanyLeaveType extends Record {
    id: number;
    leaveTypeId: number;
    companyId: number;
    leaveType: LeaveType;
    company: Company;
}

// ..\BluAttendance.Data\Entities\CompanyNotificationType.cs
export interface CompanyNotificationType extends Record {
    id: number;
    emailsDisabled: boolean;
    mobilePushNotificationsDisabled: boolean;
    companyId: number;
    company: Company;
    notificationTypeId: number;
    notificationType: NotificationType;
}

// ..\BluAttendance.Data\Entities\CompanyRules.cs
export interface CompanyRules extends Record {
    id: number;
    attendanceImageConfig: AttendanceImageConfig;
    faceRecognitionConfig: FaceRecognitionConfig;
    shiftFlowRuleType: ShiftFlowRuleType;
    timesheetCreationDay: number;
    hideClockingImagesFromTimesheet: boolean;
    disableAllNotifications: boolean;
    faceRecognitionEnabled: boolean;
    leavesApprovalLimitedToSupervisor: boolean;
    breakConfig: BreakConfig;
    shiftScheduleType: ShiftScheduleType;
    triggerOvertimeVerificationOn: TriggerOvertimeVerificationOn;
    companyId: number;
    company: Company;
}

// ..\BluAttendance.Data\Entities\CompanyRules.cs
export enum AttendanceImageConfig {
    Required = 'Required',
    NotRequired = 'NotRequired',
}

// ..\BluAttendance.Data\Entities\CompanyRules.cs
export enum FaceRecognitionConfig {
    Required = 'Required',
    NotRequired = 'NotRequired',
}

// ..\BluAttendance.Data\Entities\CompanyRules.cs
export enum ShiftFlowRuleType {
    Default = 'Default',
    BoundByWorkingHours = 'BoundByWorkingHours',
}

// ..\BluAttendance.Data\Entities\CompanyRules.cs
export enum ShiftScheduleType {
    FixedWeekSchedule = 'FixedWeekSchedule',
    VariableShiftSchedule = 'VariableShiftSchedule',
}

// ..\BluAttendance.Data\Entities\CompanyRules.cs
export enum TriggerOvertimeVerificationOn {
    ShiftWorkingHoursFullfiled = 'ShiftWorkingHoursFullfiled',
    ShiftEndTime = 'ShiftEndTime',
}

// ..\BluAttendance.Data\Entities\CompanyRules.cs
export enum BreakConfig {
    Required = 'Required',
    NotRequired = 'NotRequired',
}

// ..\BluAttendance.Data\Entities\Country.cs
export interface Country extends Record {
    id: number;
    name: string;
    iso: string;
    nicename: string;
    iso3: string;
    numCode?: number;
    phoneCode: number;
    isGcc: boolean;
    companies: Company[];
}

// ..\BluAttendance.Data\Entities\Currency.cs
export interface Currency extends Record {
    id: number;
    name: string;
    symbol: string;
    rate: number;
}

// ..\BluAttendance.Data\Entities\DayStatus.cs
export enum DayStatus {
    Leave = 'Leave',
    Holiday = 'Holiday',
    Present = 'Present',
    SickLeave = 'SickLeave',
    WeekOffDay = 'WeekOffDay',
    HalfDayLeave = 'HalfDayLeave',
}

// ..\BluAttendance.Data\Entities\Department.cs
export interface Department extends Record {
    id: number;
    name: string;
    companyId: number;
    company: Company;
}

// ..\BluAttendance.Data\Entities\EmailTask.cs
export interface EmailTask {
    id: number;
    to: string;
    createdOn: Date;
    modelJson: string;
    status: EmailTaskStatus;
    template: EmailTemplate;
    subscriptionId?: number;
}

// ..\BluAttendance.Data\Entities\EmailTask.cs
export enum EmailTaskStatus {
    Pending = 'Pending',
    Sent = 'Sent',
    Error = 'Error',
}

// ..\BluAttendance.Data\Entities\EmailTask.cs
export enum EmailTemplate {
    VerifyEmail = 'VerifyEmail',
    ResetPassword = 'ResetPassword',
}

// ..\BluAttendance.Data\Entities\FaceRecognitionLog.cs
export interface FaceRecognitionLog extends Record {
    id: number;
    message: string;
    isRecognized: boolean;
}

// ..\BluAttendance.Data\Entities\Holiday.cs
export interface Holiday extends Record {
    id: number;
    name: string;
    description: string;
}

// ..\BluAttendance.Data\Entities\IRecord.cs
export interface IRecord {
    createdOn: Date;
    updatedOn?: Date;
    createdById: string;
    createdBy: User;
    updatedById: string;
    updatedBy: User;
    isArchived: boolean;
}

// ..\BluAttendance.Data\Entities\LeaveBalanceHistory .cs
export interface LeaveBalanceHistory extends Record {
    id: number;
    userId: string;
    balanceBefore: number;
    balanceAfter: number;
    leavetypeId: number;
    action: Action;
}

// ..\BluAttendance.Data\Entities\LeaveBalanceHistory .cs
export enum Action {
    leaveRequest = 'leaveRequest',
    leaveRejection = 'leaveRejection',
    leaveApproval = 'leaveApproval',
    balanceUpdate = 'balanceUpdate',
    undoLeaveApproval = 'undoLeaveApproval',
    undoLeaveRejection = 'undoLeaveRejection',
}

// ..\BluAttendance.Data\Entities\LeaveRequestAttachment.cs
export interface LeaveRequestAttachment extends Record {
    id: number;
    name: string;
    extention: string;
    byteLength: number;
    path: string;
    userLeaveRequestId: number;
    userLeaveRequest: UserLeaveRequest;
}

// ..\BluAttendance.Data\Entities\LeaveType.cs
export interface LeaveType extends Record {
    id: number;
    name: string;
    companyId: number;
    userLeaveQuotaAndBalances: UserLeaveQuotaAndBalance[];
}

// ..\BluAttendance.Data\Entities\LeaveType.cs
export enum QuotaType {
    Annual = 'Annual',
    Monthly = 'Monthly',
}

// ..\BluAttendance.Data\Entities\LeaveType.cs
export enum QuotaUnit {
    Days = 'Days',
    Hours = 'Hours',
}

// ..\BluAttendance.Data\Entities\License.cs
export interface License extends Record {
    id: number;
    planId?: number;
    companyId: number;
    maxUsers: number;
    price: number;
    expiresOn: Date;
    plan: SubscriptionPlan;
    company: Company;
}

// ..\BluAttendance.Data\Entities\Log.cs
export interface Log extends Record {
    id: number;
    logType: LogType;
    logSource: LogSource;
    message: string;
    stackTrace: string;
    source: string;
    path: string;
    protocol: string;
    method: string;
    clientIpAddress: string;
    clientUserAgent: string;
}

// ..\BluAttendance.Data\Entities\Log.cs
export enum LogType {
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',
    BugReport = 'BugReport',
}

// ..\BluAttendance.Data\Entities\Log.cs
export enum LogSource {
    Request = 'Request',
    FCM = 'FCM',
    RefreshToken = 'RefreshToken',
    Login = 'Login',
    LeaveBalanceUpdate = 'LeaveBalanceUpdate',
}

// ..\BluAttendance.Data\Entities\LogAttachment.cs
export interface LogAttachment extends Record {
    id: number;
    name: string;
    extention: string;
    byteLength: number;
    path: string;
    logId: number;
    log: Log;
}

// ..\BluAttendance.Data\Entities\Notification.cs
export interface Notification extends Record {
    id: number;
    title: string;
    titleParams: string;
    body: string;
    bodyParams: string;
    emailTemplate: string;
    dataJson: string;
    notificationUrl: string;
    imageUrl: string;
    isRead: boolean;
    notificationTypeId?: number;
    notificationType: NotificationType;
    fromId: string;
    from: User;
    toId: string;
    ccIds: string;
    excludedToAndCcIds: string;
}

// ..\BluAttendance.Data\Entities\NotificationImage.cs
export interface NotificationImage extends Record {
    id: number;
    name: string;
    extention: string;
    byteLength: number;
    path: string;
    notificationId: number;
    notification: Notification;
}

// ..\BluAttendance.Data\Entities\NotificationType.cs
export interface NotificationType {
    id: number;
    name: string;
    defaultTitle: string;
    defaultBody: string;
}

// ..\BluAttendance.Data\Entities\Organization.cs
export interface Organization extends Record {
    id: number;
    name: string;
    companies: Company[];
}

// ..\BluAttendance.Data\Entities\OverlappedLeaveDay.cs
export interface OverlappedLeaveDay extends Record {
    id: number;
    dayOverlapped: Date;
    hoursOverlapped: number;
    userLeaveRequestId: number;
    userLeaveRequest: UserLeaveRequest;
    overlappedUserLeaveRequestId: number;
    overlappedUserLeaveRequest: UserLeaveRequest;
}

// ..\BluAttendance.Data\Entities\PerformanceTimeLog.cs
export interface PerformanceTimeLog extends Record {
    id: number;
    action: string;
    startLineNumber: number;
    stopLineNumber: number;
    timeTaken: string;
}

// ..\BluAttendance.Data\Entities\Pool.cs
export interface Pool extends Record {
    id: string;
    name: string;
    users: User[];
}

// ..\BluAttendance.Data\Entities\Priority.cs
export interface Priority {
    id: number;
    name: string;
}

// ..\BluAttendance.Data\Entities\Record.cs
export interface Record extends IRecord {
    createdOn: Date;
    updatedOn?: Date;
    createdById: string;
    createdBy: User;
    updatedById: string;
    updatedBy: User;
    isArchived: boolean;
}

// ..\BluAttendance.Data\Entities\ReportSchedulingConfig.cs
export interface ReportSchedulingConfig extends Record {
    id: number;
    reportName: string;
    companyId: number;
    companyName: string;
    companyTimezone: string;
    usersTo: string;
    usersCc: string;
    cronExpression: string;
    daysBeforeDate: number;
    daysAfterDate: number;
    parameters: string;
}

// ..\BluAttendance.Data\Entities\Request.cs
export interface Request extends Record {
    id: number;
    closedDate: Date;
    expectedClosedDate: Date;
    title: string;
    description: string;
    requestedById: string;
    requestStatusId: number;
    requestPriorityId?: number;
    requestStatus: RequestStatus;
    requestedBy: User;
    requestPriority: Priority;
}

// ..\BluAttendance.Data\Entities\RequestStatus.cs
export interface RequestStatus {
    id: number;
    name: string;
}

// ..\BluAttendance.Data\Entities\Role.cs
export interface Role {
    createdOn: Date;
    updatedOn?: Date;
    createdById: string;
    updatedById: string;
    isArchived: boolean;
    haveAuthorityOverRoles: string;
    createdBy: User;
    updatedBy: User;
}

// ..\BluAttendance.Data\Entities\Role.cs
export enum Roles {
    Clocking = 'Clocking',
    Hr = 'Hr',
    Chief = 'Chief',
    Admin = 'Admin',
}

// ..\BluAttendance.Data\Entities\RoleLicense.cs
export interface RoleLicense extends Record {
    id: number;
    roleId: string;
    licenseId: number;
    maxUsers: number;
    role: Role;
    license: License;
}

// ..\BluAttendance.Data\Entities\ScheduledShift.cs
export interface ScheduledShift extends Record {
    id: number;
    userId: string;
    user: User;
    startTime: Date;
    endTime: Date;
    shiftId?: number;
    shift: Shift;
}

// ..\BluAttendance.Data\Entities\ScheduleHistory.cs
export interface ScheduleHistory extends Record {
    id: number;
    userId: string;
    user: User;
    scheduleStartTime: Date;
    scheduleEndTime?: Date;
    shiftScheduleType: ShiftScheduleType;
    weekStartEndHoursAndMinutes: string;
}

// ..\BluAttendance.Data\Entities\Shift.cs
export interface Shift extends Record {
    id: number;
    userId: string;
    user: User;
    clockInTime: Date;
    clockInTimeUtc: Date;
    clockInLongitude?: number;
    clockInLatitude?: number;
    clockInClientType: ClientType;
    clockInImageUrl: string;
    clockInComments: string;
    clockInAddress: string;
    clockInCountry: string;
    clockOutTime?: Date;
    clockOutTimeUtc?: Date;
    clockOutLongitude?: number;
    clockOutLatitude?: number;
    clockOutClientType: ClientType;
    clockOutImageUrl: string;
    clockOutComments: string;
    clockOutAddress: string;
    clockOutCountry: string;
    hasEnded: boolean;
    breaks: Break[];
    scheduledShift: ScheduledShift;
}

// ..\BluAttendance.Data\Entities\ShiftUpdatesHistory.cs
export interface ShiftUpdatesHistory {
    id: number;
    shiftId?: number;
    breakId?: number;
    shiftUserId: string;
    updatedById: string;
    clockingType: ClockingType;
    oldClockTime: Date;
    newClockTime: Date;
}

// ..\BluAttendance.Data\Entities\StatusHistory.cs
export interface StatusHistory extends Record {
    id: number;
    status: string;
}

// ..\BluAttendance.Data\Entities\SubscriptionPlan.cs
export interface SubscriptionPlan extends Record {
    id: number;
    name: string;
    monthlyPricePerUser?: number;
    yearlyPricePerUser?: number;
    duration?: string;
}

// ..\BluAttendance.Data\Entities\Timesheet.cs
export interface Timesheet {
    id: number;
    from: Date;
    to: Date;
    createdOn?: Date;
    comment: string;
    isArchived: boolean;
    userId: string;
    user: User;
    supervisorId: string;
    supervisor: User;
    timesheetStatusId: number;
    timesheetStatus: TimesheetStatus;
}

// ..\BluAttendance.Data\Entities\TimesheetStatus.cs
export interface TimesheetStatus {
    id: number;
    name: string;
}

// ..\BluAttendance.Data\Entities\TrialCompanyData.cs
export interface TrialCompanyData extends Record {
    id: number;
    industry: string;
    size: string;
    days: number;
    reference: string;
    companyId: number;
    company: Company;
}

// ..\BluAttendance.Data\Entities\User.cs
export interface User {
    title: string;
    employeeId: string;
    branchId?: number;
    branch: Branch;
    departmentId?: number;
    department: Department;
    firstName: string;
    lastName: string;
    fullName: string;
    linkedInUrl: string;
    birthDate?: Date;
    fcmToken: string;
    imageUrl: string;
    timeZone: string;
    locale: string;
    fiscalYearStartingMonth: number;
    currentStatus: UserCurrentStatus;
    currentCustomStatusText: string;
    predifinedImagesValidForFaceRecognition: boolean;
    companyId?: number;
    company: Company;
    countryId?: number;
    country: Country;
    userRules: UserRules;
    organizations: Organization[];
    shifts: Shift[];
    workplaces: Workplace[];
    weekSchedule: WeekSchedule;
    createdOn: Date;
    updatedOn?: Date;
    createdById: string;
    updatedById: string;
    isArchived: boolean;
    createdBy: User;
    updatedBy: User;
}

// ..\BluAttendance.Data\Entities\UserCurrentStatus.cs
export enum UserCurrentStatus {
    Unknown = 'Unknown',
    Available = 'Available',
    Unavailable = 'Unavailable',
    Break = 'Break',
    Leave = 'Leave',
    Holiday = 'Holiday',
    HalfDayLeave = 'HalfDayLeave',
}

// ..\BluAttendance.Data\Entities\UserFaceImage.cs
export interface UserFaceImage extends Record {
    id: number;
    path: string;
    userId: string;
    imageSource: ImageSource;
    user: User;
    labeledFaceDescriptors: string;
}

// ..\BluAttendance.Data\Entities\UserFaceImage.cs
export enum ImageSource {
    Profile = 'Profile',
    Attendance = 'Attendance',
}

// ..\BluAttendance.Data\Entities\UserLeaveQuotaAndBalance.cs
export interface UserLeaveQuotaAndBalance extends Record {
    id: number;
    quota: number;
    quotaUnit: QuotaUnit;
    quotaType: QuotaType;
    isCummulative: boolean;
    annuallyResets: boolean;
    includeNonWorkingrDays: boolean;
    leaveOverlapsOtherLeaves: boolean;
    balance: number;
    inheritedFromCompany: boolean;
    attachmentRequired: boolean;
    userId: string;
    user: User;
    leaveTypeId: number;
    leaveType: LeaveType;
}

// ..\BluAttendance.Data\Entities\UserLeaveRequest.cs
export interface UserLeaveRequest extends Record {
    id: number;
    quotaUnit: QuotaUnit;
    includeNonWorkingrDays: boolean;
    startDate: Date;
    fromHalfDay: boolean;
    fromHalfDayPart: HalfDayPart;
    endDate: Date;
    toHalfDay: boolean;
    toHalfDayPart: HalfDayPart;
    comments: string;
    supervisorComments: string;
    status: LeaveRequestStatus;
    balanceDeducted: number;
    overlapedByBalance: number;
    userId: string;
    user: User;
    supervisorId: string;
    supervisor: User;
    leaveTypeId: number;
    leaveType: LeaveType;
    attachmentRequired: boolean;
    leaveRequestAttachements: LeaveRequestAttachment[];
}

// ..\BluAttendance.Data\Entities\UserLeaveRequest.cs
export enum LeaveRequestStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
}

// ..\BluAttendance.Data\Entities\UserLeaveRequest.cs
export enum HalfDayPart {
    AM = 'AM',
    PM = 'PM',
}

// ..\BluAttendance.Data\Entities\UserRefreshToken.cs
export interface UserRefreshToken {
    id: number;
    token: string;
    expires?: Date;
    isExpired: boolean;
    created: Date;
    createdByIp: string;
    revoked?: Date;
    revokedByIp: string;
    replacedByToken: string;
    isActive: boolean;
    userId: string;
    user: User;
}

// ..\BluAttendance.Data\Entities\UserRequest.cs
export interface UserRequest {
    id: number;
    userId: string;
    requestId: number;
    request: Request;
    user: User;
}

// ..\BluAttendance.Data\Entities\UserRole.cs
export interface UserRole {
    role: Role;
    user: User;
}

// ..\BluAttendance.Data\Entities\UserRules.cs
export interface UserRules extends Record {
    id: number;
    attendanceImageConfig: UserAttendanceImageConfig;
    faceRecognitionConfig: UserFaceRecognitionConfig;
    workplaceConfig: WorkplaceConfig;
    shiftScheduleType: UserShiftScheduleType;
    userId: string;
    user: User;
}

// ..\BluAttendance.Data\Entities\UserRules.cs
export enum UserAttendanceImageConfig {
    Required = 'Required',
    NotRequired = 'NotRequired',
    InheritedFromCompany = 'InheritedFromCompany',
}

// ..\BluAttendance.Data\Entities\UserRules.cs
export enum UserFaceRecognitionConfig {
    InheritedFromCompany = 'InheritedFromCompany',
    Required = 'Required',
    NotRequired = 'NotRequired',
}

// ..\BluAttendance.Data\Entities\UserRules.cs
export enum UserShiftScheduleType {
    InheritedFromCompany = 'InheritedFromCompany',
    FixedWeekSchedule = 'FixedWeekSchedule',
    VariableShiftSchedule = 'VariableShiftSchedule',
}

// ..\BluAttendance.Data\Entities\UserStatus.cs
export enum UserStatus {
    Available = 'Available',
    WorkingHoursNotFulfilled = 'WorkingHoursNotFulfilled',
    Abscent = 'Abscent',
    Leave = 'Leave',
    SickLeave = 'SickLeave',
    Holiday = 'Holiday',
    Future = 'Future',
    Weekend = 'Weekend',
    HalfDayLeave = 'HalfDayLeave',
}

// ..\BluAttendance.Data\Entities\UserSupervisor.cs
export interface UserSupervisor extends Record {
    id: number;
    userId: string;
    user: User;
    supervisorId: string;
    supervisor: User;
}

// ..\BluAttendance.Data\Entities\WeekDays.cs
export enum WeekDays {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
}

// ..\BluAttendance.Data\Entities\WeekSchedule.cs
export interface WeekSchedule extends Record {
    id: number;
    companyId?: number;
    company: Company;
    userId: string;
    user: User;
    weekStartEndHoursAndMinutes: string;
}

// ..\BluAttendance.Data\Entities\Workplace.cs
export interface Workplace extends Record {
    id: number;
    companyId: number;
    userId: string;
    bounds: string;
    company: Company;
    user: User;
}

// ..\BluAttendance.Data\Entities\Workplace.cs
export interface Bound {
    lat: number;
    lng: number;
}

// ..\BluAttendance.Data\Entities\Workplace.cs
export enum WorkplaceConfig {
    CompanyOrUserSpecific = 'CompanyOrUserSpecific',
    AnyLocation = 'AnyLocation',
}
