import React, { useState } from "react";
import { ShimmeredDetailsList, IDetailsListProps, Icon, TextField } from "@fluentui/react";
import { Text, Avatar, Button, Flex, FlexItem } from "@fluentui/react-northstar";
import moment from "moment";
import { ClockingType, ClockingView, Roles, Result, ClientType, ShiftHistoryView } from "../models";
import { UserStore } from "../store/user"
// @ts-ignore
import DateTimePicker from 'react-datetime-picker'
import { useTranslation } from "react-i18next";
import Icons from "../assests/images/SVGIcons/svgIcons";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircle from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { addShift } from "../api/attendance";
import { time } from "console";
import InfoIcon from '@mui/icons-material/Info';
import { getUser } from "../api/account";
import CloseIcon from '@mui/icons-material/Close';

declare const baseUrl: string;

export type TimesheetTableProps = {
  user: UserStore;
  userId: string;
  clockings?: ClockingView[];
  loading: boolean;
  detailsListStyles?: IDetailsListProps["styles"];
  clockTimesEditable?: boolean;
  onClockTimeChange: Function;
  showImages: boolean;
  timesheet: boolean;
  Reload: Function;
  selectedDay: Date;
  getShiftHistory: Function;
  shiftHistory: ShiftHistoryView[];
  timeSheetView: boolean;

};

function TimesheetTable({ selectedDay, Reload, timesheet, user, userId, clockings, loading, detailsListStyles, clockTimesEditable, onClockTimeChange, showImages, getShiftHistory, shiftHistory, timeSheetView }: TimesheetTableProps) {

  // const fields: ClockingView[] = [];

  // if (clockings) {
  //   clockings.shifts.forEach((shift) => {
  //     fields.push({
  //       ...shift,
  //       clientType: shift.clockInClientType,
  //       latitude: shift.clockInLatitude,
  //       longitude: shift.clockInLongitude,
  //       time: shift.clockInTime,
  //       timeUtc: shift.clockInTimeUtc,
  //       type: ClockingType.ClockIn,
  //       id: shift.id,
  //       address: shift.clockInAddress,
  //       comments: shift.clockInComments,
  //       imageUrl: shift.clockInImageUrl,
  //       createdById: shift.createdById,
  //       createdOn: shift.createdOn,
  //       isArchived: shift.isArchived,
  //       updatedById: shift.updatedById,
  //       updatedOn: shift.updatedOn,
  //       userId: shift.userId,
  //     });

  //     for (let i = 0; i < shift.breaks.length; i++) {
  //       const element = shift.breaks[i];
  //       fields.push({
  //         ...element,
  //         clientType: element.breakInClientType,
  //         latitude: element.breakInLatitude,
  //         longitude: element.breakInLongitude,
  //         time: element.breakInTime,
  //         timeUtc: element.breakInTimeUtc,
  //         type: ClockingType.BreakIn,
  //         id: element.id,
  //         address: element.breakInAddress,
  //         userId: "",
  //         comments: element.breakInComments,
  //         imageUrl: element.breakInImageUrl,
  //       });
  //       if (element.hasEnded) {
  //         fields.push({
  //           ...element,
  //           clientType: element.breakOutClientType,
  //           latitude: element.breakOutLatitude,
  //           longitude: element.breakOutLongitude,
  //           time: element.breakOutTime as Date,
  //           timeUtc: element.breakOutTimeUtc as Date,
  //           type: ClockingType.BreakOut,
  //           id: element.id,
  //           address: element.breakOutAddress,
  //           userId: "",
  //           comments: element.breakOutComments,
  //           imageUrl: element.breakOutImageUrl
  //         });
  //       }
  //     }

  //     if (shift.hasEnded) {
  //       fields.push({
  //         ...shift,
  //         clientType: shift.clockOutClientType,
  //         latitude: shift.clockOutLatitude,
  //         longitude: shift.clockOutLongitude,
  //         time: shift.clockOutTime as Date,
  //         timeUtc: shift.clockOutTimeUtc as Date,
  //         type: ClockingType.ClockOut,
  //         id: shift.id,
  //         address: shift.clockOutAddress,
  //         comments: shift.clockOutComments,
  //         imageUrl: shift.clockOutImageUrl,
  //       });
  //     }
  //   });
  // }

  const { t } = useTranslation();

  const [editedClockTimes, setEditedClockTimes]: any = useState([])
  const [open, setOpen] = useState(false)
  var dateOfNow = new Date(selectedDay)
  const [clockIn, setClockIn] = useState(dateOfNow);
  var dateOfNowPlus1 = new Date(selectedDay)
  dateOfNowPlus1.setHours(dateOfNowPlus1.getHours() + 1)
  const [clockOut, setClockOut] = useState(dateOfNowPlus1);
  const [openInfo, setOpenInfo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showIcon, setShowIcon] = useState(timeSheetView);
  const onChange = (time: any, id: any, type: string) => {
    var recordExists = false;
    editedClockTimes.map((clockTime: any) => {
      if (id === clockTime.id && type === clockTime.type) {
        clockTime.time = new Date(time)
        recordExists = true
      }
      return clockTime
    });
    if (!recordExists)
      editedClockTimes.push({ time, id, type })
    setEditedClockTimes(editedClockTimes)
  }


  const changeOpen = () => {
    setOpen(true)
    console.log(open)
  }

  const Close = () => {
    setOpen(false)
    console.log(open)
  }


  const submit = async () => {

    const n = clockIn.getTimezoneOffset();
    const hoursOffset = n / -60;
    var date = new Date()
    try {
      await addShift({
        clockInTime: clockIn,
        clockOutTime: clockOut,
        userId: userId,
        clockInTimeUtc: clockIn.toUTCString() as unknown as Date,
        clockInClientType: ClientType.Web,
        clockInImageUrl: "",
        clockInAddress: "",
        clockInCountry: "",
        clockOutClientType: ClientType.Web,
        clockOutImageUrl: "",
        clockOutAddress: "",
        clockOutCountry: "",
        clockInComments: "",
        clockOutComments: ""
      }, hoursOffset)

    }
    catch (error: any) {
      if (error && error.errors && error.errors[0]) {
        const res = error as Result<never>;
        alert(res.errors[0].description);
      }
    }
    setOpen(false);
    Reload();
  };

  const fullName = async (userId: string) => {
    var fullName = (await (getUser(userId))).payload.fullName
    console.log(fullName)
    return fullName
  }

  return (

    <><Flex styles={{ flexDirection: "column" }}>
      <ShimmeredDetailsList
        enableShimmer={loading}
        selectionMode={0}
        items={clockings ?? []}
        detailsListStyles={{ ...detailsListStyles, root: { overflowX: "auto" } }}
        columns={[
          {
            minWidth: 150,
            maxWidth: 300,
            key: "type",
            name: t("Type"),
            onRender: (item: ClockingView) => <Flex>
              <Flex styles={{ width: '26px', padding: '0px 4px 0px 4px' }}>
                {(() => {
                  switch (item.type) {
                    case ClockingType.ClockIn: return <Icons.ClockIn />;
                    case ClockingType.ClockOut: return <Icons.ClockOut />;
                    case ClockingType.BreakIn: return <Icons.BreakIn />;
                    case ClockingType.BreakOut: return <Icons.BreakOut />;
                    default: return <Icons.ClockIn />;
                  }
                })()}
              </Flex>
              {t(item.type)}
            </Flex>,
          },
          {
            minWidth: clockTimesEditable ? 200 : 100,
            maxWidth: clockTimesEditable ? 300 : 200,
            key: "time",
            name: t("Time"),
            onRender: (item: ClockingView) => (
              clockTimesEditable ?
                (item.isEdited ? (
                  <Flex vAlign='center'><div
                    style={{ direction: 'ltr' }}
                  >
                    <DateTimePicker
                      key={item.id + item.type}
                      onChange={(e: any) => onChange(e, item.id, item.type)}
                      value={new Date(item.time)}
                      format="y-MM-dd h:mm:ss a"
                      calendarIcon={null}
                      clearIcon={null}
                      disableCalendar
                      disableClock />
                  </div>
                    <Button icon={<Icon iconName="Save" style={{ fontSize: "20px" }} />} iconOnly text
                      title="Submit Change"
                      onClick={() => { onClockTimeChange(item, editedClockTimes); }} />
                    {showIcon == true?
                    (<IconButton onClick={() => {
                      getShiftHistory(item)
                      setOpenInfo(true)
                    }}>
                    <InfoIcon color="primary" />
                    </IconButton>)
                     : undefined  }
                  </Flex>
                ) : <Flex vAlign='center'><div
                  style={{ direction: 'ltr' }}
                >
                  <DateTimePicker
                    key={item.id + item.type}
                    onChange={(e: any) => onChange(e, item.id, item.type)}
                    value={new Date(item.time)}
                    format="y-MM-dd h:mm:ss a"
                    calendarIcon={null}
                    clearIcon={null}
                    disableCalendar
                    disableClock />
                </div>
                  <Button icon={<Icon iconName="Save" style={{ fontSize: "20px" }} />} iconOnly text
                    title="Submit Change"
                    onClick={() => { onClockTimeChange(item, editedClockTimes); }} />
                </Flex>
                )
                // : item.isEdited ?
                //  (<Flex vAlign='center'>
                //   {moment(item.time).format("hh:mm:ss A")}
                //   <IconButton onClick={() => {
                //     getShiftHistory(item)
                //     setOpenInfo(true)
                //   }}>
                //     <InfoIcon color="primary" />
                //   </IconButton>
                // </Flex>) : ()
                : (item.isEdited ? (
                  <Flex vAlign='center'>
                    {moment(item.time).format("hh:mm:ss A")}
                    {showIcon == true ? 
                    <IconButton onClick={() => {
                      getShiftHistory(item)
                      setOpenInfo(true)
                    }}>
                      <InfoIcon color="primary" />
                    </IconButton> : undefined}
                  </Flex>
                ) : <Flex vAlign='center'>
                  {moment(item.time).format("hh:mm:ss A")}

                </Flex>
                )
            ),
          },
          {
            minWidth: 60,
            maxWidth: 200,
            key: "device",
            name: t("Device"),
            onRender: (item: ClockingView) => t(item.clientType),
          },
          {
            minWidth: 150,
            maxWidth: 200,
            key: "location",
            name: t("Location"),
            onRender: (item: ClockingView) => (item.address
              ? <Text styles={{ whiteSpace: "break-spaces" }} content={item.address} />
              : <Text disabled content={t("Not available")} />),
          },
          {
            minWidth: 220,
            maxWidth: 400,
            key: "comment",
            name: t("Comment"),
            onRender: (item: ClockingView) => (item.comments && item.comments.length
              ? <Text styles={{ whiteSpace: "break-spaces" }} content={item.comments} /> : <Text disabled content={t("Not available")} />),
          },
          ...(showImages ?
            [{
              minWidth: 80,
              maxWidth: 80,
              key: "image",
              name: t("Image"),
              onRender: (item: ClockingView) => (item.imageUrl
                ? <Avatar image={baseUrl + item.imageUrl} />
                : <Text disabled content={t("Not available")} />),
            },] : []
          )
        ]} />
    </Flex>

      <>
        <Dialog
          open={openInfo}
          fullWidth={true}
          maxWidth="md"
        >
          <Flex styles={{ direction: "row" }}>
            <DialogTitle>
              <b>{t("Update Clock Information")}</b>
            </DialogTitle>
            <Flex styles={{ marginLeft: "580px" }}>
              <IconButton color="primary" aria-label="add-row" component="label" onClick={() => { setOpenInfo(false) }} size="large" >
                <CloseIcon color="primary" fontSize="inherit" />
              </IconButton>
            </Flex>
          </Flex>
          <DialogContent>
            <ShimmeredDetailsList
              enableShimmer={loading}
              selectionMode={0}
              items={shiftHistory ?? []}
              detailsListStyles={{ ...detailsListStyles, root: { overflowX: "auto" } }}
              columns={[
                {
                  minWidth: 150,
                  maxWidth: 300,
                  key: "oldClockTime",
                  name: t("Old Clock Time"),
                  onRender: (item: ShiftHistoryView) =>
                    moment(item.oldClockTime).format("hh:mm:ss A")
                },
                {
                  minWidth: 150,
                  maxWidth: 300,
                  key: "New Clock Time",
                  name: t("New Clock Time"),
                  onRender: (item: ShiftHistoryView) =>
                    moment(item.newClockTime).format("hh:mm:ss A")

                },
                {
                  minWidth: 60,
                  maxWidth: 200,
                  key: "UpdatedBy",
                  name: t("Updated By"),
                  onRender: (item: ShiftHistoryView) =>
                    item.updatedByFullName

                },
              ]}
            />
          </DialogContent>
        </Dialog>
        {(user.role === Roles.Hr && timesheet == true) ?
          <>

            <Flex styles={{ flexDirection: "column" }}>
              <IconButton color="primary" aria-label="add-row" component="label" onClick={changeOpen} >
                <AddCircle />
              </IconButton>
            </Flex>
            <Dialog
              open={open}
              //fullWidth = {true}
              maxWidth='xs'
            >
              <DialogTitle>
                <b>{t("Add Clocking")}</b>
              </DialogTitle>
              <DialogContent>

                <Flex column >
                  <Flex styles={{ flexDirection: "column", display: "flex" }} gap="gap.large"  >
                    <Flex hAlign="center" style={{ marginBottom: "25px", flexDirection: "column", maxWidth: "150px", alignContent: "center" }}>
                      <label><b>ClockIn Time</b></label>
                      <DateTimePicker
                        //key={item.id + item.type}
                        onChange={(e: any) => setClockIn(e)}
                        value={clockIn}
                        format="y-MM-dd h:mm:ss a"
                        calendarIcon={null}
                        clearIcon={null}
                        disableCalendar
                        disableClock
                      />
                    </Flex>
                    <Flex style={{ marginBottom: "25px", flexDirection: "column", maxWidth: "150px" }}>
                      <label><b>ClockOut Time</b></label>
                      <DateTimePicker
                        //key={item.id + item.type}
                        onChange={(e: any) => setClockOut(e)}
                        value={clockOut}
                        format="y-MM-dd h:mm:ss a"
                        calendarIcon={null}
                        clearIcon={null}
                        disableCalendar
                        disableClock
                      />
                    </Flex>

                    <Flex style={{ flexDirection: "row" }}>
                      <Button icon={<Icon iconName="Clear" />} onClick={() => Close()} >Cancel</Button>
                      <Button icon={<Icon iconName="Accept" />} styles={{ backgroundColor: user.companyPrimaryColor, color: "white" }} onClick={() => submit()}>Submit</Button>

                    </Flex>
                  </Flex>
                </Flex>

              </DialogContent>
            </Dialog>
          </>
          : <></>}</>
    </>

  );
}

export default TimesheetTable