import React from "react"
import { Flex, FlexItem, Menu, Divider, Text, Dropdown, Button, Card, Avatar } from "@fluentui/react-northstar"
import { withRouter } from "react-router-dom"
import { UserStore } from "../../store/user"
import { inject, observer } from "mobx-react"
import { withTranslation } from "react-i18next"
import Schedule from "../../components/schedule";
import { DayHours, EventActions, ProcessedEvent, ViewEvent } from "@aldabil/react-scheduler/types"
import { getUser, getUserRules, GetUsers, setWeekSchedule } from "../../api/account"
import { Result, ScheduledShiftView, ShiftScheduleType, UserShiftScheduleType } from "../../models"
import { getCompany } from "../../api/Company"
import { getScheduledShift, removeScheduledShift, setScheduledShift } from "../../api/shift"
import moment from "moment"



interface Props {
	user: UserStore;
	match: { params: { id?: string } };
	history: any;
	t: any;
};
interface Events {
	event_id: number,
	title: string,
	start: Date,
	end: Date,
	disabled?: boolean,
	editable?: boolean,
	deletable?: boolean,
	draggable?: boolean,
	color: string,
}
interface Employees {
	key: string
	header: string
	userId: string,
	fullName: string,
	jobTitle: string,
	color: string
}

interface IState {
	Title: string;
	items: Events[];
	employees: Employees[];
	startHour: DayHours;
	endHour: DayHours;
	users: any[];
	userId: string;
	startTime: Date;
	endTime: Date;
	fromTo: {
		from: string;
		to: string;
	}
	shift: any;
	ifExist: boolean

}

@observer
@inject("user")
class WeekSchedule extends React.Component<Props, IState> {

	constructor(props: Props) {
		super(props);


		const { t, user } = props;


		this.state = {
			Title: "Week Schedule                    ",
			items: [],
			employees: [],
			startHour: 0,
			endHour: 23,
			users: [],
			userId: "",
			startTime: new Date(),
			endTime: new Date(),
			fromTo: {
				from: "",
				to: ""
			},
			shift: "",
			ifExist: false

		};
	}
	componentDidMount() {
		this.fetchUsers();
		//this.fetchShifts();
	}


	getuserinfo = async (userid: string) => {
		const user = await getUser(userid)
		return user.payload.userRules != undefined? user.payload.userRules.shiftScheduleType : UserShiftScheduleType.InheritedFromCompany
	}

	setfilters = () => {
		var i;
		for (i = 0; i < this.state.employees.length; i++) {
			this.state.employees[i].header = this.state.employees[i]['fullName'];
			this.state.employees[i].key = this.state.employees[i]['userId'];
		}
		this.setState({ users: this.state.employees })
		this.setState({ userId: this.state.employees[0]['userId'] }, () => this.fetchShifts(this.state.userId, this.state.fromTo.from, this.state.fromTo.to))
	}

	fetchUsers = async () => {
		const { t, user } = this.props
		const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
		const users: any = await GetUsers(id)
		const filters: any = await GetUsers(id)
		const company = await getCompany(id)
		users.payload.map(async (user: { id: string; fullName: any; title: any }) => {

			const rules = this.getuserinfo(user.id)
			if (await rules == UserShiftScheduleType.VariableShiftSchedule ||
				(await rules == UserShiftScheduleType.InheritedFromCompany &&
					company.payload.companyRules.shiftScheduleType == ShiftScheduleType.VariableShiftSchedule)) {
				var newEmployee = {
					userId: user.id,
					fullName: user.fullName,
					jobTitle: user.title,
					color: "#9fb8d2"
				} as unknown as Employees | ConcatArray<Employees>

				this.setState(prevState => {
					return {
						employees: prevState.employees.concat(newEmployee)
					};
				}, () => this.setfilters());
			}
		}
		)
	};


	fetchShifts = async (userId: string, from: string, to: string) => {

		const { t, user } = this.props
		const id = this.props.match.params.id ?? this.props.user.company?.id ?? 0;
		var date = new Date()
		const shifts = await getScheduledShift(userId, from, to)
		var validateDate: any[] = []
		var dateofnow = moment().utcOffset(0, true).format().split("T")[0]
		var startTime = moment(from).add(7, 'days').utcOffset(0, true).format().split("T")
		var endTime = moment(to).add(7, 'days').utcOffset(0, true).format().split("T")
		var upcommingshifts = await getScheduledShift(userId, startTime[0], endTime[0])

		for (let i = 0; i <= 7; i++) {
			var day = moment(from).add(i, 'days').utcOffset(0, true).format().split("T")[0]
			if (dateofnow == day) {
				validateDate.push(day)
			}
		}
		if (shifts.payload.length != 0) {
			if (upcommingshifts.payload.length == 0) {
				if (validateDate.length != 0) {
					this.setState({ ifExist: true })
				}
				else {
					this.setState({ ifExist: false })
				}
			}
		}
		else {
			this.setState({ ifExist: false })
		}
		var events: any[] = []
		shifts.payload.map(shift => {

			var newShift = {
				event_id: shift.id,
				title: " ",
				start: new Date(shift.startTime),
				end: new Date(shift.endTime),
				disabled: false,
				editable: true,
				deletable: true,
				draggable: false,
				color: "#BED1DF",
				userId: this.state.userId
			}

			events.push(newShift)

		})
		this.setState({ items: events })
	}

	copyPreviousWeek = async () => {

		const shifts = await getScheduledShift(this.state.userId, this.state.fromTo.from, this.state.fromTo.to)
		for (let i = 0; i < shifts.payload.length; i++) {
			var startTime = moment(shifts.payload[i].startTime).add(7, 'days').utcOffset(0, true).format()
			var endTime = moment(shifts.payload[i].endTime).add(7, 'days').utcOffset(0, true).format()
			var userId = shifts.payload[i].userId

			try {
				const copiedShift = await setScheduledShift({
					startTime: startTime.split("Z")[0],
					endTime: endTime.split("Z")[0],
					userId: userId,
					id: 0

				})
				if (copiedShift) {
					console.log("Schedule copied successfully")
				}
				this.fetchShifts(this.state.userId, this.state.fromTo.from, this.state.fromTo.to)
			} catch (error: any) {
				if (error && error.errors && error.errors[0]) {
					const res = error as Result<never>;
					alert(res.errors[0].description);
				}
			}
		}
	}


	handleConfirm = async (
		event: ProcessedEvent,
		action: EventActions
	): Promise<ProcessedEvent> => {
		if (action === "edit") {
			var startTime = moment(event.start).utcOffset(0, true).format()
			var endTime = moment(event.end).utcOffset(0, true).format()

			var userid = this.state.userId
			var eventid = event.event_id
			try {
				const updatedshift = await setScheduledShift({
					startTime: startTime.split("Z")[0],
					endTime: endTime.split("Z")[0],
					userId: userid,
					id: eventid as number
				})
				if (updatedshift) {
					console.log("Schedule updated successfully")
				}
				this.setState({ items: [] })
				this.fetchShifts(this.state.userId, this.state.fromTo.from, this.state.fromTo.to)
			} catch (error: any) {
				if (error && error.errors && error.errors[0]) {
					const res = error as Result<never>;
					alert(res.errors[0].description);
				}
			}

		} else if (action === "create") {
			var startTime = moment(event.start).utcOffset(0, true).format()
			var endTime = moment(event.end).utcOffset(0, true).format()

			var userid = this.state.userId;
			try {
				const shift = await setScheduledShift({
					startTime: startTime.split("Z")[0],
					endTime: endTime.split("Z")[0],
					userId: userid,
					id: 0
				})

				if (shift) {
					console.log("Schedule added successfully")
				}
				this.setState({ items: [] })
				this.fetchShifts(this.state.userId, this.state.fromTo.from, this.state.fromTo.to)
			} catch (error: any) {
				if (error && error.errors && error.errors[0]) {
					const res = error as Result<never>;
					alert(res.errors[0].description);
				}
			}

		}
		return { ...event, userId: this.state.userId };

	};
	fetchRemote = async (query: ViewEvent): Promise<ProcessedEvent[]> => {
		var datefrom = new Date(query.start)
		var dateto = new Date(query.end)

		var fromYear = datefrom.getFullYear()
		var fromMonth = datefrom.getMonth() + 1
		var fromDate = datefrom.getDate()

		var toYear = dateto.getFullYear()
		var toMonth = dateto.getMonth() + 1
		var toDate = dateto.getDate()

		var from = fromYear + "-" + fromMonth + "-" + fromDate
		var to = toYear + "-" + toMonth + "-" + toDate

		var fromTo = { ...this.state.fromTo }
		fromTo.from = from
		fromTo.to = to

		this.setState({ fromTo: fromTo }, () => this.fetchShifts(this.state.userId, this.state.fromTo.from, this.state.fromTo.to))

		return new Promise((res) => {
			setTimeout(() => {
				res(this.state.items);
			}, 3000);
		});

	};

	handleDelete = async (shiftId: string | number): Promise<string> => {

		var deletedshift = await removeScheduledShift(shiftId as number)
		this.setState({ items: [] })
		this.fetchShifts(this.state.userId, this.state.fromTo.from, this.state.fromTo.to)
		return new Promise((res, rej) => {
			setTimeout(() => {
				res(shiftId as string);
			}, 1000);
		});
	};

	render() {
		const { t, user } = this.props;
		const {
			items,
			employees
		} = this.state;
		return (

			<FlexItem grow>

				<Flex column gap="gap.large" padding="padding.medium" hAlign="center">
					<Flex column gap="gap.medium" styles={{ width: "1500px", maxWidth: "100%" }}>
						<Text content={t("Users")} weight="bold" />
						<Flex gap="gap.large" styles={{ flexDirection: "row" }}>
							<Dropdown
								search
								items={this.state.users}
								onChange={(ev, props: any) => {
									var elements = document.getElementsByClassName("test")
									for (let i = 0; i < elements.length; i++) {
										const div = elements[i].parentElement ? elements[i].parentElement : null
										if (div != null) {
											const content = elements[i].firstChild?.firstChild?.lastChild?.lastChild?.firstChild?.textContent
											if (props.value != null) {
												var fullname = props.value.fullName ? props.value.fullName : " "
												if (fullname == content) {
													div.click()
													this.setState({ userId: props.value.userId }, () => this.fetchShifts(this.state.userId, this.state.fromTo.from, this.state.fromTo.to))
												}
											}
										}
									}
								}}
							/>
							{(this.state.ifExist == true ?
								<Button primary styles={{ width: "275px" }} onClick={this.copyPreviousWeek}>{t("Copy schedule for the coming week")}</Button>
								: null)}
						</Flex>
						<Schedule
							userId={this.state.userId}
							handleConfirm={this.handleConfirm}
							company={false}
							allEmployees={true}
							startHour={this.state.startHour}
							endHour={this.state.endHour}
							events={this.state.items}
							resources={this.state.employees}
							onConfirm={this.handleConfirm}
							getRemoteEvents={this.fetchRemote}
							onDelete={this.handleDelete}
							direction="rtl"
							recourseHeaderComponent=
							{(user: any) => {
								return (
									<div className="test">
										<Card aria-roledescription="card avatar" onClick={() => this.setState({ userId: user.userId }, () => this.fetchShifts(this.state.userId, this.state.fromTo.from, this.state.fromTo.to))}>
											<Card.Header fitted>
												<Flex gap="gap.small">
													<Avatar
														image={user.imageUrl}
														name={user.fullName}

													/>
													<Flex column>

														<Text content={user.fullName} size="small" styles={{ paddingTop: "10px" }} />
														{/* <Text content={user.jobTitle} styles={{fontSize:"8px"}} />   */}
													</Flex>
												</Flex>
											</Card.Header>
										</Card>
									</div>)
							}
							}
							eventRenderer={(event: Events) => {
								var AMPMStart = event.start.toLocaleTimeString().split(" ")[1] == undefined?event.start.toLocaleTimeString().split(" ")[1]:event.start.toLocaleTimeString().split(" ")[1]
                				var AMPMEnd = event.end.toLocaleTimeString().split(" ")[1] == undefined?event.end.toLocaleTimeString().split(" ")[1]:event.end.toLocaleTimeString().split(" ")[1]
								var startTime = event.start.toLocaleTimeString().split(":")[0] +
									":" + event.start.toLocaleTimeString().split(":")[1] + " "
									+ AMPMStart
								var endTime = event.end.toLocaleTimeString().split(":")[0] + ":"
									+ event.end.toLocaleTimeString().split(":")[1] + " "
									+ AMPMEnd


								return <div className="shifts">
									<div style={{ backgroundColor: "rgba(211, 223, 232,0.6)", fontSize: "11px", fontWeight: "semibold", padding: "3px", marginTop: "10px" }}>
										{startTime}-{endTime}
									</div>
								</div>;
							}
							}
						></Schedule>
					</Flex>
				</Flex>
			</FlexItem>


		);
	}
}

export default withRouter(withTranslation()(WeekSchedule as any) as any);

