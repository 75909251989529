import React, { createRef, useEffect, useState } from 'react';
import L, { LatLng, LatLngBounds, latLngBounds } from 'leaflet';
import { Flex,  } from '@fluentui/react-northstar';
import { ActionButton, Text } from '@fluentui/react';
import { MapContainer, TileLayer, 
    Marker, Popup, FeatureGroup, useMapEvents, useMap } from 'react-leaflet';
//import MarkerClusterGroup from 'react-leaflet-markercluster';
import MarkerClusterGroup from 'react-leaflet-markercluster';
// @ts-ignore
//import Control from '@skyeer/react-leaflet-custom-control';
import Control from 'react-leaflet-custom-control'
import { UserActivityView, UserView, WorkplaceModel } from '../../models';
import { UserStore } from '../../store/user';
import { observer, inject } from 'mobx-react';
import { getUsersLocation, addWorkplace, updateWorkplace, getWorkplaces, removeWorkplace } from '../../api/Company';
// @ts-ignore
import './index.scss'
import { Translation, useTranslation, withTranslation } from 'react-i18next';
import defaultProfile from "../../assests/images/defaultProfile.png";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { getDurationFormatedAgo } from '../../utils';
import { withRouter } from 'react-router-dom';
import { EditControl } from "react-leaflet-draw";



declare const baseUrl: string;

interface IProps {
    userInView: UserView;
    user: UserStore;
    companyId?: number;
    editable?: boolean;
    userSpecific?: boolean;
    companySpecific?: boolean;
}

const MapView = (inject("user"))(observer((props: IProps) => {

    const {userInView,user,companyId,editable,userSpecific,companySpecific} = props
    var currentlyRenderedCountry = "";


    //map: L.Map;
    TimeAgo.addLocale(en)
    TimeAgo.setDefaultLocale("en")


    const { t } = useTranslation();
    const [users,setusers] = useState([] as UserActivityView[]);
    const [usersLocations,setusersLocations] = useState([] as any[]);
    const [currentLocation,setcurrentLocation] = useState(new LatLng(0.0, 0.0) as LatLng );
    const [workplaces , setworkplaces] = useState ([] as (WorkplaceModel & { polygon?: L.Polygon })[])
    const [featureGroupVisible,setfeatureGroupVisible] = useState((props.userSpecific?? false) || (props.companySpecific?? false))
    const [zoom,setzoom] = useState(12);
    const [bounds,setbounds] = useState(new LatLngBounds([0,0],[0,0]) as LatLngBounds )
    const [lastzoomlevel,setLastzoomlevel] = useState();
    const [lastCenter,SetLastCenter] = useState();

    useEffect(()=>{
        fetchData()
       },[])
       
    const fetchData = async () => {

        var companyId  = props.companyId ? props.companyId :(props.user.company !=null ? props.user.company.id : 0)
        var users = await getUsersLocation(companyId, false);
        var usersLocations = [] as any[];
        setusers(users.payload);
       // console.log("usersloc" , users)
        var workplaces = await getWorkplaces(companyId, props.userInView ? props.userInView.id : props.user.userId, props.userSpecific);
        setworkplaces(workplaces.payload)

        var bounds = [];

        for (var i=0; i < users.payload.length; i++){
            var element = users.payload[i]
            bounds.push(new L.LatLng(element.latitude, element.longitude))
        }

        for (var j=0; j < users.payload.length; j++){
            var elementj = users.payload[j]
            usersLocations.push({userIndex : j, countryName : elementj.countryName?? "Unidentified Country"})
        }

        usersLocations.sort((a, b) => a.countryName.localeCompare(b.countryName));  
        setusersLocations(usersLocations)
        

        if (lastCenter && lastzoomlevel){
            setzoom(lastzoomlevel);
            setcurrentLocation(lastCenter);
    
        } else if (bounds.length > 0){
            setbounds(latLngBounds(bounds));
            navigator.geolocation.getCurrentPosition((pos) => {
                setcurrentLocation(new LatLng(pos.coords.latitude,pos.coords.longitude))
            },() => console.log("location",currentLocation))
        } else {
            navigator.geolocation.getCurrentPosition((pos) => {
                setcurrentLocation(new LatLng(pos.coords.latitude, pos.coords.longitude));
            },() => console.log("location",currentLocation));
        }

    }
    const ChangeMapView = ({lat,lng,zoom} :any) => {
        const map = useMap();
         useEffect(() => {
           map.setView([lat, lng],zoom);
         }, [lat, lng, zoom]);
         return null;
       }

    const  relodmap = async () => {
        setfeatureGroupVisible(false)
        await fetchData()
        setfeatureGroupVisible(true)
    }

    
   const onCreated = async (e: any) => {
        var latlngs = (e.layer as any)._latlngs[0];
        await addWorkplace(props.userInView.company ? (props.userInView.company?.id) : props.companyId?? 0, 
        props.userInView.id, {
            bounds: latlngs,
            id: 0
        }).then(() => {
            relodmap();
        });
    }

    const onEdited = async (e: any) => {
        var layers = e.layers.getLayers();
        for (var i = 0; i < layers.length; i++) {
            var layer = layers[i] as unknown as { id: number, _latlngs: LatLng[][] };
            await updateWorkplace(layer.id, { bounds: layer._latlngs[0], id: 0 })
        }
         relodmap();     
    }
    const onDeleted = async (e: any) => {
        var layers = e.layers.getLayers();
        for (var i = 0; i < layers.length; i++) {
            var layer = layers[i] as unknown as { id : number };
            await removeWorkplace(layer.id)
        }
        relodmap();
    }
    const onUserClick = (user : any) => {
        //this.setState({ bounds: undefined, currentLocation: new L.LatLng(user.latitude, user.longitude), zoom: 12 });
         setbounds(new LatLngBounds([0,0],[0,0]));
         setzoom(20);
         setcurrentLocation(new LatLng(user.latitude, user.longitude));

    }
    const onCountryClick = (user : any) => {
        // this.setState({ bounds: undefined, currentLocation: new L.LatLng(user.latitude, user.longitude), zoom: 9 });
        setbounds(new LatLngBounds([0,0],[0,0]));
        setzoom(9);
        setcurrentLocation(new L.LatLng(user.latitude, user.longitude))
    }


  return (
       
            <MapContainer 
            style={{ height: "100%", width: '100%' }}
            center = {currentLocation}
            zoom={zoom}
            // onViewportChanged={(props: any) => this.onViewportChanged(props)}
            scrollWheelZoom = {true}
            >
            <TileLayer url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
            <ChangeMapView lat={currentLocation.lat} lng={currentLocation.lng} zoom={zoom} />
            {props.userSpecific ? (<></>) : (<>    
                    {users && users.map(x => {
                        return (
                        <Marker key={x.id} position={[x.latitude, x.longitude]}
                            icon={new L.Icon.Default()} >
                            <Popup>
                                <Flex hAlign='center'><img alt="Profile" src={x.imageUrl ? baseUrl + x.imageUrl : defaultProfile} height="50" width="50" style={{ objectFit: "cover" }} /></Flex>
                                <br />
                                <Flex column hAlign='center'>

                                <a href={`/users/${x.id}/${x.country.isGcc? 1 : 0}/timesheet`}>{x.fullName}</a>
                                {/* <Text>{t('Clock In: ') + x.lastActivityTime}</Text>
                                <Text>{t('Working Duration: ') + x.workingDuration}</Text> */}
                                <div >
                                    <Text styles={{root : {textAlign: "center"}}}>{t(x.lastActivity)} - {user.locale === 'ar' ? t("ago") : ""} {getDurationFormatedAgo(x.lastActivityDuration)} {user.locale === 'ar' ? "" : t("ago")}</Text>
                                </div>
                                <div style={{direction: user.rtl ? 'rtl' : 'ltr'}}>
                                    <Text styles={{root : {textAlign: "center"}}}>{t("Working Duration-") + ((x.workingDuration.split('.').length) <= 2 ? x.workingDuration.split('.')[0] : x.workingDuration.split('.')[0] + " " + t("days") + "," + x.workingDuration.split('.')[1]) }</Text>
                                </div>
                                </Flex>
                            </Popup>
                        </Marker>
                    )})}
             <Control position="bottomright">
                    <div className="mapNavContainer">
                        {users && usersLocations && usersLocations.map((x, i) => {
                            if (x.countryName !== currentlyRenderedCountry){
                                currentlyRenderedCountry = x.countryName
                                return (
                                    <div key={"actionButton" + x.userIndex}>
                                        <div>
                                            <ActionButton
                                                //iconProps={{ iconName: 'Location' }} 
                                                onClick={() => onCountryClick(users[x.userIndex])}>
                                                {x.countryName}
                                            </ActionButton>
                                        </div>
                                        <div style={{marginLeft: '10px'}}>
                                            <ActionButton 
                                                iconProps={{ iconName: 'Location' }} 
                                                onClick={() => onUserClick(users[x.userIndex])}>
                                                {users[x.userIndex].fullName}
                                            </ActionButton>
                                        </div>
                                    </div>)
                            }
                            return (
                            <div key={"actionButton" + x.userIndex} style={{marginLeft: '10px'}}>
                                <ActionButton 
                                    iconProps={{ iconName: 'Location' }} 
                                    onClick={() => onUserClick(users[x.userIndex])}>
                                    {users[x.userIndex].fullName}
                                </ActionButton>
                            </div>)
                        })}
                    </div>
                </Control></>)
                }
               {
                  featureGroupVisible ? (
                        <FeatureGroup 
                        ref={ref => {
                             workplaces.forEach(x => {
                                if (ref) {
                                    if (x.polygon) {
                                        ref.removeLayer(x.polygon); 
                                        //ref.removeLayer(x.polygon);
                                    }
                                    x.polygon = L.polygon(x.bounds);
                                    //x.polygon.addTo(ref.leafletElement);
                                    x.polygon.addTo(ref);
                                    (x.polygon as any).id = x.id;
                                }
                            });
                        }}
                        >
                            {/* {props.editable || props.editable === undefined ? 
                            {/*  <EditControl draw={{
                                rectangle: false,
                                polyline: false,
                                circle: false,
                                marker: false,
                                circlemarker: false,
                                polygon: {
                                    allowIntersection: false,
                                }
                            }}
                             
                                position = "topleft"
                                onDeleted={onDeleted}
                                onEdited={onEdited}
                                onCreated={onCreated} 
                                />
                                : null

                    
                        } */}

                        {props.editable || props.editable === undefined?
                            <EditControl
                            position = "topleft"
                            onEdited={onEdited}
                            onCreated={onCreated} 
                            onDeleted={onDeleted} 
                             draw={{
                                rectangle: false,
                                polyline: false,
                                circle: false,
                                marker: false,
                                circlemarker: false,
                                polygon: {
                                    allowIntersection: false,
                                }
                             }}
                            />
                        :null 
                        }

                        </FeatureGroup>
                    ) : (<></>)
                } 


            </MapContainer >

  );
  
  }));
export default withRouter(withTranslation()(MapView as any) as any)as unknown as React.FunctionComponent<IProps>;
  