import { serialize } from "object-to-formdata";
import request from "../utils/request";
import { CompanyView, Company, CompanyInput, Result, SubscriptionPlan, UserActivityView, WorkplaceModel, SignUpTrialInput, ExtendTrialInput, ActivateTrialCompanyInput, Branch, Department, CompanyRulesView, CompanyRulesInput, WeekScheduleView, WeekScheduleInput } from "../models/index";
import { List } from "@fluentui/react";

// export async function getLeavePerUser(userId: string) {
//    const response = await request.get<Result<LeavesModel[]>>(`Leave/GetLeavePerUser/${userId}`);
//    return response.data;
// }

// export async function getBalancePerUser(userId: string) {
//    const response = await request
//        .get<Result<LeaveBalanceModel[]>>(`Leave/GetBalancePerUser/${userId}`);
//    return response.data;
// }

export async function getCompanies(organizationId?: number) {
  const response = await request
    .get<Result<CompanyView[]>>(`Company/GetCompanies/${organizationId ?? ""}`);
  return response.data;
}

export async function getCompany(id: string | number) {
  const response = await request.get<Result<CompanyView>>(`Company/${id}`);
  return response.data;
}

export async function addCompany(input: CompanyInput) {
  const response = await request
    .post<Result<Company>>("Company/AddCompany", serialize(input));
  return response.data;
}

export async function signupTrial(input: SignUpTrialInput) {
  const response = await request
    .post<Result<Company>>("Company/SignupTrial", serialize(input));
  return response.data;
}

export async function extendTrial(input: ExtendTrialInput) {
  const response = await request
    .post<Result<boolean>>("Company/ExtendTrial", input);
  return response.data;
}

export async function activateTrialCompany(input: ActivateTrialCompanyInput) {
  const response = await request
    .post<Result<boolean>>("Company/ActivateTrialCompany", input);
  return response.data;
}

export async function getPlans() {
  const response = await request.get<Result<SubscriptionPlan[]>>("Company/Plans");
  return response.data;
}

export async function updateCompany(id: number, input: CompanyInput) {
  const response = await request.put<Result<Company>>(`Company/${id}`, serialize(input));
  return response.data;
}

export async function deleteCompany(id: number) {
  const response = await request.delete<Result<{}>>(`Company/${id}`);
  return response.data;
}

export async function getUsersLocation(companyId: number, onlyAvailable: boolean) {
  const response = await request.get<Result<UserActivityView[]>>(`Company/${companyId}/Location`);
  return response.data;
}

export async function getWorkplaces(companyId: number, userId: string, userSpecificOnly?: boolean) {
  const response = await request.get<Result<WorkplaceModel[]>>(`Company/${companyId}/${userId}/${userSpecificOnly ?? false}/Workplaces`);
  return response.data;
}

export async function addWorkplace(companyId: number, userId: string, workplace: WorkplaceModel) {
  const response = await request.post<Result<WorkplaceModel>>(`Company/${companyId}/${userId}/Workplaces`, workplace);
  return response.data;
}

export async function removeWorkplace(workplaceId: number) {
  const response = await request.delete<Result<boolean>>(`Company/Workplaces/${workplaceId}`);
  return response.data;
}

export async function updateWorkplace(workplaceId: number, workplace: WorkplaceModel) {
  const response = await request.put<Result<WorkplaceModel>>(`Company/Workplaces/${workplaceId}`, workplace);
  return response.data;
}

export async function getBranches(companyId: number) {
  const response = await request.get<Result<Branch[]>>(`Company/Branches/${companyId}`);
  return response.data;
}

export async function getDepartments(companyId: number) {
  const response = await request.get<Result<Department[]>>(`Company/Departments/${companyId}`);
  return response.data;
}
export async function getCountryDepartments(countryId: number[], companyId: number) {
  const response = await request.post<Result<Department[]>>(`Company/GetCountryDepartments/${companyId}`, countryId);
  return response.data;
}
export async function getCountryBranches(countryId: number[], companyId: number) {
  const response = await request.post<Result<Branch[]>>(`Company/GetCountryBranches/${companyId}`, countryId);
  return response.data;
}
export async function getBranchDepartments(branchId: number[], companyId: number) {
  const response = await request.post<Result<Department[]>>(`Company/GetBranchDepartments/${companyId}`, branchId);
  return response.data;
}
export async function addUpdateBranches(companyId: number, branches: Branch[]) {
  const response = await request.post<Result<Branch[]>>(`Company/Branches/${companyId}`, branches);
  return response.data;
}

export async function addUpdateDepartments(companyId: number, departments: Department[]) {
  const response = await request.post<Result<Department[]>>(`Company/Departments/${companyId}`, departments);
  return response.data;
}

export async function getCompanyRules(companyId: number) {
  const response = await request.get<Result<CompanyRulesView>>(`Company/GetCompanyRules/${companyId}`);
  return response.data;
}

export async function updateCompanyRules(companyId: number, input: CompanyRulesInput) {
  const response = await request.post<Result<CompanyRulesView>>(`Company/UpdateCompanyRules/${companyId}`, input);
  return response.data;
}

export async function getWeekSchedule(companyId: string | number) {
  const response = await request.get<Result<WeekScheduleView>>(`Company/GetWeekSchedule/${companyId}`);
  return response.data;
}
export async function setWeekSchedule(details: WeekScheduleInput) {
  const response = await request.post<Result<WeekScheduleView>>(`Company/SetWeekSchedule`, details);
  return response.data;
}


