import React from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Button, Dialog, Flex, TextArea, Text, Alert, Loader, Breadcrumb } from "@fluentui/react-northstar";
import Clock from "react-live-clock";
import { withRouter } from "react-router-dom";
import WebCam from "react-webcam";
import { Panel, PanelType, Separator, TextField } from "@fluentui/react";
import { ClockingType, ClientType, ClockingInput, ClockingView, WorkplaceConfig, AttendanceImageConfig, UserAttendanceImageConfig, BreakConfig, UserFaceRecognitionConfig, FaceRecognitionConfig } from "../../models";
import Timer from "../../components/timer";
import GpsInput from "../../components/gps-input";
import { UserStore } from "../../store/user";
import * as AttendanceApi from "../../api/attendance";
import MediaQuery from "../../components/media-query";
import "./index.scss";
import TimesheetTable from "../../components/timesheet-table";
import { getFormattedDuration, isLocInsideWorkplaces } from "../../utils";
import { ChevronEndIcon, CloseIcon, PlayIcon, PauseIcon } from '@fluentui/react-icons-northstar';
import { getWorkplaces, getCompanyRules } from "../../api/Company";
import { withTranslation } from "react-i18next";
import { UiStore } from "../../store/ui";
import Icons from "../../assests/images/SVGIcons/svgIcons";
import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { loadLabeledFaceDescriptors, loadModels } from "../../utils/faceSystem.js";
import ClockDialog from "../../components/ClockDialog";
import { FaceMatcher, LabeledFaceDescriptors } from "face-api.js";
import { addLabeledFaceDescriptors } from "../../api/account";


function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(",");
  const mime = (arr[0].match(/:(.*?);/) ?? [])[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}


type Props = Readonly<{
  t: any;
  ui: UiStore;
  user: UserStore;
  match: { params: { action?: string, shiftId?: number } }
}>;

type State = {
  dialogVisible: boolean;
  input: ClockingInput;
  clockingType: ClockingType;
  loading: boolean;
  clockingStatus?: ClockingView[];
  error?: string;
  panelOpen: boolean;
  errorDialogOpen: boolean;
  errorDialogContent: string;
  errorDialogCancelAllowed: boolean;
  errorDialogConfirmButtonContent: string;
  errorDialogCancelButtonContent: string;
  overtimeVerificationAlertVisible: boolean;
  inBreakVerificationAlertVisible: boolean;
  BreakConfig: BreakConfig;
  labeledFaceDescriptors: any[];
  faceMatch: string;
  stopFaceRecog: boolean;
  stopCam: boolean;
  webcam: any;
  loadedLabeledFaceDescriptors: boolean;
  faceRecognitionRequired: boolean;
  warningVisible: boolean;
  alertContent: string
};

@inject("user")
@observer
class Attendance extends React.Component<Props, State> {
  interval: NodeJS.Timeout | null;
  webcamRef = React.createRef<WebCam>() as React.RefObject<WebCam> & string;
  gpsRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    const { t } = props
    this.interval = null;
    this.state = {
      dialogVisible: false,
      clockingType: ClockingType.ClockIn,
      input: {
        userId: props.user.userId,
        comments: "",
        clientType: ClientType.Web,
        time: new Date(),
        fullAddress: "",
        hoursOffset: 0,
        image: null,
      },
      loading: false,
      panelOpen: false,
      errorDialogOpen: false,
      errorDialogContent: '',
      errorDialogCancelAllowed: false,
      errorDialogConfirmButtonContent: t('OK'),
      errorDialogCancelButtonContent: t('Cancel'),
      overtimeVerificationAlertVisible: false,
      inBreakVerificationAlertVisible: false,
      BreakConfig: BreakConfig.NotRequired,
      labeledFaceDescriptors: [],
      loadedLabeledFaceDescriptors: false,
      faceRecognitionRequired: false,
      faceMatch: "unknown",
      stopFaceRecog: false,
      stopCam: false,
      warningVisible: false,
      alertContent: "",
      webcam: <><WebCam ref={this.webcamRef}
        audio={false}
        style={{ backgroundColor: "lightgrey", width: "100%" }}
        id="cameraFeed" /><canvas id="reflay" className="overlay" style={{ position: 'absolute', top: 0, left: 0 }}></canvas></>
    };
  }

  async faceRecognitionPrep() {
    if (!this.props.user.userFaceImage || !this.props.user.userFaceImage?.path) {
      this.setState({ warningVisible: true, alertContent: "Update your profile picture for Face Recognition to work" })
    }
    else {
      if (!this.props.user.faceRecognitionModelsLoaded) {
        await loadModels()
        this.props.user.faceRecognitionModelsLoaded = true;
      }
      console.log("loading descriptors")
      if (this.props.user.userFaceImage?.labeledFaceDescriptors && this.props.user.userFaceImage?.labeledFaceDescriptors != "[[]]") {
        const labeledFaceDescriptors: any = this.props.user.userFaceImage?.labeledFaceDescriptors;
        const deserializedLabeledFaceDescriptors: any[] = JSON.parse(labeledFaceDescriptors);
        const reconstructedLabeledFaceDescriptors: LabeledFaceDescriptors[] = deserializedLabeledFaceDescriptors.map((item: any) => {
          const reconstructedDescriptors = item.descriptors.map((array: number[]) => new Float32Array(array));
          return new LabeledFaceDescriptors(item.label, reconstructedDescriptors);

        });
        var reconstructedDescriptors = reconstructedLabeledFaceDescriptors
        this.props.user.labeledFaceDescriptors = reconstructedDescriptors
        this.setState({ loadedLabeledFaceDescriptors: true })
      }
      else {
        const labeledFaceDescriptors: any[] = await loadLabeledFaceDescriptors(this.props.user.userFaceImage?.path, this.props.user.firstName);
        if (labeledFaceDescriptors[0].length == 0) {
          this.setState({ warningVisible: true, alertContent: "Update your profile picture for Face Recognition to work" })
        }
        else {
          const serializedLabeledFaceDescriptors = JSON.stringify(labeledFaceDescriptors)
          addLabeledFaceDescriptors({
            labeledFaceDescriptors: serializedLabeledFaceDescriptors,
            imageId: this.props.user.userFaceImage?.id
          })
          this.props.user.labeledFaceDescriptors = labeledFaceDescriptors
          this.setState({ loadedLabeledFaceDescriptors: true })
        }
      }
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    if ((this.props.user.userFaceRecognitionConfig == UserFaceRecognitionConfig.InheritedFromCompany
      && this.props.user?.company?.faceRecognitionConfig == FaceRecognitionConfig.Required)
      || this.props.user.userFaceRecognitionConfig == UserFaceRecognitionConfig.Required) {
      this.faceRecognitionPrep()
      this.setState({ faceRecognitionRequired: true })
    }
    this.getCompanyBreakConfig();
    if (!this.props.user.firstName)
      this.props.user.getUserInfo();
    else
      this.props.user.getStatus();
    const updateEvery = 1000;
    this.interval = setInterval(() => {
      this.update(updateEvery);
    }, updateEvery);
    if (!this.state.clockingStatus) {
      this.fetch();
    }
    if (this.props.match.params.action && this.props.match.params.action.toLowerCase().indexOf("verifyovertime") > -1) {
      if (this.props.match.params.shiftId)
        AttendanceApi.verifyOvertime(this.props.match.params.shiftId).then(res => {
          if (res.status === 200 && res.payload) {
            this.setState({ overtimeVerificationAlertVisible: true })
            setTimeout(() => this.setState({ overtimeVerificationAlertVisible: false }), 10000)
          }
        }).catch(err => {
          console.log(err)
        });

    }
    else if (this.props.match.params.action && this.props.match.params.action.toLowerCase().indexOf("verifyinbreak") > -1) {
      if (this.props.match.params.shiftId)
        AttendanceApi.verifyInBreak(this.props.match.params.shiftId).then(res => {
          if (res.status === 200 && res.payload) {
            this.setState({ inBreakVerificationAlertVisible: true })
            setTimeout(() => this.setState({ inBreakVerificationAlertVisible: false }), 1000)
          }
        }).catch(err => {
          console.log(err)
        })

    }
    this.setUpSignalRConnection()
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }

  }
  openDialog = (type: ClockingType) => {
    this.getLocation()
    this.setState({
      dialogVisible: true,
      //stopFaceRecog: false,
      stopCam: false,
      // loading: true, 
      clockingType: type, error: ""
    }
    );

  };

  update = (interval: number) => {
    const { user } = this.props;
    user.now = moment(new Date());
    user.incrementWorkingTimer = user.isTimeRunning && !user.isBreakTimeRunning ? user.incrementWorkingTimer + (interval / 1000) : user.incrementWorkingTimer;
    user.incrementBreakTimer = user.isBreakTimeRunning ? user.incrementBreakTimer + (interval / 1000) : user.incrementBreakTimer;
  };



  setUpSignalRConnection = async () => {
    const connection = new HubConnectionBuilder()
      .withUrl('/api/mainhub')
      .withAutomaticReconnect()
      .build();
    connection.on('Message', (message: string) => {
      console.log('Message', message);
    });
    connection.on('UserClocks', (message: string) => {
      //console.log("Message", message)
      this.props.user.getUserInfo();
      this.fetch()
    });
    try {
      await connection.start();
    } catch (err) {
      console.log(err);
    }

    if (connection.state === HubConnectionState.Connected) {
      connection.invoke('SubscribeToPersonalAttendancePool', this.props.user.userId).catch((err: Error) => {
        return console.error(err.toString());
      });
    }
    return connection;
  };
  fetch = async () => {
    this.setState({ loading: true });
    const today = new Date().toJSON().split('T')[0];
    const { payload } = await AttendanceApi.getClockingsPerDay(this.props.user.userId, today, today);
    if (payload)
      this.setState({ loading: false, clockingStatus: payload[0] });
    else
      this.setState({ loading: false })
  };
  getCompanyBreakConfig = async () => {
    var breakConfig = (await getCompanyRules(this.props.user.company?.id as number)).payload.breakConfig;
    this.setState({ BreakConfig: breakConfig });
  }


  getLocation = () => {
    this.gpsRef.current && this.gpsRef.current.getLocation();
  };

  closeDialog = () => {
    // this.setState({ stopFaceRecog: true })
    this.setState({ dialogVisible: false }, () => { console.log("dialog closed") })
  }

  setStopFaceRecog = () => {
    this.setState({ stopFaceRecog: true })
    this.setState({ stopCam: true })
  }

  render() {

    const { user, t } = this.props;
    const {
      breaksTime, totalBreaksTime, totalWorkingTime, workingTime, currentWorkingDuration,
    } = user as UserStore;

    const timeTxt = workingTime ? getFormattedDuration(workingTime.asSeconds()) : "00:00:00";
    const breakTxt = breaksTime ? getFormattedDuration(breaksTime.asSeconds()) : "00:00:00";
    const totalTimeTxt = totalWorkingTime
      ? getFormattedDuration(totalWorkingTime.asSeconds()) : "00:00:00";
    const totalBreakTxt = totalBreaksTime
      ? getFormattedDuration(totalBreaksTime.asSeconds()) : "00:00:00";

    const fullTime = moment.duration(currentWorkingDuration * 3600000);
    const progress = workingTime ? workingTime?.asSeconds() / fullTime.asSeconds() : 0;

    var webcam = <><WebCam ref={this.webcamRef}
      audio={false}
      style={{ backgroundColor: "lightgrey", width: "100%" }}
      id="cameraFeed" /><canvas id="reflay" className="overlay" style={{ position: 'absolute', top: 0, left: 0 }}></canvas></>

    if (user.attendanceImageConfig === UserAttendanceImageConfig.InheritedFromCompany) {
      if (user.company?.attendanceImageConfig === AttendanceImageConfig.NotRequired)
        webcam = <></>
    } else {
      if (user.attendanceImageConfig === UserAttendanceImageConfig.NotRequired)
        webcam = <></>
    }

    return (
      <>
        {/* {dialogs} */}
        {/* {this.state.faceRecognitionRequired ? this.props.user.labeledFaceDescriptors.length > 0 ? */}
        <ClockDialog
          user={this.props.user}
          updateParent={() => { this.fetch() }}
          webcamRef={this.webcamRef}
          webcam={this.state.webcam}
          gpsRef={this.gpsRef}
          dialogVisible={this.state.dialogVisible}
          closeDialog={() => { this.closeDialog() }}
          clockingType={this.state.clockingType}
          faceMatcher={this.state.faceRecognitionRequired ? this.state.loadedLabeledFaceDescriptors ?
            new FaceMatcher(this.props.user.labeledFaceDescriptors, 0.6) : [] : []}
          primaryColor={this.props.user.companyPrimaryColor ?? "#005bab"}
          faceRecognitionRequired={this.state.faceRecognitionRequired}
        //stopFaceRecognition = {this.state.stopFaceRecog}
        ></ClockDialog>

        {/* // : null } */}


        <MediaQuery minWidth="1200" callback={(lg) => (
          <Flex vAlign="stretch">
            <Flex column styles={{
              justifyContent: "start",
              alignItems: "center",
              flex: 1,
            }} gap="gap.small" padding="padding.medium">
              <Alert visible={this.state.overtimeVerificationAlertVisible} content={t("Overtime verified successfully")} success />
              <div style={{
                width: "100%",
                minWidth: "250px",
                maxWidth: "500px",
                marginBottom: "-70px"
              }}>
                <Alert visible={this.state.inBreakVerificationAlertVisible} content={t("Extra break time verified successfully")} success />
                <div style={{
                  width: "100%",
                  minWidth: "250px",
                  maxWidth: "500px",
                  marginBottom: "-70px"
                }}></div>
                <Timer text={timeTxt} progress={progress} secondaryText={totalTimeTxt} primaryColor={user.companyPrimaryColor} />
              </div>
              <Text size="larger" align="center">
                <Clock format={"dddd, Do MMMM, h:mm:ss A"} ticking />
              </Text>
              {this.state.BreakConfig == BreakConfig.Required ?
                <Text size="medium" align="center">
                  <div>
                    <b>{t("Break(shift)-")}</b>
                    <span>{breakTxt}</span>
                  </div>
                  <div>
                    <b>{t("Break(today)-")}</b>
                    <span>{totalBreakTxt}</span>
                  </div>
                </Text>
                : null}
              {this.state.warningVisible ? <Alert visible={this.state.warningVisible}
                content={this.state.alertContent} warning /> :
                this.state.faceRecognitionRequired ? this.state.loadedLabeledFaceDescriptors ?
                  <><div style={{ width: "200px", marginTop: "2em" }}>
                    {user.allowedActions.includes(ClockingType.BreakIn) && this.state.BreakConfig == BreakConfig.Required ?
                      <Button fluid size="medium" icon={<Icons.BreakIn />}
                        content={t("Break In")} circular
                        onClick={() => this.openDialog(ClockingType.BreakIn)} />
                      : null}
                    {user.allowedActions.includes(ClockingType.BreakOut) && this.state.BreakConfig == BreakConfig.Required ?
                      <Button fluid size="medium" icon={<Icons.BreakOut />}
                        content={t("Break Out")} circular
                        onClick={() => this.openDialog(ClockingType.BreakOut)} />
                      : null}
                  </div><div style={{ width: "200px" }}>
                      {user.allowedActions.includes(ClockingType.ClockIn) &&
                        <Button fluid size="medium" icon={<Icons.ClockIn fill='#ffffff' />} primary
                          content={t("Clock In")} circular
                          onClick={() => this.openDialog(ClockingType.ClockIn)} />}
                      {user.allowedActions.includes(ClockingType.ClockOut) && (

                        <Button fluid size="medium" icon={<Icons.ClockOut fill='#ffffff' />} primary
                          content={t("Clock Out")} circular
                          onClick={() => this.openDialog(ClockingType.ClockOut)} />)}

                    </div></> : <Loader /> :
                  <><div style={{ width: "200px", marginTop: "2em" }}>
                    {user.allowedActions.includes(ClockingType.BreakIn) && this.state.BreakConfig == BreakConfig.Required ?
                      <Button fluid size="medium" icon={<Icons.BreakIn />}
                        content={t("Break In")} circular
                        onClick={() => this.openDialog(ClockingType.BreakIn)} />
                      : null}
                    {user.allowedActions.includes(ClockingType.BreakOut) && this.state.BreakConfig == BreakConfig.Required ?
                      <Button fluid size="medium" icon={<Icons.BreakOut />}
                        content={t("Break Out")} circular
                        onClick={() => this.openDialog(ClockingType.BreakOut)} />
                      : null}
                  </div><div style={{ width: "200px" }}>
                      {user.allowedActions.includes(ClockingType.ClockIn) &&
                        <Button fluid size="medium" icon={<Icons.ClockIn fill='#ffffff' />} primary
                          content={t("Clock In")} circular
                          onClick={() => this.openDialog(ClockingType.ClockIn)} />}
                      {user.allowedActions.includes(ClockingType.ClockOut) && (

                        <Button fluid size="medium" icon={<Icons.ClockOut fill='#ffffff' />} primary
                          content={t("Clock Out")} circular
                          onClick={() => this.openDialog(ClockingType.ClockOut)} />)}

                    </div></>

              }
              {/* <div style={{ width: "200px", marginTop: "2em" }}>
                {user.allowedActions.includes(ClockingType.BreakIn) && this.state.BreakConfig == BreakConfig.Required ?
                  <Button fluid size="medium" icon={<Icons.BreakIn />}
                    content={t("Break In")} circular
                    onClick={() => this.openDialog(ClockingType.BreakIn)} />
                :null}
                {user.allowedActions.includes(ClockingType.BreakOut) && this.state.BreakConfig == BreakConfig.Required ? 
                  <Button fluid size="medium" icon={<Icons.BreakOut />}
                    content={t("Break Out") } circular
                    onClick={() => this.openDialog(ClockingType.BreakOut)} />
               :null}
              </div>
              <div style={{ width: "200px" }}>
                {user.allowedActions.includes(ClockingType.ClockIn) && 
                    <Button fluid size="medium" icon={<Icons.ClockIn fill='#ffffff' /> } primary
                    content={t("Clock In")} circular
                    onClick={() => this.openDialog(ClockingType.ClockIn)} />  
                }
                {user.allowedActions.includes(ClockingType.ClockOut) && (

                    <Button fluid size="medium" icon={<Icons.ClockOut fill='#ffffff' />} primary
                    content={t("Clock Out")} circular
                    onClick={() => this.openDialog(ClockingType.ClockOut)} /> 
                
              </div> */}
            </Flex>
            {!lg && <>
              <Panel isOpen={this.state.panelOpen}
                isLightDismiss
                onDismiss={() => this.setState({ panelOpen: false })}
                customWidth="800px"
                type={PanelType.custom}
              >
                <TimesheetTable
                  selectedDay={new Date()}
                  Reload={() => { }}
                  timesheet={false}
                  user={user}
                  userId={user.userId}
                  clockings={this.state.clockingStatus}
                  loading={this.state.loading}
                  detailsListStyles={{
                    root: {
                      height: "calc(100vh - 107px)",
                      overflowY: "auto",
                      overflowX: "hidden"
                    },
                  }}
                  onClockTimeChange={() => { }}
                  showImages={!this.props.user.company?.hideClockingImagesFromTimesheet ?? false}
                  getShiftHistory={() => { }}
                  shiftHistory={[]}
                  timeSheetView={false}
                />
              </Panel>
              <Flex styles={{ ":hover": { backgroundColor: "lightgrey" } }}
                onClick={() => this.setState({ panelOpen: true })}
                vAlign="center" hAlign="center">
                <Button iconOnly text icon={<ChevronEndIcon />} />
              </Flex>
            </>}
            {lg && <>
              <Separator vertical />
              <TimesheetTable
                selectedDay={new Date()}
                Reload={() => { }}
                timesheet={false}
                userId={user.userId}
                user={user}
                clockings={this.state.clockingStatus}
                loading={this.state.loading}
                detailsListStyles={{
                  root: {
                    flex: 1,
                    height: "calc(100vh - 107px)",
                    overflowY: "auto",
                    overflowX: "hidden"
                  },
                }}
                onClockTimeChange={() => { }}
                showImages={!this.props.user.company?.hideClockingImagesFromTimesheet ?? false}
                getShiftHistory={() => { }}
                shiftHistory={[]}
                timeSheetView={false}
              />
            </>}
          </Flex>
        )} />
      </>
    );
  }
}

export default withRouter(withTranslation()(Attendance as any) as any);
