import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import { Flex, FlexItem, Divider, Button, Input, UserFriendsIcon, MoreIcon, TrashCanIcon, ShiftActivityIcon, Dialog, ApprovalsAppbarIcon } from "@fluentui/react-northstar";
import { CommandBar, SearchBox, IColumn, ShimmeredDetailsList, TooltipHost, TextField, Dropdown, Text } from "@fluentui/react";
import { getCompanies, deleteCompany, extendTrial, activateTrialCompany } from "../../api/Company";
import { UserStore } from "../../store/user";
import { CompanyView, OrganizationView } from "../../models";
import { copyAndSort, shadeBlend } from "../../utils";
import Pagination from "react-paginating";
import { withTranslation } from "react-i18next";
import { GetOrganizations } from "../../api/Organization";

interface IProps {
  user: UserStore;
  match: { params: { id?: number } };
  history: any;
  t: any
}

interface IState {
  loading: boolean;
  companies: CompanyView[];
  organizations: OrganizationView[];
  nonTrialOrganizations: OrganizationView[];
  showFilters: boolean;
  columns: IColumn[];
  offset: number;
  count: number;
  items: CompanyView[];
  extendTrailDialogOpen: boolean;
  extendTrialDays: number;
  extendTrialCompanyId: number;
  trialFilterValue?: string | number;
  activateTrialCompanyId: number;
  activateTrialCompanyOrganizaitonId: number;
  activateTrailCompanyDialogOpen: boolean;
}

@inject("user")
@observer
class Companies extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    const { t } = props;

    this.state = {
      count: 10,
      offset: 0,
      items: [],
      companies: [],
      organizations: [],
      nonTrialOrganizations: [],
      loading: false,
      showFilters: false,
      columns: [
        {
          key: "name",
          name: t("Name"),
          onRender: (item: CompanyView) => item.name,
          minWidth: 80,
          maxWidth: 200,
        },
        {
          key: "country",
          name: t("Country"),
          onRender: (item: CompanyView) => item.country.name,
          minWidth: 80,
          maxWidth: 200,
        },
        // {
        //   key: "plan",
        //   name: "Plan",
        //   onRender: (item: CompanyView) => item.plan,
        //   minWidth: 80,
        //   maxWidth: 200,
        // },
        // {
        //   key: "NumberOfUsers",
        //   name: t("Subscribed Users"),
        //   onRender: (item: CompanyView) => (item.numberofUsers + "/" + item.maxNumberOfUsers),
        //   minWidth: 80,
        //   maxWidth: 200,
        // },
        // {
        //   key: "Maxnumberofusers",
        //   name: "Max Number Of Users",
        //   onRender: (item: CompanyView) => item.maxNumberOfUsers,
        //   minWidth: 80,
        //   maxWidth: 200,
        // },
        {
          key: "phonenumber",
          name: t("Phone Number"),
          onRender: (item: CompanyView) => item.phoneNumber,
          minWidth: 80,
          maxWidth: 200,
        },
        // {
        //   key: "websitelink",
        //   name: "Web Link",
        //   onRender: (item: CompanyView) => (
        //     <Flex gap="gap.medium">

        //       <Link to={item.websiteLink}>
        //         <a href="#/">{item.websiteLink}</a>

        //       </Link>
        //     </Flex>
        //   ),

        //   minWidth: 80,
        //   maxWidth: 200,
        // },
        // {
        //   key: "expireson",
        //   name: "License Expires On",
        //   onRender: (item: CompanyView) => item.licenseExpireOn,
        //   minWidth: 80,
        //   maxWidth: 200,
        // },
        {
          key: "status",
          name: t("Status"),
          onRender: (item: CompanyView) => t(item.status) + (item.trialDays? (" " + item.trialDays + " " + t("days left")) : ""),
          minWidth: 80,
          maxWidth: 200,
        },
        {
          key: "options",
          name: t("Options"),
          onRender: (item: CompanyView) => (
            <Flex gap="gap.medium">
              <TooltipHost content={t("Details")}>
                <Link to={`/companies/${item.id}`}>
                  <Button size="small" iconOnly icon={<MoreIcon />} />
                </Link>
              </TooltipHost>
              <TooltipHost content={t("Users")}>
                <Link to={`/companies/${item.id}/users`}>
                  <Button size="small" iconOnly icon={<UserFriendsIcon />} />
                </Link>
              </TooltipHost>
              <TooltipHost content={t("Archive")}>
                <Button size="small" iconOnly icon={<TrashCanIcon />} onClick={async () => {
                  if (window.confirm(t("Are you sure you want to delete this company?"))) {
                    await deleteCompany(item.id);
                    this.fetchData();
                  }
                }} />
              </TooltipHost>
              {
                item.organization && item.organization.name === "Trial Companies Organization" ? 
                <>
                <TooltipHost content={t("Extend trial period")}>
                  <Button size="small" iconOnly icon={<ShiftActivityIcon />} onClick={() => this.openExtendTrialDialog(item.id)} />
                </TooltipHost>
                <TooltipHost content={t("Activate")}>
                  <Button size="small" iconOnly icon={<ApprovalsAppbarIcon />} onClick={() => this.openActivateTrialDialog(item.id)} />
                </TooltipHost>
                </>
                : <></>
              }
            </Flex>
          ),
          minWidth: 120,
          maxWidth: 200,
        },
      ],
      extendTrailDialogOpen: false,
      extendTrialDays: 0,
      extendTrialCompanyId: 0,
      trialFilterValue: 'nonTrial',
      activateTrialCompanyId: 0,
      activateTrialCompanyOrganizaitonId: 0,
      activateTrailCompanyDialogOpen: false
    };

    this.state.columns.forEach((c) => c.onColumnClick = (_: any, column: IColumn) => {
      const newColumns: IColumn[] = this.state.columns.slice();
      const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newItems = copyAndSort(this.state.items, currColumn.key, currColumn.isSortedDescending);
      this.setState({ columns: newColumns, items: newItems });
    });
  }

  componentDidMount() {
    this.fetchData();
    
  }

  fetchData = async () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const companies = await getCompanies(id);
    const organizations = await GetOrganizations();
    this.setState({
      loading: false,
      companies: companies.payload,
      items: companies.payload,
      organizations: organizations.payload,
      nonTrialOrganizations: organizations.payload.filter(o => o.name !== "Trial Companies Organization"),
      trialFilterValue: 'nonTrial'
    });
    this.filterData(x => x.organization.name ?? "", "Trial Companies Organization", true)
  };


  filterData(callbackfn: (value: CompanyView) => string, value: string, doesNotInclude?: boolean ) {
    this.setState(state => {
      return {
        items: state.companies.filter(u => doesNotInclude ? !callbackfn(u).toLowerCase().includes(value.toLowerCase()) : callbackfn(u).toLowerCase().includes(value.toLowerCase()))
      }
    });

    if (value === "")
      this.setState({trialFilterValue: 'all'})
  }

  filterDataGlobal(value: string) {
    var valueLower = value.toLowerCase();
    this.setState(state => {
      return {
        items: state.companies.filter(u => u.name.toLowerCase().includes(valueLower)
          || u.country?.name?.toLowerCase()?.includes(valueLower)
          || u.address?.toLowerCase()?.includes(valueLower)
          || u.email?.toLowerCase()?.includes(valueLower)
          || u.organization?.name?.toLowerCase()?.includes(valueLower)
          || u.phoneNumber?.toLowerCase()?.includes(valueLower)
          || u.plan?.toLowerCase()?.includes(valueLower))
      }
    });

    if (value === "")
      this.setState({trialFilterValue: 'all'})
  }

  filterDataTrialNonTrial(value?: string | number) {
    switch(value){
      case 'trial':
        this.filterData(x => x.organization.name ?? "", "Trial Companies Organization")
        break;
      case 'nonTrial':
        this.filterData(x => x.organization.name ?? "", "Trial Companies Organization", true)
        break;
      case 'all':
        this.filterData(x => x.organization.name ?? "", "")
        break;
      default:
        this.filterData(x => x.organization.name ?? "", "Trial Companies Organization", true)
        break;
    }
  }

  openExtendTrialDialog = (companyId: number) => {
    this.setState({ extendTrialCompanyId: companyId, extendTrailDialogOpen: true});
  }

  openActivateTrialDialog = (companyId: number) => {
    this.setState({ activateTrialCompanyId: companyId, activateTrailCompanyDialogOpen: true});
  }

  submitExtendTrial = async () => {
    try{
      await extendTrial({days : this.state.extendTrialDays, companyId : this.state.extendTrialCompanyId});
      this.setState({extendTrailDialogOpen: false})
      this.fetchData()
    } catch (data){
      console.log("error: ", data);
    }
  }

  submitActivateTrial = async () => {
    try{
      await activateTrialCompany({companyId : this.state.activateTrialCompanyId, organizationId : this.state.activateTrialCompanyOrganizaitonId});
      this.setState({activateTrailCompanyDialogOpen: false})
      this.fetchData()
    } catch (data){
      console.log("error: ", data);
    }
  }

  render() {

    const {
      offset,
      columns,
      count,
      items,
      loading,
      showFilters
    } = this.state;

    const { t, user } = this.props;

    const dialogs = (
      <>
        <Dialog open={this.state.extendTrailDialogOpen}
            onCancel={() => this.setState({ extendTrailDialogOpen: false })}
            header={t("Extend Trial Period")}
            content={(
                <Flex column gap="gap.medium" hAlign="stretch"
                    styles={{ width: "100%" }}>
                    <TextField 
                        placeholder={t("Amount of days")}
                        type='number'
                        min={1}
                        onChange={(e, props) => this.setState((state) => ({extendTrialDays: parseInt(props?? '10')}))} 
                        />
                </Flex>
            )} confirmButton={t("Submit")}
            onConfirm={() => this.submitExtendTrial()}
            cancelButton={t("Cancel")} />

        <Dialog open={this.state.activateTrailCompanyDialogOpen}
            onCancel={() => this.setState({ activateTrailCompanyDialogOpen: false })}
            header={t("Activate Company")}
            content={(
                <Flex column gap="gap.medium" hAlign="stretch"
                    styles={{ width: "100%" }}>
                    <Text>{t("Choose Organization-")}</Text>
                    <Dropdown
                      options={this.state.nonTrialOrganizations.map(o => ({
                          key: o.id,
                          text: o.name
                        }))}
                      selectedKey={this.state.activateTrialCompanyOrganizaitonId}
                      onChange={(e, props) => this.setState({activateTrialCompanyOrganizaitonId : typeof props?.key == "number" ? props?.key ?? 0 : 0 })}
                    />
                </Flex>
            )} confirmButton={t("Submit")}
            onConfirm={() => this.submitActivateTrial()}
            cancelButton={t("Cancel")} />
      </>
    )

    return (
      <Flex column style={{ overflowX: "auto" }}>
        {dialogs}
        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" hAlign="center">
          <div
          className="mainHeader" style={{color: user.companyPrimaryColor?? "#005bab"}}
          >{t("Companies")}</div>
        </Flex>
        <Divider />
        <Flex >
          <Flex vAlign="center" styles={{padding: '0px 0px 0px 10px', width: '140px'}}>
            <Dropdown
              styles={{root: {width: '140px'}}}
              options={[
                {
                  key : 'nonTrial',
                  text : t("Normal"),
                },
                {
                  key : 'trial',
                  text : t("Trial")
                },
                {
                  key : 'all',
                  text : t("All")
                }
              ]}
              selectedKey={this.state.trialFilterValue}
              onChange={(ev, input) => {
                this.setState({trialFilterValue: input?.key})
                this.filterDataTrialNonTrial(input?.key)
              }}
            />
          </Flex>
          <Flex 
            style={{ width: '100%'}}
          >
            <CommandBar
              style={{width: '100%'}}
              items={[
                {
                  key: "search",
                  onRender: () => (
                    <Flex vAlign="center" styles={{ paddingRight: "10px" }}>
                      <SearchBox
                        placeholder={t("Search")}
                        iconProps={{styles: {root: {color: user.companyPrimaryColor}}}}
                        onChange={(ev, input) => this.filterDataGlobal(input ?? "")}
                      />
                    </Flex>
                  ),
                },
                {
                  key: "filters",
                  iconProps: { iconName: "filter" },
                  buttonStyles: {
                    icon: { color: user.companyPrimaryColor },
                    iconHovered: { color: shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) },
                  },
                  onClick: () => {
                    this.setState(state => ({ showFilters: !state.showFilters }));
                  },
                }
              ]}
              farItems={[
                {
                  key: "add.company",
                  name: t("Add Company"),
                  iconProps: { iconName: "add" },
                  buttonStyles: {
                    icon: { color: user.companyPrimaryColor },
                    iconHovered: { color: shadeBlend( -0.2 ,user.companyPrimaryColor, undefined) },
                  },
                  onClick: () => {
                    this.props.history.push("/companies/add");
                  },
                },
              ]} />
          </Flex>
        </Flex>
        <Divider />
        <FlexItem grow>
          <Flex column>
            {showFilters
              && (
                <Flex gap="gap.medium" padding="padding.medium">
                  <Input placeholder={t("Name")}
                    onChange={(ev, input) => this.filterData(x => x.name ?? "", input?.value ?? "")}
                  />
                  <Input placeholder={t("Country")}
                    onChange={(ev, input) => this.filterData(x => x.country.name ?? "", input?.value ?? "")}
                  />
                  {/* <Input placeholder="Filter by plan"
                    onChange={(ev, input) => this.filterData(x => x.plan ?? "", input?.value ?? "")}
                  /> */}
                </Flex>
              )}
            <ShimmeredDetailsList
              selectionMode={0}
              detailsListStyles={{ root: { width: "100vw", overflowY: "auto" } }}
              enableShimmer={loading}
              items={items.slice(offset, Math.min(offset + count, items.length))}
              columns={columns} />
            <div style={{ padding: "1em" }}>

              <Pagination
                currentPage={offset / count + 1}
                total={items.length}
                limit={count}
                onPageChange={(page) => this.setState({ offset: ((page ?? 1) - 1) * count })}>
                {({ pages, currentPage, totalPages, getPageItemProps }) => (
                  <div>
                    {pages.map((page) => (
                      <Button
                        key={page}
                        iconOnly
                        primary={currentPage === page}
                        {...getPageItemProps({
                          pageValue: page,
                          total: totalPages,
                          onClick: () => this.setState({ offset: ((page ?? 1) - 1) * count }),
                        }) as any}>
                        {page}
                      </Button>
                    ))}
                  </div>
                )}
              </Pagination>
            </div>
          </Flex>
        </FlexItem>
      </Flex>
    );
  }
}
export default withRouter(withTranslation()(Companies as any) as any);
