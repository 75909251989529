import { Notification } from "../models";
import moment from "moment";

export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getMonday(d: Date) {
  d = new Date(d);
  const day = d.getDay();
  const monday = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(monday));
}

export function getSunday(d: Date) {
  d = new Date(d);
  const day = d.getDay();
  const sunday = d.getDate() - day; // adjust when day is sunday
  return new Date(d.setDate(sunday));
}

export function getInitialDay(d: Date, isGCC: boolean = false) {
    return isGCC ? getSunday(d) : getMonday(d)
}

export function getYearMonthPickerFromMonth() {
  const yearMonthPickerFromMonth = new Date();
  yearMonthPickerFromMonth.setMonth(0);
  yearMonthPickerFromMonth.setFullYear(new Date().getFullYear() - 8);
  return yearMonthPickerFromMonth;
}

export function getYearMonthPickerToMonth() {
  const yearMonthPickerToMonth = new Date();
  yearMonthPickerToMonth.setMonth(12);
  yearMonthPickerToMonth.setFullYear(new Date().getFullYear() + 1);
  return yearMonthPickerToMonth;
}

export function getFormattedDuration(seconds: number) {
  const secs = (seconds) % 60 | 0;
  const minutes = (seconds / 60) % 60 | 0;
  const hours = (seconds / 3600) | 0;
  return `${hours.toString().padStart(2, "0")}`
    + `:${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
}

export function getDurationFormatedAgo(duration: string) {
  var res : any = moment.duration(duration).humanize();

  return res.toString();
}

export function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => {

    if (typeof (b[key]) === "undefined") {
      return !isSortedDescending ? 1 : -1;
    } if (typeof (a[key]) === "undefined") {
      return !isSortedDescending ? -1 : 1;
    }

    if (typeof (a[key]) === "string" && typeof (b[key]) === "string") {
      return !isSortedDescending
        ? (a[key] as unknown as string).localeCompare(b[key] as unknown as string)
        : (b[key] as unknown as string).localeCompare(a[key] as unknown as string);
    }
    return ((isSortedDescending
      ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1);
  });
}

export function isNullOrWhiteSpace(str?: string) {
  if (str) {
    str = str.replace(/^\s+/, '').replace(/\s+$/, '');
    return str === '';
  }
  return true;
}

export function createUrl(notification: Notification) {
  var url = notification.notificationUrl;
  if (!url)
    return undefined

  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }

  // switch (notification.notificationTypeId) {
  //   case NotificationTypeId.Timesheet:
  //     return `/timesheet/${url}`;
  // }
  return undefined;
}

// ref: https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
export function shadeBlend(p: any,c0: any,c1: any) {
  var n=p<0?p*-1:p,u=Math.round,w=parseInt;
  var f: any,t: any
  if(c0.length>7){
      f=c0.split(",")
      t=(c1?c1:p<0?"rgb(0,0,0)":"rgb(255,255,255)").split(",")
      var R=w(f[0].slice(4)),G=w(f[1]),B=w(f[2]);
      return "rgb("+(u((w(t[0].slice(4))-R)*n)+R)+","+(u((w(t[1])-G)*n)+G)+","+(u((w(t[2])-B)*n)+B)+")"
  }else{
      f=w(c0.slice(1),16)
      t=w((c1?c1:p<0?"#000000":"#FFFFFF").slice(1),16)
      // eslint-disable-next-line
      var R1=f>>16,G1=f>>8&0x00FF,B1=f&0x0000FF;
      // eslint-disable-next-line
      return "#"+(0x1000000+(u(((t>>16)-R1)*n)+R1)*0x10000+(u(((t>>8&0x00FF)-G1)*n)+G1)*0x100+(u(((t&0x0000FF)-B1)*n)+B1)).toString(16).slice(1)
  }
}

export function isLocInsideWorkplaces(currentLocation: any, workplaces: any[]) {
  var inside = false;
  workplaces.some(workplace => {
    inside = isMarkerInsidePolygon(currentLocation, workplace.bounds)
    return inside;
  })
  return inside;
}

export function isMarkerInsidePolygon(marker : any, poly: any) {
  var polyPoints = poly;       
  var x = marker.lat, y = marker.lng;

  var inside = false;
  for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
      var xj = polyPoints[j].lat, yj = polyPoints[j].lng;

      var intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }

  return inside;
};

export function lightOrDark(color : any) {

  // Variables for red, green, blue values
  var r, g, b, hsp;
  
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      
      r = color[1];
      g = color[2];
      b = color[3];
  } 
  else {
      
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace( 
      color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      // eslint-disable-next-line
      g = color >> 8 & 255;
      b = color & 255;
  }
  
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
  0.299 * (r * r) +
  0.587 * (g * g) +
  0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp>127.5) {

      return 'light';
  } 
  else {

      return 'dark';
  }
}