import { serialize } from "object-to-formdata";
import {
  LoginInput,
  Result,
  UserView,
  WeekScheduleView,
  PasswordResetInput,
  PasswordRecoveryInput,
  ProfileInput,
  ChangePasswordInput,
  SignUpInput,
  LoginResponse,
  LogoutInput,
  UpdateCustomStatusInput,
  UserRulesView,
  UserRulesInput,
  StatusHistory,
  LogView,
  LogDetailsInput,
  LogAttachmentInput,
  LogAttachmentView,
  LogAttachment,
  WeekScheduleInput,
  LabeledFaceDescriptorsInput
} from "../models";
import request from "../utils/request";

export async function login(input: LoginInput) {
  const response = await request.post<Result<LoginResponse>>("account/login", input);
  return response.data;
}

export async function logout() {
  const response = await request.post("account/Logout", { removeFcmToken : false });
  return response.data;
}

export async function refreshToken() {
  const response = await request.post<Result<LoginResponse>>("account/RefreshToken");
  return response.data;
}

export async function getUserInfo() {
  const response = await request.get<Result<UserView>>("account");
  return response.data;
}

export async function resetPassword(input: PasswordResetInput) {
  const response = await request.post<Result<boolean>>("account/password/reset", input);
  return response.data;
}

export async function recoverPassword(input: PasswordRecoveryInput) {
  const response = await request.post<Result<boolean>>("account/password/recovery", input);
  return response.data;
}

export async function updateProfile(input: ProfileInput, id?: string) {
  const response = await request
    .post<Result<UserView>>(id ? `account/${id}` : "account", serialize(input));
  return response.data;
}

export async function changePassword(input: ChangePasswordInput) {
  const response = await request.post<Result<boolean>>("account/password", input);
  return response.data;
}

export async function changeLocale(locale : string) {
  const response = await request.post<Result<boolean>>(`account/changeLocale/${locale}`);
  return response.data;
}

export async function getLocale() {
  const response = await request.get<Result<string>>("account/locale");
  return response.data;
}

export async function removeImage() {
  const response = await request.delete<Result<boolean>>("account/image");
  return response.data;
}

export async function GetUsers(companyId: String | number) {
  const response = await request.get<Result<UserView[]>>(`account/getusers/${companyId}`);
  return response.data;
}
export async function GetUsersPerDepartment(departmentId: String | number) {
  const response = await request.get<Result<UserView[]>>(`account/GetUsersPerDepartment/${departmentId}`);
  return response.data;
}
export async function GetUsersPerCountry(countryId: String | number) {
  const response = await request.get<Result<UserView[]>>(`account/GetUsersPerCountry/${countryId}`);
  return response.data;
}
export async function GetUsersPerBranch(branchId: String | number) {
  const response = await request.get<Result<UserView[]>>(`account/GetUsersPerBranch/${branchId}`);
  return response.data;
}
export async function GetAllUsers(companyId: number) {
  const response = await request.get<Result<UserView[]>>(`account/getallusers/${companyId}`);
  return response.data;
}
export async function getUser(id: string) {
  const response = await request.get<Result<UserView>>(`account/${id}`);
  return response.data;
}

export async function addUsers(users: SignUpInput[]) {
  const response = await request.post<Result<{}>>("account/addUsers", users);
  return response.data;
}

export async function deleteUser(id: string) {
  const response = await request.delete<Result<{}>>(`account/${id}`);
  return response.data;
}

export async function restoreUser(id: string) {
  const response = await request.put<Result<{}>>(`account/${id}`);
  return response.data;
}

export async function revokeAccess(id: string) {
  const response = await request.post<Result<{}>>(`account/RevokeAccess/${id}`);
  return response.data;
}

export async function getRoles() {
  const response = await request.get<Result<string[]>>(`account/roles`);
  return response.data;
}

export async function checkIsSupervisor(userId: string) {
  const response = await request.get<Result<boolean>>(`account/CheckIsSupervisor/${userId}`);
  return response.data;
}

export async function getDictionary(locale : string) {
  const response = await request.get<Result<string[]>>(`account/dictionary/${locale}`);
  return response.data;
}

export async function UpdateCustomStatus(input :UpdateCustomStatusInput ) {
  const response = await request.post<Result<UserView>>(`account/UpdateCustomStatus`,input);
  return response.data;
}

export async function getUserRules(userId: string) {
  const response = await request.get<Result<UserRulesView>>(`account/GetUserRules/${userId}`);
  return response.data;
}

export async function updateUserRules(userId: string, input: UserRulesInput) {
  const response = await request.post<Result<UserRulesView>>(`account/UpdateUserRules/${userId}`, input);
  return response.data;
}

export async function GetStatusHistory() {
  const response = await request.get<Result<StatusHistory[]>>(`account/GetStatusHistory`);
  return response.data;
}

export async function Log(details : LogDetailsInput )
{
  // const response = await request.post<Result<Logview>>(`account/Log`,details);
  // return response.data;
  var formData = new FormData();
  formData.append('message', details.message.toString())
  formData.append('logType', details.logType.toString())
  for (var i = 0; i < details.attachments.length; i++)
    formData.append('attachments', details.attachments[i])
  const response = await request.post<Result<LogView>>(`account/Log`,
    formData);
  return response.data;

}

export async function AddLogAttachment(details : LogAttachmentInput )
{
  // const response = await request.post<Result<LogAttachmentView[]>>(`account/AddLogAttachment`,details);
  // return response.data;
  var formData = new FormData();
  formData.append('logId', details.logId.toString())
  for (var i = 0; i < details.attachments.length; i++)
    formData.append('attachments', details.attachments[i])
  const response = await request.post<Result<LogAttachmentView[]>>(`account/AddLogAttachment`,
    formData);
  return response.data;
}


export async function setWeekSchedule(details: WeekScheduleInput) {
  const response = await request.post<Result<WeekScheduleView>>(`account/SetWeekSchedule`,details);
  return response.data;
}

export async function getWeekSchedule(userId: string ) {
  const response = await request.get<Result<WeekScheduleView>>(`account/GetWeekSchedule/${userId}`);
  return response.data;
}

export async function getTimeSheetWeekSchedule(userId: string ) {
  const response = await request.get<Result<WeekScheduleView>>(`account/GetTimeSheetWeekSchedule/${userId}`);
  return response.data;
}
export async function addLabeledFaceDescriptors(input: LabeledFaceDescriptorsInput ) {
  const response = await request.post<Result<UserView>>(`account/AddLabeledFaceDescriptors`,input);
  return response.data;
}




